import axios from './axios';

export function create (contractUuid) {
  return axios.post(
    `/api/v1/contracts/${contractUuid}/redirect-tokens`,
  );
}

export default {
  create,
};
