<script setup lang="ts">
import { useConfirmDialog } from '@vueuse/core';

import { defineAsyncComponent } from 'vue';
import { useRouter } from 'vue-router';
import { removePersistedData } from '@/js/utils';
import LitButton from '@/js/components/Base/LitButton.vue';

const props = defineProps<Props>();

const LitModal = defineAsyncComponent(() => import('@/js/components/Base/LitModal.vue'));

const { confirm, onConfirm } = useConfirmDialog();

interface Props {
  routeName: string
  storageKey: 'distribution'
  isDistributionNameMissing: boolean
}

const router = useRouter();

onConfirm(() => {
  removePersistedData(props.storageKey);
  router.push({ name: props.routeName });
});
</script>

<template>
  <LitModal
    :model-value="isDistributionNameMissing"
    :max-width="480"
  >
    <template #header>
      <h3>
        Zvolte distribuční síť
      </h3>
    </template>

    <template #body>
      <p class="text-center">
        Kliknutím na tlačítko se dostanete na výběr distribucí.
        Bez této volby nelze pokračovat dál.
        Vaše volba bude uložena.
      </p>
    </template>

    <template #footer>
      <div class="flex flex-center content-center">
        <LitButton
          size="sm"
          @click="confirm"
        >
          Vybrat distribuci
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>
