<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import { get } from 'lodash';
import { getActivePinia } from 'pinia';
import { computed, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';

const props = withDefaults(defineProps<{
  namespace: string
  fieldPath: string
  validationPath?: string | null
  debounce?: number
  defaultValue?: string | number | boolean | object | null
  validateOnInput?: boolean
}>(), {
  validationPath: null,
  debounce: 300,
  defaultValue: null,
  validateOnInput: false,
});

const emit = defineEmits<{
  validate: [{
    value: unknown
    pathToValidate: string | undefined
    fieldPath: string
    contractUuid: string | string[]
    urlPath: string
  }]
}>();

const route = useRoute();

const field = computed(() => {
  const store = getActivePinia();

  if (!store) {
    throw new Error('Pinia store is not active');
  }

  return get(store.state.value, `${props.namespace}.${props.fieldPath}`);
});

const value = computed(() => {
  return field.value?.value;
});

const errors = computed<Array<string>>(() => {
  return field.value?.errors || [];
});

const readOnly = computed(() => {
  return field.value?.readOnly;
});

const setValue = useDebounceFn((value: unknown, skipValidation = false) => {
  field.value.value = value;

  if (props.validateOnInput && !skipValidation) {
    handleValidation(value);
  }
}, props.debounce || 0);

function handleInput<T> (value: T) {
  setValue(value);
}

async function handleValidation (value: unknown, pathToValidate: null | string = null) {
  emit('validate', {
    value,
    pathToValidate: pathToValidate || props.validationPath || undefined,
    fieldPath: props.fieldPath,
    contractUuid: route.params.contractUuid,
    urlPath: route.path,
  });
}

onBeforeMount(() => {
  if (value.value === null) {
    // not debounce here, because we want to set the value immediately
    field.value.value = props.defaultValue;
  }
});
</script>

<template>
  <div dusk="server-validation">
    <slot
      :value="value"
      :errors="errors"
      :read-only="readOnly"
      :input="handleInput"
      :validate="handleValidation"
    />
  </div>
</template>
