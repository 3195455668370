<template>
  <div>
    <LitModal
      :model-value="modelValue"
      :closeable="false"
      class="modal--no-header modal--no-footer"
    >
      <template #body>
        <div class="flex flex-center mb-20 flip">
          <img
            :src="getIconUrl('icon-loader_blue')"
            alt="Načítání..."
            class="spin"
          >
        </div>

        <h3 class="text-loading text-center text-bold">
          {{ message }}
        </h3>

        <slot name="action" />
      </template>
    </LitModal>
  </div>
</template>

<script lang="ts">
import { getIconUrl } from '../../utils.ts';
import LitModal from '../Base/LitModal.vue';

const CONTRACT_PROCESSING_PRINT = 'Dochází ke zpracování vaší smlouvy ...';
const REQUEST_PROCESSING_PRINT = 'Dochází ke zpracování vaší žádosti ...';
const LITSIGN_REDIRECT = 'Za okamžik budete přesměrováni k elektronickému podpisu dokumentů.';
const BANKID_REDIRECT = 'Za okamžik budete přesměrováni k podpisu přes Bank iD.';

export default {
  components: {
    LitModal,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    modelValue: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: null,
      validator (val: string) {
        return ['online', 'distribution', 'termination', 'transfer', 'jps', 'changeRequest'].includes(val);
      },
    },

    isDigital: {
      type: Boolean,
      default: false,
    },

    isBankId: {
      type: Boolean,
      default: false,
    },
  },

  setup () {
    return {
      getIconUrl,
    };
  },

  computed: {
    text () {
      return {
        online: {
          print: CONTRACT_PROCESSING_PRINT,
          bankId: BANKID_REDIRECT,
        },
        distribution: {
          print: CONTRACT_PROCESSING_PRINT,
          digital: LITSIGN_REDIRECT,
        },
        termination: {
          print: REQUEST_PROCESSING_PRINT,
          digital: LITSIGN_REDIRECT,
          bankId: BANKID_REDIRECT,
        },
        transfer: {
          print: REQUEST_PROCESSING_PRINT,
          digital: LITSIGN_REDIRECT,
        },
        jps: {
          print: REQUEST_PROCESSING_PRINT,
          digital: LITSIGN_REDIRECT,
          bankId: BANKID_REDIRECT,
        },
        changeRequest: {
          print: REQUEST_PROCESSING_PRINT,
          digital: LITSIGN_REDIRECT,
        },
      };
    },

    message () {
      if (this.type === null) {
        return this.title;
      }

      let textType;

      switch (true) {
        case this.isDigital:
          textType = 'digital';
          break;
        case this.isBankId:
          textType = 'bankId';
          break;
        default:
          textType = 'print';
          break;
      }

      // @ts-expect-error: TODO: Fix this
      return this.text[this.type][textType];
    },
  },
};
</script>
