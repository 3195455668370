import * as ContractNumberGenerator from '../views/contract-number-generator';

const generatedNumbers = {
  path: '/generovani-cisla-smlouvy',
  component: ContractNumberGenerator.Layout,
  children: [
    {
      path: 'udaje-distributora',
      name: 'contractNumberGenerator.distributorInformation',
      component: ContractNumberGenerator.DistributorInformation,
    },
  ],
};

export default generatedNumbers;
