<script lang="ts" setup>
import type { RouterLinkProps } from 'vue-router';
import { useAttrs } from 'vue';

export type Props = {
  as?: string
  type?: string
  disabled?: boolean | null | string
} & RouterLinkProps;

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
  type: 'button',
  disabled: false,
});

const attrs = useAttrs();
</script>

<template>
  <component
    :is="as"
    v-if="!to"
    v-bind="{ ...attrs, ...props }"
  >
    <slot />
  </component>
  <RouterLink
    v-else
    v-bind="{ ...attrs, ...props }"
  >
    <slot />
  </RouterLink>
</template>
