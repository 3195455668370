<template>
  <LitModal
    :model-value="showModal"
    dusk="inactivity-modal"
    class="z-index-1002"
    @update:model-value="confirmUserActivity"
  >
    <template #header>
      <h3 class="text-center">
        {{ headerText }}
      </h3>
    </template>

    <template #body>
      <div class="text-center">
        <p>{{ bodyText }}</p>
      </div>
    </template>
    <template #footer>
      <div class="text-center">
        <LitButton
          @click="confirmUserActivity"
        >
          {{ buttonText }}
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { addYears } from 'date-fns';
import { get } from 'lodash';
import { getActivePinia } from 'pinia';
import { computed, ref } from 'vue';
import { contracts } from '@/js/api';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitModal from '@/js/components/Base/LitModal.vue';
import { syncedStoreField } from '@/js/stores/utils';

// Initially we do not know the session expiration time.
// To avoid working with null, we set it to very distant future.
// Hope this code won't be used in 42 000 years anymore.
const initialExpiration = addYears(Date.now(), 42000);

export default {
  components: {
    LitModal,
    LitButton,
  },

  props: {
    contractType: {
      type: String as PropType<'online' | 'distribution' | 'transfer' | 'termination' | 'jps' | 'changeRequest'>,
      required: true,
    },

    forbiddenRouteNames: {
      type: Array,
      require: false,
      default: () => ([]),
    },

    contractUuid: {
      type: String,
      required: true,
    },

    warnBefore: {
      type: Number,
      required: false,
      default: 60 * 2, // in seconds
    },

    headerText: {
      type: String,
      required: false,
      default: 'Jste tu ještě?',
    },

    bodyText: {
      type: String,
      required: false,
      default: 'Časový limit pro vyplnění smlouvy téměř uplynul.\n'
        + 'Pokud chcete smlouvu dokončit, klikněte na tlačítko Pokračovat.',
    },

    buttonText: {
      type: String,
      required: false,
      default: 'POKRAČOVAT',
    },
  },

  setup (props) {
    const activeStore = computed(() => {
      const store = getActivePinia();

      if (!store) {
        throw new Error('Pinia store is not active');
      }

      return get(store.state.value, `${props.contractType}`);
    });

    const submitResponse = computed({
      ...syncedStoreField(activeStore.value, {
        path: 'submitResponse.value',
      }),
    });

    const showModal = ref(false);
    const expiresAt = ref(initialExpiration.getTime());
    const now = ref(Date.now());
    const clockInterval = ref<number | null>(null);
    const modalTimer = ref<number | null>(null);
    const isThankYouPage = ref(false);

    return {
      submitResponse,
      showModal,
      expiresAt,
      now,
      clockInterval,
      modalTimer,
      isThankYouPage,
    };
  },

  data: () => ({

  }),

  computed: {
    secondsToExpire () {
      return Math.round((this.expiresAt - this.now) / 1000);
    },

    route () {
      return this.$route.name;
    },

    disabledRoute () {
      return this.forbiddenRouteNames.includes(this.$route.name);
    },
  },

  watch: {
    route (val) {
      if (this.forbiddenRouteNames.includes(val) || this.submitResponse !== null) {
        this.showModal = false;
        this.clearTimers();
      }
    },

    submitResponse (val) {
      if (val !== null) {
        this.showModal = false;
        this.clearTimers();
      }
    },

    secondsToExpire (val) {
      if (val < 0) {
        this.redirectToExpirationPage();
      } else if (val < this.warnBefore) {
        this.showModal = true;
      }
    },
  },

  mounted () {
    if (!this.disabledRoute || this.submitResponse == null) {
      this.startClock(5000);
      this.loadExpirationTime().then(this.setModalTimer);
    } else {
      this.showModal = false;
      this.clearTimers();
    }
  },

  unmounted () {
    this.clearTimers();
  },

  methods: {
    async confirmUserActivity () {
      try {
        await contracts.update(this.contractUuid, {
          touch: true,
          validateOnly: [],
        });

        await this.loadExpirationTime();
        this.setModalTimer();

        this.showModal = false;
      } catch (e) {
        console.warn(e);

        this.showModal = false;
        this.clearTimers();
      }

      this.showModal = false;
    },

    startClock (tickLength = 1000) {
      this.clockInterval = setInterval(() => {
        this.now = Date.now();
      }, tickLength);
    },

    clearTimers () {
      if (this.clockInterval !== null) {
        clearInterval(this.clockInterval);
      }

      if (this.modalTimer !== null) {
        clearTimeout(this.modalTimer);
      }
    },

    async loadExpirationTime () {
      try {
        if (this.disabledRoute) {
          return;
        }

        const { headers } = await contracts.show(this.contractUuid, this.contractType);
        this.expiresAt = new Date(headers['x-contract-expires-at']!).getTime();
      } catch (e) {
        console.warn(e);

        this.showModal = false;
        this.clearTimers();
      }
    },

    setModalTimer () {
      // Number of seconds we want to reload contract expiration time before the modal pops up.
      // This makes sure modal won't be shown if there was an activity with the contract.
      const diff = 10;

      if (this.disabledRoute) {
        return;
      }

      if (this.secondsToExpire + diff > this.warnBefore) {
        const timeout = this.secondsToExpire - this.warnBefore - diff;

        if (this.modalTimer !== null) {
          clearTimeout(this.modalTimer);
        }

        if (timeout > 0) {
          this.modalTimer = setTimeout(() => {
            this.loadExpirationTime().then(this.setModalTimer);
          }, timeout * 1000);
        }
      }
    },

    redirectToExpirationPage () {
      const dict = {
        online: 'onlineAgreement',
      };

      if (this.showModal) {
        this.$router.push({
          name: 'sessionExpired',
          query: {
            type: dict[this.contractType as keyof typeof dict] || this.contractType,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.z-index-1002 {
  z-index: 1002;
}
</style>
