<template>
  <div dusk="transfer-confirmation">
    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm" />
    </div>

    <div class="container--sm mt-20">
      <h2>Doplňující údaje</h2>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        namespace="transfer"
        field-path="newContract.contractNumber"
        @validate="validateField"
      >
        <LitInput
          class="mb-20"
          label="Číslo nové smlouvy (zkontrolujte správné číslo smlouvy)"
          name="new-contract-number"
          dusk="new-contract-number"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <hr class="my-30">
    </div>

    <div class="container--sm mt-30 mb-30">
      <StepButtons
        namespace="transfer"
        previous-step="transferConfirmation"
        @submit="submitStep"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import StepButtons from '../../common/StepButtons.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import gtm from '@/js/services/gtm';
import { useTransferStore } from '@/js/stores';

import { scrollToError } from '@/js/utils';

const route = useRoute();
const router = useRouter();

const store = useTransferStore();
const { validateField, validateFields } = store;
const { newContract } = storeToRefs(store);

const validating = ref(false);

const hasAlreadyNewContract = computed({
  get () {
    return newContract.value.hasAlreadyNewContract.value;
  },
  set (value) {
    newContract.value.hasAlreadyNewContract.value = value;
  },
});

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'newContract.contractNumber',
      ],
    });

    validating.value = false;

    await router.push({
      name: 'transfer.transferConfirmation',
      params: {
        contractUuid: route.params.contractUuid,
      },
      query: { contract: hasAlreadyNewContract.value.toString() },
    });

    gtm.onStepSubmit('doplnujici-udaje');
  } catch (e) {
    validating.value = false;

    scrollToError();

    console.warn(`There was a validation newContractTransferConfirmationPageError: ${e}`);
  }
}

function setCurrentContractNumber () {
  hasAlreadyNewContract.value = true;
}

onMounted(() => {
  setCurrentContractNumber();
});
</script>
