export default [
  { value: 'choose', label: '-- vyberte --' },
  { value: 'allianz', label: 'Allianz penzijní společnost' },
  { value: 'conseq', label: 'Conseq penzijní společnost' },
  { value: 'generali', label: 'Generali penzijní společnost' },
  { value: 'kb', label: 'KB Penzijní společnost' },
  { value: 'nn', label: 'NN Penzijní společnost' },
  { value: 'csas', label: 'Penzijní společnost České spořitelny' },
  { value: 'rentea', label: 'Rentea penzijní společnost' },
  { value: 'uniqa', label: 'UNIQA penzijní společnost' },
];
