<script lang="ts">
import { storeToRefs } from 'pinia';
import { type NavigationGuardNext, type RouteLocationNormalized, useRoute } from 'vue-router';
import { accessTokens, contracts } from '@/js/api';
import InactivityModal from '@/js/components/Modals/InactivityModal.vue';

import { useGeneralStore } from '@/js/stores';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';

import { restoreState } from '@/js/stores/utils';
import { getIconUrl, getPersistedData, persistContractMetadata } from '@/js/utils';
import TheFooter from '@/js/views/TheFooter.vue';
import TheHeader from '@/js/views/TheHeader.vue';

type StatusResponse = 404 | 500;

interface Heading {
  contractSettings: string
  personalData: string
  emailAndConsents: string
  strategy: string
  nominees: string
  legalRequirements: string
  identityDocument: string
}

export default {
  components: {
    InactivityModal,
    TheHeader,
    TheFooter,
  },

  async beforeRouteUpdate (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { contractUuid } = to.params;

    // This method on every update checks whether the
    // contract exists/is available.

    // We do not want to do that after the last step
    // when the contract is submitted/signed and becomes unavailable.
    // Therefor we skip the validation for the thankYou page.
    if (to.name === 'changeRequest.thankYouPage') {
      next();
      return;
    }

    try {
      await contracts.show(contractUuid as string, 'changeRequest');
      next();
    } catch (e: any) {
      const status = e?.response?.status as StatusResponse;

      const pages = {
        404: 'notFound',
        500: 'internalError',
      };

      const name = pages[status] || 'error';
      const type = (to.name as string).split('.')[0];

      next({
        name,
        query: {
          type,
        },
      });
    }
  },

  async beforeRouteEnter (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { contractUuid } = to.params;
    const { draftToken, redirectToken } = to.query;

    try {
      if (typeof redirectToken !== 'undefined' || typeof draftToken !== 'undefined') {
        const tokenName = typeof redirectToken !== 'undefined' ? 'redirectToken' : 'draftToken';
        const tokenValue = typeof redirectToken !== 'undefined' ? redirectToken : draftToken;

        const { data } = await accessTokens.create(
          contractUuid,
          {
            name: tokenName,
            value: tokenValue,
          },
        );

        persistContractMetadata({
          accessTokenValue: data.token,
          accessTokenValidTo: data.valid_to,
          contractUuid,
          contractType: 'changeRequest',
        });
      }

      const { data } = await contracts.show(contractUuid as string, 'changeRequest');

      if (data.form_data) {
        const formData = JSON.parse(data.form_data);

        next((vm: any) => {
          restoreState(vm.store.$state, formData);
        });
      } else {
        next((vm: any) => {
          vm.rehydrated.value = true;
        });
      }
    } catch (e: any) {
      const status = e?.response?.status as StatusResponse;

      const pages = {
        404: 'notFound',
        500: 'internalError',
      };

      const name = pages[status] || 'error';
      const type = (to.name as string).split('.')[0];

      next({
        name,
        query: {
          type,
        },
      });
    }
  },

  setup () {
    const generalStore = useGeneralStore();
    const store = useChangeRequestStore();
    const { rehydrated } = storeToRefs(store);
    const route = useRoute();

    function setDataFromLocalStorage () {
      const generalStoreData = getPersistedData('distribution');

      if (generalStoreData !== null) {
        const data = JSON.parse(generalStoreData);

        generalStore.isTiedAgent.value = data.isTiedAgent;
        generalStore.distribution.value = data.distribution;
      }
    }

    setDataFromLocalStorage();

    return {
      getIconUrl,
      rehydrated,
      store,
      route,
    };
  },

  data: () => ({
    headings: {
      contractSettings: 'Úprava nastavení smlouvy',
      personalData: 'Úprava osobních a kontaktních údajů',
      emailAndConsents: 'Úprava e-mailu a souhlasů',
      strategy: 'Úprava strategie',
      nominees: 'Úprava určených osob',
      legalRequirements: 'Úprava zákonných požadavků',
      identityDocument: 'Úprava dokladu totožnosti',
    } as Heading,
  }),

  computed: {
    setHeading () {
      const form = this.store.changeSettings.involvedForms.value[0] as keyof Heading;

      return this.headings[form];
    },
  },
};
</script>

<template>
  <div class="body">
    <TheHeader>
      <template #heading>
        <h1>
          {{ setHeading }}
        </h1>
      </template>

      <template #header_contact_phone>
        <a
          href="tel:+420224116655"
        >
          <img
            :src="getIconUrl('phone')"
            alt="Icon tel."
          ><span>224 116 655</span>
        </a>
      </template>
    </TheHeader>

    <InactivityModal
      contract-type="changeRequest"
      :contract-uuid="$route.params.contractUuid as string"
      :forbidden-route-names="[
        'changeRequest.thankRedirectPage',
        'changeRequest.thankYouPage',
        'changeRequest.thankYouSignaturePage'
      ]"
    />

    <main v-if="rehydrated.value">
      <RouterView />
    </main>

    <TheFooter />
  </div>
</template>
