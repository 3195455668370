<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AddressEntry from '@/js/components/AddressEntry.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';
import PhonePrefixSelect from '@/js/components/PhonePrefixSelect.vue';

import { useDateRange } from '@/js/composables/useDateRange.ts';
import gtm from '@/js/services/gtm';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import { scrollToError } from '@/js/utils.ts';
import StepButtons from '@/js/views/common/StepButtons.vue';

const store = useChangeRequestStore();
const { validateField, validateFields } = store;

const route = useRoute();
const router = useRouter();

const showFormsOfferModal = ref(false);
const validating = ref(false);

const { personalData } = storeToRefs(store);

const firstName = computed(() => {
  return personalData.value.firstName.value;
});

const lastName = computed(() => {
  return personalData.value.lastName.value;
});

const phonePrefix = computed(() => {
  return personalData.value.phonePrefix.value;
});

const phoneNumber = computed(() => {
  return personalData.value.phoneNumber.value;
});

const permanentAddressQuery = computed(() => {
  return personalData.value.permanentAddress.query.value;
});

const mailingAddressQuery = computed(() => {
  return personalData.value.mailingAddress.query.value;
});

function offerForms () {
  showFormsOfferModal.value = true;
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'personalData.firstName',
        'personalData.lastName',
        'personalData.phonePrefix',
        'personalData.phoneNumber',

        'personalData.permanentAddress.street',
        'personalData.permanentAddress.streetNumber',
        'personalData.permanentAddress.city',
        'personalData.permanentAddress.zip',
        'personalData.permanentAddress.country',

        'personalData.mailingAddress.street',
        'personalData.mailingAddress.streetNumber',
        'personalData.mailingAddress.city',
        'personalData.mailingAddress.zip',
        'personalData.mailingAddress.country',

        'changeSettings.involvedForms',
      ],
    });

    await router.push({
      name: 'changeRequest.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('osobni-udaje');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation clientsStatementError: ${e}`);
  } finally {
    validating.value = false;
  }
}

function resetAddress (addressType: string) {
  if (addressType === 'permanent') {
    personalData.value.permanentAddress.city.value = null;
    personalData.value.permanentAddress.street.value = null;
    personalData.value.permanentAddress.streetNumber.value = null;
    personalData.value.permanentAddress.zip.value = null;
    personalData.value.permanentAddress.country.value = null;
  } else {
    personalData.value.mailingAddress.city.value = null;
    personalData.value.mailingAddress.street.value = null;
    personalData.value.mailingAddress.streetNumber.value = null;
    personalData.value.mailingAddress.zip.value = null;
    personalData.value.mailingAddress.country.value = null;
  }
}

watch(firstName, (newVal) => {
  if (newVal !== null || newVal !== '') {
    personalData.value.changeRequired.value = true;
  }
});

watch(lastName, (newVal) => {
  if (newVal !== null || newVal !== '') {
    personalData.value.changeRequired.value = true;
  }
});

watch(phonePrefix, (newVal) => {
  if (newVal !== null || newVal !== '') {
    personalData.value.changeRequired.value = true;
  }
});

watch(phoneNumber, (newVal) => {
  if (newVal !== null || newVal !== '') {
    personalData.value.changeRequired.value = true;
  }
});

watch(permanentAddressQuery, (newVal) => {
  if (newVal !== null || newVal !== '') {
    personalData.value.changeRequired.value = true;
  } else {
    resetAddress('permanent');
  }
});

watch(mailingAddressQuery, (newVal) => {
  if (newVal !== null || newVal !== '') {
    personalData.value.changeRequired.value = true;
  } else {
    resetAddress('mailing');
  }
});
</script>

<template>
  <div class="container--sm">
    <h3>Osobní a kontaktní údaje</h3>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate, readOnly }"
      namespace="changeRequest"
      field-path="personalData.firstName"
      :debounce="0"
      @validate="validateField"
    >
      <LitInput
        class="mb-20"
        name="given-name"
        dusk="first-name"
        placeholder="Např. Karel"
        label="Jméno"
        :disabled="readOnly"
        :read-only="readOnly"
        :model-value="value"
        :errors="errors"
        :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate, readOnly }"
      namespace="changeRequest"
      field-path="personalData.lastName"
      :debounce="0"
      @validate="validateField"
    >
      <LitInput
        class="mb-20"
        name="family-name"
        dusk="last-name"
        placeholder="Např. Novák"
        label="Příjmení"
        :disabled="readOnly"
        :read-only="readOnly"
        :model-value="value"
        :errors="errors"
        :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>

    <div class="phone mb-20">
      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="changeRequest"
        field-path="personalData.phonePrefix"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateField"
      >
        <PhonePrefixSelect
          dusk="phone-prefix"
          label="Telefonní číslo"
          :errors="errors"
          :model-value="value"
          :preselect-first="true"
          :searchable="true"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        namespace="changeRequest"
        field-path="personalData.phoneNumber"
        :debounce="0"
        @validate="validateField"
      >
        <LitInput
          class="control__number"
          type="tel"
          name="tel"
          :model-value="value"
          :errors="errors"
          dusk="mobile"
          placeholder="Např. 777888999"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>
    </div>

    <h3 class="mt-50">
      Trvalá a korespondenční adresa
    </h3>

    <AddressEntry
      store-path="personalData.permanentAddress"
      namespace="changeRequest"
      name="permanent-address"
      dusk="permanent-address"
      label="Trvalá adresa"
      :validate-fnc="validateField"
    />

    <AddressEntry
      label="Korespondenční adresa"
      name="mailing-address"
      dusk="mailing-address"
      namespace="changeRequest"
      store-path="personalData.mailingAddress"
      :validate-fnc="validateField"
    />

    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      namespace="changeRequest"
      field-path="personalData.effectiveDate"
      :validate-on-input="true"
      :debounce="0"
      @validate="validateField"
    >
      <LitSelect
        class="mb-20 mt-30"
        dusk="optimalization-start-date"
        label="Datum účinnosti úpravy osobních a kontaktních údajů"
        :options="useDateRange(3)"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <hr class="separator my-30">

    <div class="flex flex-between">
      <StepButtons
        namespace="changeRequest"
        previous-step="clientData"
        @submit="offerForms"
      />
    </div>

    <ChangeRequestFormsOfferModal
      v-model="showFormsOfferModal"
      :forms="store.changeSettings.involvedForms.value"
      @close="showFormsOfferModal = false"
      @finish="handleSubmit"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.phone {
  display: flex;
  max-width: 517px;

  @include media(max, $sm) {
    display: block;
  }
}
</style>
