<template>
  <div :dusk="dusk">
    <LitAlert
      v-if="showAlert === true"
      class="mb-20"
    >
      Vyplňte adresu trvalého pobytu uvedenou v občanském průkazu.
      Postupným psaním názvu ulice, čísla popisného a PSČ
      si můžete ze seznamu vybrat potřebnou adresu.
      Případně můžete přepnout na ruční zadání adresy.
    </LitAlert>

    <LitInput
      ref="queryRef"
      v-model="syncQuery"
      class="mb-20"
      :autocomplete="autocomplete"
      :input-class="`smartform-instance-${name} smartform-address-whole-address`"
      :label="label"
      :name="name"
      :show-append="true"
      :tooltip="tooltip"
      :editable="syncEditable"
      :description="description"
      :disabled="disabled"
    >
      <template #actions>
        <div
          v-if="showActionButtons"
          class="actions-group"
        >
          <button
            v-if="syncEditable"
            type="button"
            dusk="set-manually"
            class="btn btn-simple"
            @click="$emit('setAddressManually')"
          >
            Zadat ručně
          </button>

          <button
            v-else
            type="button"
            class="btn btn-simple"
            dusk="change-address"
            @click="syncEditable = true"
          >
            <i>
              <img
                :src="getIconUrl('icon-edit')"
                alt="Ikona upravit"
              >
            </i>
            Změnit adresu
          </button>

          <slot name="actions" />
        </div>
      </template>
    </LitInput>

    <input
      ref="streetRef"
      type="text"
      :class="`hidden smartform-instance-${name} smartform-address-street`"
      tabindex="-1"
    >

    <input
      ref="streetNumberRef"
      type="text"
      :class="`hidden smartform-instance-${name} smartform-address-number`"
      tabindex="-1"
    >

    <input
      ref="cityRef"
      type="text"
      :class="`hidden smartform-instance-${name} smartform-address-city`"
      tabindex="-1"
    >

    <input
      ref="zipRef"
      type="text"
      :class="`hidden smartform-instance-${name} smartform-address-zip`"
      tabindex="-1"
    >
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed, onMounted, ref } from 'vue';

import { getIconUrl, removeCharsFromString } from '../utils';
import LitAlert from './Base/LitAlert.vue';
import LitInput from './Base/LitInput.vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },

  label: {
    type: String,
    default: '',
  },

  name: {
    type: String,
    default: '',
  },

  tooltip: {
    type: String,
    default: '',
  },

  dusk: {
    type: String,
    default: '',
  },

  description: {
    type: String,
    default: '',
  },

  query: {
    type: String as PropType<string | null>,
    default: '',
  },

  editable: {
    type: Boolean,
    default: null,
  },

  autocomplete: {
    type: String,
    default: 'off',
  },

  showAlert: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  showCountryInPrediction: {
    type: Boolean,
    default: false,
  },

  showActionButtons: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'update:query',
  'update:editable',
  'setAddressManually',
  'placeDetailLoaded',
]);

const intQuery = ref('');
const intEditable = ref(true);

const queryRef = ref();
const streetRef = ref<HTMLInputElement>();
const streetNumberRef = ref<HTMLInputElement>();
const cityRef = ref<HTMLInputElement>();
const zipRef = ref<HTMLInputElement>();

computed({
  get () {
    return props.modelValue;
  },

  set (val: unknown) {
    emit('update:modelValue', val);
  },
});

const syncQuery = computed({
  get () {
    if (props.query !== null) {
      return props.query;
    }

    return intQuery.value;
  },

  set (val: string) {
    if (props.query === null) {
      intQuery.value = val;
    }

    emit('update:query', val);
  },
});

const syncEditable = computed({
  get () {
    if (props.editable !== null) {
      return props.editable;
    }

    return intEditable.value;
  },

  set (val: boolean) {
    if (props.editable !== null && props.editable !== val) {
      emit('update:editable', val);
    } else {
      intEditable.value = val;
    }
  },
});

function emitAddressDetail () {
  emit('placeDetailLoaded', {
    street: streetRef.value!.value,
    city: cityRef.value!.value,
    streetNumber: removeCharsFromString(streetNumberRef.value!.value, ['ev.č.', 'č.p.', 'č.ev.']),
    zip: zipRef.value!.value,
    country: 'Česká republika',
    countryCode: 'cz',
  });

  syncQuery.value = queryRef.value?.inputRef.value;
  syncEditable.value = false;

  streetRef.value!.value = '';
  streetNumberRef.value!.value = '';
  cityRef.value!.value = '';
  zipRef.value!.value = '';

  getSmartformInstance()
    ?.clearSuggestContext();
}

function getSmartformInstance () {
  const ids = window.smartform?.getInstanceIds();

  const currentId = ids?.find((id: string) => id.includes(props.name));

  if (!currentId) {
    return null;
  }

  return window.smartform?.getInstance(currentId).addressControl;
}

onMounted(() => {
  if (typeof window?.smartform?.rebindAllForms === 'function') {
    window.smartform.rebindAllForms();
  }

  const zipDescriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(zipRef.value), 'value');

  const debouncedOnChange = () => {
    emitAddressDetail();
  };

  Object.defineProperty(zipRef.value, 'value', {
    set (...params) {
      // TODO, this is not pretty
      // but otherwise we need to listen for all input changes and
      // emit ony once (debounce)
      if (params[0]) {
        setTimeout(debouncedOnChange, 300);
      }

      return zipDescriptor?.set?.apply(this, params);
    },
    get () {
      return zipDescriptor?.get?.apply(this);
    },
  });
});
</script>
