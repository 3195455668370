import type { ComputedRef } from 'vue';
import { isNull } from 'lodash';
import { ref, toValue, watchEffect } from 'vue';
import academicTitles from '@/js/data/academicTitles.ts';

export const useCheckTitleInName = (name: string | ComputedRef, errorMessage: string = 'jméno nesmí obsahovat titul') => {
  const nameWarning = ref<string>('');

  const check = () => {
    if (isNull(toValue(name))) {
      nameWarning.value = '';

      return;
    }

    if (toValue(name).includes('.')) {
      nameWarning.value = errorMessage;

      return;
    }

    academicTitles.forEach((title) => {
      title = title.replace(/\./g, '');
      title = title.toLowerCase().trim();

      if (toValue(name).toLowerCase().includes(`${title} `)) {
        nameWarning.value = errorMessage;
      }
    });
  };

  watchEffect(() => {
    nameWarning.value = '';
    check();
  });

  return nameWarning;
};
