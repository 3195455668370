import env from '../env';

function getDataLayer () {
  if (!env.GTM_ENABLE) {
    throw new Error('GTM is disabled, skipping.');
  }

  const { dataLayer } = window;

  if (!dataLayer) {
    throw new Error('Global variable `dataLayer` does not exist, skipping.');
  }

  return dataLayer;
}

function onFormFieldError (fieldName, errorMessage) {
  if (!errorMessage) {
    console.warn('No error message was provided, skipping.');
    return;
  }

  try {
    getDataLayer().push({
      event: 'formErrorMessage',
      vpv: {
        fieldName,
        errMessage: errorMessage,
      },
    });
  } catch (e) {
    console.warn(e);
  }
}

function onStepSubmit (stepName) {
  try {
    getDataLayer().push({
      event: 'stepSend',
      stepVariable: {
        formStep: stepName,
      },
    });
  } catch (e) {
    console.warn(e);
  }
}

function onFormSubmit (contractNumber) {
  try {
    const dataLayer = getDataLayer();

    dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: contractNumber,
            affiliation: 'Online',
            revenue: '10000.00',
          },
          products: [{
            name: 'pp-online',
            price: '10000.00',
            category: 'PP online sjednani',
            quantity: 1,
          }],
        },
      },
    });

    dataLayer.push({ event: 'ecommSend' });
  } catch (e) {
    console.warn(e);
  }
}

export default {
  onFormFieldError,
  onStepSubmit,
  onFormSubmit,
};
