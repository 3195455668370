import { computed, ref } from 'vue';

export interface Question {
  id: number
  label: string
  options: Array<{
    value: string
    label: string
  }>
  optionPoints: Record<string, number>
  koAnswer: string | null
  answer: string | null
}

export function useQuestionnaire<T> (
  q: Question[],
  answers: T,
) {
  const internalQuestions = ref(q);
  const loading = ref(false);

  const questions = computed(() => {
    return internalQuestions.value.map((question) => ({
      ...question,
      /* @ts-expect-error: ignore */
      answer: answers[`question${question.id}`].value,
    }));
  });

  const lastAnsweredQuestion = computed(() => {
    let lastQuestion = 0;

    questions.value.forEach((question) => {
      if (question.answer !== null) {
        lastQuestion = question.id;
      }
    });

    return lastQuestion;
  });

  const status = computed<{
    totalPoints: number | null
    unansweredQuestions: number
    isKo: boolean
  }>(() => {
    let questionsToAnswer = questions.value.length;
    let points = 0;

    for (const question of questions.value) {
      const answerId = question.answer as Partial<keyof typeof question.optionPoints>;

      if (question.answer !== null) {
        questionsToAnswer -= 1;

        if (answerId === question.koAnswer) {
          return {
            totalPoints: 0,
            unansweredQuestions: 3,
            isKo: true,
          };
        }

        points += question.optionPoints[answerId] || 0;
      }
    }

    return {
      totalPoints: questionsToAnswer === 0 ? points : null,
      unansweredQuestions: questionsToAnswer,
      isKo: false,
    };
  });

  // When the user receives a KO, dont display the following question (+0).
  //  Otherwise, show (+1).
  const isQuestionVisible = (questionId: number) => {
    return questionId <= lastAnsweredQuestion.value + (status.value.isKo ? 0 : 1);
  };

  const resetAnswer = (questionId: number, cb: (questionId: number, answer: string | null) => void) => {
    if (typeof questionId !== 'number' || questionId < 1) {
      throw new Error('Invalid questionId');
    }

    questions.value.forEach((question) => {
      if (question.id >= questionId) {
        cb(question.id, null);
      }
    });
  };

  return {
    questions,
    lastAnsweredQuestion,
    isQuestionVisible,
    status,
    loading,
    resetAnswer,
  };
}
