<template>
  <div>
    <LitInput
      class="control__number"
      type="tel"
      name="tel"
      placeholder="777 888 999"
      tooltip="Testovací text"
      :model-value="modelValue"
      :label="label"
      :show-prepend="true"
      :read-only="readOnly"
      :disabled="disabled"
      :error="allowPhoneChange ? '' : error"
      :dusk="dusk"
      :tooltip-position="tooltipPosition"
      :show-tooltip="showTooltip"
      :setter="numberSetter"
      :getter="numberGetter"
      @update:model-value="$emit('update:modelValue', $event)"
      @blur="$emit('blur', $event)"
    >
      <template #prepend>
        <span class="prefix">
          <img
            :src="getIconUrl('icon-flag_cz')"
            alt="tel"
          >
          <span>
            +&nbsp;420
          </span>
        </span>
      </template>

      <template
        v-if="allowPhoneChange"
        #actions
      >
        <div class="actions-group">
          <button
            class="btn-simple"
            dusk="change-mobile"
            @click="showModal = !showModal"
          >
            <i>
              <img
                :src="getIconUrl('icon-edit')"
                alt="Ikona upravit"
              >
            </i>
            Změnit telefonní číslo
          </button>
        </div>
      </template>
    </LitInput>

    <LitModal
      v-model="showModal"
      dusk="modal-change-mobile"
    >
      <template #header>
        <h3>
          Změna telefonního čísla
        </h3>
      </template>

      <template #body>
        <LitAlert class="mb-20">
          <div class="mb-5">
            Zadejte nové telefonní číslo, na kterém jste k zastižení.
          </div>

          <div>
            Na uvedeném telefonním čísle vás zároveň bude kontaktovat
            kurýr, který vám poveze smlouvu k podpisu.
          </div>
        </LitAlert>

        <LitInput
          v-model="phoneNumber"
          class="control__number control__number--modal field-modal"
          type="tel"
          name="modal-tel"
          label="Mobil"
          tooltip="Testovací text"
          tooltip-position="left"
          placeholder="777 888 999"
          dusk="modal-mobile-input"
          :errors="errors"
          :show-prepend="true"
          :show-tooltip="showTooltip"
          :setter="modalNumberSetter"
          :getter="numberGetter"
        >
          <template #prepend>
            <span class="prefix">
              <img
                :src="getIconUrl('icon-flag_cz')"
                alt="tel"
              >
              <span>
                +&nbsp;420
              </span>
            </span>
          </template>
        </LitInput>
      </template>

      <template #footer>
        <div class="modal__buttons">
          <LitButton
            dusk="close-modal"
            variant="outline"
            @click="showModal = false"
          >
            <span>Zrušit</span>
          </LitButton>

          <LitButton
            dusk="save-new-mobile-modal"
            @click="updatePhoneNumber"
          >
            <span>Uložit nový telefon</span>
          </LitButton>
        </div>
      </template>
    </LitModal>
  </div>
</template>

<script lang="ts">
import { getIconUrl } from '../utils';

import LitAlert from './Base/LitAlert.vue';
import LitButton from './Base/LitButton.vue';
import LitInput from './Base/LitInput.vue';
import LitModal from './Base/LitModal.vue';

const transformPhoneNumber = (phoneNumber: string) => {
  const rules = [
    {
      regex: /^\d{3}$/,
      action: (val: string) => `${val} `,
    },
    {
      regex: /^\d{3}\s\d{3}$/,
      action: (val: string) => `${val} `,
    },
  ];

  let transformed = phoneNumber;

  for (let i = 0; i < rules.length; i += 1) {
    const { regex, action } = rules[i];

    if (regex.test(phoneNumber)) {
      transformed = action(phoneNumber);
      break;
    }
  }

  return transformed.replace(/[^0-9\s]/g, '')
    .replace(/\s+/, ' ')
    .substr(0, 11);
};

interface State {
  showModal: boolean
  phoneNumber: string
  errors: string[]
}

export default {
  components: {
    LitInput,
    LitModal,
    LitButton,
    LitAlert,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: 'Mobil',
    },

    allowPhoneChange: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: '',
    },

    dusk: {
      type: String,
      default: '',
    },

    tooltipPosition: {
      type: String,
      default: 'top',
    },

    showTooltip: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'update:modelValue',
    'blur',
  ],

  setup () {
    return {
      getIconUrl,
    };
  },

  data: (): State => ({
    showModal: false,
    phoneNumber: '',
    errors: [],
  }),

  methods: {
    numberGetter (phoneNumber: string) {
      return phoneNumber;
    },

    numberSetter (phoneNumber: string) {
      // Do not transform when deleting
      if (phoneNumber.length <= this.modelValue?.length) {
        return phoneNumber;
      }

      return transformPhoneNumber(phoneNumber);
    },

    modalNumberSetter (phoneNumber: string) {
      // Do not transform when deleting
      if (phoneNumber.length <= this.phoneNumber?.length) {
        return phoneNumber;
      }

      return transformPhoneNumber(phoneNumber);
    },

    updatePhoneNumber () {
      if (this.phoneNumber === '') {
        return;
      }

      if (this.validatePhoneNumber()) {
        this.$emit('update:modelValue', this.phoneNumber);
        this.showModal = false;
        this.phoneNumber = '';
      } else {
        this.showModal = true;
      }
    },

    validatePhoneNumber () {
      this.errors = [];

      if (!this.isValidPhoneNumber()) {
        this.errors.push('Zadejte prosím platné telefonní číslo');
      }

      return this.errors.length === 0;
    },

    isValidPhoneNumber () {
      const rule = /^\d{3}\s?\d{3}\s?\d{3}$/;
      return rule.test(this.phoneNumber);
    },
  },
};
</script>

<style lang="scss">
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.control__number {
  &--modal {
    @include media(min, $sm) {
      label {
        flex-basis: 170px;
      }

      .control__wrapper {
        flex-basis: 346px;
      }

      .field-info--m {
        margin-left: 180px;
      }
    }
  }

  .control--prefix {
    input {
      padding-left: 92px;
    }
  }

  font-weight: $bold;
}
</style>
