<template>
  <div class="body">
    <TheHeader />

    <main>
      <div class="benefits bg-blue pt-30 pb-10">
        <div class="container--sm">
          <h2 class="dash text-bold">
            Chyba
          </h2>
        </div>
      </div>

      <div class="center-page error-page mt-20">
        <div class="container--sm">
          <div class="error-icon" />
          <h2 class="text-center">
            <span class="text-bold">Něco se nepovedlo</span>
          </h2>
          <p>
            <strong>Digitální podpis se bohužel nezdařil.</strong>
          </p>

          <p>
            Je možné, že jste se pokoušeli podepsat vícekrát a následně neprošla konečná kontrola.
          </p>

          <p v-if="isBankId">
            Kontaktujte prosím svého obchodního zástupce
          </p>

          <a
            type="button"
            class="btn btn-big btn-outline"
            :href="getFormHomeUrl()"
          >
            <span v-if="isBankId">Na hlavní stránku</span>
            <span v-else>Zpět na hlavní rozcestník</span>
          </a>
        </div>
      </div>
    </main>

    <TheFooter />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import TheFooter from './TheFooter.vue';
import TheHeader from './TheHeader.vue';
import { getHomepageUrl } from '@/js/utils';

const route = useRoute();

const isBankId = computed(() => {
  return route.query?.process === 'bankId';
});

function getFormHomeUrl () {
  if (isBankId.value) {
    return getHomepageUrl();
  }

  return `${window.location.origin}/distribuce`;
}
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';

h2 {
  margin-top: 0;
}

.wrap {
  flex-grow: 1;
}

.info-text {
  flex: 2;
}

.chart-img {
  flex-grow: 1;
  text-align: center;
  display: none;

  @include media(min, $md) {
    display: initial;
  }

  &__mobile {
    display: initial;
    display: flex;
    justify-content: center;

    @include media(min, $md) {
      display: none;
    }
  }
}
</style>
