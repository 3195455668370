<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';

import NomineesList from '@/js/components/NomineesList.vue';
import gtm from '@/js/services/gtm';

import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import { scrollToError } from '@/js/utils.ts';
import StepButtons from '@/js/views/common/StepButtons.vue';

const route = useRoute();
const router = useRouter();

const store = useChangeRequestStore();
const { nominees, validateField, validateFields } = store;

const validating = ref(false);
const showFormsOfferModal = ref(false);

function offerForms () {
  showFormsOfferModal.value = true;
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [

      ],
    });

    await router.push({
      name: 'changeRequest.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zmena-smlouvy');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation clientsStatementError: ${e}`);
  } finally {
    validating.value = false;
  }
}
</script>

<template>
  <div class="container--sm">
    <NomineesList
      v-model:share="nominees.nomineesShare.value"
      namespace="nomineesChange"
      store-path="nominees"
      :validate-fnc="validateField"
    />

    <hr class="separator my-30">

    <div class="flex flex-between">
      <StepButtons
        namespace="changeRequest"
        previous-step="clientData"
        @submit="offerForms"
      />
    </div>

    <ChangeRequestFormsOfferModal
      v-model="showFormsOfferModal"
      :forms="store.changeSettings.involvedForms.value"
      @close="showFormsOfferModal = false"
      @finish="handleSubmit"
    />
  </div>
</template>
