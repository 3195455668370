<template>
  <div dusk="email-input">
    <LitInput
      ref="emailRef"
      class="my-20 input-email"
      :name="name"
      :dusk="dusk"
      :tooltip="tooltip"
      :show-tooltip="showTooltip"
      :placeholder="placeholder"
      :label="label"
      :model-value="modelValue"
      :error="error"
      :errors="errors"
      @update:model-value="emit('update:modelValue', $event)"
      @blur="emit('blur', $event)"
    >
      <template #number-actions>
        <button
          type="button"
          class="btn btn-email"
          dusk="insert-at"
          @click="insertAt"
        >
          Vložit @
        </button>
      </template>
    </LitInput>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import LitInput from './Base/LitInput.vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },

  label: {
    type: String,
    default: 'E-mail',
  },

  error: {
    type: String,
    default: '',
  },

  errors: {
    type: Array,
    default: () => ([]),
  },

  dusk: {
    type: String,
    default: '',
  },

  placeholder: {
    type: String,
    default: 'Např. karel.novotny@gmail.com',
  },

  name: {
    type: String,
    default: '',
  },

  tooltip: {
    type: String,
    default: '',
  },

  tooltipPosition: {
    type: String,
    default: 'top',
  },

  tooltipIcon: {
    type: String,
    default: '',
  },

  showTooltip: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'blur',
]);

const emailRef = ref();

const insertAt = () => {
  if (!props.modelValue) {
    emit('update:modelValue', '@');
  } else if (!props.modelValue.includes('@')) {
    emit('update:modelValue', `${props.modelValue}@`);
  }

  emailRef.value?.inputRef.focus();
};
</script>

<style lang="scss">
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.input-email {
  .control {
    input {
      padding-right: 81px;
    }

    .btn-email {
      font-family: $fontDefault, Helvetica, Arial, sans-serif;
      background: getColor(lighter-blue);
      color: getColor(default);
      border-radius: 5px;
      position: absolute;
      padding: 4px;
      right: 11px;
    }

    &:focus-within {
      .btn-email {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.btn-email {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
</style>
