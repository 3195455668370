<template>
  <div dusk="personal-data">
    <TransferStepper current-step="personalData" />

    <div class="container--sm mt-20">
      <form
        method="post"
        @submit.prevent="submitStep"
      >
        <ClientsPersonalData
          store-path="personalData"
          namespace="transfer"
          title="Osobní údaje osoby, pro kterou je smlouva ukončována"
          :display-insurance-numbers="false"
          :validate-fnc="validateField"
        />

        <SignerPersonalData
          store-path="signer"
          namespace="transfer"
          copy-address-source="personalData"
          copy-names-source="contactInformation"
          :validate-fnc="validateField"
        />
      </form>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="transfer"
      previous-step="contactInformation"
      :fields="fields"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ClientsPersonalData from '../../common/ClientsPersonalData.vue';
import SignerPersonalData from '../../common/SignerPersonalData.vue';
import StepButtons from '../../common/StepButtons.vue';
import TransferStepper from '@/js/components/Steppers/TransferStepper.vue';
import { clientsPersonalDataFields } from '@/js/data/validationFields/commonFields';

import { documentInfoFields, signerFields } from '@/js/data/validationFields/distribution';
import gtm from '@/js/services/gtm';
import { useTransferStore } from '@/js/stores';
import { scrollToError } from '@/js/utils';

const route = useRoute();
const router = useRouter();

const store = useTransferStore();
const { validateField, validateFields } = store;
const { personalData } = storeToRefs(store);

const validating = ref(false);

const fields = shallowRef([
  'personalData.birthDate',
  'personalData.gender',
  'personalData.permanentAddressArea',
  ...clientsPersonalDataFields,
  ...documentInfoFields,
  ...signerFields,
]);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    await router.push({
      name: 'transfer.additionalData',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zadani-osobnich-udaju');
  } catch (e: any) {
    validating.value = false;

    personalData.value.permanentAddress.setManually.value = true;

    scrollToError();

    console.warn(`There was a validation additionalDataError: ${e}`);
  }
}
</script>
