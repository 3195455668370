<template>
  <div>
    <div class="field">
      <label
        v-if="label"
        :for="name"
      >
        {{ label }}

        <span
          v-if="required"
          class="required"
        > *</span>
      </label>

      <div
        v-show="editable"
        class="control__wrapper"
        :class="{
          [className]: true
        }"
      >
        <div
          class="control"
          :class="{
            'control--prefix': showPrepend,
            'control--unit': showAppend,
            'control--error': error || errors.length > 0,
            'disabled': disabled
          }"
        >
          <slot
            v-if="showPrepend"
            name="prepend"
          />

          <input
            :id="name"
            ref="inputRef"
            :autocomplete="autocomplete"
            :class="inputClass"
            :disabled="disabled"
            :dusk="dusk"
            :inputtype="inputType"
            :max="max"
            :min="min"
            :name="name"
            :pattern="pattern"
            :placeholder="placeholder"
            :readonly="readOnly"
            :step="step"
            :type="type"
            :value="syncValue"
            @input="syncValue = ($event.target as HTMLInputElement).value"
            @change="$emit('change', ($event.target as HTMLInputElement).value)"
            @blur="$emit('blur', $event)"
            @keyup="$emit('keyup', $event)"
            @keydown="$emit('keydown', $event)"
          >

          <slot
            v-if="showAppend"
            name="append"
          />

          <slot name="number-actions" />
        </div>

        <slot name="tooltip">
          <LitTooltip
            v-if="tooltip && showTooltip"
            :text="tooltip"
            :position="tooltipPosition"
            :icon-path="iconTooltip"
          />
        </slot>
      </div>

      <span
        v-show="!editable"
        :class="['control__selected', disabled ? 'disabled' : '']"
      >
        {{ syncValue }}
      </span>

      <slot name="actions" />
    </div>

    <div
      class="field-info"
      :class="{
        'field-info--m': label
      }"
    >
      <small
        v-if="description"
        :class="['description', disabled ? 'disabled' : '']"
      >
        {{ description }}
      </small>

      <small
        v-if="warning"
        class="warning"
      >
        {{ warning }}
      </small>

      <small
        v-if="error"
        class="error"
      >
        {{ error }}
      </small>

      <small
        v-if="errors.length > 0"
        class="error"
      >
        <span
          v-for="(validationError, index) in errors"
          :key="index"
        >
          {{ validationError }}<br>
        </span>

        <slot name="link" />
      </small>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

import { clamp } from '../../utils';
import LitTooltip from './LitTooltip.vue';

const props = defineProps({
  description: {
    type: String,
    default: '',
  },

  error: {
    type: String,
    default: '',
  },

  errors: {
    type: Array,
    required: false,
    default: () => ([]),
  },

  warning: {
    type: String,
    default: '',
  },

  iconTooltip: {
    type: String,
    default: '',
  },

  label: {
    type: String,
    default: '',
  },

  name: {
    type: String,
    default: '',
  },

  placeholder: {
    type: String,
    default: '',
  },

  required: {
    type: Boolean,
    default: false,
  },

  showAppend: {
    type: Boolean,
    default: false,
  },

  showPrepend: {
    type: Boolean,
    default: false,
  },

  step: {
    type: [Number, String],
    default: '',
  },

  tooltip: {
    type: String,
    default: '',
  },

  tooltipPosition: {
    type: String,
    default: 'top',
  },

  type: {
    type: String,
    default: 'text',
  },

  modelValue: {
    type: [String, Number],
    default: '',
  },

  readOnly: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  className: {
    type: String,
    default: '',
  },

  dusk: {
    type: String,
    default: '',
  },

  min: {
    type: Number,
    required: false,
    default: null,
  },

  max: {
    type: Number,
    required: false,
    default: null,
  },

  pattern: {
    type: String,
    default: null,
  },

  autocomplete: {
    type: String,
    default: 'on',
  },

  inputType: {
    type: String,
    default: null,
  },

  inputClass: {
    type: String,
    default: '',
  },

  showTooltip: {
    type: Boolean,
    default: true,
  },

  setter: {
    type: Function,
    default: (val: unknown) => val,
  },

  getter: {
    type: Function,
    default: (val: unknown) => val,
  },

  editable: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits([
  'update:modelValue',
  'change',
  'blur',
  'keyup',
  'keydown',
]);

const inputRef = ref();

const syncValue = computed({
  get () {
    return props.getter(props.modelValue);
  },

  set (val) {
    const formatted = props.setter(val);

    if (val !== formatted) {
      inputRef.value.value = formatted;
    }

    emit('update:modelValue', formatted);
  },
});

watch(() => props.modelValue, (newVal) => {
  if (props.type === 'number') {
    const clamped = Number.parseFloat(
      clamp(
        props.min,
        props.max,
        newVal as number,
      ).toString(),
    );

    if (clamped !== newVal) {
      emit('update:modelValue', clamped);
    }
  }
});

defineExpose({
  inputRef,
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

%formStyle {
  border: none;
  background-color: transparent;
  font-size: $root-small;
  color: getColor(default);

  &::placeholder {
    color: getColor(light-blue);
    font-weight: $normal;
  }
}

input {
  @extend %formStyle;

  pointer-events: all;

  &[type='date'],
  &[type='datetime-local'],
  &[type='email'],
  &[type='month'],
  &[type='password'],
  &[type='search'],
  &[type='tel'],
  &[type='text'],
  &[type='time'],
  &[type='url'],
  &[type='number'],
  &[type='week'] {
    padding: 0 rem(11);
    margin: 0;
    min-height: $input-height;
    width: 100%;
    font-size: $root;
    font-weight: bold;

    &::-ms-clear {
      display: none;
    }
  }

  /* stylelint-disable */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
  /* stylelint-enable */

  &.date {
    min-width: 120px;
  }

  &[type='time'] {
    min-width: 73px;

    &::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }
  }

  &:focus {
    outline: none;
  }

  &:last-of-type:not(:only-of-type) {
    margin-right: .063em;
  }
}

.field {
  display: flex;
  flex-flow: column;

  @include media(min, $sm) {
    flex-flow: row;
  }

  &-info {
    display: flex;
    flex-flow: column;
    max-width: 330px;

    &--m {
      @include media(min, $sm) {
        margin-left: 187px;
      }
    }
  }
}

textarea {
  @extend %formStyle;

  width: 100%;
  padding: .75em;
  min-height: 38px;
  resize: none;
}

label {
  margin-bottom: 5px;
  display: block;
  font-weight: $normal;

  @include media(min, $sm) {
    flex-basis: 177px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    flex-shrink: 0;
  }

  button {
    font-size: 11px;
    margin-bottom: -4px;
  }
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: getColor(bg);
  border-radius: 3px;
  min-height: $input-height;
  border: 1px solid getColor(light-blue);
  position: relative;
  max-width: 100%;
  flex-basis: 100%;

  @include media(min, $sm) {
    flex-basis: 330px;
  }

  &--unit {
    input {
      padding-right: 40px;
    }
  }

  &--prefix {
    input {
      padding-left: 40px;
    }
  }

  &--error {
    border-color: getColor(danger, 1);
  }

  &--warning {
    border-color: getColor(warning, 1);
  }

  &.disabled {
    opacity: .5;
    color: #8397b3;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    flex-shrink: 0; //too long description

    @include media(min, $sm) {
      flex-basis: 360px;
    }

    &.control {
      &--xs {
        .control {
          max-width: 91px;
        }
      }

      &--sm {
        .control {
          max-width: 220px;

          @include media(min, $sm) {
            max-width: 177px;
          }
        }

        @include media(min, $sm) {
          flex-basis: 207px;
        }
      }

      &--md {
        .control {
          max-width: 320px;
        }
      }
    }
  }

  :deep(.unit) {
    font-size: $root-small;
    position: absolute;
    right: 0;
    width: $input-height;
    height: $input-height;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    color: getColor(light-blue);
  }

  :deep(.prefix) {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    height: $input-height;
    padding: 0 11px;
    font-size: $root;

    img {
      margin-right: 10px;
    }
  }

  &:focus-within {
    border-color: getColor(blue);
  }

  &__selected {
    font-weight: $bold;
    font-size: $root;
    display: flex;
    align-items: center;
    min-height: $input-height;

    @include media(min, $sm) {
      flex-basis: 330px;
      margin-right: 30px;
      flex-shrink: 0;
    }
  }
}

:deep(.actions-group) {
  line-height: 1.3;
  display: inline-flex;
  flex-flow: column;
  margin-top: 5px;
  align-items: flex-start;
  max-width: 360px;
  padding-right: 31px;

  > *:not(:last-child) {
    margin-bottom: 3px;
  }

  @include media (min, $sm) {
    margin-top: 0;
    margin-left: 10px;
    align-self: center;
    max-width: none;
    padding-right: 0;
    align-items: none;
  }
}

// Description + Error
.description,
.error {
  display: block;
  padding: .125em 0;
  margin-top: .25em;
}

.disabled {
  color: #8397b3;
}

// Required field + Error
.required,
.error {
  color: getColor(danger, 1);
}

.required {
  margin: 0 auto 0 .125em;
}

.warning {
  color: getColor(warning, 1);
}
</style>
