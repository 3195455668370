import { persistContractMetadata } from '../utils';
import axios from './axios';

export function show (uuid: string, contractType = 'online', submitToBankId = false) {
  return axios.get(`/api/v1/contracts/${uuid}?contractType=${contractType}&submitToBankId=${submitToBankId}`);
}

export async function create (contractType = 'online') {
  const { data } = await axios.post('/api/v1/contracts', {
    contract_type: contractType,
  });

  persistContractMetadata({
    accessTokenValue: data.access_token.value,
    accessTokenValidTo: data.access_token.valid_to,
    contractUuid: data.contract.uuid,
    contractType,
  });

  return data;
}

export function update (uuid: string, payload: unknown) {
  return axios.patch(`/api/v1/contracts/${uuid}`, payload);
}

export function destroy (uuid: string) {
  return axios.delete(`/api/v1/contracts/${uuid}`);
}

export default {
  show,
  create,
  update,
  destroy,
};
