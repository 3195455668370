<template>
  <div>
    <OnlineAgreementStepper :current-step="4" />

    <div class="container--sm">
      <h2>Dokumenty a souhlasy</h2>

      <h3>Dokumenty k vaší smlouvě</h3>

      <LitAlert class="mb-20">
        Seznamte se s dokumenty k vaší smlouvě.
      </LitAlert>

      <div>
        <ul class="list-unstyled">
          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/informace_o_pobidkach.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Informace o pobídkách.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/klicove_informace_ucastnickych_fondu.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Klíčové informace účastnických fondů.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/podminky_pro_doplnkove_penzijni_sporeni.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Podmínky pro doplňkové penzijní spoření.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/predsmluvni_informace.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Předsmluvní informace.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/sazebnik.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Sazebník.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/statuty_ucastnickych_fondu.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Statuty účastnických fondů.pdf
              </span>
            </a>
          </li>
        </ul>
      </div>

      <p class="mb-20">
        Stáhnout všechny dokumenty najednou ve formátu ZIP

        &nbsp;<a
          class="link-icon"
          href="/storage/documents/DPS_smluvni_dokumentace.zip"
          download
        >
          <i>
            <img
              :src="getIconUrl('icon-filetype_ZIP')"
              alt="Ikona pdf"
            >
          </i>

          <span>.ZIP</span>
        </a>

        &nbsp;nebo&nbsp;<a
          class="link-icon"
          href="/storage/documents/DPS_smluvni_dokumentace.pdf"
          download
        >
          <i>
            <img
              :src="getIconUrl('icon-filetype_PDF')"
              alt="Ikona pdf"
            >
          </i>

          <span>.PDF</span>
        </a>
      </p>

      <h3 class="mt-40">
        Souhlas se zpracováním osobních údajů
      </h3>

      <div dusk="consents">
        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="onlineAgreement"
          field-path="consents.marketingPurposes"
          :default-value="false"
          :debounce="0"
          :validate-on-input="true"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20"
            dusk="marketing-purposes"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          >
            Souhlasím se

            <a
              href="https://www.csob.cz/portal/csob/ochrana-osobnich-udaju"
              target="_blank"
            >
              zpracováním a sdílením svých údajů ve skupině ČSOB pro marketingové účely</a>.
            Souhlas je účinný okamžikem podpisu smlouvy.
          </LitCheckbox>
        </ServerValidatedFormField>
      </div>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <div class="container--sm flex flex-between">
      <router-link
        :to="{ name: 'onlineAgreement.contractSettings' }"
        class="btn btn-big btn-icon-back btn-outline"
      >
        <LitIconSvg
          class="btn__icon btn__icon--big"
          icon-name="arrow_green-left"
        />

        <span>
          Zpět
        </span>
      </router-link>

      <button
        class="btn btn-green btn-big btn-icon-right"
        dusk="submit-step"
        @click="submitStep"
      >
        <span>
          Pokračovat
        </span>

        <LitIconSvg
          class="btn__icon btn__icon--end btn__icon--end--big"
          icon-name="arrow_white-right"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';

import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import OnlineAgreementStepper from '@/js/components/Steppers/OnlineAgreementStepper.vue';
import gtm from '@/js/services/gtm';
import { useOnlineAgreementStore } from '@/js/stores';
import { measureAdobeAnalytics } from '@/js/stores/utils';

import { getIconUrl, scrollToError } from '@/js/utils';

const fields = shallowRef([
  'consents.marketingPurposes',
]);
const validating = ref(false);

const router = useRouter();
const route = useRoute();

const store = useOnlineAgreementStore();
const { validateFields, validateField } = store;
const { isOnlineJps } = storeToRefs(store);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    gtm.onStepSubmit('smlouva-dokumenty-a-souhlasy');

    if (!isOnlineJps.value) {
      measureAdobeAnalytics({
        state: store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });
    }

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    await router.push({
      name: 'onlineAgreement.contractPreview',
      params: {
        contractUuid,
      },
    });
  } catch (e: any) {
    if (e?.response.status === 401 && e?.response && e?.response.data && e?.response.data.message && e?.response.data.message.includes('Bank iD validation Error')) {
      await router.push({
        name: 'error',
      });
    }

    validating.value = false;

    scrollToError();

    console.error(`There was a validation ContractPreviewError: ${e}`);
  }
}
</script>
