<script setup lang="ts" generic="T extends DocumentReturn">
import type { DocumentReturn } from '@/js/stores/types';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';
import { useDocumentTypeTranslation } from '@/js/composables/useDocumentTypeTranslation.ts';

interface Props {
  storePath: T
  namespace: string
}

const props = defineProps<Props>();
</script>

<template>
  <template v-if="typeof props.storePath !== 'undefined'">
    <tr>
      <td>Typ dokladu</td>
      <td
        colspan="2"
      >
        {{ useDocumentTypeTranslation(props.storePath.type.value) }}
        <span v-if="props.storePath.type.value === 'anotherDoc'">
          : {{ props.storePath.name.value }}
        </span>
      </td>
    </tr>
    <RecapitulationTableRow :path="props.storePath.number" table-key="Číslo dokladu" />
    <RecapitulationTableRow :path="props.storePath.validUntil" table-key="Platný do" />
    <RecapitulationTableRow :path="props.storePath.issuedBy" table-key="Vydal" />
  </template>
</template>
