<template>
  <LitModal v-model="show" :max-width="600">
    <template #header>
      <h3>ČSOB Penze - Debug info</h3>
    </template>

    <template #body>
      <h3>App info</h3>
      <ul>
        <li>APP_VERSION: <b>{{ appVersion }}</b></li>
      </ul>

      <div v-if="enableDevtools">
        <h3>Contract drafts</h3>

        <select
          v-model="contractType"
          name="contractType"
        >
          <option value="onlineAgreement">
            Online
          </option>

          <option value="changeRequest">
            Změny
          </option>

          <option value="distribution">
            Distribuce
          </option>

          <option value="termination">
            Výpověď
          </option>

          <option value="transfer">
            Převod
          </option>

          <option value="jps">
            JpsDistribuce
          </option>
          <option value="changeRequest">
            Změny
          </option>
        </select>
        <ul>
          <li
            v-for="(draft, index) in drafts"
            :key="index"
          >
            {{ draft.name }}

            <a
              href="#"
              @click.prevent="restoreDraft(index)"
            >
              (Obnovit)
            </a>

            <a
              href="#"
              @click.prevent="deleteDraft(index)"
            >
              (Smazat)
            </a>
          </li>
        </ul>

        <LitButton size="sm" @click="saveDraft">
          <span>Uložit draft</span>
        </LitButton>

        <h3>Jump to</h3>
        <ul>
          <li>
            <a
              href="#"
              @click.prevent="goTo('clientsStatement')"
            >
              Prohlášení klienta
            </a>
          </li>
          <li>
            <a
              href="#"
              @click.prevent="goTo('newContractTransferConfirmationPage')"
            >
              Převod smlouvy - meziděkovačka 1
            </a>
          </li>
        </ul>

        <h3>Submit multiple contracts</h3>
        <p>
          Nástroj pro ladění odesílání hromadného počtu smluv.<br>
          Využito pro ladění

          <a
            target="_blank"
            href="https://gitlab.litea.dev/csob-penze/csob-penze-cz/csob-penze-onboarding/-/issues/537"
          >
            incidentu 537
          </a>.
        </p>

        <LitInput
          v-model="contractCreation.loopsCount"
          name="loopsCount"
          type="number"
          class="mt-20"
          label="Loops count"
          title="Počet iterací"
          :min="1"
        />

        <LitInput
          v-model="contractCreation.batchSize"
          name="batchSize"
          type="number"
          class="mt-20"
          label="Batch size"
          title="Počet současně vytvořených smluv v iteraci"
          :min="1"
        />

        <LitButton
          size="sm"
          :disabled="contractCreation.loading"
          class="mt-20"
          :class="{ disabled: contractCreation.loading }"
          @click="createMultipleContracts"
        >
          <span>
            {{ contractCreation.loading
              ? 'Vytvářím...'
              : 'Vytvořit smlouvy' }}
          </span>
        </LitButton>
      </div>
    </template>

    <template #footer>
      <div class="modal__buttons flex-end">
        <LitButton
          variant="outline"
          @click="show = false"
        >
          <span>Zavřít</span>
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>

<script lang="ts">
import { getActivePinia } from 'pinia';
import { contracts } from '../api';

import env from '../env';
import { formData } from '../formData';

import { useDistributionStore } from '../stores';
import { restoreState } from '../stores/utils';
import LitButton from './Base/LitButton.vue';
import LitInput from './Base/LitInput.vue';
import LitModal from './Base/LitModal.vue';

interface Draft {
  name: string
  content: any
}

interface State {
  show: boolean
  drafts: Draft[]
  contractType: string
  appVersion: string | null
  strokeSequence: number

  contractCreation: {
    loopsCount: number
    batchSize: number
    loading: boolean
  }
}

export default {
  components: {
    LitModal,
    LitInput,
    LitButton,
  },

  props: {
    enableDevtools: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: (): State => ({
    show: false,
    drafts: [],
    contractType: 'distribution',
    appVersion: null,
    strokeSequence: 0,

    contractCreation: {
      loopsCount: 3,
      batchSize: 3,
      loading: false,
    },
  }),

  computed: {
    activeStores () {
      const store = getActivePinia();

      return store?.state.value;
    },
  },

  watch: {
    contractType () {
      this.loadDrafts();
    },
  },

  mounted () {
    this.appVersion = env.APP_VERSION;
    this.registerKeyBinding();
    this.loadDrafts();
  },

  beforeUnmount () {
    this.unregisterKeyBinding();
  },

  methods: {
    handler (e: KeyboardEvent) {
      if (e.code === 'KeyD') {
        this.strokeSequence += 1;

        setTimeout(() => {
          this.strokeSequence = 0;
        }, 500);
      }

      // ddd (fastly typed)
      if (this.strokeSequence === 3) {
        this.show = true;
      }
    },

    registerKeyBinding () {
      window.addEventListener('keyup', this.handler);
    },

    unregisterKeyBinding () {
      window.removeEventListener('keyup', this.handler);
    },

    loadDrafts () {
      const storageKey = `contractDrafts.${this.contractType}`;
      const drafts = localStorage.getItem(storageKey) || '[]';

      this.drafts = JSON.parse(drafts);
    },

    persistDrafts () {
      const storageKey = `contractDrafts.${this.contractType}`;
      localStorage.setItem(storageKey, JSON.stringify(this.drafts));
      console.warn('Drafts saved');
    },

    saveDraft () {
      // eslint-disable-next-line no-alert
      const name = window.prompt('Enter draft name');

      if (name) {
        if (this.activeStores && this.activeStores[this.contractType] === undefined) {
          // eslint-disable-next-line no-alert
          window.alert(`No active store for ${this.contractType}`);
          return;
        }

        if (this.activeStores) {
          this.drafts.push({
            name,
            content: this.activeStores[this.contractType],
          });

          this.persistDrafts();
        }
      }
    },

    restoreDraft (index: number) {
      const draft = this.drafts[index];

      if (this.activeStores && this.activeStores[this.contractType] === undefined) {
        // eslint-disable-next-line no-alert
        window.alert(`No active store for ${this.contractType}`);
        return;
      }

      if (this.activeStores) {
        restoreState(this.activeStores[this.contractType], draft.content);

        console.warn('Draft restored');
      }
    },

    deleteDraft (index: number) {
      const draft = this.drafts[index];

      // eslint-disable-next-line no-alert
      if (window.confirm(`Delete draft ${draft.name}?`)) {
        this.drafts.splice(index, 1);
        this.persistDrafts();
      }
    },

    goTo (name: string) {
      const routeName = `${this.contractType}.${name}`;

      this.$router.push({
        name: routeName,
        params: this.$route.params,
      });
    },

    async createMultipleContracts () {
      this.contractCreation.loading = true;

      for (let i = 0; i < this.contractCreation.loopsCount; i += 1) {
        console.warn(`Iteration ${i}`);

        const creationPromises = [];

        for (let j = 0; j < this.contractCreation.batchSize; j += 1) {
          creationPromises.push(contracts.create('distribution'));
        }

        const createdContracts = await Promise.all(creationPromises);
        const uuids = createdContracts.map((contract) => contract.uuid);
        const distributionStore = useDistributionStore();

        await Promise.all(
          uuids.map((contractUuid) => distributionStore.validateFields({
            contractUuid,
            throwOnErrors: true,
            fieldPaths: ['beforeStart.distributor.firstName.value'],
          })),
        );

        await Promise.all(uuids.map((uuid) => {
          console.warn(`Submitting ${uuid}`);

          return contracts.update(uuid, {
            formData,
            validateOnly: null,
            submitContract: true,
          }).then(() => {
            console.warn(`Submitted contract ${uuid}`);
          });
        }));
      }

      this.contractCreation.loading = false;
    },
  },
};
</script>

<style scoped>
  h3 {
    border-bottom: 1px solid #eee;
  }
</style>
