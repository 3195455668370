<template>
  <div class="field">
    <div
      v-if="label"
      class="radio__title"
    >
      {{ label }}
    </div>
    <div
      :class="{
        radio__wrapper: showInline,
        wrapper: !showInline
      }"
    >
      <div
        class="radio"
        :class="{
          'collapsed': showOnlySelected,
          'radio-inline': showInline
        }"
      >
        <label
          v-for="option in normalizedOptions"
          :key="option.value"
          :class="{ checked: option.value === modelValue, disabled: option.disabled }"
          :dusk="`label-${option.value}`"
        >
          <span class="radio__input">
            <input
              v-model="syncValue"
              type="radio"
              :name="name"
              :value="option.value"
              :disabled="option.disabled"
            >
            <span
              class="radio__control"
              :dusk="`radio-${option.value}`"
            />
          </span>
          <span class="radio__label">
            {{ option.label }}
          </span>
        </label>

        <small
          v-if="error"
          class="error"
        >
          {{ error }}
        </small>
      </div>

      <slot name="after">
        <LitTooltip
          v-if="hasTooltipSlot || tooltip"
          :text="tooltip"
          :icon-path="tooltipIcon"
          :position="tooltipPosition"
        >
          <slot name="tooltip" />
        </LitTooltip>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import LitTooltip from './LitTooltip.vue';

export default {
  name: 'LitRadio',

  components: {
    LitTooltip,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: '',
    },

    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },

    options: {
      type: [Object, Array],
      required: true,
      default: null,
    },

    showOnlySelected: {
      type: Boolean,
      default: false,
    },

    showInline: {
      type: Boolean,
      default: false,
    },

    tooltipIcon: {
      type: String,
      default: '',
    },

    tooltipPosition: {
      type: String,
      default: 'top',
    },

    tooltip: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    error: {
      type: String,
      default: '',
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    syncValue: {
      get () {
        return this.modelValue;
      },
      set (value: string | number | boolean) {
        this.$emit('update:modelValue', value);
      },
    },

    hasTooltipSlot () {
      return !!this.$slots.tooltip;
    },

    normalizedOptions () {
      if (Array.isArray(this.options)) {
        return this.options;
      }

      const result: Array<{ label: string | unknown, value: string | number | boolean }> = [];

      Object.entries(this.options).forEach(([key, value]) => {
        result.push({
          label: value,
          value: key,
        });
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.field {
  @include media(min, $md) {
    display: flex;
  }

  .wrapper {
    width: 100%;
  }
}

.radio {
  position: relative;

  &-inline {
    display: flex;
    flex-flow: row wrap;
    flex-basis: 330px;
    align-items: center;

    label {
      max-width: 50%;
      flex: 50%;
      margin-bottom: 0 !important;
      font-weight: $bold;
    }
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__title {
    flex-basis: 177px;
    margin-right: 10px;
    display: block;
    margin-bottom: 5px;

    @include media(min, $md) {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      flex-shrink: 0;
    }
  }

  label {
    line-height: 1.45;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    &:not(.checked):hover {
      cursor: pointer;
    }

    &:focus-within .radio__control {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(green);
    }
  }

  &__input {
    display: flex;
    align-items: center;
    margin-right: 10px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }
  }

  &__label {
    flex: 1;
  }

  &__control {
    $control-bg: #d8e8c5;

    width: rem(23);
    height: rem(23);
    border-radius: 50%;
    border: 1px solid $control-bg;
    transform: translateY(-.05em);
    background: $control-bg;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      width: rem(9);
      height: rem(9);
      border-radius: 50%;
      transition: 180ms transform ease-in-out;
      transform: scale(0);
      background: getColor(green);
    }
  }

  input:checked + &__control {
    background: getColor(white);
    border-color: getColor(green);

    &::before {
      transform: scale(1);
    }
  }

  &.collapsed {
    label:not(.checked) {
      display: none;
    }
  }
}

.error {
  display: block;
  padding: .125em 0;
  margin-top: .25em;
  color: getColor(danger, 1);
}

.disabled {
  cursor: none;
  color: getColor(grey);
  pointer-events: none;
}
</style>
