import axios from './axios';

export function create (contractUuid, phoneNumber) {
  return axios.post(
    `/api/v1/contracts/${contractUuid}/signing-keys`,
    { phoneNumber },
  );
}

export default {
  create,
};
