import env from '../env';
import axios from './axios';

export async function getFundData (type: string, from = null, to = null, year = null) {
  const params = {
    type,
    ...(from !== null && { from }),
    ...(to !== null && { to }),
    ...(year !== null && { year }),
  };

  const { data } = await axios.get(`${env.REST_API_URI}/v1/funds`, {
    params,
    withCredentials: false,
  });

  return data;
}
