export const usePermanentAddressAreaTranslation = (value: string | null) => {
  switch (true) {
    case value === 'cz':
      return 'Česká republika';
    case value === 'eu':
      return 'EU';
    case value === 'other':
      return 'mimo EU';
    default:
      return value;
  }
};
