<template>
  <div dusk="personal-data">
    <JpsStepper current-step="personalData" />

    <div class="container--sm mt-20">
      <form
        method="post"
        @submit.prevent="submitStep"
      >
        <ClientsPersonalData
          namespace="jps"
          store-path="personalData"
          :display-insurance-numbers="personalData.permanentAddressArea.value === 'eu'"
          :validate-fnc="validateField"
        />

        <SignerPersonalData
          store-path="signer"
          namespace="jps"
          copy-address-source="personalData"
          copy-names-source="contactInformation"
          :validate-fnc="validateField"
        />

        <div class="mt-30">
          <hr>
        </div>
      </form>
    </div>

    <StepButtons
      namespace="jps"
      previous-step="contractDocumentsAndConsents"
      :fields="fields"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { parse as parseDate } from 'date-fns';
import { storeToRefs } from 'pinia';

import { computed, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ClientsPersonalData from '../../common/ClientsPersonalData.vue';

import SignerPersonalData from '../../common/SignerPersonalData.vue';
import StepButtons from '../../common/StepButtons.vue';
import { canEnterRent, scrollToError } from '@/js/utils';
import { useJpsStore } from '@/js/stores';
import { documentInfoFields, signerFields } from '@/js/data/validationFields/distribution';
import gtm from '@/js/services/gtm';
import { clientsPersonalDataFields } from '@/js/data/validationFields/commonFields';
import JpsStepper from '@/js/components/Steppers/JpsStepper.vue';

const validating = ref(false);

const fields = shallowRef([
  'personalData.birthDate',
  'personalData.gender',
  'personalData.permanentAddressArea',
  ...clientsPersonalDataFields,
  ...documentInfoFields,
  ...signerFields,
]);

const route = useRoute();
const router = useRouter();

const store = useJpsStore();
const { validateField, validateFields, resetPensionQuestionnaire } = store;
const { personalData, isParticipant, signer, participantsBirthDay } = storeToRefs(store);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    await router.push({
      name: 'jps.legalRequirements',
      params: {
        contractUuid,
      },
    });

    gtm.onStepSubmit('zadani-osobnich-udaju');
  } catch (e) {
    validating.value = false;

    personalData.value.permanentAddress.setManually.value = true;

    if (isParticipant.value && typeof signer.value.permanentAddress !== 'undefined') {
      signer.value.permanentAddress.setManually.value = true;
    }

    scrollToError();

    console.warn(`There was a validation legalRequirementsError: ${e}`);
  }
}

const birthDay = computed(() => {
  if (personalData.value.permanentAddressArea.value !== 'cz') {
    return parseDate(personalData.value.birthDate.value, 'dd. MM. yyyy', new Date());
  }

  return participantsBirthDay.value;
});

const preferConservativeFund = computed(() => {
  return canEnterRent(birthDay.value);
});

watch(preferConservativeFund, () => {
  resetPensionQuestionnaire();
});
</script>
