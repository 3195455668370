import axios from './axios';

export function create (contractUuid: string, signMethod: string) {
  return axios.post(`/api/v1/contracts/${contractUuid}/draft-tokens`, {
    sendVia: 'email',
    signMethod,
  });
}

export function show (contractUuid: string, signMethod: string) {
  return axios.get(`/api/v1/contracts/${contractUuid}/draft-tokens`, {
    params: { signMethod },
  });
}

export default {
  create,
  show,
};
