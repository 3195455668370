<template>
  <div>
    <Stepper
      :steps="steps"
      :current-step="currentStep"
      metro-class="metro--narrow"
    />
  </div>
</template>

<script>
import Stepper from './Stepper.vue';

export default {
  name: 'OnlineAgreementStepper',

  components: {
    Stepper,
  },

  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },

  data: () => ({
    steps: [
      {
        stepNumber: 1,
        title: 'Vaše osobní údaje',
        uri: { name: 'onlineAgreement.personalDataEntryMethodSelection' },
      },
      {
        stepNumber: 2,
        title: 'Zákonné požadavky',
        uri: { name: 'onlineAgreement.legalRequirements' },
      },
      {
        stepNumber: 3,
        title: 'Váš příspěvek a strategie',
        uri: { name: 'onlineAgreement.contributionAndStrategy' },
      },
      {
        stepNumber: 4,
        title: 'Nastavení smlouvy',
        uri: { name: 'onlineAgreement.contractSettings' },
      },
    ],
  }),
};
</script>
