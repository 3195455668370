<script setup lang="ts">
import { computed, shallowRef } from 'vue';
import LitButton from '@/js/components/Base/LitButton.vue';

interface Props {
  formType?: 'contract' | 'request'
}

const props = withDefaults(defineProps<Props>(), {
  formType: 'contract',
});

defineEmits([
  'showModal',
]);

const text = shallowRef({
  contract: 'smlouvu',
  request: 'žádost',
});

const form = computed(() => {
  return text.value[props.formType];
});
</script>

<template>
  <div class="container--xs my-20 center">
    Pokud není možné podepsat {{ form }} elektronicky, můžete využít možnosti
    <LitButton
      class="print-link"
      variant="link"
      @click="$emit('showModal')"
    >
      vytisknout
    </LitButton> {{ form }} a podepsat ručně.
  </div>
</template>

<style scoped lang="scss">
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

:deep(.print-link.lit-btn) {
  color: getColor(grey);
}
</style>
