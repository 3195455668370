<script setup lang="ts">
import LitAlert from '@/js/components/Base/LitAlert.vue';

const props = defineProps({
  error: {
    type: Boolean,
    default: false,
  },

  formType: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div class="container--sm mt-20">
    <h2>Rekapitulace</h2>

    <LitAlert
      v-if="props.error"
      class="mb-20"
      alert-type="danger"
      dusk="contract-sending-failure"
    >
      <div>
        Při odesílání {{ props.formType }} došlo k chybě. Překontrolujte si všechny zadané údaje
        a zkuste to znovu. V případě, že se chyba bude opakovat, nás prosím kontaktujte.
      </div>
    </LitAlert>

    <slot name="recapitulation" />
  </div>
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

$breakpoint-mobile-small: 400px;

.modal_header {
  font-size: 22px;
  margin-left: 20px;
  align-self: center;
}

.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  border-radius: 80px;
  transition: box-shadow .25s ease;

  .btn {
    border-radius: 80px 0 0 80px;
    margin: 0;
    padding: 20px 30px 15px 25px;
    text-align: right;
    border: 0;
    outline: 0;
    align-items: center;

    @media screen and (max-width: $breakpoint-mobile-small) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .btn, .dropdown-toggle-split {
    transition: background .5s ease-in-out, color .5s ease-in-out, border-color .5s ease-in-out;
  }

  &--open {
    .btn, .dropdown-toggle-split {
      z-index: 1001;
    }
  }

  .dropdown-toggle-split {
    svg {
      transition: .25s ease transform;
    }
  }

  &--open .dropdown-toggle-split {
    svg {
      transform: scaleY(-1);
    }
  }

  .dropdown-menu {
    transition: .25s ease drop-shadow;
  }
}

.button_subtitle {
  text-transform: initial;
  opacity: .65;
}

.disable {
  display: none;
}

.btn-big {
  margin: 0 !important;
}

.btn-back {
  align-items: center !important;
}

.initial_text {
  text-transform: initial;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
}

.pointer {
  cursor: pointer;
}

.flex-v-stretch {
  align-items: stretch;
}

.btn-custom {
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: 1rem !important;
}

.btn-custom-smaller {
  @media screen and (max-width: $breakpoint-mobile-small) {
    padding: 10px;
  }
}

.modal__body {
  .btn-custom {
    padding-left: 0;
    padding-right: 0;
  }

  .btn-big {
    padding: 20px 30px 15px 25px;
  }

  .btns-small-font {
    .btn {
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .icons {
    flex: 0 0 70px;
    position: relative;

    &__warning {
      position: absolute;
      top: 0;
      right: 20px;
      width: 18px;
    }

    &__default {
      width: 35px;
      height: 35px;
    }
  }

  .info-text {
    color: rgba(9, 47, 104, .647);
  }
}
</style>
