<template>
  <div dusk="before-start-wrapper">
    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm">
        <h2 class="dash">
          Ukončení smlouvy
        </h2>
      </div>
    </div>

    <div class="container--sm my-30">
      <h2>Než začneme...</h2>

      <form
        dusk="before-start"
        @submit.prevent="submitStep"
      >
        <DistributorData
          namespace="termination"
          store-path="beforeStart"
          title="Smlouvu za ČSOB PS ukončuje"
          alert-text="Vyplňte svou e-mailovou adresu, zašleme na ni kopii kompletní dokumentace."
          :show-meeting-address-title="beforeStart.meetingAddress?.setManually.value ?? false"
          :display-optional-fields="false"
          :validate-fnc="validateField"
          :display-address-field="beforeStart.isTiedAgent.value"
          :show-alert="!beforeStart.isTiedAgent.value"
        />
      </form>
    </div>

    <StepButtons
      dusk="submit-step"
      namespace="termination"
      button-position="flex-center"
      :display-previous-step-button="false"
      @submit="submitStep"
    />

    <DistributionNameMissingModal
      :is-distribution-name-missing="missingDistributionName"
      storage-key="distribution"
      route-name="distribution.home"
    />
  </div>
</template>

<script setup lang="ts">
import { isBoolean } from 'lodash';
import { storeToRefs } from 'pinia';

import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DistributorData from '../../common/DistributorData.vue';
import StepButtons from '../../common/StepButtons.vue';
import { useGeneralStore, useTerminationStore } from '@/js/stores';
import { scrollToError, syncPersistedDistributor } from '@/js/utils';
import gtm from '@/js/services/gtm';
import { beforeStartFields } from '@/js/data/validationFields/distribution';
import DistributionNameMissingModal from '@/js/components/Modals/DistributionNameMissingModal.vue';

const validating = ref(false);
const missingDistributionName = ref(false);

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const { validateFields, validateField } = store;
const { terminationSettings, beforeStart } = storeToRefs(store);
const generalStore = useGeneralStore();

const isDistributionNameMissing = computed(() => {
  // csob-ps was removed from bank offer, but could stay in localStorage
  return beforeStart.value.distributionName.value === ''
    || beforeStart.value.distributionName.value === null
    || beforeStart.value.distributionName.value === 'csob-ps';
});

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: beforeStartFields,
    });

    validating.value = false;

    await router.push({
      name: 'termination.salesInterview',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('nez-zacneme');
  } catch (e) {
    validating.value = false;

    if (isDistributionNameMissing.value) {
      missingDistributionName.value = true;
    }

    if (beforeStart.value.isTiedAgent.value && typeof beforeStart.value.meetingAddress !== 'undefined') {
      beforeStart.value.meetingAddress.setManually.value = true;
    }

    scrollToError();

    console.warn(`There was a validation contactInformationError: ${e}`);
  }
}

onMounted(() => {
  if (route.query.bankId === 'true') {
    terminationSettings.value.isBankId.value = true;
    terminationSettings.value.withTransfer.value = false;
  }

  const { distributionName, isTiedAgent } = beforeStart.value;

  if (distributionName.value === null) {
    beforeStart.value.distributionName.value = generalStore.distribution.value;
  }

  if (!isBoolean(isTiedAgent.value)) {
    beforeStart.value.isTiedAgent.value = generalStore.isTiedAgent.value;
  }

  syncPersistedDistributor(beforeStart.value.isTiedAgent.value, beforeStart.value.distributionName.value);

  if (isDistributionNameMissing.value) {
    missingDistributionName.value = true;
  }
});
</script>
