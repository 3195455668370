import axios from '@/js/api/axios.js';

export async function create (contractUuid, state) {
  return axios.post(`/api/v1/contracts/${contractUuid}/meeting-records`, {
    state,
  });
}

export async function get (id) {
  return axios.get(`/api/v1/contracts/${id}/meeting-records`);
}

export default {
  create,
  get,
};
