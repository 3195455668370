<template>
  <div class="nominees-list">
    <div
      v-for="(nominee, index) in nominees"
      :key="index"
      class="nominee mb-30"
    >
      <div class="pl-34">
        <h3 class="nominee__title">
          Určená osoba č. {{ index + 1 }}

          <button
            v-if="nominees.length > 1"
            class="btn-simple btn-simple--red"
            :dusk="`remove-nominee-${index}`"
            @click="removeNominee(index)"
          >
            <i>
              <img
                :src="getIconUrl('icon-delete')"
                alt="Ikona smazat"
              >
            </i>

            <span>
              Odstranit osobu č. {{ index + 1 }}
            </span>
          </button>
        </h3>

        <ServerValidatedFormField
          v-slot="{ input, value, errors, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.${index}.firstName`"
          :validate-on-input="false"
          :debounce="0"
        >
          <LitInput
            class="mb-20"
            label="Jméno"
            placeholder="Např. Karel"
            :name="`nominee${index}-first-name`"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate(
              $event.target.value,
              `${storePath}.*.firstName`
            )"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, errors, input, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.${index}.lastName`"
          :validate-on-input="false"
          :debounce="0"
        >
          <LitInput
            class="mb-20"
            label="Příjmení"
            placeholder="Např. Novotný"
            :name="`lastName${index}`"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate(
              $event.target.value,
              `${storePath}.*.lastName`
            )"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, errors, input, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.${index}.personalId`"
          :validate-on-input="false"
          :debounce="0"
        >
          <LitInput
            v-if="nominee.usePersonalId.value"
            class="mb-20"
            label="Rodné číslo"
            placeholder="Např. 816008/0610"
            :name="`nominee${index}-personal-id`"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate(
              $event.target.value,
              `${storePath}.*.personalId`
            )"
            @keydown.space.prevent
          >
            <template #actions>
              <div class="actions-group">
                <button
                  class="btn-simple"
                  @click="updateNomineeField(index, 'usePersonalId', false)"
                >
                  Místo RČ zadat datum a stát narození
                </button>
              </div>
            </template>
          </LitInput>
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, errors, input }"
          :namespace="namespace"
          :validate-on-input="true"
          :field-path="`${storePath}.${index}.birthdate`"
          :debounce="0"
        >
          <DatePicker
            v-if="!nominee.usePersonalId.value"
            class="mb-20"
            label="Datum narození"
            :name="`nominee${index}-birthday`"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
          >
            <template #actions>
              <div class="actions-group">
                <button
                  class="btn-simple"
                  @click="updateNomineeField(index, 'usePersonalId', true)"
                >
                  Místo data a státu narození zadat rodné číslo
                </button>
              </div>
            </template>
          </DatePicker>
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, errors, input }"
          :namespace="namespace"
          :debounce="0"
          :validate-on-input="true"
          :field-path="`${storePath}.${index}.countryOfBirth`"
        >
          <LitSelect
            v-if="!nominee.usePersonalId.value"
            class="mb-20"
            label="Stát narození"
            :options="countryOfBirthOptions"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
          />
        </ServerValidatedFormField>

        <AddressEntry
          label="Trvalá adresa"
          :namespace="namespace"
          :validation-path="`${storePath}.*.permanentAddress`"
          tooltip="Postupným psaním názvu ulice, čísla popisného
          a PSČ si můžete ze seznamu vybrat potřebnou adresu."
          :searchable-country="true"
          :dusk="`permanent-address-${index}`"
          :name="`nominee${index}-permanentAddress`"
          :store-path="`${storePath}.${index}.permanentAddress`"
          :validate-fnc="props.validateFnc"
          :allow-autocomplete="isClientFromCzechia"
        >
          <template
            v-if="!props.allowPermanentAddressCopy"
            #autocomplete-actions
          >
            <button
              class="btn-simple"
              type="button"
              :dusk="`same-address-as-clients-${index}`"
              @click="useAddressFromPersonalData(index)"
            >
              Shodná s adresou účastníka smlouvy
            </button>
          </template>
        </AddressEntry>
      </div>
    </div>

    <div
      v-if="nominees.length === 1"
      class="custom-field part-full mb-30 pl-34"
    >
      <label>
        Podíl
      </label>

      <div class="control">
        Tato určená osoba <strong>dostane 100 % naspořených prostředků</strong>
      </div>
    </div>

    <button
      v-if="nominees.length < 2"
      class="btn-add mb-30"
      dusk="add-nominee"
      @click="addNominee"
    >
      <i>
        <img
          :src="getIconUrl('btn-plus')"
          alt="Ikona plus"
        >
      </i>

      <span>
        Přidat další určenou osobu
      </span>
    </button>

    <div
      v-if="nominees.length > 1"
      class="pl-34"
    >
      <h3>Rozdělení naspořené částky mezi určené osoby</h3>

      <div class="part-share">
        <div
          v-for="(person, index) in nominees"
          :key="index"
          class="part-share__item"
        >
          <div class="part-share__header mb-10">
            <div class="part-share__title">
              {{ person.firstName.value }} {{ person.lastName.value }}
            </div>

            <div class="part-share__subtitle">
              (Určená osoba č. {{ index + 1 }})
            </div>
          </div>

          <ServerValidatedFormField
            v-slot="{ value, errors }"
            :namespace="namespace"
            :field-path="`${storePath}.${index}.share`"
          >
            <LitInput
              class-name="control--xs"
              type="number"
              :min="1"
              :max="99"
              :show-append="true"
              :model-value="value"
              :errors="errors"
              @update:model-value="calculateShare(index, $event)"
            >
              <template #append>
                <span class="unit">%</span>
              </template>

              <template #number-actions>
                <div class="number-actions">
                  <button
                    class="number-actions__btn"
                    @click="incrementShare(index)"
                  >
                    +
                  </button>

                  <button
                    class="number-actions__btn"
                    @click="decrementShare(index)"
                  >
                    -
                  </button>
                </div>
              </template>
            </LitInput>
          </ServerValidatedFormField>
        </div>

        <RangeSlider
          v-model.number="nomineesShare"
          class="part-share__range"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cloneDeep, get, set } from 'lodash';

import { getActivePinia } from 'pinia';

import { computed, onMounted, watch } from 'vue';
import AddressEntry from './AddressEntry.vue';
import LitInput from './Base/LitInput.vue';
import LitSelect from './Base/LitSelect.vue';
import DatePicker from './DatePicker.vue';

import RangeSlider from './RangeSlider.vue';
import { clamp, getIconUrl } from '@/js/utils';
import { copyAddress } from '@/js/stores/utils';
import { countriesOfBirth as countryOfBirthOptions } from '@/js/data/countryOfBirthOptions';
import { addressField } from '@/js/stores/createStoreFields';
import { useStoreField } from '@/js/composables/useStoreField';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils';
import type { Nominee } from '@/js/stores/types';

interface Props {
  namespace: string
  storePath: string
  share: number
  allowPermanentAddressCopy?: boolean
  isClientFromCzechia?: boolean
  validateFnc: ValidateStoreParamsFnc
}

const props = withDefaults(defineProps<Props>(), {
  allowPermanentAddressCopy: true,
  isClientFromCzechia: true,
});

const emit = defineEmits(['update:share']);

const MAX_NOMINEE_SHARE = 100;

function createNominee () {
  return {
    firstName: useStoreField(''),
    lastName: useStoreField(''),
    personalId: useStoreField(''),
    usePersonalId: useStoreField(true),
    countryOfBirth: useStoreField('cz'),
    birthdate: useStoreField(''),
    share: useStoreField(100),
    permanentAddress: addressField(),
  };
}

const nomineesShare = computed({
  get () {
    return props.share;
  },
  set (newValue) {
    emit('update:share', newValue);
  },
});

const activeStore = computed(() => {
  const store = getActivePinia();

  if (!store) {
    throw new Error('Pinia store is not active');
  }

  return get(store.state.value, `${props.namespace}`);
});

const nominees = computed<Nominee[]>({
  get () {
    return get(activeStore.value, props.storePath) || [];
  },
  set (value) {
    set(activeStore.value, props.storePath, value);
  },
});

const isClientFromCzechia = computed(() => {
  return get(activeStore.value, 'personalData.permanentAddressArea.value') === 'cz';
});

function addNominee () {
  if ([...nominees.value].length >= 2) {
    return;
  }

  nominees.value = [
    ...nominees.value,
    createNominee(),
  ];

  nomineesShare.value = MAX_NOMINEE_SHARE / nominees.value.length;
}

function removeNominee (index: number) {
  nominees.value = [
    ...nominees.value.slice(0, index),
    ...nominees.value.slice(index + 1),
  ];

  nomineesShare.value = MAX_NOMINEE_SHARE / nominees.value.length;
}

function updateNomineeField (index: number, fieldPath: string, value: unknown) {
  if (index >= nominees.value.length) {
    return;
  }

  const updatedNominee = cloneDeep(nominees.value[index]);

  set(updatedNominee, `${fieldPath}.value`, value);

  nominees.value = [
    ...nominees.value.slice(0, index),
    updatedNominee,
    ...nominees.value.slice(index + 1),
  ];
}

function setShare (nomineeIndex: number, newShare: number) {
  updateNomineeField(nomineeIndex, 'share', newShare);
}

function calculateShare (nomineeIndex: number, share: number) {
  const clampedShare = clamp(0, MAX_NOMINEE_SHARE, share);

  if (nomineeIndex === 0) {
    nomineesShare.value = Number.parseFloat(clampedShare.toString());
  } else if (nomineeIndex === 1) {
    nomineesShare.value = MAX_NOMINEE_SHARE - Number.parseFloat(clampedShare.toString());
  } else {
    throw new Error('There should be only two nominees');
  }
}

function incrementShare (index: number, val = 1) {
  calculateShare(index, ([...nominees.value][index].share.value || 0) + val);
}

function decrementShare (index: number, val = 1) {
  calculateShare(index, ([...nominees.value][index].share.value || 0) - val);
}

function restoreNomineesShare () {
  nomineesShare.value = nominees.value[0].share.value || 0;
}

function useAddressFromPersonalData (index: number) {
  if (!props.allowPermanentAddressCopy) {
    return;
  }

  copyAddress(activeStore.value, {
    destination: `${props.storePath}.${index}.permanentAddress`,
    source: 'personalData.permanentAddress',
  });

  if (activeStore.value.personalData.permanentAddress.query.value === '') {
    set(activeStore.value, `${props.storePath}.${index}.permanentAddress.setManually.value`, true);
  }

  if (isClientFromCzechia.value) {
    set(activeStore.value, `${props.storePath}.${index}.permanentAddress.setManually.value`, false);
  } else {
    set(activeStore.value, `${props.storePath}.${index}.permanentAddress.setManually.value`, true);

    set(activeStore.value, `${props.storePath}.${index}.permanentAddress.setManually.value`, true);
  }
}

watch(nomineesShare, (newValue) => {
  setShare(0, newValue);
  setShare(1, MAX_NOMINEE_SHARE - newValue);
});

onMounted(() => {
  if (nominees.value.length === 0) {
    addNominee();
  } else {
    restoreNomineesShare();
  }
});
</script>

<style lang="scss" scoped>
@use 'sass:math';

@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.part-share {
  position: relative;

  @include media(min, $sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    flex: 1;

    &:nth-child(2) {
      @include media(min, $sm) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    /* stylelint-disable */
    &:nth-child(2) :deep(.field) {
      @include media(min, $sm) {
        justify-content: flex-end;

        .control__wrapper {
          flex-basis: auto;
        }
      }
    }

    /* stylelint-enable */

    @include media(max, $sm) {
      margin-bottom: 20px;
    }
  }

  &__range {
    display: none;

    @include media(min, $sm) {
      display: inherit;
      position: absolute;
      left: 101px;
      right: 101px;
      bottom: (math.div($input-height, 2));
      width: auto;
    }
  }

  &__title,
  &__subtitle {
    font-weight: $bold;
    line-height: 1.45;
  }

  &__title {
    font-size: $root;
  }

  &__header {
    min-height: 43px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }
}

.nominee {
  &__title {
    @include media(min, $sm) {
      display: flex;
      align-items: baseline;

      button {
        margin-left: 12px;
      }
    }

    @include media(max, $sm) {
      button {
        margin-top: 10px;
      }
    }
  }
}

.number-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;

  .number-actions__btn {
    height: math.div($input-height, 2);
    width: math.div($input-height, 2);
    color: getColor(light-blue);
    outline: none;
    font-size: $root;

    &:hover {
      background: getColor(light-blue);
      color: getColor(white);
    }

    &:focus {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(light-blue);
    }
  }
}

.actions-group {
  pointer-events: all;
}

.control.control--unit {
  input[type=number] {
    padding-right: 50px;
    font-weight: $bold;
  }

  .unit {
    right: math.div($input-height, 2);
    width: 25px;
    font-weight: $bold;
    color: inherit;
  }
}

.custom-field {
  &.part-full {
    margin-top: -10px;
  }

  label {
    margin-bottom: 10px;
    display: block;
  }

  @include media(min, $md) {
    display: flex;
    align-items: center;

    label {
      flex-basis: 177px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &--bank {
    &__label {
      @include media(min, $md) {
        align-self: flex-start;
        margin-top: 8px;
      }
    }

    :deep(.field-info) {
      margin-left: 0;
    }
  }
}
</style>
