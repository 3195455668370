<template>
  <DatePicker
    v-model:value="valueDate"
    :lang="lang"
    :class="(error || errors.length > 0) ? 'control--error' : ''"
  >
    <template #input>
      <LitInput
        ref="datePickerInput"
        :label="label"
        :name="name"
        :dusk="name"
        :placeholder="placeholder"
        :tooltip="tooltip"
        :show-append="true"
        :required="required"
        :type="type"
        :description="description"
        :model-value="modelValue"
        :error="error"
        :errors="errors"
        :setter="dateSetter"
        @update:model-value="$emit('update:modelValue', $event)"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
      >
        <template #append>
          <span
            class="unit"
            dusk="datepicker-icon"
          >
            <LitIconSvg icon-name="calendar" />
          </span>
        </template>

        <template #actions>
          <slot name="actions" />
        </template>
      </LitInput>
    </template>
  </DatePicker>
</template>

<script>
import { format } from 'date-fns';
import DatePicker from 'vue-datepicker-next';

import { getIconUrl } from '../utils';
import LitIconSvg from './Base/LitIconSvg.vue';

import LitInput from './Base/LitInput.vue';
// CSS
import 'vue-datepicker-next/index.css';

const MONTHS = [
  'Leden',
  'Únor',
  'Březen',
  'Duben',
  'Květen',
  'Červen',
  'Červenec',
  'Srpen',
  'Zaří',
  'Říjen',
  'Listopad',
  'Prosinec',
];

const WEEK_DAYS = [
  'Neděle',
  'Pondělí',
  'Úterý',
  'Středa',
  'Čtvrtek',
  'Pátek',
  'Sobota',
];

const WEEK_DAYS_MIN = [
  'Ne',
  'Po',
  'Út',
  'St',
  'Čt',
  'Pá',
  'So',
];

export default {
  components: {
    LitInput,
    DatePicker,
    LitIconSvg,
  },

  props: {
    description: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    modelValue: {
      type: String,
      default: '',
    },

    error: {
      type: String,
      default: '',
    },

    errors: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },

  emits: [
    'update:modelValue',
    'change',
    'blur',
  ],

  setup () {
    return {
      getIconUrl,
    };
  },

  data: () => ({
    time: null,
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
        months: MONTHS,
        weekdays: WEEK_DAYS,
        weekdaysMin: WEEK_DAYS_MIN,
      },
      monthFormat: 'MMMM',
      months: MONTHS,
    },
  }),

  computed: {
    valueDate: {
      get () {
        return this.modelValue;
      },

      set (val) {
        const date = this.dateSetter(format(val, 'dd. MM. yyyy'));

        this.$emit('update:modelValue', date);
        this.$emit('change', date);
      },
    },

  },

  mounted () {
    const picker = this.$refs.datePickerInput.inputRef;
    const events = ['mousedown', 'touchstart'];

    events.map((event) => picker.addEventListener(event, (e) => e.stopPropagation()));
  },

  methods: {

    dateSetter (date) {
      // Do not transform when deleting
      if (!this.valueDate || date.length <= this.valueDate.length) {
        return date;
      }
      // 1 -> 1. -> 1._ -> 1._1 -> 1._1. -> 1._1._ -> 1._1._2 ->
      // -> 1._1._20 -> 1._1._202 -> 1._1._2021
      //
      //
      // \d\d             += ._
      // \d\d?\.          += _
      // \d\d?\._\d\d?\.  += _
      // \d\d?\._\d\d     += ._

      const rules = [
        // '12' -> '12. '
        {
          regex: /^(\d\d)$/,
          action: (val) => `${val}. `,
        },
        // '9.' -> '09. '
        {
          regex: /^\d\.$/,
          action: (val) => `0${val} `,
        },
        // '09. 1.' -> '09. 01. '
        {
          regex: /^(\d\d\.\s)(\d\.)$/,
          replace: '$10$2 ',
        },
        // '09. 12' -> '09. 12. '
        {
          regex: /^\d\d\.\s\d\d$/,
          action: (val) => `${val}. `,
        },
      ];

      let transformed = date;

      for (let i = 0; i < rules.length; i += 1) {
        const { regex, action, replace } = rules[i];

        if (!regex) {
          console.warn(`Missing regex for rule no. ${i}, skipping.`);
          continue;
        }

        if (!regex.test(date)) {
          continue;
        }

        if (typeof action === 'function') {
          transformed = action(date);
        } else if (typeof replace === 'string') {
          transformed = transformed.replace(regex, replace);
        }
      }

      return transformed
        .replace(/[^0-9.\s]/g, '')
        .replace(/\s\.+$/, ' ')
        .substr(0, 12);
    },
  },
};
</script>

<style lang="scss" scoped>
/* stylelint-disable */
:deep(.mx-icon-calendar) {
  display: none !important;
}

:deep(.control--unit input) {
  font-weight: 700;
}
/* stylelint-enable */
</style>

<style lang="scss">
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

/* stylelint-disable */
.mx-calendar-header .mx-btn,
.mx-calendar-header .mx-calendar-header-label {
  color: getColor(green);
  font-weight: $bold;
  font-family: $fontDefault, Helvetica, Arial, sans-serif;
}

.mx-calendar-header {
  .mx-icon-left,
  .mx-icon-right,
  .mx-icon-double-right,
  .mx-icon-double-left {
    &::before,
    &::after {
      border-width: 3px 0 0 3px;
    }
  }
}

.mx-table-date .cell.not-current-month {
  color: getColor(light-blue) !important;
}

.mx-datepicker-main {
  color: getColor(default);

  @include media(min, $sm) {
    left: calc((100vw - 378px) / 2) !important;
  }
}

.mx-calendar-content {
  .cell {
    &:hover {
      color: getColor(default);
      background: getColor(lighter-blue);
    }
    &.active {
      background: getColor(blue) !important;
      color: getColor(white) !important;
    }
  }
}

.mx-table-date .today {
  color: getColor(blue) !important;
}

.mx-table th {
  font-weight: 700;
}

.mx-datepicker {
  width: 100%;
}

.mx-input-wrapper {
  pointer-events: none;
  user-focus: none;
}

.unit {
  cursor: pointer;
  font-size: 1rem !important;
  pointer-events: all;
}
/* stylelint-enable */
</style>
