<script setup lang="ts">
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { Field } from '@/js/stores/types.ts';
import { contracts } from '@/js/api';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitInput from '@/js/components/Base/LitInput.vue';

import LitModal from '@/js/components/Base/LitModal.vue';
import LitRadioGroup from '@/js/components/Base/LitRadioGroup.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import EmailInput from '@/js/components/EmailInput.vue';
import LitSelectBox from '@/js/components/LitSelectBox.vue';
import PhoneNumberInput from '@/js/components/PhoneNumberInput.vue';
import { useCheckTitleInName } from '@/js/composables/useTitleInName.ts';

import pensionCompanies from '@/js/data/pensionCompanies';
import { contactInformationFields } from '@/js/data/validationFields/commonFields';

import gtm from '@/js/services/gtm';
import { useOnlineAgreementStore } from '@/js/stores';
import { measureAdobeAnalytics, setStoreField } from '@/js/stores/utils';
import { EContractType } from '@/js/types/contract.ts';
import { getIconUrl, scrollToError } from '@/js/utils';

const store = useOnlineAgreementStore();
const { contractSettings, isOnlineJps, isChild, contractSelection, contactInformation } = toRefs(store);
const { validateField, validateFields } = store;

const router = useRouter();
const route = useRoute();

const validating = ref(false);
const displayBankIdFailedModal = ref(false);

const currentPensionCompanyOptions = ref(pensionCompanies);

const contractType = computed(() => {
  return contractSelection.value.type.value;
});

const isNewContract = computed(() => {
  return contractType.value === EContractType.Online;
});

const childSelectIsDisabled = computed(() => {
  return !isNewContract.value && contractType.value !== null;
});

const contractOptions = computed(() => {
  return [
    { value: 'Dospělého', isDisabled: false },
    { value: 'Dítě', isDisabled: childSelectIsDisabled.value },
  ];
});

const displayJpsBlock = computed(() => {
  return !route.query.jps || route.query.jps === 'true';
});

const currentCompanyName = computed(() => {
  return contractSelection.value.currentContract.companyName.value;
});

const childContractRequired = computed(() => {
  return route.query.smlouva === 'detska';
});

const firstNameWarning = useCheckTitleInName(computed<string>(() => contactInformation.value.firstName.value));
const lastNameWarning = useCheckTitleInName(
  computed<string>(() => contactInformation.value.lastName.value),
  'příjmení nesmí obsahovat titul',
);

function setChildContract () {
  if (childContractRequired.value) {
    contactInformation.value.contractFor.value = '1';
  }
}
// method to get S1 and S2 from URL params if there are any (= sjednatelská čísla)
function getAffiliatedNumbers () {
  if (route.query.s1) {
    contractSettings.value.s1.value = route.query.s1 as string;
  }

  if (route.query.s2) {
    contractSettings.value.s2.value = route.query.s2 as string;
  }
}

function getPromoCodeFromUrl () {
  if (route.query.kod) {
    contractSettings.value.usePromoCode.value = true;
    contractSettings.value.promoCode.value = route.query.kod as string;
  }
}

const showTransferInputs = computed(() => {
  return ['dps', 'trf'].includes(contractType.value);
});

function closeBankIdRejectModal () {
  displayBankIdFailedModal.value = false;
  router.replace({ query: undefined });
}

async function updateContractType (contractType: string) {
  const { contractUuid } = route.params;

  try {
    await contracts.update(contractUuid as string, {
      newContractType: contractType,
      formData: store.$state,
      validateOnly: [],
    });
  } catch (e) {
    console.warn(e);
  }
}

watch((contractType), async (contractType) => {
  if (contractType !== EContractType.Online) {
    setStoreField(store.$state, {
      fieldPath: `contactInformation.contractFor.value`,
      value: '0',
    });

    contactInformation.value.contractFor.value = '0';

    return await updateContractType(EContractType.JpsOnline);
  }

  return await updateContractType(EContractType.Online);
});

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid: contractUuid as string,
      throwOnErrors: true,
      fieldPaths: [
        ...contactInformationFields,
        'contactInformation.contractFor',
        'contractSelection.currentContract.contractNumber',
        'contractSelection.currentContract.companyName',
        'contractSelection.type',
      ],
    });

    validating.value = false;

    gtm.onStepSubmit('kontaktni-udaje');

    if (!showTransferInputs.value) {
      measureAdobeAnalytics({
        state: store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });
    }

    await router.push({
      name: 'onlineAgreement.personalDataEntryMethodSelection',
      params: {
        contractUuid,
      },
    });
  } catch (e) {
    validating.value = false;

    scrollToError();

    if (isChild.value) {
      console.warn(`There was a validation personalDataError: ${e}`);
    } else {
      console.warn(`There was a validation personalDataEntryMethodSelectionError: ${e}`);
    }
  }
}

const contractTypes = computed<Field[]>(() => [
  {
    value: 'online',
    title: 'Nemám',
    text: '',
    onlyBankId: false,
  },
  {
    value: 'dps',
    title: 'Mám doplňkové penzijní spoření a chci si ho převést k vám',
    text: 'Doplňkové penzijní spoření je novější typ penzijka uzavíraný od ledna 2013.',
    onlyBankId: true,
  },
  {
    value: 'trf',
    title: 'Mám penzijní připojištění a chci si ho převést k vám',
    text: 'Penzijní připojištění je starší typ penzijka uzavíraný do konce roku 2012.',
    onlyBankId: true,
  },
]);

const labels = computed(() => {
  if (isChild.value) {
    return {
      firstName: 'Jméno dítěte',
      lastName: 'Příjmení dítěte',
      email: 'E-mail zákonného zástupce',
      mobile: 'Mobil zákonného zástupce',
    };
  }

  return {
    firstName: 'Jméno',
    lastName: 'Příjmení',
    email: 'E-mail',
    mobile: 'Mobil',
  };
});

watch(isChild, async (newValue) => {
  if (newValue) {
    contractSettings.value.finishMethod.value = 'courier';
  } else {
    contractSettings.value.finishMethod.value = 'online';
  }
});

watch(currentCompanyName, async (newValue) => {
  if (isOnlineJps.value && newValue !== 'choose') {
    contractSelection.value.currentContract.companyName.errors = [];
  }
});

onMounted(() => {
  getAffiliatedNumbers();
  getPromoCodeFromUrl();
  setChildContract();

  const { contractUuid } = route.params;

  measureAdobeAnalytics({
    state: store.$state,
    action: 'formStepView',
    contractUuid,
    path: route.path,
    fields: [
      { storePath: 'contractSettings.s1.value', fieldName: 'distributornumber' },
    ],
  });

  if (route.query?.bankIdSign === 'failed') {
    displayBankIdFailedModal.value = true;
  }
});
</script>

<template>
  <div dusk="contact-information">
    <div class="benefits bg-blue py-30">
      <div class="container--sm">
        <h2 class="dash">
          Sjednejte si penzijní spoření rychle, jednoduše a online
        </h2>

        <div class="row">
          <div class="col-md-4">
            <div class="benefits__item">
              <i>
                <img
                  :src="getIconUrl('icon-benefit')"
                  alt="Ikona výhody"
                >
              </i>
              <span>Penzijní spoření si uzavřete <br>
                <strong>ve 4 jednoduchých krocích</strong>
              </span>
            </div>
          </div>

          <div class="col-md-4">
            <div class="benefits__item">
              <i>
                <img
                  :src="getIconUrl('icon-benefit')"
                  alt="Ikona výhody"
                >
              </i>

              <span>Se vším vám poradíme, <br> <strong>ale výběr bude na vás</strong></span>
            </div>
          </div>

          <div class="col-md-4">
            <div class="benefits__item">
              <i>
                <img
                  :src="getIconUrl('icon-benefit')"
                  alt="Ikona výhody"
                >
              </i>

              <span>Vyplnění žádosti zabere <strong>maximálně 10 minut</strong></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container--sm">
      <form
        dusk="contact-form"
        @submit.prevent="submitStep"
      >
        <div v-show="displayJpsBlock && !childContractRequired">
          <h2>Máte už jinde aktivní penzijní spoření?</h2>

          <ServerValidatedFormField
            v-slot="{ input, value, errors }"
            namespace="onlineAgreement"
            field-path="contractSelection.type"
            default-value="online"
            :debounce="0"
            validate-on-input
            @validate="validateField"
          >
            <LitSelectBox
              :fields="contractTypes"
              :error="errors.length > 0 ? errors[0] : ''"
              :model-value="value"
              height="150px"
              @update:model-value="input"
            />
          </ServerValidatedFormField>

          <div v-if="showTransferInputs">
            <div>
              <ServerValidatedFormField
                v-slot="{ value, input, errors }"
                namespace="onlineAgreement"
                field-path="contractSelection.currentContract.companyName"
                :debounce="0"
                @validate="validateField"
              >
                <LitSelect
                  label="Stávající penzijní společnost"
                  dusk="current-contract-company"
                  class="mb-20"
                  :options="currentPensionCompanyOptions"
                  :model-value="value"
                  :error="errors.length > 0 ? errors[0] : ''"
                  @update:model-value="input"
                />
              </ServerValidatedFormField>
            </div>

            <ServerValidatedFormField
              v-slot="{ value, input, errors, validate }"
              namespace="onlineAgreement"
              field-path="contractSelection.currentContract.contractNumber"
              :debounce="0"
              @validate="validateField"
            >
              <LitInput
                class="mb-20"
                type="number"
                name="currentContractNumber"
                dusk="current-contract-number"
                placeholder="Např. 5875737291"
                label="Číslo mé současné smlouvy"
                :model-value="value"
                :errors="errors"
                @update:model-value="input"
                @blur="validate($event.target.value)"
              />
            </ServerValidatedFormField>
          </div>
        </div>
        <div class="mb-20">
          <h2>Prozraďte nám něco o sobě...</h2>

          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="onlineAgreement"
            field-path="contactInformation.contractFor"
            default-value="0"
            :debounce="0"
            validate-on-input
            @validate="validateField"
          >
            <LitRadioGroup
              name="contractFor"
              dusk="contract-for"
              title="Zakládám smlouvu pro"
              :options="contractOptions"
              :error="errors[0]"
              :model-value="Number(value)"
              @update:model-value="input"
            />
          </ServerValidatedFormField>
        </div>

        <LitAlert
          v-if="isChild"
          class="mb-20"
          alert-type="warning"
          dusk="contract-info-alert"
        >
          Pokud zakládáte smlouvu pro nezletilého, vyplňte dále údaje tohoto nezletilého.
          Pouze kontaktní údaje zadejte na zákonného zástupce.
        </LitAlert>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="onlineAgreement"
          field-path="contactInformation.firstName"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            name="given-name"
            dusk="first-name"
            class="mb-20"
            placeholder="Např. Karel"
            :label="labels.firstName"
            :errors="errors"
            :warning="firstNameWarning"
            :model-value="value"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="onlineAgreement"
          field-path="contactInformation.lastName"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            name="family-name"
            dusk="last-name"
            class="mb-20"
            placeholder="Např. Novotný"
            :label="labels.lastName"
            :errors="errors"
            :warning="lastNameWarning"
            :model-value="value"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="onlineAgreement"
          field-path="contactInformation.email"
          :debounce="0"
          @validate="validateField"
        >
          <EmailInput
            name="email"
            dusk="email"
            :model-value="value"
            :label="labels.email"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="onlineAgreement"
          field-path="contactInformation.phoneNumber"
          :debounce="0"
          @validate="validateField"
        >
          <PhoneNumberInput
            class="mb-20"
            dusk="mobile"
            :show-tooltip="false"
            :label="labels.mobile"
            :error="errors.length > 0 ? errors[0] : ''"
            :model-value="value"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>
      </form>

      <p>
        Pokračováním v žádosti potvrzuji, že uvedené údaje jsou pravdivé,
        že souhlasím s poskytnutím předsmluvních a&nbsp;smluvních informací v elektronické podobě,
        že jsem seznámen s
        <a
          target="_blank"
          href="https://www.csob.cz/portal/csob/ochrana-osobnich-udaju"
        >
          Informacemi o zpracování osobních údajů</a>, a&nbsp;že&nbsp;mne
        můžete kontaktovat v souvislosti se žádostí.
      </p>
    </div>

    <div class="container my-30 center">
      <hr>

      <button
        class="btn btn-green btn-big btn-icon-right"
        dusk="submit-step"
        :class="{ disabled: validating }"
        :disabled="validating"
        @click="submitStep"
      >
        <span>
          Pokračovat
        </span>

        <LitIconSvg
          class="btn__icon btn__icon--end btn__icon--end--big"
          icon-name="arrow_white-right"
        />
      </button>
    </div>

    <LitModal
      v-model="displayBankIdFailedModal"
    >
      <template #header>
        <h3>
          Je nám líto, ale dokončení smlouvy přes BankID není možné
        </h3>
      </template>

      <template #body>
        <div class="center">
          <h3>Zkuste vyplnit smlouvu znovu nebo se obraťte na některého z našich poradců</h3>
          <p>
            Vyberte si <a
              target="_blank"
              href="https://www.csob-penze.cz/pobocky/"
            >
              jakoukoliv naši pobočku</a>
            nebo nás kontaktujte na telefonu
            <a href="tel:+420495800600">
              495 800 600
            </a>
            a my vám se založením penzijního spoření poradíme.
          </p>
        </div>
      </template>

      <template #footer>
        <div class="flex flex-center">
          <button
            dusk="close-modal"
            class="btn btn-big btn-green"
            @click="closeBankIdRejectModal"
          >
            ROZUMÍM
          </button>
        </div>
      </template>
    </LitModal>
  </div>
</template>

<style lang="scss">
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.benefits {
  &__item {
    display: flex;
    align-items: center;

    @include media(max, $md) {
      margin-bottom: 10px;
    }

    span {
      line-height: 1.45;
    }
  }

  h2 {
    margin: 0;

    @include media(max, $md) {
      font-size: rem(24);
    }
  }

  img {
    height: 41px;
    width: 41px;
    max-width: none;
    margin-right: 10px;
  }
}

.input-email {
  .control {
    input {
      padding-right: 81px;
    }

    .btn-email {
      font-family: $fontDefault, Helvetica, Arial, sans-serif;
      background: getColor(lighter-blue);
      color: getColor(default);
      border-radius: 5px;
      position: absolute;
      padding: 4px;
      right: 11px;
    }

    &:focus-within {
      .btn-email {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.btn-email {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
</style>
