export default [
  'Ak.arch.',
  'Ak.mal.',
  'Ak.soch.',
  'Arch.',
  'B.Pharm',
  'BBus',
  'Bc.',
  'Bc. et Bc.',
  'Bc.DiS.',
  'Bc.Ing.',
  'Bc.MBA',
  'Bc.Mgr.',
  'BcA.',
  'Dipl. Ing.',
  'Dipl.ek.',
  'Dipl.t.',
  'Dipl.tech.',
  'Doc.',
  'Doc.Ak.',
  'Doc.CSc.',
  'Doc.Dr.',
  'Doc.DrSc.',
  'Doc.Ing.',
  'Doc.JUDr.',
  'Doc.MUDr.',
  'Doc.PhDr.',
  'Doc.RNDr.',
  'Dott.arch.',
  'Dr.',
  'Dr. Dr.',
  'Dr. Ing.',
  'Dr.CSc.',
  'Dr.Ing.',
  'Ing.',
  'Ing. JUDr.',
  'Ing. Mgr.',
  'Ing. arch.',
  'Ing.Arch.',
  'Ing.Arch.Bc.',
  'Ing.Bc.',
  'Ing.CSc.',
  'Ing.DiS.',
  'Ing.Dr.',
  'Ing.DrSc.',
  'Ing.JUDr.',
  'Ing.MBA',
  'Ing.MVDr.',
  'Ing.Mgr.',
  'Ing.Ph.D.',
  'Ing.PhDr.',
  'Ing.RNDr.',
  'Ing.arch.',
  'JUDr.',
  'JUDr.CSc.',
  'JUDr.Ing.',
  'JUDr.PhDr.',
  'Lic.',
  'Licenciada',
  'MA',
  'MDDr.',
  'MUDr.',
  'MUDr.CSc.',
  'MUDr.Ing.',
  'MUDr.Mgr.',
  'MUDr.PhDr.',
  'MVDr.',
  'MVDr.CSc.',
  'Mag.',
  'MgA.',
  'MgA. Mgr.',
  'Mgr.',
  'Mgr. Bc.',
  'Mgr. PhDr.',
  'Mgr. art.',
  'Mgr. et Bc.',
  'Mgr. et Mgr.',
  'Mgr.A.',
  'Mgr.Bc.',
  'Mgr.Dr.',
  'Mgr.Ing.',
  'Mgr.PhD.',
  'PaeDr.',
  'PaedDr.',
  'PhD.Ing.',
  'PhDr.',
  'PhDr.CSc.',
  'PhDr.Ing.',
  'PhDr.Mgr.',
  'PhMr.',
  'Pharm.',
  'Pharm.Dr.',
  'PharmDr.',
  'Prof.',
  'Prof.CSc.',
  'Prof.Dr.',
  'Prof.DrSc.',
  'Prof.Ing.',
  'Prof.JUDr.',
  'Prof.MUDr.',
  'Prof.RNDr.',
  'Prom.Mat.',
  'RNDr.',
  'RNDr. Ing.',
  'RNDr.CSc.',
  'RNDr.Doc.CSc.',
  'RNDr.DrSc.',
  'RNDr.Ing.',
  'RNDr.PhDr.',
  'RNDr.PhMr.',
  'RSDr.',
  'Th.Mgr.',
  'ThDr.',
  'ThLic.',
  'abs. v. š.',
  'akad. arch.',
  'akad. mal.',
  'akad. soch.',
  'arch.',
  'csc',
  'doc.',
  'doc. Ing.',
  'doc. MUDr.',
  'doc.MgA.',
  'prof.',
  'prof. Ing.',
  'prom. biol.',
  'prom. chem.',
  'prom. ek.',
  'prom. fil.',
  'prom. filol.',
  'prom. fyz.',
  'prom. geog.',
  'prom. geol.',
  'prom. hist.',
  'prom. knih.',
  'prom. logop.',
  'prom. mat.',
  'prom. nov.',
  'prom. ped.',
  'prom. pharm.',
  'prom. práv.',
  'prom. psych.',
  'prom. vet.',
  'prom. zub.',
  'ArtD.',
  'BA',
  'BA (Hons)',
  'BBA',
  'BBS',
  'BSc',
  'CSc.',
  'DPhil',
  'DiS.',
  'DiS. MBA',
  'Dr.Sc.',
  'DrSc.',
  'Jnr.',
  'LL.M.',
  'MA',
  'MBA',
  'MBA, LL.M.',
  'MBA, MPA',
  'MSc',
  'P.ThLic.',
  'Ph.D.',
  'Ph.D. CSc.',
  'Ph.D., MBA',
  'PhD.',
  'Th.D.',
  'ThLic.',
  'dipl. ek.',
  'dipl. tech.',
  'dipl. um.',
  'dr. h. c.',
  'jr.',
  'ml.',
  'senior',
  'st.',
];
