<template>
  <div dusk="recapitulation">
    <TransferStepper current-step="recapitulation" />

    <RecapitulationTemplate
      form-type="žádosti"
      :error="error"
    >
      <template #recapitulation>
        <RecapitulationDistributor
          namespace="transfer"
          :store-path="beforeStart"
        />

        <hr class="my-30">

        <RecapitulationContactInformation
          v-if="isParticipant"
          namespace="transfer"
          :store-path="contactInformation"
          title="Kontaktní údaje klienta"
        />

        <hr
          v-if="isParticipant"
          class="my-30"
        >

        <RecapitulationPersonalData
          namespace="transfer"
          :store-path="personalData"
          :is-participant="isParticipant"
        />

        <hr class="my-30">

        <RecapitulationContactInformation
          v-if="!isParticipant"
          namespace="transfer"
          title="Kontaktní údaje podepisující osoby"
          :store-path="contactInformation"
        />

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <RecapitulationSignerPersonalData
          v-if="!isParticipant"
          namespace="transfer"
          :store-path="signer"
        />

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <!-- additional Data -->
        <table class="recapitulation-table mb-16">
          <thead>
            <tr>
              <th
                colspan="2"
              >
                <h3>Doplňující údaje k převodu</h3>
              </th>
              <th>
                <router-link
                  :to="{ name: 'transfer.additionalData' }"
                  class="edit-section"
                >
                  <LitIconSvg icon-name="edit_green" />

                  Upravit
                </router-link>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Číslo smlouvy, která má být ukončena</td>
              <td
                colspan="2"
              >
                {{ currentContractInformation.contractNumber.value }}
              </td>
            </tr>
            <tr>
              <td>Platnost smlouvy od</td>
              <td
                colspan="2"
              >
                {{ currentContractInformation.contractValidityFrom.value }}
              </td>
            </tr>
            <tr>
              <td>Orientační datum ukončení smlouvy</td>
              <td
                colspan="2"
              >
                {{ formatDate(currentContractInformation.terminationDay.value) }}
              </td>
            </tr>
          </tbody>
        </table>

        <hr class="my-30">

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="transfer"
          field-path="contractSettings.transferSignatureDate"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Datum podepsání převodu"
            name="transferSignatureDate"
            dusk="transfer-sign-date"
            disabled
            :model-value="(formatDate(value) as string)"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="transfer"
          field-path="contractSettings.transferSignaturePlace"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Místo podepsání převodu"
            name="transferSignaturePlace"
            dusk="transfer-sign-place"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <hr class="my-30">
      </template>
    </RecapitulationTemplate>

    <StepButtons
      class="mt-30 mb-30"
      namespace="transfer"
      previous-step="additionalData"
      @submit="submitStep()"
    />
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import StepButtons from '../../common/StepButtons.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import TransferStepper from '@/js/components/Steppers/TransferStepper.vue';
import gtm from '@/js/services/gtm';

import { useTransferStore } from '@/js/stores';
import { scrollToError } from '@/js/utils';
import RecapitulationContactInformation from '@/js/views/common/recapitulation/ContactInformation.vue';
import RecapitulationDistributor from '@/js/views/common/recapitulation/Distributor.vue';

import RecapitulationPersonalData from '@/js/views/common/recapitulation/PersonalData.vue';

import RecapitulationTemplate from '@/js/views/common/recapitulation/RecapitulationTemplate.vue';

import RecapitulationSignerPersonalData from '@/js/views/common/recapitulation/SignerPersonalData.vue';

const route = useRoute();
const router = useRouter();

const store = useTransferStore();
const { validateField, validateFields } = store;
const {
  beforeStart,
  currentContractInformation,
  contactInformation,
  contractSettings,
  isParticipant,
  signer,
  personalData,
} = storeToRefs(store);

const validating = ref(false);
const error = ref(false);
const dateFormat = ref('dd. MM. yyyy');
const today = ref(new Date());

const transferSignatureDate = computed({
  get () {
    return contractSettings.value.transferSignatureDate.value;
  },

  set (value) {
    contractSettings.value.transferSignatureDate.value = value;
  },
});

function setSignaturePlace () {
  if (typeof beforeStart.value.meetingAddress !== 'undefined') {
    contractSettings.value.transferSignaturePlace.value = beforeStart.value.meetingAddress.city.value;
  }
}

async function setContractSignatureDate () {
  const { contractUuid } = route.params;

  transferSignatureDate.value = format(today.value, 'yyyy-MM-dd');

  await validateFields({
    contractUuid,
    throwOnErrors: true,
    fieldPaths: ['contractSettings.transferSignatureDate'],
  });
}

function formatDate (date: string | null) {
  if (!date) {
    return null;
  }

  const dateToFormat = new Date(date);

  return format(dateToFormat, dateFormat.value);
}

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'contractSettings.transferSignatureDate',
        'contractSettings.transferSignaturePlace',
      ],
    });

    validating.value = false;

    await router.push({
      name: 'transfer.transferConfirmation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('rekapitulace');
  } catch (e: any) {
    validating.value = false;

    scrollToError();

    console.warn(`There was a validation transferConfirmationError: ${e}`);

    throw new Error(`Transfer contract ${contractUuid} submission error.
          Status code ${e?.response?.status}, Message: ${e?.response?.message}`);
  }
}

onMounted(() => {
  setContractSignatureDate();

  if (beforeStart.value.isTiedAgent.value && (contractSettings.value.transferSignaturePlace.value === null || contractSettings.value.transferSignaturePlace.value === '')) {
    setSignaturePlace();
  }
});
</script>
