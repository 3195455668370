<template>
  <div>
    <Stepper :current-step="1" />

    <div
      class="container--sm"
      dusk="documents-upload"
    >
      <h2>Nahrajte sken či fotografii 2 dokladů a pokračujte dále</h2>

      <LitAlert>
        Doklady můžete nahrát ve formátech <strong>JPG, JPEG, PNG.</strong>
        <br>
        Maximální velikost souboru je <strong>10 MB</strong>.
      </LitAlert>

      <h3>Občanský průkaz</h3>

      <div class="row">
        <div
          class="col-md-5 mb-10"
          dusk="front-idCard"
        >
          <ServerValidatedFormField
            v-slot="{ input, value, errors }"
            default-value=""
            namespace="onlineAgreement"
            field-path="documents.idCardFrontId"
            :debounce="0"
            @validate="validateField"
          >
            <DocumentUploader
              :model-value="value"
              :errors="errors"
              :contract-uuid="(route.params.contractUuid as string)"
              :use-ocr="true"
              :disabled="loading"
              name="idCardFront"
              title="Přední strana dokladu"
              button-text="přední stranu OP"
              document-type="id-card-front"
              description="Přetáhněte sem soubor s přední stranou OP nebo klikněte pro nahrání"
              @update:model-value="input"
              @uploading="changeLoadingState"
            />
          </ServerValidatedFormField>
        </div>

        <div
          class="col-md-5 mb-10"
          dusk="back-idCard"
        >
          <ServerValidatedFormField
            v-slot="{ errors, value, input }"
            namespace="onlineAgreement"
            field-path="documents.idCardBackId"
            default-value=""
            :debounce="0"
            :validate-on-input="true"
            @validate="validateField"
          >
            <DocumentUploader
              :model-value="value"
              :errors="errors"
              :contract-uuid="(route.params.contractUuid as string)"
              :disabled="loading"
              name="idCardBack"
              title="Zadní strana dokladu"
              button-text="zadní stranu OP"
              document-type="id-card-back"
              description="Přetáhněte sem soubor se zadní stranou OP nebo klikněte pro nahrání"
              @update:model-value="input"
              @uploading="changeLoadingState"
            />
          </ServerValidatedFormField>
        </div>
      </div>

      <h3>Řidičský průkaz nebo cestovní pas</h3>

      <div class="row">
        <div
          class="col-md-5 mb-10"
          dusk="front-otherDoc"
        >
          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="onlineAgreement"
            field-path="documents.secondDocumentFrontId"
            default-value=""
            :debounce="0"
            :validate-on-input="true"
            @validate="validateField"
          >
            <DocumentUploader
              :model-value="value"
              :errors="errors"
              :contract-uuid="(route.params.contractUuid as string)"
              :disabled="loading"
              name="secondDocumentFront"
              title="Přední strana dokladu"
              description="Přetáhněte sem soubor s přední stranou ŘP
            či pasu nebo klikněte pro nahrání"
              button-text="přední stranu"
              document-type="second-document-front"
              @update:model-value="input"
              @uploading="changeLoadingState"
            />
          </ServerValidatedFormField>
        </div>
      </div>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <div class="container--sm flex flex-between">
      <router-link
        :to="{ name: 'onlineAgreement.personalDataEntryMethodSelection' }"
        class="btn btn-big btn-icon-back btn-outline"
      >
        <LitIconSvg
          class="btn__icon btn__icon--big"
          icon-name="arrow_green-left"
        />

        <span>
          Zpět
        </span>
      </router-link>

      <button
        class="btn btn-green btn-big btn-icon-right"
        dusk="submit-step"
        :class="{ disabled: validating }"
        :disabled="validating"
        @click="submitStep"
      >
        <span>
          Pokračovat
        </span>

        <LitIconSvg
          class="btn__icon btn__icon--end btn__icon--end--big"
          icon-name="arrow_white-right"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { contracts } from '@/js/api';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

import DocumentUploader from '@/js/components/DocumentsUploader.vue';
import Stepper from '@/js/components/Steppers/Stepper.vue';
import gtm from '@/js/services/gtm';
import { useOnlineAgreementStore } from '@/js/stores';

import {
  measureAdobeAnalytics,
  restoreState,
  updateContract as updateStoreContract,
} from '@/js/stores/utils';

import { scrollToError } from '@/js/utils';

const store = useOnlineAgreementStore();
const { documents, personalData } = storeToRefs(store);
const { validateFields, validateField } = store;

const route = useRoute();
const router = useRouter();

const validating = ref(false);
const contractDocumentsRequired = ref(false);
const loading = ref (false);

const fields = shallowRef([
  'documents.idCardBackId',
  'documents.idCardFrontId',
  'documents.secondDocumentFrontId',
]);

const idCardFrontId = computed(() => {
  return documents.value.idCardFrontId.value;
});

const idCardBackId = computed(() => {
  return documents.value.idCardBackId.value;
});

const secondDocumentFrontId = computed (() => {
  return documents.value.secondDocumentFrontId.value;
});

const selectedEntryMethod = computed({
  get () {
    return personalData.value.selectedEntryMethod.value;
  },
  set (value) {
    personalData.value.selectedEntryMethod.value = value;
  },
});

function changeLoadingState (e: any) {
  loading.value = e.isUploading;
}

function updateContract (fields = []) {
  const { contractUuid } = route.params;

  updateStoreContract({
    state: store.$state,
    contractUuid: contractUuid as string,
    fields,
  });
}

function unsubscribe () {
  if (!window.Echo) {
    console.warn('Echo is not defined, skipping');
    return;
  }

  const { contractUuid } = route.params;

  window.Echo.leave(`contracts.${contractUuid}`);
  console.warn('Unsubscribed from contracts');
}

function listenForContractChanges () {
  if (!window.Echo) {
    console.warn('Echo is not defined, skipping');
    return;
  }

  const { contractUuid } = route.params;

  window.Echo.channel(`contracts.${contractUuid}`)
    .listen('ContractUpdated', async () => {
      console.warn('Contract has been changed');
      const { data } = await contracts.show(contractUuid as string);

      if (!data.form_data) {
        throw new Error('Missing form data');
      }

      const formData = JSON.parse(data.form_data);
      restoreState(store.$state, formData);

      console.warn('Contract reloaded');
    });

  console.warn('Listening for contract changes');
}

async function submitStep () {
  const { contractUuid } = route.params;

  unsubscribe();

  try {
    validating.value = true;
    selectedEntryMethod.value = 'upload';
    contractDocumentsRequired.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
      documentsSent: contractDocumentsRequired.value,
    });

    validating.value = false;

    gtm.onStepSubmit('upload-dokladu');

    measureAdobeAnalytics({
      state: store.$state,
      action: 'ufSubmit',
      contractUuid,
      path: route.path,
      fields: [
        { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
        { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
        { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
        { storePath: 'contactInformation.email.value', fieldName: 'email' },
        { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
        { fetcher: () => true, fieldName: 'marketingagreement' },
      ],
    });

    await router.push({
      name: 'onlineAgreement.personalData',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e) {
    contractDocumentsRequired.value = false;
    validating.value = false;
    scrollToError();

    console.warn(`There was a validation PersonalDataError: ${e}`);
  }
}

watch(idCardFrontId, (val) => {
  if (val === null) {
    updateContract();
  }
});

watch(idCardBackId, (val) => {
  if (val === null) {
    updateContract();
  }
});

watch(secondDocumentFrontId, (val) => {
  if (val === null) {
    updateContract();
  }
});

onMounted(() => {
  listenForContractChanges();
});
</script>
