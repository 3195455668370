<template>
  <div
    class="metro"
    :class="[{ show }, metroClass]"
  >
    <div :class="containerClass">
      <button
        class="metro-toggle"
        @click.prevent="show = !show"
      >
        <span>
          <span class="metro-number">
            {{ currentStep }}/{{ steps.length }}&nbsp;
          </span>

          <span class="metro__name">
            {{ currentStepTitle }}
          </span>
        </span>

        <span class="metro-arrow-down" />
      </button>

      <ul>
        <li
          v-for="step in steps"
          :key="step.stepNumber"
          :class="{
            active: step.stepNumber === currentStep,
            completed: step.stepNumber < currentStep
          }"
        >
          <component
            :is="step?.disabled ? 'a' : 'router-link'"
            v-if="step.stepNumber < currentStep"
            :to="step?.disabled ? null : step.uri"
          >
            <span class="metro__counter">
              <span class="metro-check">
                <img
                  :src="getIconUrl('step-check')"
                  alt=""
                >
              </span>

              <span class="metro-number">
                {{ step.stepNumber }}
              </span>
            </span>

            <span class="metro__name">
              {{ step.title }}
            </span>
          </component>
          <span v-else>
            <span class="metro__counter">
              <span class="metro-check">
                <img
                  :src="getIconUrl('step-check')"
                  alt=""
                >
              </span>

              <span class="metro-number">
                {{ step.stepNumber }}
              </span>
            </span>

            <span class="metro__name">
              {{ step.title }}
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed, onBeforeUnmount, ref, watch } from 'vue';

import { getIconUrl } from '../../utils';

const props = defineProps({
  currentStep: {
    type: Number,
    default: 1,
  },

  steps: {
    type: Array as PropType<{ stepNumber: number, title: string, uri: { name: string }, disabled?: boolean }[]>,
    default: () => ([]),
  },

  containerClass: {
    type: String,
    default: 'container--sm',
  },

  metroClass: {
    type: String,
    default: '',
  },
});

const show = ref(false);

const currentStepTitle = computed(() => {
  for (let i = 0; i < props.steps.length; i += 1) {
    if (props.steps[i].stepNumber === props.currentStep) {
      return props.steps[i].title;
    }
  }

  return '';
});

watch(show, (value, oldValue) => {
  if (!value && oldValue) {
    document.querySelector('body')
      ?.classList
      .remove('overflow-hidden');
  } else {
    document.querySelector('body')
      ?.classList
      .add('overflow-hidden');
  }
});

onBeforeUnmount(() => {
  document.body.classList.remove('overflow-hidden');
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

$steps-width: 100%;
$label-line-height: 41px;

$counter-font-size: rem(21);

$circle-diameter: 41px;
$circle-border-width: 2px;
$line-width: 2px;

.metro {
  $root: &;

  background: getColor(blue);
  font-family: $fontSecondary;
  font-size: $root;
  position: relative;
  z-index: 100;

  @include media(min, $md) {
    background: getColor(lighter-blue);
    padding: 30px 0;
  }

  @include media(max, $md) {
    &.show ul {
      display: block;
    }
  }

  ul {
    display: none;
    list-style: none;
    width: $steps-width;
    padding: 0;
    margin: 0;
    background: getColor(white);
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    box-shadow: inset 0 -1px 0 getColor(light-blue);

    @include media(min, $md) {
      box-shadow: none;
      position: static;
      display: flex;
      background: none;
    }

    @include media(max, $md) {
      padding-left: calc((100vw - 768px) / 2);
      padding-right: calc((100vw - 768px) / 2);
    }
  }

  li {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include media(min, $md) {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    a,
    & > span {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: getColor(default);
      padding: 10px 15px;
      font-size: $root;

      @include media(min, $md) {
        padding: 0 0 0 22px;

        &::before {
          content: '';
          position: absolute;
          z-index: -1;
          width: 14.5px;
          height: 14.5px;
          left: -10px;
          top: calc(50% - 6px);
          border: solid getColor(default);
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(-45deg);
        }
      }

      @include media-between($md, $lg) {
        @at-root #{$root}--wide :is(li > span, li a)::before {
          width: 10.5px;
          height: 10.5px;
          left: -6px;
          top: calc(50% - 5px);
        }
      }
    }

    &:first-child {
      @include media(min, $md) {
        a,
        & > span {
          padding-left: 0;

          &::before {
            content: none;
          }
        }
      }
    }

    &.active {
      .metro__counter {
        background: getColor(default);
        color: getColor(white);
      }

      &:not(:last-child) {
        border-bottom-color: getColor(default);
      }
    }

    &.completed {
      .metro__counter {
        background: getColor(green);
        color: getColor(white);
      }

      .metro__name {
        color: getColor(green);
      }

      .metro-number {
        display: none;
      }

      .metro-check {
        display: grid;
      }

      a::before {
        border-color: getColor(green);
      }

      &:not(:last-child) {
        border-bottom-color: getColor(green);
      }
    }

    @include media(max, $md) {
      &:not(:last-child) {
        border-bottom: 1px solid getColor(light-blue);
      }
    }
  }

  &__counter {
    background: getColor(light-blue);
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 10px;
    font-weight: $bold;
    font-size: $root-mini;

    @include media(min, $md) {
      background: getColor(white);
      font-size: 21px;
      width: 41px;
      height: 41px;
    }

    @include media-between($md, $lg) {
      @at-root #{$root}--wide & {
        font-size: 16px;
        width: 26px;
        height: 26px;
      }
    }
  }

  &__name {
    @include media(min, $md) {
      font-size: rem(16);
    }

    @include media-between($md, $lg) {
      @at-root #{$root}--wide & {
        font-size: rem(14);
      }
    }
  }

  &-check {
    display: none;

    @include media(max, $md) {
      width: 12px;

      img {
        width: 100%;
      }
    }

    @include media-between($md, $lg) {
      @at-root #{$root}--wide & {
        width: 10px;
      }
    }
  }

  &-number {
    @include media-between($md, $lg) {
      @at-root #{$root}--wide & {
        height: 15px;
      }
    }
  }

  &-toggle {
    color: getColor(white);
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    min-height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    outline: none;
    padding: 13px 0;

    .metro-arrow-down {
      border: solid getColor(white);
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      flex-shrink: 0;
      transform: rotate(45deg);
      margin-top: -3px;
    }

    @include media(min, $md) {
      display: none;
    }
  }

  &.show::after {
    content: '';
    background-color: rgba(0, 47, 79, .3);
    position: absolute;
    height: 100vh;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    backdrop-filter: blur(2px);
  }
}
</style>
