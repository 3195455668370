<template>
  <div dusk="recapitulation">
    <DistributionStepper current-step="recapitulation" />

    <RecapitulationTemplate
      form-type="smlouvy"
      :error="error"
    >
      <template #recapitulation>
        <RecapitulationDistributor
          namespace="distribution"
          :store-path="beforeStart"
        />

        <hr class="my-30">

        <RecapitulationConsents
          namespace="distribution"
        >
          <template #marketing>
            {{ consents.marketingPurposes.value === true ? 'Ano' : 'Ne' }}
          </template>
        </RecapitulationConsents>

        <hr class="my-30">

        <RecapitulationContactInformation
          v-if="isParticipant"
          namespace="distribution"
          title="Kontaktní údaje klienta"
          :store-path="contactInformation"
          :display-table-head="true"
        />

        <hr
          v-if="isParticipant"
          class="my-30"
        >

        <RecapitulationPersonalData
          namespace="distribution"
          :store-path="personalData"
          :is-participant="isParticipant"
        />

        <hr class="my-30">

        <!-- signer contact information -->
        <RecapitulationContactInformation
          v-if="!isParticipant"
          namespace="distribution"
          title="Kontaktní údaje podepisující osoby"
          :store-path="contactInformation"
        />

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <RecapitulationSignerPersonalData
          v-if="!isParticipant"
          namespace="distribution"
          :store-path="signer"
        />

        <hr
          v-if="!isParticipant"
          class="my-30"
        >

        <RecapitulationLegalRequirements
          namespace="distribution"
          :store-path="legalRequirements"
          :is-participant="isParticipant"
          :is-signer-politically-exposed="signer.isPoliticallyExposed.value ?? false"
        />

        <hr class="my-30">

        <RecapitulationContributionAndStrategy
          namespace="distribution"
          :store-path="contributionAndStrategy"
          :current-contract-store-path="contractSettings.currentContract"
          :transferring-current-contract="contractSettings.transferExistingRetirementSavings.value"
          :is-from-request="isFromRequest"
          :is-from-transfer="isFromTransfer"
          :is-granted-pension="legalRequirements.grantedPension.value"
        />

        <hr class="my-30">

        <RecapitulationContractSettings
          namespace="distribution"
          :store-path="contractSettings"
        />

        <hr class="my-30">

        <RecapitulationClientsStatement
          v-if="store.beforeStart.isTiedAgent.value && consents.clientStatement.value !== null && consents.clientStatement.value !== ''"
          namespace="distribution"
        >
          <template #statement>
            {{ consents.clientStatement.value }}
          </template>
        </RecapitulationClientsStatement>

        <hr
          v-if="store.consents.clientStatement.value !== null && store.consents.clientStatement.value !== ''"
          class="my-30"
        >

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="distribution"
          field-path="contractSettings.contractSignatureDate"
          :validate-on-input="true"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            name="contractSignDate"
            dusk="contract-sign-date"
            disabled
            :label="signatureDateLabel"
            :read-only="true"
            :model-value="formatDate(value)"
            :errors="errors"
            @update:model-value="input"
          />
        </ServerValidatedFormField>

        <!-- contract creating date -->
        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="distribution"
          field-path="contractSettings.contractCreationDate"
          :validate-on-input="true"
          :debounce="0"
          :default-value="defaultNewContractCreationDate.value"
          @validate="validateField"
        >
          <LitSelect
            class="mb-20 mt-30"
            dusk="contract-start-date"
            :label="isFromTermination
              ? 'Předpokládané datum vzniku penzijního spoření' : 'Datum vzniku penzijního spoření'"
            :tooltip="savingCreationDateTooltip"
            :options="dateOriginPensionSavingsOptions"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          />
        </ServerValidatedFormField>

        <hr class="my-30">
      </template>
    </RecapitulationTemplate>

    <div
      :class="['container--sm', 'mt-30', allowAnotherDeviceSignature ? 'mb-90' : 'mb-30']"
    >
      <div
        class="row"
        dusk="email-alert"
      >
        <div class="col-md-6">
          <MeetingRecordPreview
            v-if="store.beforeStart.isTiedAgent.value"
          />
        </div>
      </div>

      <div class="btn-recapitulation flex flex-between flex-v-stretch content-center my-30">
        <LitButton
          variant="outline"
          :to="{ name: 'distribution.clientsStatement' }"
        >
          <template #before>
            <ArrowLeft :height="20" :width="20" />
          </template>

          Zpět
        </LitButton>

        <div v-if="isFromRequest">
          <LitButton
            :dusk="isFromTransfer ? 'redirectToTransferConfirmation' : 'redirectToTerminationConfirmation'"
            @click="redirectToRequest"
          >
            POKRAČOVAT

            <template #after>
              <ArrowRight :height="20" :width="20" />
            </template>
          </LitButton>
        </div>

        <div v-if="!isFromRequest">
          <div v-if="!canSignerUseLitSign">
            <LitButton
              dusk="print-submit-step"
              @click="submitStep('offline')"
            >
              VYTISKNOUT SMLOUVU

              <template #after>
                <ArrowRight :height="20" :width="20" />
              </template>
            </LitButton>
          </div>

          <div v-else>
            <LitButton
              v-if="!isRedirected"
              class="text-uppercase"
              @click="openModal('digital')"
            >
              Podepsat elektronicky
            </LitButton>
            <LitButton
              v-else
              class="text-uppercase"
              @click="submitStep('digital')"
            >
              Podepsat elektronicky s&nbsp;klientem
            </LitButton>
          </div>
        </div>
      </div>

      <div
        v-show="canSignerUseLitSign && !isFromRequest"
        dusk="print-info"
        class="print-info"
      >
        <div class="container mt-30">
          <hr>
        </div>

        <PrintOffer @show-modal="openModal('offline')" />
      </div>

      <ContractSubmissionModal
        type="distribution"
        :model-value="submitting"
      />

      <ContractSubmissionModal
        type="distribution"
        :model-value="redirecting"
        :is-digital="true"
      >
        <template #action>
          <p
            v-if="signingLink !== null"
            class="text-loading text-center"
          >
            Pokud nedojde k přesměrování, klikněte prosím na
            <a
              :href="signingLink"
            >
              tento odkaz
            </a>.
          </p>
        </template>
      </ContractSubmissionModal>

      <ContractSigningModal
        v-model="showDigitalSignModal"
        @sign-with-phone="sendDraftToken('digital')"
        @sign-later="sendDraftToken('digital')"
        @sign-with-computer="submitStep('digital')"
      />

      <FinishWithPaperPrintModal
        form-type="contract"
        :model-value="showPrintConfirmationModal"
        @close="showPrintConfirmationModal = false"
        @click="submitStep('offline')"
      />

      <DateCorrectionModal
        form-type="distribution"
        :model-value="dateCorrectionModal"
        @click="closeDateCorrectionModal()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { add, addMonths, eachMonthOfInterval, format, lastDayOfMonth, startOfMonth } from 'date-fns';
import { ArrowLeft, ArrowRight } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { draftTokens, signatures } from '@/js/api';
import { fields as pathToFields } from '@/js/commonStepFieldPaths';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitInput from '@/js/components/Base/LitInput.vue';

import LitSelect from '@/js/components/Base/LitSelect.vue';
import MeetingRecordPreview from '@/js/components/MeetingRecordPreview.vue';
import ContractSigningModal from '@/js/components/Modals/ContractSigningModal.vue';
import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import DateCorrectionModal from '@/js/components/Modals/DateCorrectionModal.vue';
import FinishWithPaperPrintModal from '@/js/components/Modals/FinishWithPaperPrintModal.vue';
import PrintOffer from '@/js/components/PrintOffer.vue';
import DistributionStepper from '@/js/components/Steppers/DistributionStepper.vue';

import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat';
import { useDistributionStore, useTerminationStore, useTransferStore } from '@/js/stores';

import { copyRootFields, submitContract } from '@/js/stores/utils';
import { scrollToError } from '@/js/utils';
import RecapitulationClientsStatement from '@/js/views/common/recapitulation/ClientsStatement.vue';
import RecapitulationConsents from '@/js/views/common/recapitulation/Consents.vue';

import RecapitulationContactInformation from '@/js/views/common/recapitulation/ContactInformation.vue';
import RecapitulationContractSettings from '@/js/views/common/recapitulation/ContractSettings.vue';
import RecapitulationContributionAndStrategy from '@/js/views/common/recapitulation/ContributionAndStrategy.vue';
import RecapitulationDistributor from '@/js/views/common/recapitulation/Distributor.vue';
import RecapitulationLegalRequirements from '@/js/views/common/recapitulation/LegalRequirements.vue';
import RecapitulationPersonalData from '@/js/views/common/recapitulation/PersonalData.vue';
import RecapitulationTemplate from '@/js/views/common/recapitulation/RecapitulationTemplate.vue';
import RecapitulationSignerPersonalData from '@/js/views/common/recapitulation/SignerPersonalData.vue';

const fields = shallowRef([
  'contractSettings.contractCreationDate',
  'contractSettings.contractSignatureDate',
]);

const submitting = ref(false);

const validating = ref(false);
const error = ref(false);
const allowAnotherDeviceSignature = ref(false);
const dateFormat = ref('dd. MM. yyyy');
const signingLink = ref(null);
const redirecting = ref(false);
const isRedirected = ref(false);
const dateCorrectionModal = ref(false);
const showDigitalSignModal = ref(false);
const showPrintConfirmationModal = ref(false);

const router = useRouter();
const route = useRoute();

const store = useDistributionStore();
const {
  contractSettings,
  transferUuid,
  contributionAndStrategy,
  signer,
  personalData,
  beforeStart,
  consents,
  contactInformation,
  draftToken,
  legalRequirements,
  terminationUuid,
  submitResponse,
  isFromTransfer,
  isFromTermination,
  isFromRequest,
  isParticipant,
} = storeToRefs(store);
const { validateField, validateFields } = store;

const signatureDateLabel = computed(() => {
  switch (true) {
    case isFromTermination.value:
      return 'Datum podpisu žádosti o dávku';
    case isFromTransfer.value:
      return 'Datum podpisu žádosti o převod';
    default:
      return 'Datum podepsání smlouvy';
  }
});

const savingCreationDateTooltip = computed(() => {
  switch (true) {
    case isFromTermination.value:
    case isFromTransfer.value:
      return 'Datum vzniku Smlouvy se odvíjí od data ukončení původní Smlouvy a případné výpovědní lhůty. '
        + 'O případném posunu data vzniku nové Smlouvy bude klient písemně informován.';
    default:
      return 'Může být nejdříve první den následujícího měsíce od podpisu smlouvy. '
        + 'V případě převodu smlouvy musí navazovat na ukončení/zahájení '
        + 'výplaty původní smlouvy.';
  }
});

const contractStartDate = computed(() => {
  if (contractSettings.value.transferExistingRetirementSavings.value === true
    && contractSettings.value.currentContract.contractEndsAt.value !== null) {
    return new Date(contractSettings.value.currentContract.contractEndsAt.value as string);
  }

  return new Date();
});

const dateOriginPensionSavingsOptions = computed(() => {
  const rangeOfMonths = eachMonthOfInterval({
    start: add(contractStartDate.value, {
      months: 1,
    }),
    end: add(contractStartDate.value, {
      months: isFromTransfer.value ? 1 : 6,
    }),
  });

  return rangeOfMonths.map((date) => ({
    value: format(date, 'yyyy-MM-dd'),
    label: format(date, dateFormat.value),
  }));
});

const defaultNewContractCreationDate = computed(() => {
  const startDate = add(contractStartDate.value, { months: 1 });

  return {
    value: format(startOfMonth(startDate), 'yyyy-MM-dd'),
    label: format(startOfMonth(startDate), dateFormat.value),
  };
});

const isLegalRepresentative = computed(() => {
  return signer.value.type.value === 'legalRepresentative';
});

const canSignerUseLitSign = computed(() => {
  return isParticipant.value || isLegalRepresentative.value;
});

const signingLinks = computed(() => {
  return submitResponse.value.value?.metadata.signingLinks;
});

const currentContractEndDate = computed(() => {
  return contractSettings.value.currentContract.contractEndsAt.value;
});

const expectedContractEndDate = computed(() => {
  const addedMonths = 1;
  const expectedMinDate = addMonths(
    new Date(contractSettings.value.contractSignatureDate.value as string),
    addedMonths,
  );

  return lastDayOfMonth(expectedMinDate);
});

function closeDateCorrectionModal () {
  dateCorrectionModal.value = false;

  if (currentContractEndDate.value !== null) {
    contractSettings.value.currentContract.contractEndsAt.value = useDateTimeFormat(expectedContractEndDate.value, 'yyyy-MM-dd');
  }

  setStartDateOfNewPensionSavings();
}

function setStartDateOfNewPensionSavings () {
  if (!currentContractEndDate.value) {
    return;
  }

  const startDate = startOfMonth(add(new Date(currentContractEndDate.value), {
    months: 1,
  }));

  contractSettings.value.contractCreationDate.value = format(startDate, 'yyyy-MM-dd');
}

function areDatesValid () {
  if (contractSettings.value.currentContract.type.value === null) {
    return true;
  }

  if (currentContractEndDate.value !== null && expectedContractEndDate.value) {
    return currentContractEndDate.value >= useDateTimeFormat(expectedContractEndDate.value, 'yyyy-MM-dd');
  }
}

async function openModal (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    if (signMethod === 'digital') {
      showDigitalSignModal.value = true;
    }

    if (signMethod === 'offline') {
      showPrintConfirmationModal.value = true;
    }
  } catch (e: any) {
    throw new Error(e);
  }
}

function refillTransferFields () {
  const transferStore = useTransferStore();

  copyRootFields(store.$state, transferStore.$state, pathToFields());
}

function refillTerminationFields () {
  const terminationStore = useTerminationStore();

  copyRootFields(store.$state, terminationStore.$state, pathToFields());
}

async function redirectToTransferConfirmation () {
  const { contractUuid } = route.params;
  const transferId = transferUuid.value.value;
  const transferStore = useTransferStore();

  refillTransferFields();

  await validateFields({
    contractUuid,
    throwOnErrors: true,
    fieldPaths: [],
  });

  await transferStore.validateFields({
    contractUuid: transferId as string,
    throwOnErrors: true,
    fieldPaths: [],
  });

  await router.push({
    name: 'transfer.transferConfirmation',
    params: { contractUuid: transferId },
    query: {
      contract: 'ok',
      newContractUuid: contractUuid,
    },
  });
}

async function redirectToTerminationConfirmation () {
  const { contractUuid } = route.params;
  const terminationId = terminationUuid.value.value;
  const terminationStore = useTerminationStore();

  refillTerminationFields();

  await validateFields({
    contractUuid,
    throwOnErrors: true,
    fieldPaths: [],
  });

  await terminationStore.validateFields({
    contractUuid: terminationId as string,
    throwOnErrors: true,
    fieldPaths: [],
  });

  await router.push({
    name: 'termination.intermediateStep',
    params: { contractUuid: terminationId },
    query: {
      contract: 'ok',
      newContractUuid: contractUuid,
    },
  });
}

function redirectToRequest () {
  if (isFromTransfer.value) {
    redirectToTransferConfirmation();
  }

  if (isFromTermination.value) {
    redirectToTerminationConfirmation();
  }
}

function setContractSignatureDate () {
  contractSettings.value.contractSignatureDate.value = useDateTimeFormat(new Date(), 'yyyy-MM-dd');
}

function formatDate (date: string | null) {
  if (date === null) {
    return '';
  }
  const dateToFormat = new Date(date);
  return format(dateToFormat, dateFormat.value);
}

async function submitStep (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    showDigitalSignModal.value = false;
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    const isDigital = signMethod === 'digital';

    if (isDigital) {
      redirecting.value = true;
    } else {
      submitting.value = true;
    }

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), true);

    await submitContract(store.$state, {
      contractUuid: contractUuid as string,
      throwOnErrors: true,
      signature,
    });

    if (isDigital) {
      // @ts-expect-error: TODO: fix this
      signingLink.value = signingLinks.value[0].value;
      window.location.href = signingLink.value || '';
    } else {
      await router.push({
        name: 'distribution.thankYouPage',
        params: {
          contractUuid: route.params.contractUuid,
        },
      });
    }
  } catch (e: any) {
    error.value = true;
    submitting.value = false;
    redirecting.value = false;
    submitting.value = false;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });

    throw new Error(`Distribution new contract ${contractUuid} with signMethod ${signMethod} submission error.
          Status code ${e?.response?.status}, Message: ${e?.response?.message}`);
  } finally {
    validating.value = false;
  }
}

async function sendDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    const { data } = await draftTokens.create(contractUuid as string, signMethod);

    draftToken.value.value.value = data.draftToken;
    draftToken.value.value.expiresAt = data.validTo;

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature));

    await router.push({
      name: 'distribution.thankYouSignaturePage',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

watch(signingLink, (newValue) => {
  if (newValue !== null) {
    redirecting.value = false;
  }
});

onMounted(async () => {
  setContractSignatureDate();
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fields: [],
      validateAll: true,
      omit: ['contractSettings.contractSignatureDate', 'contractSettings.contractCreationDate'],
    });
  } catch (e) {
    console.error(e);
  }

  if (route.query.draftToken) {
    isRedirected.value = true;
  }

  if (route.query.draftToken && !areDatesValid()) {
    dateCorrectionModal.value = true;
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';

@include media(max, $xs) {
  .btn-recapitulation {
    flex-direction: column-reverse;
    gap: 16px;
  }
}

@include media-between($xs, $sm) {
  .lit-btn {
    font-size: 14px;
  }
}
</style>
