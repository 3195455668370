<template>
  <div dusk="payment-option">
    <TerminationStepper
      current-step="paymentOption"
    />
    <div
      :class="[isAdditionalPensionSaving ? 'container--md'
        : 'container--sm', 'my-30']"
    >
      <h1>Způsob výplaty</h1>
      <h2>Údaje o smlouvě</h2>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        namespace="termination"
        field-path="currentContractInformation.contractNumber"
        :debounce="0"
        @validate="validateField"
      >
        <LitInput
          dusk="contract-number"
          label="Číslo ukončované smlouvy"
          class="mb-20"
          placeholder="Např: 46553455435"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
        <div
          v-if="!isProduction"
          class="debug-buttons"
        >
          <button
            type="button"
            @click="contractNumber = '6907502610'"
          >
            <b>[DEBUG] Číslo smlouvy DPS: 6907502610</b>
          </button>
          <button
            type="button"
            @click="contractNumber = '7154897114'"
          >
            <b>[DEBUG] Číslo smlouvy TRF: 7154897114</b>
          </button>
        </div>
      </ServerValidatedFormField>

      <div v-if="isLitSignEnabled && isTwoSignaturesType">
        <hr>
        <h3>Kontaktní údaje druhého obchodního zástupce</h3>
        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="termination"
          field-path="beforeStart.secondDistributor.firstName"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="Jméno"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="termination"
          field-path="beforeStart.secondDistributor.lastName"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            :model-value="value"
            :errors="errors"
            label="Příjmení"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="termination"
          field-path="beforeStart.secondDistributor.email"
          :debounce="0"
          @validate="validateField"
        >
          <LitInput
            class="mb-20"
            label="E-mail"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>
      </div>

      <hr>
      <h3>Možné způsoby výplaty</h3>

      <div
        :class="[isAdditionalPensionSaving ? 'termination__types--md' : 'termination__types--sm']"
      >
        <div
          v-for="terminationOption in terminationOptions"
          :key="terminationOption.value"
          :dusk="terminationOption.dusk"
          :class="[terminationType === terminationOption.value
            ? 'active' : '', 'termination__option'
          ]"
        >
          <div
            v-if="!terminationOption.isEnabled"
            :class="['termination__forbidden-box']"
          >
            <p>{{ terminationOption.informationBox }}</p>
            <LitTooltip
              class="tool"
            >
              <p>{{ terminationOption.toolTip.header }}</p>
              <ul
                v-if="terminationOption.toolTip.texts"
              >
                <li
                  v-for="text in terminationOption.toolTip.texts"
                  :key="text"
                  class="tooltip__list"
                >
                  {{ text }}
                </li>
                <li>
                  Podrobnější informace
                  <a
                    target="_blank"
                    href="https://www.csob-penze.cz/distribuce/jak-vybrat-prostredky-z-dps-a-pp/#dashicons-css"
                  >
                    zde
                  </a>
                </li>
              </ul>
              <ul
                v-else
              >
                <li>
                  Podrobnější informace
                  <a
                    target="_blank"
                    href="https://www.csob-penze.cz/distribuce/jak-vybrat-prostredky-z-dps-a-pp/#dashicons-css"
                  >
                    zde
                  </a>
                </li>
              </ul>
            </LitTooltip>
          </div>

          <div
            v-if="!terminationOption.isEnabled"
            class="termination__option__forbidden"
          />

          <div
            :class="`termination__input ${terminationOption.value === 'oldAgePensionShortTerm'
              ? 'base-line'
              : ''}`"
          >
            <ServerValidatedFormField
              v-if="terminationOption.isEnabled"
              namespace="termination"
              field-path="terminationSettings.paymentOption"
              :validate-on-input="true"
              @validate="validateField"
            >
              <div class="contribution__input">
                <input
                  :id="terminationOption.value"
                  v-model="terminationType"
                  :value="terminationOption.value"
                  type="radio"
                >
                <span class="contribution__control" />
              </div>
            </ServerValidatedFormField>

            <label
              :for="terminationOption.value"
            >
              <h4 :class="terminationOption.class">
                {{ terminationOption.name }}
              </h4>
            </label>
          </div>
          <label
            :for="terminationOption.value"
          >
            <ul
              :class="isAdditionalPensionSaving
                ? 'termination__list--md' : 'termination__list--sm'"
            >
              <li
                v-for="list in terminationOption.bodyTextList"
                :key="JSON.stringify(list)"
              >
                {{ list }}
              </li>
              <li v-if="terminationOption.redirectLink && terminationOption.isEnabled">
                Podrobnější informace
                <a
                  target="_blank"
                  :href="terminationOption.redirectLink"
                >
                  zde
                </a>
              </li>
            </ul>
          </label>
        </div>
      </div>

      <div
        v-if="isNewContractEnabled"
      >
        <h3>Chcete převést část peněz na novou smlouvu?</h3>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="termination"
          field-path="terminationSettings.withTransfer"
          :default-value="true"
          :debounce="0"
          :validate-on-input="true"
          @validate="validateField"
        >
          <LitRadio
            dusk="termination-transfer"
            name="termination-transfer"
            :error="errors.length > 0 ? errors[0] : ''"
            :options="transfer"
            :model-value="value"
            @update:model-value="input"
          >
            <template #after>
              <div class="recommended--box">
                <p>
                  Doporučujeme
                </p>
              </div>
            </template>
          </LitRadio>
        </ServerValidatedFormField>
      </div>

      <hr class="my-30">
    </div>

    <StepButtons
      dusk="submit-step"
      namespace="termination"
      previous-step="personalData"
      :class="[isAdditionalPensionSaving ? 'container--md' : 'container--sm', 'p-0']"
      :fields="fields"
      @submit="submitStep"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import StepButtons from '../../common/StepButtons.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitTooltip from '@/js/components/Base/LitTooltip.vue';

import ServerValidatedFormField from '@/js/components/ServerValidatedFormField.vue';
import TerminationStepper from '@/js/components/Steppers/TerminationStepper.vue';
import env from '@/js/env';

import { useTerminationStore } from '@/js/stores';
import { scrollToError } from '@/js/utils';

import '@csob-penze/csob-penze-design/dist/style.css';

const MIN_AMOUNT_FOR_TWO_SIGNATURES = 150_000;
const MAX_AMOUNT_FOR_TWO_SIGNATURES = 300_000;

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const { validateField, validateFields } = store;
const {
  terminationSettings,
  currentContractInformation,
  hasClientEmail,
} = storeToRefs(store);

const fields = shallowRef([
  'currentContractInformation.contractNumber',
  'currentContractInformation.totalAmount',
  'terminationSettings.paymentOption',
  'beforeStart.secondDistributor.firstName',
  'beforeStart.secondDistributor.lastName',
  'beforeStart.secondDistributor.email',
]);

const transfer = shallowRef([
  { label: 'Ano, chci převést část peněz na novou smlouvu', value: true },
  { label: 'Ne, nechci převést a chci pouze ukončit smlouvu', value: false },
]);

const isLitSignEnabled = computed(() => {
  return env.ENABLE_LITSIGN;
});

const terminationType = computed({
  get () {
    return terminationSettings.value.paymentOption.value;
  },
  set (value) {
    terminationSettings.value.paymentOption.value = value;
  },
});

const isNewContractEnabled = computed(() => {
  // new requirement - new contract will not be offered during this process
  return false;
});

const isTwoSignaturesType = computed(() => {
  return currentContractInformation.value.totalAmount.value > MIN_AMOUNT_FOR_TWO_SIGNATURES
    && currentContractInformation.value.totalAmount.value <= MAX_AMOUNT_FOR_TWO_SIGNATURES;
});

const isAdditionalPensionSaving = computed(() => {
  return currentContractInformation.value.isAdditionalPensionSaving.value;
});

const oneTimeSettlementEnabled = computed(() => {
  return terminationSettings.value.oneTimeSettlement.isEnabled.value;
});

const isOldAgePensionAvailable = computed(() => {
  return terminationSettings.value.oldAgePension.isEnabled.value;
});

const contractNumber = computed({
  get () {
    return currentContractInformation.value.contractNumber.value;
  },
  set (value) {
    currentContractInformation.value.contractNumber.value = value;
  },
});

const isProduction = computed(() => {
  return env.APP_ENV === 'production';
});

const terminationOptions = computed(() => {
  if (isAdditionalPensionSaving.value) {
    return [
      {
        name: 'Jednorázové vyrovnání',
        bodyTextList: [
          'Peníze dostanete jednorázově na Váš účet po schválení výplaty',
          'Výnosy se daní 15%',
        ],
        value: 'oneTimeSettlement',
        dusk: 'dps-one-time-settlement',
        isEnabled: oneTimeSettlementEnabled.value,
        class: 'red',
        informationBox: 'Na tento typ výplaty nemáte nárok',
        toolTip: {
          header: 'Podmínkou pro výplatu úspor z jednorázového vyrovnání je:',
          texts: [
            'věk minimálně 60 let',
            'doba spoření alespoň 5 let (tj. 60 zaplacených měsíčních přípěvků)',
          ],
        },
      },
      {
        name: 'Starobní penze 3-9 let',
        bodyTextList: [
          'Peníze budete dostávat každý měsíc po dobu minimálně 3 let (dle vašeho výběru)',
          'Při výplatě penze po dobu 3 – 9 let budou zdaněny výnosy 15%',
        ],
        value: 'oldAgePensionShortTerm',
        dusk: 'dps-old-age-pension-short',
        isEnabled: isOldAgePensionAvailable.value,
        class: 'orange',
        informationBox: 'Na tento typ výplaty nemáte nárok',
        toolTip: {
          header: 'Podmínkou pro výplatu úspor z penzijního spoření formou starobní penze je:',
          texts: [
            'věk minimálně 60 let',
            'doba spoření alespoň 5 let (tj 60. zaplacených měsíčních přípěvků)',
          ],
        },
      },
      {
        name: 'Starobní penze 10 a více let',
        bodyTextList: [
          'Peníze budete dostávat každý měsíc po dobu minimálně 10 let',
          'Při starobní penzi na 10 či více let státu nic nevracíte',
        ],
        value: 'oldAgePensionLongTerm',
        dusk: 'dps-old-age-pension-long',
        isEnabled: isOldAgePensionAvailable.value,
        class: 'green',
        informationBox: 'Na tento typ výplaty nemáte nárok',
        toolTip: {
          header: 'Podmínkou pro výplatu úspor z penzijního spoření formou starobní penze je:',
          texts: [
            'věk minimálně 60 let',
            'doba spoření alespoň 5 let (tj 60. zaplacených měsíčních přípěvků)',
          ],
        },
      },
      {
        name: 'Odbytné',
        bodyTextList: [
          'Peníze dostanete jednorázově na Váš účet po schválení výplaty',
          'Přijdete o státní příspěvky a výnosy i příspěvky zaměstnavatele budou zdaněny 15%',
        ],
        value: 'smartMoney',
        dusk: 'dps-smart-money',
        isEnabled: !oneTimeSettlementEnabled.value,
        class: 'red',
        informationBox: 'Nevýhodný typ výplaty',
        redirectLink: 'https://www.csob-penze.cz/distribuce/jak-vybrat-prostredky-z-dps-a-pp/',
        toolTip: {
          header: 'Výplata odbytného není vhodná.',
          texts: [
            'V případě existence nároku na více způsobů výplaty z nich bude možné vybrat v dalších krocích žádosti.',
          ],
        },
      },
    ];
  }

  return [
    {
      name: 'Jednorázové vyrovnání',
      bodyTextList: [
        'Peníze dostanete jednorázově na Váš účet po schválení výplaty',
        'Výnosy se daní 15%',
      ],
      value: 'oneTimeSettlement',
      dusk: 'trf-one-time-settlement',
      isEnabled: oneTimeSettlementEnabled.value,
      class: 'red',
      informationBox: 'Na tento typ výplaty nemáte nárok',
      toolTip: {
        header: 'Podmínkou pro výplatu jednorázového vyrovnání z transformovaného fondu stabilita je: ',
        texts: [
          '60 zaplacených měsíců a dosažení věku 60 let , případně 50 let (určuje penzijní plán podle kterého je smlouva uzavřena)',
        ],
      },
    },

    {
      name: 'Odbytné',
      bodyTextList: [
        'Peníze dostanete jednorázově na Váš účet po schválení výplaty',
        'Přijdete o státní příspěvky a výnosy i příspěvky zaměstnavatele budou zdaněny 15%',
      ],
      value: 'smartMoney',
      dusk: 'trf-smart-money',
      isEnabled: !oneTimeSettlementEnabled.value,
      class: 'red',
      informationBox: 'Nevýhodný typ výplaty',
      toolTip: {
        header: 'Výplata odbytného není vhodná.',
        texts: [
          'V případě existence nároku na více způsobů výplaty z nich bude možné vybrat v dalších krocích žádosti',
        ],
      },
    },
  ];
});

const validating = ref(false);
async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    await router.push({
      name: 'termination.additionInformation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e) {
    validating.value = false;

    scrollToError();
    console.error(`There was a validation ContractDocumentsAndConsentsError: ${e}`);
  }
}

watch(terminationType, () => {
  const defaultLongPensionYears = 13;
  const defaultShortPensionYears = 3;

  terminationSettings.value.withTransfer.value = false;

  if (terminationType.value === 'oldAgePensionShortTerm') {
    terminationSettings.value.oldAgePension.selectedYears.value = defaultShortPensionYears;
  }

  if (terminationType.value === 'oldAgePensionLongTerm') {
    terminationSettings.value.oldAgePension.selectedYears.value = defaultLongPensionYears;
  }
});

onMounted(() => {
  if (!hasClientEmail.value) {
    terminationSettings.value.withTransfer.value = false;
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/settings/config';
@import '@sass/tools/mixins';

.termination__input,
.signature__input {
  display: flex;
  align-items: flex-end;
}

.signature__input {
  margin-bottom: 10px;
}

.termination__input {
  cursor: pointer;
  align-items: center;

  .red {
    color: #c9141c;
  }

  .orange {
    color: #ff6000;
  }

  .green {
    color: var(--color-green);
  }
}

.termination__option-select {
  margin-left: 10px;
}

.termination__input h4,
.signature__input h4 {
  margin-left: 10px;
  margin-bottom: 0;
}

.signature__input h4 {
  font-weight: bold;
  font-size: 1rem;
}

.termination__type {
  max-height: 100px;
}

.signature__type {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.termination__types--md {
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}

.termination__types--sm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.year-select {
  width: 70px;
  margin-left: 20px;
  color: orange;
}

.termination__option,
.signature__option {
  box-sizing: border-box;
  padding: 8.5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(206, 227, 234, 1);
  justify-content: space-evenly;
  position: relative;
  border-radius: 5px;

  &__forbidden {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    cursor: none;
    background-color: #e6eaf1;
    box-shadow: 0 4px 9px 0 rgb(190 204 208 / 75%);
    opacity: 50%;
  }
}

.termination__forbidden-box {
  width: 90%;
  margin: auto;
  background: rgba(59, 59, 59, .7);
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  padding: .3rem .5rem;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  box-sizing: border-box;
  border-radius: 6px;

  p {
    margin: 0;
    font-weight: bold;
  }

  .tooltip__list {
    text-align: left;
    max-width: 240px;
  }
}

.amount {
  white-space: nowrap;

  .red {
    background: #c9141c;
    color: white;
  }

  .orange {
    background: #ff6000;
    color: white;
  }

  .green {
    background: var(--color-green);
    color: white;
  }
}

.signature--footer {
  box-sizing: border-box;
  padding: 0 .5rem;
  display: flex;
  margin: auto;
  justify-content: space-between;
  background: var(--color-lighter-blue);

  &--icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--text {
    margin: 0 0 0 .9rem;
  }
}

.termination__option {
  box-shadow: 0 4px 9px rgb(190 204 208 / 75%);
  position: relative;
  margin-top: 20px;
  padding: 2em 1em 1em;
}

.termination__option > *,
.signature__option > * {
  cursor: pointer;
}

.text-bold {
  margin-left: -5px;
}

.active {
  background-color: rgba(120, 195, 28, .098);
  border-width: 1.5px;
  border-style: solid;
  border-color: rgba(120, 195, 28, 1);
  border-radius: 3px;
}

.border-none {
  border: none;
}

.amount__price--bold {
  border-radius: 5px;
  font-weight: bold;
  box-sizing: border-box;
  padding: .2rem .5rem;
  white-space: nowrap;
  margin-left: 10px;
}

.amount--bold {
  font-size: 1.1rem;
  white-space: nowrap;
  box-sizing: border-box;
  letter-spacing: - .3px;
}

.text-center {
  text-align: center;
}

.base-line {
  align-items: baseline;
}

.recommended--box {
  position: relative;
  top: -66px;
  left: 334px;
  width: 105px;
  background-color: rgba(120, 195, 28, 1);
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  p {
    margin: 0;
  }
}

li {
  margin-top: .8rem;
  text-align: left;
}

h4 {
  padding: 0;
  margin-top: 0 !important;
  cursor: pointer;
}

.tooltip__text {
  max-height: 50px;
  text-align: left;
}

.termination__list--md {
  min-height: 120px;
}

.termination__list--sm {
  min-height: 90px;
}

.tool {
  white-space: normal;

  p {
    text-align: left;
  }
}

input {
  vertical-align: middle;
  margin-bottom: .2px;
}

.debug-buttons {
  display: flex;
  flex-direction: column;
}
</style>
