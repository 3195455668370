import type { Nominee, NullableString, SubmitResponse } from '@/js/stores/types';
import { useStoreField } from '@/js/composables/useStoreField';
import {
  beforeStart,
  contactInformation,
  contributionAndStrategy,
  legalRequirements,
  personalData,
  personalDocument,
  signer,
} from '@/js/stores/createStoreFields';

export const state = {
  rehydrated: useStoreField(false, false),

  beforeStart: beforeStart({}),

  contactInformation: {
    ...contactInformation(),
    phonePrefix: useStoreField('420'),
  },

  personalData: {
    ...personalData({}),
    birthDate: useStoreField(''),
    gender: useStoreField(''),
    permanentAddressArea: useStoreField('cz'),
    citizenshipInEu: useStoreField(true),
    permanentAddressInEu: useStoreField(true),
    insuranceNumbers: {
      medicalInsurance: useStoreField<NullableString>(''),
      pensionInsurance: useStoreField<NullableString>(''),
      retirementPension: useStoreField<NullableString>(''),
    },

    document: personalDocument({}),
  },

  signer: signer({}),

  legalRequirements: {
    ...legalRequirements(),
    grantedPension: useStoreField(false),
  },

  contributionAndStrategy: contributionAndStrategy({}),

  contractSettings: {
    contractCreationDate: useStoreField<NullableString>(null),
    signatureBank: useStoreField<NullableString>('', false),
    contractSignatureDate: useStoreField<NullableString>(null),
    contractSignaturePlace: useStoreField<NullableString>(''),

    taxOptimization: useStoreField(false),
    contributionFromEmployer: useStoreField(true),

    employerInfo: {
      name: useStoreField<NullableString>(null),
      vat: useStoreField<NullableString>(null),
    },

    transferExistingRetirementSavings: useStoreField(true),

    currentContract: {
      type: useStoreField<NullableString>(null),
      companyName: useStoreField<NullableString>(null),
      contractNumber: useStoreField<NullableString>(null),
      contractEndsAt: useStoreField<NullableString>(null),
    },

    specifyNominees: useStoreField(false),
    nominees: [] as Nominee[],
    nomineesShare: useStoreField(50),
  },

  consents: {
    marketingPurposes: useStoreField(false),
    sharingInformation: useStoreField(true),
    usingEmailAddress: useStoreField(true),
    clientStatement: useStoreField(''),
  },

  draftToken: useStoreField<{ value: NullableString, expiresAt: NullableString }>({
    value: null,
    expiresAt: null,
  }, false),

  meetingRecordFilePath: useStoreField<NullableString>(null),

  // These value is received at the very and after contract is submitted
  submitResponse: useStoreField<SubmitResponse | null>(null, false),
};
