<script lang="ts" setup generic="T extends StepperOption">
import { objectEntries, useMediaQuery, useStepper } from '@vueuse/core';
import { h, resolveComponent, useAttrs, watchEffect } from 'vue';
import type { DropdownItem } from '@/js/components/Base/LitDropdown.vue';
import type { StepperOption } from '@/js/stores/types';
import LitDropdown from '@/js/components/Base/LitDropdown.vue';

import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  currentStep: keyof T
  steps: T
}>();

const attrs = useAttrs();

const {
  steps,
  next,
  previous,
  isFirst,
  isLast,
  current,
  stepNames,
  index,
  isCurrent,
  goTo,
  isAfter,
  isBefore,
} = useStepper(props.steps);

watchEffect(() => {
  goTo(props.currentStep as any);
});

const items = objectEntries(steps.value).map(([key, value]) => ({
  label: value.title,
  to: value.uri,
  disabled: isBefore(key as any),
  isActive: isCurrent(key as any),
})) as DropdownItem[];

const isDesktop = useMediaQuery('(min-width: 991px)');

const routerLinkComponent = resolveComponent('RouterLink');

const renderPrev = () => h('div', { class: 'stepper__prev' }, [
  !isFirst.value && previous.value
  && h(routerLinkComponent, { to: steps.value[previous.value].uri }, () => [
    h('span', { class: 'flex content-center' }, [
      h(LitIconSvg, { iconName: 'arrow_left-blue' }),
      isDesktop.value && h('span', { class: 'stepper__prev--text' }, (steps.value as any)[previous.value].title),
    ]),
  ]),
]);

const renderNext = () => h('div', { class: 'stepper__next text-right' }, [
  !isLast.value && next.value && isDesktop.value
  && h('span', `Další krok: ${(steps.value as any)[next.value].title}`),
  !isLast.value && next.value && !isDesktop.value
  && h(LitIconSvg, { iconName: 'checklist-blue' }),
]);
</script>

<template>
  <section class="bg-blue stepper" v-bind="attrs">
    <renderPrev v-if="isDesktop" />

    <div class="stepper__content">
      <div class="stepper__content--wrapper flex content-center">
        <renderPrev v-if="!isDesktop" />

        <h3>
          <LitDropdown v-slot="{ open }" offset="2.375rem" :items="items">
            {{ current.title }} ({{ index + 1 }}/{{ stepNames.length }}) <LitIconSvg :class="{ active: open }" icon-name="arrow_up-blue" />
          </LitDropdown>
        </h3>

        <renderNext v-if="!isDesktop" />
      </div>

      <div class="stepper__indicator">
        <span
          v-for="step in stepNames"
          :key="step"
          :class="{
            current: isCurrent(step),
            prev: isAfter(step)
          }"
        />
      </div>

      <div v-if="!isDesktop && next && !isLast" class="stepper__content--next">
        Další krok: {{ steps[next].title }}
      </div>
    </div>

    <renderNext v-if="isDesktop" />
  </section>
</template>

<style scoped lang="scss">
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.stepper {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media(min, $sm) {
    padding: 30px;

    & > div {
      flex-basis: 100%;
    }
  }

  :deep(.stepper__prev),
  :deep(.stepper__next) {
    &, a {
      color: getColor('default');
      font-size: $root;
      text-decoration: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    &--wrapper {
      margin-bottom: 1rem;
      justify-content: space-between;
      width: 100%;

      @include media(min, $md) {
        white-space: nowrap;
        justify-content: center;
      }
    }

    h3 {
      margin: 0 1rem;
    }

    svg {
      transform: rotate(180deg);
      display: inline-block;
      margin-left: 10px;

      &.active {
        transform: rotate(0);
      }
    }

    &--next {
      color: getColor('default');
      opacity: .5;
      font-size: $root-mini;
      margin-top: 12px;
    }
  }

  .stepper__indicator {
    display: inline-flex;
    width: 100%;
    justify-content: center;

    & > span {
      background: getColor('light-blue');
      width: 33px;
      display: block;
      height: 4px;
      margin: 0 4px;
      border-radius: 4px;
      flex-basis: 100%;

      @include media(min, $md) {
        flex-basis: auto;
        width: 46px;
        height: 6px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &.prev {
        background: getColor('green');
      }

      &.current {
        background: getColor('default');
      }
    }
  }

  :deep(.stepper__prev--text) {
    display: block;
    margin-left: 16px;
  }
}
</style>
