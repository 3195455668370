<template>
  <div class="the-box">
    <div v-if="$slots.icon" class="the-box__icon">
      <slot name="icon" />
    </div>

    <div class="the-box__description text-center">
      <slot />
    </div>

    <slot name="button" />
  </div>
</template>

<style lang="scss" scoped>
.the-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 12px;
  border: 1px solid #e5e5e5;
  min-height: 238px;

  &__icon {
    min-height: 70px;
  }

  &__description {
    flex-grow: 1;
    margin: 12px 0 24px;
  }
}
</style>
