<template>
  <div>
    <Stepper
      :current-step
      :steps="steps"
    />
  </div>
</template>

<script lang="ts" setup>
import { shallowRef } from 'vue';

import Stepper from './StepperDistribution.vue';

type Step = typeof steps.value;

defineProps<{
  currentStep: keyof Step
}>();

const steps = shallowRef({
  clientData: {
    title: 'Identifikace účastníka',
    uri: { name: 'changeRequest.clientData' },
  },
  contractSettings: {
    title: 'Úprava nastavení smlouvy',
    uri: { name: 'changeRequest.contractSettings' },
  },
  recapitulation: {
    title: 'Rekapitulace',
    uri: { name: 'changeRequest.recapitulation' },
  },
});
</script>
