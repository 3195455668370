<template>
  <div>
    <JpsStepper current-step="salesInterview" />

    <SalesInterview />

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="jps"
      previous-step="beforeStart"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import JpsStepper from '@/js/components/Steppers/JpsStepper.vue';
import { scrollToError } from '@/js/utils';
import SalesInterview from '@/js/views/common/SalesInterview.vue';

import StepButtons from '@/js/views/common/StepButtons.vue';

const route = useRoute();
const router = useRouter();

async function submitStep () {
  try {
    await router.push({
      name: 'jps.contactInformation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  }
}
</script>
