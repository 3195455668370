import { add, eachMonthOfInterval, endOfMonth, format, startOfMonth } from 'date-fns';

export const useDateRange = (monthsCount: number, value: string | null = null, isFirstDayOfMonth: boolean = true) => {
  const date = value === null ? new Date() : new Date(value);

  const rangeOfMonths = eachMonthOfInterval({
    start: add(date, {
      months: 1,
    }),
    end: add(date, {
      months: monthsCount,
    }),
  });

  if (isFirstDayOfMonth) {
    return rangeOfMonths.map((formattedDate) => ({
      value: format(startOfMonth(formattedDate), 'yyyy-MM-dd'),
      label: format(startOfMonth(formattedDate), 'dd. MM. yyyy'),
    }));
  }

  return rangeOfMonths.map((formattedDate) => ({
    value: format(endOfMonth(formattedDate), 'yyyy-MM-dd'),
    label: format(endOfMonth(formattedDate), 'dd. MM. yyyy'),
  }));
};
