<template>
  <div>
    <div class="blue-box" />

    <div class="container--xs mt-20">
      <div v-if="beginning">
        <p>
          Nyní vyfoťte své doklady.
        </p>

        <ul class="list-unstyled">
          <li class="flex content-center">
            <i>
              <img
                alt="Iconka občanský průkaz"
                :src="getIconUrl('icon-doklad')"
              >
            </i>

            <span class="text-bold ml-10">
              Občanský průkaz
            </span>
          </li>

          <li class="flex content-center">
            <i>
              <img
                alt="Iconka občanský průkaz"
                :src="getIconUrl('icon-doklad_auto')"
              >
            </i>

            <span class="text-bold ml-10">
              Řidičský průkaz nebo cestovní pas
            </span>
          </li>
        </ul>

        <LitAlert class="my-20">
          Nebojte, je to jednoduché a vaše údaje uchováme v bezpečí.
        </LitAlert>

        <hr>

        <div class="flex flex-center">
          <button
            class="btn btn-big btn-green"
            type="button"
            @click="scanPersonalIdFront"
          >
            Vyfotit občanský průkaz
          </button>
        </div>
      </div>

      <h2 v-if="currentStep">
        {{ currentStep.title }}
      </h2>

      <LitAlert
        v-if="success"
        alert-type="success"
        class="mb-20"
      >
        <span class="text-bold">Váš snímek je takto v pořádku.</span><br>
        {{ currentStep.success }}
      </LitAlert>

      <LitAlert
        v-if="scanningIdCardFront && !screenshotTaken"
        class="mb-20"
      >
        <template v-if="success && scanningIdCardBack">
          Nyní stejným způsobem vyfoťte druhou stranu svého občanského průkazu.
        </template>

        <template v-if="!success">
          Nasměřujte fotoaparát svého telefonu na doklad, aby byl celý doklad v
          zobrazeném rámečku. Poté stiskněte tlačítko VYFOTIT DOKLAD.
        </template>
      </LitAlert>

      <LitAlert
        v-if="scanningIdCardBack && !success"
        class="mb-20"
      >
        Nyní stejným způsobem vyfoťte druhou stranu vašeho
        občanského průkazu.
      </LitAlert>

      <LitAlert
        v-if="scanningSecondDocument && !screenshotTaken"
        class="mb-20"
      >
        Nasměrujte fotoaparát svého telefonu na
        <span class="text-bold">stranu s osobními údaji druhého dokladu</span>
        (řidičský průkaz nebo cestovní pas). Poté stiskněte tlačítko VYFOTIT DOKLAD.
      </LitAlert>

      <div v-show="showCanvas">
        <div
          class="ocr"
          :class="{
            warning,
            error
          }"
        >
          <div
            v-show="videoStream"
            ref="videoWrapper"
            class="video-wrapper"
          >
            <div
              v-if="loading"
              class="loader"
            >
              <i class="loader__icon">
                <img
                  :src="getIconUrl('icon-loader')"
                  alt="Ikona načítání"
                >
              </i>
            </div>

            <video ref="video" />

            <canvas
              ref="videoOutput"
              :style="{ zIndex: screenshotTaken ? 3 : 0 }"
              class="ocr__canvas"
              @click="retakePhoto"
            />

            <canvas
              ref="videoFrame"
              :style="{ zIndex: 2 }"
              class="ocr__canvas ocr__frame"
              @click="takeScreenshot"
            />
          </div>
        </div>

        <div v-if="!success && !error">
          <hr class="mt-20">

          <div class="flex flex-center">
            <button
              type="button"
              class="btn btn-big btn-green"
              :class="{
                disabled: loading
              }"
              @click="takeScreenshot"
            >
              Vyfotit doklad
            </button>
          </div>
        </div>
      </div>

      <div v-if="success || error">
        <button
          type="button"
          class="btn btn-simple mt-10"
          @click="retakePhoto"
        >
          <i>
            <img
              :src="getIconUrl('icon-edit')"
              alt="Ikona upravit"
            >
          </i>
          Vyfotit znovu
        </button>
      </div>

      <div v-if="scanningIdCardFront && success">
        <hr class="mt-20">

        <div class="flex flex-center">
          <button
            v-if="success"
            class="btn btn-big btn-green"
            type="button"
            @click="nextStep"
          >
            Pokračovat na druhou stranu
          </button>
        </div>
      </div>

      <div v-if="scanningIdCardBack && success">
        <hr class="mt-20">

        <div class="flex flex-center">
          <button
            class="btn btn-big btn-green"
            type="button"
            @click="nextStep"
          >
            Pokračovat na druhý doklad
          </button>
        </div>
      </div>

      <div v-if="scanningSecondDocument && success">
        <hr class="my-20">

        <template v-if="comesFromDesktop">
          <p class="mb-5">Dokončit žádost na</p>

          <div class="flex flex-between">
            <button
              type="button"
              class="btn btn-green btn-big"
              @click="continueOnPc"
            >
              Počítači
            </button>

            <button
              type="button"
              class="btn btn-green btn-big"
              @click="continueOnPhone"
            >
              Telefonu
            </button>
          </div>
        </template>

        <template v-else>
          <p class="mb-5">Nyní pokračujte na zadání osobních údajů</p>

          <div class="flex flex-between">
            <button
              type="button"
              class="btn btn-green btn-big"
              @click="continueOnPhone"
            >
              Pokračovat
            </button>
          </div>
        </template>
      </div>

      <LitAlert
        v-if="error"
        alert-type="danger"
        class="mb-20"
      >
        {{ error }}
      </LitAlert>

      <LitAlert
        v-if="warning"
        alert-type="warning"
        class="mb-20"
      >
        {{ warning }}
      </LitAlert>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { computed, nextTick } from 'vue';
import { contractFiles } from '@/js/api';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import gtm from '@/js/services/gtm';
import { useOnlineAgreementStore } from '@/js/stores/online/agreement';
import { measureAdobeAnalytics, refreshContract, setStoreField, updateContract, validateStoreField } from '@/js/stores/utils';
import { doubleRaf, getCitizenship, getIconUrl } from '@/js/utils';

const STEP = {
  BEGINNING: 1,
  ID_CARD_FRONT: 2,
  ID_CARD_BACK: 3,
  SECOND_DOCUMENT: 4,
  SUCCESS: 5,
};

const canvasToBlob = (canvas) => (new Promise((resolve) => {
  canvas.toBlob(resolve);
}));

export default {
  components: {
    LitAlert,
  },

  setup () {
    const store = useOnlineAgreementStore();
    const { personalData } = storeToRefs(store);

    const idCardNumber = computed({
      get () {
        return personalData.value.idCard.number.value;
      },
      set (value) {
        personalData.value.idCard.number.value = value;
      },
    });

    const citizenship = computed({
      get () {
        return personalData.value.citizenship.value;
      },
      set (value) {
        personalData.value.citizenship.value = value;
      },
    });

    return {
      getIconUrl,
      store,
      idCardNumber,
      citizenship,
      validateStoreField,
      setStoreField,
    };
  },

  data: () => ({
    step: STEP.BEGINNING,
    success: null,
    error: null,
    warning: null,
    videoStream: null,
    loading: false,
    screenshotTaken: false,
    idCardFront: 'id-card-front',
    contractDocumentsRequired: false,
  }),

  computed: {
    currentStep () {
      const states = {
        [STEP.ID_CARD_FRONT]: {
          title: 'Focení občanského průkazu',
          documentType: 'id-card-front',
          storeFieldPath: 'documents.idCardFrontId',
          nextStep: STEP.ID_CARD_BACK,
          success: 'První strana vašeho dokladu byla '
            + 'úspěšně vyfocena. Pokračujte na druhou stranu.',
        },

        [STEP.ID_CARD_BACK]: {
          title: 'Focení občanského průkazu',
          documentType: 'id-card-back',
          storeFieldPath: 'documents.idCardBackId',
          nextStep: STEP.SECOND_DOCUMENT,
          success: 'Občanský průkaz byl úspěšně vyfocen. '
            + 'Pokračujte na focení druhého dokladu.',
        },

        [STEP.SECOND_DOCUMENT]: {
          title: 'Focení druhého dokladu',
          documentType: 'second-document-front',
          storeFieldPath: 'documents.secondDocumentFrontId',
          success: 'Druhý doklad byl úspěšně vyfocen. '
            + 'Pokračujte na zadání osobních údajů.',
        },
      };

      return states[this.step] || null;
    },

    beginning () {
      return this.step === STEP.BEGINNING;
    },

    scanningIdCardFront () {
      return this.step === STEP.ID_CARD_FRONT;
    },

    scanningIdCardBack () {
      return this.step === STEP.ID_CARD_BACK;
    },

    scanningSecondDocument () {
      return this.step === STEP.SECOND_DOCUMENT;
    },

    showCanvas () {
      return this.scanningIdCardFront
        || this.scanningIdCardBack
        || this.scanningSecondDocument;
    },

    videoElement () {
      return this.$refs.video;
    },

    activeTrack () {
      if (this.videoStream === null) {
        return null;
      }

      const [track] = this.videoStream.getVideoTracks();

      return track || null;
    },

    videoSettings () {
      if (this.activeTrack === null) {
        return null;
      }

      return this.activeTrack.getSettings();
    },

    // This indicates whether the user
    // has been redirected from desktop via
    // sms/query to scan the documents.
    comesFromDesktop () {
      return this.$route.query.redirected !== '0';
    },
  },

  methods: {
    async scanPersonalIdFront () {
      await this.loadCamera();
      this.step = STEP.ID_CARD_FRONT;
    },

    nextStep () {
      if (this.currentStep && this.currentStep.nextStep) {
        this.success = null;
        this.screenshotTaken = false;
        this.step = this.currentStep.nextStep;
      }
    },

    continueOnPc () {
      this.stopCamera();
      this.contractDocumentsRequired = true;

      const { contractUuid } = this.$route.params;

      updateContract({
        state: this.store.$state,
        contractUuid,
        fields: [],
        documentsSent: this.contractDocumentsRequired,
      });

      gtm.onStepSubmit('scan-dokladu');

      measureAdobeAnalytics({
        state: this.store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: this.$route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });

      this.$router.push({ name: 'onlineAgreement.personalDataComputerRedirect' });
    },

    continueOnPhone () {
      this.stopCamera();
      this.contractDocumentsRequired = true;

      const { contractUuid } = this.$route.params;

      updateContract({
        state: this.store.$state,
        contractUuid,
        fields: [],
        documentsSent: this.contractDocumentsRequired,
      });

      this.$router.push({ name: 'onlineAgreement.personalData' });

      gtm.onStepSubmit('scan-dokladu');

      measureAdobeAnalytics({
        state: this.store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: this.$route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });
    },

    stopCamera () {
      if (this.videoStream !== null) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
      }
    },

    async loadCamera () {
      const video = document.querySelector('video');

      // This is required in order for the camera
      // to work on ios
      video?.setAttribute('autoplay', '');
      video?.setAttribute('muted', '');
      video?.setAttribute('playsinline', '');

      const idealWidth = window.env.OCR_VIDEO_WIDTH || 1920;
      const idealHeight = window.env.OCR_VIDEO_HEIGHT || 1920;

      this.videoStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
          width: { ideal: idealWidth },
          height: { ideal: idealHeight },
        },
      });

      const [track] = this.videoStream.getVideoTracks();
      const videoSettings = track.getSettings();

      if (videoSettings.width === null || videoSettings.height === null) {
        console.error('Could not get device dimensions');
        return;
      }

      const { width, height } = videoSettings;

      const output = this.$refs.videoOutput;
      output.width = width;
      output.height = height;

      const frame = this.$refs.videoFrame;
      frame.width = width;
      frame.height = height;

      const frameCtx = frame.getContext('2d');
      frameCtx.strokeStyle = 'red';
      frameCtx.lineWidth = 4;

      const frameXMargin = 200;
      const frameRatio = 1.585185185185185; // w/h ratio, odpovídá poměru stran OP

      const frameX = frameXMargin;
      const frameWidth = width - (2 * frameXMargin);
      const frameHeight = Math.round(frameWidth / frameRatio); // fW/fH = frameRatio
      const frameY = Math.round((height - frameHeight) / 2);

      frameCtx.rect(
        frameX,
        frameY,
        frameWidth,
        frameHeight,
      );

      frameCtx.stroke();

      video.srcObject = this.videoStream;

      await video.play();

      nextTick(async () => {
        await doubleRaf();

        const videoRect = video.getBoundingClientRect();
        video.width = videoRect.width;
        video.height = videoRect.height;

        const { width: outWidth } = output.getBoundingClientRect();
        const scaleWidth = outWidth / videoRect.width;

        video.style.transform = `scale(${scaleWidth})`;
      });
    },

    async takeScreenshot () {
      // Dimension of real video element
      const { width: vidWidth, height: vidHeight } = this.videoElement;

      const out = this.$refs.videoOutput;
      const outCtx = out.getContext('2d');

      // Dimension of output canvas
      const { width: outWidth, height: outHeight } = out;

      const sourceX = Math.round(0);
      const sourceW = Math.round(vidWidth);

      const vidRatio = outHeight / vidHeight;

      const sourceY = Math.round((vidHeight - (outHeight * vidRatio)) / 2);
      const sourceH = Math.round(outHeight * vidRatio);

      const destX = 0;
      const destY = 0;
      const destW = outWidth;
      const destH = outHeight;

      outCtx.drawImage(
        this.videoElement,
        sourceX,
        sourceY,
        sourceW,
        sourceH,
        destX,
        destY,
        destW,
        destH,
      );

      // Reset the matrix
      outCtx.setTransform(1, 0, 0, 1, 0, 0);

      this.loading = true;
      this.screenshotTaken = true;

      try {
        const blob = await canvasToBlob(out);
        await this.uploadFile(blob);
        this.success = true;
      } catch (e) {
        console.error(e);
        this.error = 'Při odesílání dokumentu došlo k chybě, zkuste to prosím znovu';
      } finally {
        this.loading = false;
      }
    },

    retakePhoto () {
      this.screenshotTaken = false;
      this.loading = false;
      this.success = null;
      this.error = null;
    },

    async uploadFile (blob) {
      const { contractUuid } = this.$route.params;

      const response = await contractFiles.create(
        contractUuid,
        blob,
        this.currentStep.documentType,
      );

      await refreshContract(
        this.store.$state,
        contractUuid,
      );

      if (!this.citizenship) {
        this.citizenship = 'cz';
      } else if (response.document_type === this.idCardFront) {
        this.citizenship = getCitizenship(this.citizenship);
      }

      this.setStoreField(this.store.$state, {
        fieldPath: `${this.currentStep.storeFieldPath}.value`,
        value: response.id,
      });

      await this.validateStoreField({
        throwOnErrors: true,
        state: this.store.$state,
        contractUuid,
        value: response.id,
        fieldPath: this.currentStep.storeFieldPath,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.ocr {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /*  aspect-ratio: 16 / 9; not supported yet */
  border: 1px solid transparent;
  overflow: hidden;

  &.error {
    border-color: getColor(danger, 1);
  }

  &.warning {
    border-color: getColor(warning, 1);
  }

  &__canvas {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
    background: getColor(light-grey);
  }

  &__frame {
    background: none;
  }

  .video-wrapper {
    border: 1px solid black;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    position: absolute;
    top: 0;

    video {
      z-index: 1;
      position: absolute;
    }

    .loader {
      z-index: 4;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, .6);
    }

    .loader__icon {
      height: 40px;
      animation: spin 1s linear infinite reverse;
    }
  }
}

.btn-big {
  margin-top: 15px;
}

.blue-box {
  $root: &;

  background: getColor(blue);
  font-family: $fontSecondary;
  font-size: $root;
  position: relative;
  z-index: 100;
  min-height: 45px;

  @include media(min, $md) {
    background: getColor(lighter-blue);
    padding: 30px 0;
  }
}
</style>
