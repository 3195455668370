import type { Question } from '@/js/composables/useQuestionnaire.ts';

export default <Question[]> [
  {
    id: 1,
    label: 'Jaké jsou vaše cíle ve vztahu k doplňkovému penzijnímu spoření?',
    options: [
      {
        value: 'a',
        label: 'Jiné krátkodobé cíle, například využití příspěvků zaměstnavatele na '
          + 'přilepšenou (plánovaná délka spoření do 5 let).',
      },
      {
        value: 'b',
        label: 'Vnímám jej jako doplňující spořicí produkt – chci mít jistotu, že mám nějaké prostředky bokem, '
          + 'proto spořím a časem se uvidí.',
      },
      {
        value: 'c',
        label: 'Jiné dlouhodobé cíle, například spoření pro dítě (plánovaná délka spoření 5 a více let).',
      },
      {
        value: 'd',
        label: 'Chci se zabezpečit na stáří a udržet si solidní životní úroveň i v důchodu.',
      },
    ],
    optionPoints: {
      a: 0,
      b: 1,
      c: 2,
      d: 3,
    },
    koAnswer: 'a',
    answer: null,
  },
  {
    id: 2,
    label: 'Jaké jsou vaše znalosti základních finančních produktů '
      + '(například běžný účet, spotřebitelský úvěr, hypotéka, spořicí účet)?',
    options: [
      {
        value: 'a',
        label: 'Nikdy jsem o nich neslyšel(a), své osobní finance neřeším.',
      },
      {
        value: 'b',
        label: 'Mám o nich povědomí, ale žádný nepoužívám.',
      },
      {
        value: 'c',
        label: 'Své osobní finance aktivně spravuji, používám 1‐2 takové produkty.',
      },
      {
        value: 'd',
        label: 'Své osobní finance aktivně spravuji, používám 3 a více takových produktů.',
      },
    ],
    optionPoints: {
      a: 0,
      b: 1,
      c: 2,
      d: 3,
    },
    koAnswer: 'a',
    answer: null,
  },
  {
    id: 3,
    label: 'Které z níže uvedených investičních nástrojů je možné považovat za nejméně rizikové?',
    options: [
      {
        value: 'a',
        label: 'Fondy peněžního trhu',
      },
      {
        value: 'b',
        label: 'Dluhopisy a dluhopisové fondy',
      },
      {
        value: 'c',
        label: 'Akcie a akciové fondy',
      },
    ],
    optionPoints: {
      a: 3,
      b: 0,
      c: 0,
    },
    koAnswer: null,
    answer: null,
  },
  {
    id: 4,
    label: 'Jaké jsou vaše zkušenosti s investicemi?',
    options: [
      {
        value: 'a',
        label: 'Nemám žádné zkušenosti s investicemi.',
      },
      {
        value: 'b',
        label: 'Mám s investicemi zkušenosti, ale neinvestuji pravidelně.',
      },
      {
        value: 'c',
        label: 'Pravidelně investuji do fondů peněžního trhu a/nebo do dluhopisů a dluhopisových fondů.',
      },
      {
        value: 'd',
        label: 'Pravidelně investuji do akcií a/nebo akciových fondů, případně i do jiných investičních nástrojů.',
      },
    ],
    optionPoints: {
      a: 0,
      b: 1,
      c: 2,
      d: 3,
    },
    koAnswer: null,
    answer: null,
  },
  {
    id: 5,
    label: 'Dáváte přednost výši výnosu nebo jistotě?',
    options: [
      {
        value: 'a',
        label: 'I minimální kolísání hodnoty investice je pro mě nepřijatelné.',
      },
      {
        value: 'b',
        label: 'Preferuji co nejmenší kolísání hodnoty investice, případný pokles by měl být vyrovnán v krátkodobém '
          + 'horizontu, zhruba do dvou let.',
      },
      {
        value: 'c',
        label: 'Jsem ochoten kvůli dosažení vyššího výnosu akceptovat výraznější kolísání hodnoty investice, '
          + 'ale případný pokles by měl být vyrovnán ve střednědobém horizontu.',
      },
      {
        value: 'd',
        label: 'Preferuji vysoké výnosy a jsem srozuměn s tím, že hodnota mé investice může výrazně kolísat.',
      },
    ],
    optionPoints: {
      a: 0,
      b: 1,
      c: 2,
      d: 3,
    },
    koAnswer: null,
    answer: null,
  },
  {
    id: 6,
    label: 'Sníží‐li se hodnota vaší investice s investičním horizontem 5 a více let o 15–20 %, jak zareagujete?',
    options: [
      {
        value: 'a',
        label: 'Ukončím penzijní spoření a požádám o výplatu prostředků.',
      },
      {
        value: 'b',
        label: 'Rychle požádám o převod investice z rizikovějšího fondu do konzervativnějšího fondu.',
      },
      {
        value: 'c',
        label: 'Vyčkám na další vývoj: bude‐li hodnota investice i nadále klesat, požádám o převod části investovaných '
          + 'prostředků z rizikovějšího fondu do bezpečnějšího.',
      },
      {
        value: 'd',
        label: 'Nebudu prostředky přemisťovat, naopak využiji této příležitosti nižší ceny a zvýším svou investici v '
          + 'rizikovějším fondu.',
      },
    ],
    optionPoints: {
      a: 0,
      b: 1,
      c: 2,
      d: 3,
    },
    koAnswer: null,
    answer: null,
  },
];
