<template>
  <Teleport :to="to" :disabled="disableTeleport">
    <Transition name="fade">
      <div
        v-show="showModal"
        class="modal"
        :dusk="dusk"
        v-bind="$attrs"
      >
        <div
          class="modal__backdrop"
          @click="closeModal"
        />

        <div
          class="modal__dialog"
          :style="{
            maxWidth: `${maxWidth}px`
          }"
        >
          <div v-if="closeable" class="flex flex-end">
            <button
              type="button"
              class="modal__close"
              @click="closeModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 18 18"
              >
                <path
                  :fill="closeButtonColor"
                  d="M15.0532622,0.836369556 C15.642423,0.355841619 16.5114854,
          0.390165043 17.0606602,0.939339828 C17.6464466,
          1.52512627 17.6464466,2.47487373 17.0606602,3.06066017 L17.0606602,
          3.06066017 L11.121,9 L17.0606602,14.9393398 C17.6464466,
          15.5251263 17.6464466,16.4748737 17.0606602,17.0606602 C16.5114854,
          17.609835 15.642423,17.6441584 15.0532622,17.1636304 L14.9393398,
          17.0606602 L9,11.121 L3.06066017,17.0606602 L2.94673776,
          17.1636304 C2.35757702,17.6441584 1.48851461,17.609835 0.939339828,
          17.0606602 C0.353553391,16.4748737 0.353553391,15.5251263 0.939339828,
          14.9393398 L0.939339828,14.9393398 L6.879,9 L0.939339828,
          3.06066017 C0.353553391,2.47487373 0.353553391,1.52512627 0.939339828,
          0.939339828 C1.48851461,0.390165043 2.35757702,0.355841619 2.94673776,
          0.836369556 L3.06066017,0.939339828 L9,6.879 L14.9393398,0.939339828 Z"
                />
              </svg>
            </button>
          </div>

          <div
            v-if="$slots.icon"
            class="mb-24 flex flex-center"
          >
            <slot name="icon" />
          </div>

          <div
            v-if="$slots.header"
            class="modal__header"
          >
            <slot name="header" />
          </div>

          <div v-if="$slots.body" class="modal__body">
            <slot name="body" />

            <small
              v-if="error"
              class="error"
            >
              {{ error }}
            </small>
          </div>

          <div v-if="$slots.footer" class="modal__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts">
export default {
  name: 'LitModal',

  inheritAttrs: false,

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    dusk: {
      type: String,
      default: '',
    },

    to: {
      type: String,
      default: 'body',
    },

    disableTeleport: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: Number,
      default: 567,
    },

    error: {
      type: String,
      default: '',
    },

    closeButtonColor: {
      type: String,
      default: '#092F68',
    },

    closeable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue', 'close'],

  data: () => ({
    scrollY: 0,
  }),

  computed: {
    showModal: {
      get () {
        return this.modelValue;
      },
      set (val: boolean) {
        this.$emit('update:modelValue', val);
      },
    },
  },

  watch: {
    showModal (value, oldValue) {
      if (!value && oldValue) {
        this.disableScrollPage(false);
      } else {
        this.disableScrollPage(true);
      }
    },
  },

  mounted () {
    if (this.modelValue) {
      this.disableScrollPage(true);
    }
  },

  beforeUnmount () {
    this.disableScrollPage(false);
  },

  methods: {
    closeModal () {
      this.showModal = false;
      this.$emit('close');
    },

    openModal () {
      this.showModal = true;
    },

    disableScrollPage (disabled: boolean) {
      const { body } = document;
      const scrollY = document.documentElement.scrollTop;

      if (!disabled) {
        body.classList.remove('overflow-hidden');
        body.style.top = '';
        window.scrollTo(0, this.scrollY);
      } else {
        this.scrollY = scrollY;
        body.style.top = `-${scrollY}px`;
        body.classList.add('overflow-hidden');
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@sass/tools/variables';
  @import '@sass/tools/functions';
  @import '@sass/tools/mixins';

  .modal {
    $root: &;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 109;
    color: var(--color-default);

    &--no-header &__header {
      display: none;
    }

    &--no-footer &__footer {
      display: none;
    }

    &__backdrop {
      background-color: #092f6899;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      backdrop-filter: blur(2px);
    }

    &__dialog {
      background-color: getColor(white);
      position: relative;
      width: 100%;
      max-height: 98%;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      padding: 32px 24px;
      z-index: 2;
      overflow-y: auto;
    }

    &__close {
      width: 14px;
      height: 14px;
      padding: 13px;
      box-sizing: content-box;
    }

    &__footer {
      padding-top: 24px;
    }

    &__header {
      text-align: center;
      padding-bottom: 24px;

      > * {
        margin: 0;
      }

      #{$root}--paper & {
        background-color: transparent;
        justify-content: flex-end;

        svg path {
          fill: var(--color-grey);
        }
      }
    }

    &__body {
      & > *:last-child {
        margin-bottom: 0;
      }

      #{$root}--paper & {
        padding-top: 0;

        .btn {
          margin-bottom: 2rem !important;
        }
      }
    }

    &--paper &__text {
      margin-top: 0;
      margin-bottom: 30px;
      padding: 0 4rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 1rem;

      button {
        min-width: 100px;
      }

      @include media(max, $sm) {
        flex-flow: column;

        button:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .error {
      display: block;
      padding: .125em 0;
      margin-top: .25em;
      font-weight: 400;
      color: getColor(danger, 1);
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
