<script setup lang="ts">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

const props = defineProps({
  namespace: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <table
    class="recapitulation-table mb-16"
  >
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Vyjádření klienta</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.clientsStatement` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>Poznámka klienta do záznamu z jednání</td>
        <td
          style="word-break: break-word;"
          colspan="2"
        >
          <slot name="statement" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
