<template>
  <div
    v-show="isActive"
    class="tabs__content"
  >
    <ul
      v-if="titleInfo"
      class="list"
    >
      <li
        v-for="(item, tabIndex) in titleInfo"
        :key="tabIndex"
        v-html="item"
      />
    </ul>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import type { PropType, Ref } from 'vue';
import { inject, onBeforeMount, onUnmounted, ref, useAttrs, watch } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: '',
  },

  label: {
    type: String as PropType<string | null>,
    default: '',
  },

  labelIcon: {
    type: String,
    default: '',
  },

  titleInfo: {
    type: Array,
    default: null,
  },

  timeIndicator: {
    type: String,
    default: '',
  },

  indicatorType: {
    type: String,
    default: 'info',
    validator (val: string) {
      return ['info', 'success', 'danger', 'warning'].includes(String(val));
    },
  },

  labelType: {
    type: String,
    default: 'info',
    validator (val: string) {
      return ['info', 'success', 'danger', 'warning'].includes(String(val));
    },
  },

  dusk: {
    type: String,
    default: '',
  },
});

const attrs = useAttrs();

const isActive = ref(false);
const hashId = ref('');

const activeTabHashId = inject<Ref<string>>('activeTabHashId');
const createTab = inject<any>('createTab');
const removeTab = inject<any>('removeTab');

onBeforeMount(() => {
  hashId.value = `id-${Math.random().toString(36).substring(2, 16)}-${Date.now().toString(36)}`;

  if (!createTab) {
    throw new Error('Child LitTab is not defined');
  }

  createTab({
    ...props,
    attrs: {
      ...attrs,
    },
    hashId: hashId.value,
  });
});

onUnmounted(() => {
  if (!removeTab) {
    throw new Error('Child LitTab is not defined');
  }

  removeTab({
    ...props,
    attrs: {
      ...attrs,
    },
    hashId: hashId.value,
  });
});

watch(activeTabHashId!, () => {
  isActive.value = activeTabHashId?.value === hashId.value;
});

defineExpose({
  isActive,
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.list {
  margin-bottom: 20px;

  @include media(min, $md) {
    display: none;
  }
}
</style>
