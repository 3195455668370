export const bankIdErrorMessage = [
  { key: 'areDocumentsMissing', label: 'neobsahuje žádné osobní doklady', isBankIdError: false },
  { key: 'moreValidIdCards', label: 'obsahuje více platných OP', isBankIdError: false },
  { key: 'noIdCards', label: 'neobsahuje OP', isBankIdError: false },
  { key: 'areAddressesMissing', label: 'neobsahuje adresu', isBankIdError: false },
  { key: 'invalidPermanentAddress', label: 'neobsahuje pro nás platnou trvalou adresu', isBankIdError: false },
  { key: 'isBirthDayMissing', label: 'neobsahuje datum narození', isBankIdError: false },
  { key: 'isGenderMissing', label: 'neobsahuje údaj o pohlaví', isBankIdError: false },
  { key: 'isBirthNumberMissing', label: 'neobsahuje rodné číslo', isBankIdError: false },
  { key: 'isBirthPlaceMissing', label: 'neobsahuje údaj o místě narození', isBankIdError: false },
  { key: 'isBirthCountryMissing', label: 'neobsahuje údaj o zemi narození', isBankIdError: false },
  { key: 'areNationalitiesMissing', label: 'neobsahuje údaj o národnosti', isBankIdError: false },
  { key: 'isMajorityMissing', label: 'neobsahuje údaj o plnoletosti', isBankIdError: false },
  { key: 'isLimitedLegalCapacityMissing', label: 'neobsahuje údaj o způsobilosti', isBankIdError: false },
  { key: 'permanentAddressOutOfCzechia', label: 'obsahuje trvalou adresu mimo území ČR', isBankIdError: false },
  { key: 'hasNotCzechNationality', label: 'obsahuje národnost jinou než českou', isBankIdError: false },
  { key: 'hasLegalCapacityLimited', label: 'obsahuje informaci o omezení způsobilosti', isBankIdError: false },
  { key: 'clientNotAdult', label: 'obsahuje údaj o nezletilosti', isBankIdError: false },
  { key: 'isPepMissing', label: 'neobsahuje údaj, zda jste politicky exponovaná osoba', isBankIdError: false },
  { key: 'incorrectFirstName', label: 'obsahuje jiné křestní jméno, než bylo uvedeno ve formuláři', isBankIdError: false },
  { key: 'incorrectLastName', label: 'obsahuje jiné přijmení, než bylo uvedeno ve formuláři', isBankIdError: false },
  { key: 'incorrectBirthNumber', label: 'obsahuje jiné rodné číslo, než bylo uvedeno ve formuláři', isBankIdError: false },
  { key: 'hasNoValidIdCard', label: 'neobsahuje platný OP', isBankIdError: false },
  { key: 'incorrectIcCardNumber', label: 'obsahuje jiné číslo OP, než bylo uvedeno ve formuláři', isBankIdError: false },
  // TODO dočasný snake_case, než bude zapracován převod v knihovně
  { key: 'eid_doesnt_exist', label: 'Bank iD u Vás neeviduje platnou službu elektronické identifikace (bankovní identitu)', isBankIdError: true },
  { key: 'user_not_eligible', label: 'Bank iD u vašeho profilu vykazuje věkové omezení, omezení svéprávnosti, či použitá metoda autentifikace nesplňuje bezpečnostní úroveň vyžadovanou poskytovatelem identity (bankou)', isBankIdError: true },
];
