/*
* Return Short Payment Descriptor
* EU standard
* Specification: https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
*/

export default function paymentDescriptor (
  accountIban: string,
  amount: string | null = null,
  currency: string | null = null,
  message: string | null = null,
  variableSymbol: string | null = null,
  specificSymbol: string | null = null,
  constantSymbol: string | null = null,
) {
  const header = 'SPD*1.0';
  const keys: string[] = [];

  const data: { [key: string]: string | null } = {
    'acc': accountIban,
    'am': amount,
    'cc': currency,
    'msg': message,
    'x-vs': variableSymbol,
    ...(specificSymbol !== null && { 'x-ss': specificSymbol }),
    'x-ks': constantSymbol,
  };

  Object.keys(data).forEach((key) => {
    if (data[key]) {
      keys.push(`${key}:${data[key]}`);
    }
  });

  return `${header}*${keys.sort().join('*').toUpperCase()}`;
}
