import * as Jps from '../../views/distribution/jps';

const jps = {
  path: '/jps/:contractUuid',
  component: Jps.Layout,
  children: [
    {
      path: 'nez-zacneme',
      name: 'jps.beforeStart',
      component: Jps.BeforeStart,
    },
    {
      path: 'kontaktni-udaje',
      name: 'jps.contactInformation',
      component: Jps.ContactInformation,
    },
    {
      path: 'zadani-osobnich-udaju',
      name: 'jps.personalData',
      component: Jps.PersonalData,
    },
    {
      path: 'zakonne-pozadavky',
      name: 'jps.legalRequirements',
      component: Jps.LegalRequirements,
    },
    {
      path: 'vyse-prispevku-a-strategie',
      name: 'jps.contributionAndStrategy',
      component: Jps.ContributionAndStrategy,
    },
    {
      path: 'smlouva-nastaveni-parametru',
      name: 'jps.contractSettings',
      component: Jps.ContractSettings,
    },

    {
      path: 'podpis-bank-id',
      name: 'jps.bankIdSignaturePage',
      component: Jps.BankList,
    },
    {
      path: 'smlouva-dokumenty-a-souhlasy',
      name: 'jps.contractDocumentsAndConsents',
      component: Jps.ContractDocumentsAndConsents,
    },
    {
      path: 'prodejni-rozhovor',
      name: 'jps.salesInterview',
      component: Jps.SalesInterview,
    },
    {
      path: 'prohlaseni-klienta',
      name: 'jps.clientsStatement',
      component: Jps.ClientsStatement,
    },
    {
      path: 'rekapitulace',
      name: 'jps.recapitulation',
      component: Jps.Recapitulation,
    },
    {
      path: 'dekujeme-odeslani-odkazu',
      name: 'jps.thankYouSignaturePage',
      component: Jps.ThankYouSignaturePage,
    },
    {
      path: 'dekujeme-za-sjednani',
      name: 'jps.LitSignThankYouRedirectPage',
      component: Jps.LitSignThankYouRedirectPage,
    },
    {
      path: 'dekujeme',
      name: 'jps.bankIdThankYouPage',
      component: Jps.BankIdThankYouPage,
    },
  ],
};

export default jps;
