<script lang="ts" setup>
import { objectEntries } from '@vueuse/core';
import { computed, defineAsyncComponent, reactive, ref } from 'vue';
import LitButton from '../Base/LitButton.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

import { useTruncate } from '@/js/composables/useTruncate';
import fundsData from '@/js/data/fundsData';

const props = withDefaults(defineProps<{
  preferConservativeFund: boolean
}>(), {
  preferConservativeFund: false,
});

defineEmits([
  'update:modelValue',
]);

const LifeCycleStrategyDetail = defineAsyncComponent(() => import('@/js/components/LifeCycleStrategyDetail.vue'));
const FundsChart = defineAsyncComponent(() => import('@/js/components/FundsChart.vue'));
const LitModal = defineAsyncComponent(() => import('@/js/components/Base/LitModal.vue'));

const selectedFund = defineModel<string | null>({ default: null, required: true });

const showStrategyDetail = ref(false);

interface StrategyDetail {
  title: string
  description: string
  fund: string | null
}

const initialState: StrategyDetail = {
  title: '',
  description: '',
  fund: null,
};

const strategyDetail = reactive<StrategyDetail>({ ...initialState });

const filteredFundsData = computed(() => {
  return objectEntries(fundsData)
    .filter(([key, _value]) => {
      if (props.preferConservativeFund) {
        return key !== 'conservative';
      }

      return key !== 'preferredConservativeFund';
    })
    .map(([_key, value]) => ({ ...value }));
});

function handleShowStrategyDetail (item: any) {
  strategyDetail.title = item.titleFull;
  strategyDetail.description = item.content.description;

  strategyDetail.fund = item.slug === 'reinvestment' ? null : item.slug;

  showStrategyDetail.value = true;
}
</script>

<template>
  <div class="cols">
    <template
      v-for="item in filteredFundsData"
      :key="`selection-funds${item.slug}`"
    >
      <div
        :class="[
          'contribution',
          { selected: selectedFund === item.slug }
        ]"
      >
        <label :for="`strategySelectionFunds-${item.slug}`">
          <div class="flex flex-between content-center mb-10">
            <div class="contribution__value">
              <div class="contribution__input">
                <input
                  :id="`strategySelectionFunds-${item.slug}`"
                  :checked="selectedFund === item.slug"
                  type="radio"
                  name="strategySelectionFunds"
                  @input="() => $emit('update:modelValue', item.slug)"
                >

                <span class="contribution__control" />
              </div>
            </div>

            <span
              v-if="item.label"
              class="contribution__batch"
            >
              {{ item.label }}
            </span>
          </div>

          <div class="contribution__label">
            {{ item?.titleFull }}
          </div>

          <div class="contribution__result truncate-overflow">
            {{ useTruncate(item.content.description, 110) }}
          </div>

          <div class="flex flex-between pt-20 content-end flex-1">
            <div :class="item.typeIconName">
              <span class="pr-8 text-bold">
                {{ item.typeText }}
              </span>

              <LitIconSvg
                v-if="item.typeIconName"
                :icon-name="item.typeIconName"
                color="red"
              />
            </div>

            <div class="fond-link" @click.prevent="handleShowStrategyDetail(item)">
              Více
            </div>
          </div>

        </label>
      </div>
    </template>
  </div>

  <LitModal
    v-model="showStrategyDetail"
    :max-width="838"
    @close="Object.assign(strategyDetail, initialState)"
  >
    <template #header>
      <h3 class="text-left">
        {{ strategyDetail.title }}
      </h3>
    </template>

    <template #body>
      <div v-if="strategyDetail.fund && strategyDetail.fund !== 'lifeCycle'">
        <FundsChart
          :fund-type="strategyDetail.fund"
          class="mb-30"
        />
      </div>
      <p>
        {{ strategyDetail.description }}
      </p>

      <LifeCycleStrategyDetail v-if="strategyDetail.fund === 'lifeCycle'" />
    </template>

    <template #footer>
      <div class="modal__buttons flex-end">
        <LitButton
          variant="outline"
          @click="showStrategyDetail = false"
        >
          <span>Zavřít</span>
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.cols {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}

.bar-1 {
  color: getColor(success, 1);

  svg {
    fill: getColor(success, 1);
  }
}

.bar-2 {
  color: getColor(warning, 1);

  svg {
    fill: getColor(warning, 1);
  }
}

.bar-3 {
  color: getColor(danger, 1);

  svg {
    fill: getColor(danger, 1);
  }
}

.flex-1 {
  flex: 1;
}

.text-bold {
  font-weight: $bold;
  font-size: 13px;
}

.fond-link {
  color: getColor(green);
  text-decoration: underline;
  cursor: pointer;
  font-size: $root-mini;

  &:hover {
    text-decoration: none;
  }
}

.cols > * {
  width: 100%;

  @include media(min, $sm) {
    width: calc((100% / 3) - 12px);
  }
}

.contribution {
  position: relative;

  label {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    padding: 20px;
    border: 1px solid getColor(lighter-grey);
    border-radius: 2px;
    height: 100%;

    &:focus-within {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(green);
    }
  }

  &__value {
    font-weight: $bold;
    display: flex;
  }

  &__label {
    font-weight: $bold;
    margin-bottom: 12px;
  }

  &__input {
    display: flex;
    align-items: center;
    margin-right: 10px;
    flex-shrink: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }
  }

  &__control {
    $control-bg: #d8e8c5;

    width: rem(23);
    height: rem(23);
    border-radius: 50%;
    border: 1px solid $control-bg;
    background: $control-bg;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      width: rem(9);
      height: rem(9);
      border-radius: 50%;
      transition: 180ms transform ease-in-out;
      transform: scale(0);
      background: getColor(green);
    }
  }

  input:checked + &__control {
    background: getColor(white);
    border-color: getColor(green);

    &::before {
      transform: scale(1);
    }
  }

  &__result {
    font-size: $root-mini;
    display: flex;
    justify-content: space-between;
    text-wrap: balance;
  }

  &__batch {
    background: getColor(yellow);
    color: getColor(white);
    font-size: 10px;
    font-weight: $bold;
    padding: 4px 5px;
  }

  &.selected label {
    border-color: getColor(green);
    box-shadow: inset 0 4px 0 getColor(green);
  }

  &--error label {
    border-color: getColor(danger, 1);
  }

  &--error.selected label {
    border-color: getColor(danger, 1);
    box-shadow: inset 0 4px 0 getColor(danger, 1);
  }

  &:not(.selected) label:hover {
    cursor: pointer;
  }
}
</style>
