export default [
  'cz',
  'be',
  'bg',
  'dk',
  'ee',
  'fi',
  'fr',
  'hr',
  'ie',
  'it',
  'cy',
  'lt',
  'lv',
  'lu',
  'hu',
  'mt',
  'de',
  'nl',
  'pl',
  'pt',
  'at',
  'ro',
  'gr',
  'sk',
  'si',
  'es',
  'se',
];
