<template>
  <div dusk="contract-preview">
    <OnlineAgreementStepper :current-step="4" />

    <div class="container--sm">
      <h2>Náhled smlouvy a prohlášení</h2>

      <LitAlert
        v-if="internalError"
        class="mb-20"
        alert-type="danger"
      >
        <div>
          Při odesílání smlouvy došlo k neočekávané chybě. Zkuste to prosím znovu
          a v případě, že se chyba bude opakovat nás prosím kontaktujte.
        </div>
      </LitAlert>

      <LitAlert
        v-if="validationError"
        class="mb-20"
        alert-type="danger"
      >
        <div>
          Při odesílání smlouvy došlo k chybě. Překontrolujte si prosím všechny zadané údaje
          a zkuste to znovu. V případě, že se chyba bude opakovat nás prosím kontaktujte.
        </div>
      </LitAlert>

      <h3>Vaše smlouva</h3>

      <div class="preview-wrapper">
        <LitPreview
          v-if="loadingPreview"
          :previews="previews"
        />
      </div>

      <h3>Prohlášení</h3>

      <p>
        Podpisem smlouvy potvrzuji, že mi byly poskytnuty následující dokumenty: předsmluvní informace,
        včetně informací o&nbsp;rizicích udržitelnosti, informace o&nbsp;zpracování osobních údajů (Informační memorandum ČSOB skupiny),
        Sdělení klíčových informací, Statuty fondů účastnických fondů, obchodní podmínky a&nbsp;další související dokumenty.
        Byl/a jsem seznámen/a se službou Penzijní portál, která umožňuje on-line přístup ke smlouvě. Registrace na
        <a
          href="https://penzijniportal.cz"
          target="_blank"
        >
          www.penzijniportal.cz</a>.
      </p>

      <p>
        Poskytnutím svojí e-mailové adresy ve smlouvě souhlasím s&nbsp;elektronickou komunikací s&nbsp;ČSOB&nbsp;PS.
        Zároveň beru na vědomí, že&nbsp;Penzijní společnost mi bude zasílat veškerou korespondenci
        na tuto doručovací e-mailovou adresu a&nbsp;že&nbsp;mohu využívat službu Penzijní portál v&nbsp;rozsahu
        uvedeném v&nbsp;Obchodních podmínkách pro doplňkové penzijní spoření, pokud ve své smlouvě uvedu české
        číslo svého mobilního telefonu. Podpisem smlouvy dále souhlasím s&nbsp;poskytováním informací způsobem
        umožňující dálkový přístup, včetně sdělení klíčových informací a&nbsp;statutů fondů. Potvrzuji,
        že&nbsp;údaje uvedené ve smlouvě jsou pravdivé a&nbsp;úplné a&nbsp;jejich případnou změnu se zavazuji
        ČSOB&nbsp;PS bez zbytečného odkladu sdělit.
      </p>

      <p>
        Prohlašuji, že&nbsp;jsem požádal o&nbsp;zainvestování prostředků dle této smlouvy
        před uplynutím lhůty k&nbsp;odstoupení od&nbsp;smlouvy.
        (Účastník má právo odstoupit od&nbsp;smlouvy do&nbsp;30&nbsp;dnů ode&nbsp;dne jejího uzavření.)
      </p>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <div class="container--sm flex flex-between buttons">
      <router-link
        :to="{ name: 'onlineAgreement.contractDocumentsAndConsents' }"
        class="btn btn-big btn-icon-back btn-outline"
      >
        <LitIconSvg
          class="btn__icon btn__icon--big"
          icon-name="arrow_green-left"
        />

        <span>
          Zpět
        </span>
      </router-link>

      <BankIdButton
        v-if="isBankIdEntry"
        label="Podepsat"
        sub-label="s Bank iD"
        dusk="sign-contract-button"
        @click="submitStep('bankId')"
      />

      <button
        v-else
        class="btn btn-green btn-big btn-icon-right"
        dusk="submit-step"
        :class="{ disabled: submitting }"
        :disabled="submitting"
        @click="submitStep('offline')"
      >
        <span>
          SOUHLASÍM SE SMLOUVOU
        </span>

        <LitIconSvg
          class="btn__icon btn__icon--end btn__icon--end--big"
          icon-name="arrow_white-right"
        />
      </button>
    </div>

    <ContractSubmissionModal
      type="online"
      :model-value="submitting"
      :is-bank-id="isBankIdEntry"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { Preview } from '@/js/stores/types.ts';
import { bankMetadata, signatures } from '@/js/api';
import BankIdButton from '@/js/components/BankIdButton.vue';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitPreview from '@/js/components/LitPreview.vue';
import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import OnlineAgreementStepper from '@/js/components/Steppers/OnlineAgreementStepper.vue';

import gtm from '@/js/services/gtm';

import { useOnlineAgreementStore } from '@/js/stores';
import { setStoreField, submitContract } from '@/js/stores/utils';
import { EContractType, EPreviewType } from '@/js/types/contract.ts';

const store = useOnlineAgreementStore();
const { validateFields } = store;
const {
  isBankIdEntry,
  isOnlineFinish,
  personalData,
  submitResponse,
  signature,
  contractSelection,
} = storeToRefs(store);

const route = useRoute();
const router = useRouter();

const fields = shallowRef([
  'consents.sharingInformation',
  'consents.usingEmailAddress',
]);

const validating = ref(false);

const internalError = ref(false);
const validationError = ref(false);
const submitting = ref(false);
const previews = ref<Preview[]>([]);

const requiresSmsSignature = computed(() => {
  return isOnlineFinish.value && !isBankIdEntry.value;
});

const bankData = ref<string | null>();
const signMethod = ref<string>();

const loadingPreview = ref(false);

const isJps = computed(() => {
  return contractSelection.value.type.value !== EContractType.Online;
});

const fetchMetadata = async (contractUuid: string) => {
  const bank = personalData.value.verifiedBank.value === null
    ? null
    : JSON.stringify((await bankMetadata.show(contractUuid)).data);

  const signInfo = await signatures.show(contractUuid);
  const sign = signInfo.data[0].signMethod;

  bankData.value = bank;
  signMethod.value = sign;

  const newContract = {
    index: 0,
    bankData: bankData.value,
    signMethod: signMethod.value,
    store,
    previewType: EPreviewType.NewContract,
    contractType: EContractType.Online,
    previewName: 'Nová smlouva',
  };

  const termination = {
    index: 1,
    bankData: bankData.value,
    signMethod: signMethod.value,
    store,
    previewType: EPreviewType.Termination,
    contractType: EContractType.Online,
    previewName: 'Výpověď',
  };

  previews.value = [
    newContract,
  ];

  if (isJps.value) {
    previews.value = [
      ...previews.value,
      termination,
    ];
  }
};

function resetErrors () {
  internalError.value = false;
  validationError.value = false;
}

async function submitStep (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    resetErrors();
    validating.value = true;
    submitting.value = true;

    gtm.onStepSubmit('nahled-smlouvy');

    if (requiresSmsSignature.value) {
      await validateFields({
        contractUuid,
        throwOnErrors: true,
        fieldPaths: fields.value,
      });

      await router.push({
        name: 'onlineAgreement.contractSmsSignature',
        params: {
          contractUuid: route.params.contractUuid,
        },
      });
    } else {
      const isBankId = signMethod === 'bankId';

      const signature = [
        {
          signer: 'client',
          signMethod,
        },
      ];

      if (isBankId) {
        await bankMetadata.create(contractUuid, personalData.value.verifiedBank.value, 'signature');
      }

      await signatures.update(contractUuid, JSON.stringify(signature), true, false);

      await submitContract(store.$state, {
        contractUuid: route.params.contractUuid as string,
        throwOnErrors: true,
        signature,
      });

      if (isBankId) {
        const [signingLink] = submitResponse.value.value?.metadata.signingLinks || [];
        window.location.href = signingLink;
      } else {
        await router.push({
          name: 'onlineAgreement.thankYouPage',
          params: {
            contractUuid: route.params.contractUuid,
          },
        });
      }
    }
  } catch (e: any) {
    if (e?.response.status === 401 && e?.response && e?.response.data && e?.response.data.message && e?.response.data.message.includes('Bank iD validation Error')) {
      await router.push({
        name: 'error',
      });
    }

    submitting.value = false;

    if (requiresSmsSignature.value) {
      console.warn(`There was a validation contractSmsSignaturesError: ${e}`);
    } else {
      const statusCode = e?.response?.status;

      if (statusCode === 422) {
        validationError.value = true;
      } else {
        internalError.value = true;
      }

      throw new Error(`Online contract ${contractUuid} submission error.
            Status code ${statusCode}. Message: ${e?.response.message}`);
    }
  } finally {
    validating.value = false;
  }
}

onMounted(async () => {
  signature.value.smsKey.value = '';
  loadingPreview.value = false;

  const { contractUuid } = route.params;
  await fetchMetadata(contractUuid as string);

  loadingPreview.value = true;

  if (personalData.value.selectedEntryMethod.value === 'bankId' && personalData.value.verifiedBank.value === null) {
    const signatureBank = (await bankMetadata.show(contractUuid))?.data.name;

    setStoreField(store.$state, {
      fieldPath: 'personalData.verifiedBank.value',
      value: signatureBank,
    });
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.ratio {
  position: relative;
  width: 100%;
  padding-bottom: 65%;
  min-height: 300px;
}

.buttons {
  align-items: center;

  span {
    display: block;
  }

  @include media(max, $xs) {
    margin-top: 20px;
    flex-direction: column-reverse;
  }
}

.loading {
  background-color: lightgray;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.preview-wrapper {
  width: 100%;

  object, iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
