<template>
  <div>
    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm">
        <h2 class="dash">
          Nyní podepíšete žádost prostřednictvím vaší banky
        </h2>
      </div>
    </div>

    <div class="container--sm">
      <LitAlert
        v-if="error && !displayAlert"
        class="my-20"
        alert-type="danger"
        dusk="contract-sending-failure"
      >
        <div>
          Při odesílání výpovědi k podpisu došlo k chybě. Je možné, že jste výpoveď již podepsali v jiném okně.
          Pokud ne, využijte znovu odkaz z e-mailu.
          <br class="mb-15">
          Pokud problém přetrvává, kontaktujte nás prosím.
        </div>
      </LitAlert>
    </div>

    <div
      v-if="isBankIdOn"
      class="container--sm text-center"
    >
      <h2 class="mt-20 pt-20">
        <strong>Poslední krok:</strong> <br> Vyberte banku pro podepsání vaší žádosti
      </h2>

      <Suspense>
        <div>
          <BankList
            v-model="bank"
            @click="error = false"
          />

          <BankIdButton
            v-if="isBankIdOn"
            label="Závazně odeslat"
            sub-label="do Bank iD"
            class="flex flex-center py-20 label__long"
            :disabled="submitting"
            @click="submitStep('bankId')"
          />
        </div>

        <template #fallback>
          <div>
            <div class="flex flex-center mb-20 flip">
              <img
                :src="getIconUrl('icon-loader_big')"
                alt="Načítání..."
                class="spin"
              >
            </div>
            <div class="text-warning text-center text-bold">
              Chvíli strpení, načítáme seznam dostupných bank
            </div>
          </div>
        </template>
      </Suspense>

      <LitAlert
        v-show="displayAlert"
        alert-type="warning"
        class="my-20"
      >
        Vyberte prosím banku pro podpis žádosti
      </LitAlert>

      <ContractSubmissionModal
        type="jps"
        :model-value="submitting"
        :is-bank-id="true"
      />
    </div>

    <div
      v-else
      class="container--sm text-center"
    >
      <LitAlert
        class="my-20"
        alert-type="warning"
      >
        Omlouváme se, ale Bank iD je dočasně mimo provoz. Nyní tedy nelze žádost dokončit.
        Prosím, využijte odkaz v e-mailu nebo QR kód později.
      </LitAlert>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import BankList from '../../common/BankList.vue';
import { bankMetadata, signatures } from '@/js/api';
import BankIdButton from '@/js/components/BankIdButton.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import env from '@/js/env.ts';
import { useJpsStore } from '@/js/stores';

import { submitContract } from '@/js/stores/utils';

import { getIconUrl } from '@/js/utils.ts';

export default {
  components: {
    BankIdButton,
    LitAlert,
    ContractSubmissionModal,
    BankList,
  },

  async beforeRouteEnter (from, _to) {
    const { contractUuid } = from.params;

    if (from.query?.submitToBankId) {
      await bankMetadata.update(contractUuid as string, 'canceled', 'signature');
    }
  },

  setup () {
    const store = useJpsStore();
    const { submitResponse, contractSettings } = storeToRefs(store);

    const signingLinks = computed(() => {
      return submitResponse.value.value?.metadata.signingLinks;
    });

    const bank = computed({
      get () {
        return contractSettings.value.signatureBank.value;
      },
      set (value) {
        contractSettings.value.signatureBank.value = value;
      },
    });

    const showPortalRecommendationModal = ref(false);
    const contractDocumentsRequired = ref(false);
    const areBankIdFieldsFilled = ref(false);
    const newlyChosenBank = ref(null);
    const submitting = ref(false);
    const displayAlert = ref(false);
    const error = ref(false);
    const isBankIdEnabled = ref(true);

    return {
      signingLinks,
      bank,
      store,
      showPortalRecommendationModal,
      contractDocumentsRequired,
      areBankIdFieldsFilled,
      newlyChosenBank,
      submitting,
      displayAlert,
      submitResponse,
      error,
      isBankIdEnabled,
    };
  },

  computed: {
    isProduction () {
      return env.APP_ENV === 'production';
    },

    isBankIdOn () {
      if (this.isProduction) {
        return env.BANKID_ENABLED === true;
      }

      return this.isBankIdEnabled;
    },
  },

  watch: {
    bank (newValue) {
      if (newValue !== '' && newValue !== null) {
        this.displayAlert = false;
      }
    },
  },

  mounted () {
    this.submitResponse.value = null;
  },

  methods: {
    getIconUrl,
    async submitStep (signMethod: string) {
      const { contractUuid } = this.$route.params;

      if (this.bank === '' || this.bank === null) {
        this.displayAlert = true;
      }

      try {
        this.submitting = true;
        this.error = false;
        await bankMetadata.create(contractUuid, this.bank, 'signature');

        const draftToken = this.$route.query?.draftToken;

        await this.$router.replace({
          query: {
            draftToken,
            submitToBankId: 'true',
          },
        });

        const signature = [
          {
            signer: 'client',
            signMethod,
          },
        ];

        await signatures.update(contractUuid, JSON.stringify(signature), true, false);

        await submitContract(this.store.$state, {
          contractUuid: contractUuid as string,
          throwOnErrors: true,
          signature,
        });

        if (this.signingLinks) {
          window.location.href = [this.signingLinks].toString();
        }
      } catch (e: any) {
        this.submitting = false;
        this.error = true;

        const statusCode = e?.response?.status;
        const message = e?.response?.message;

        throw new Error(`Jps contract ${contractUuid} (bankId) submission error. Status code ${statusCode}, Response message ${message}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/tools/functions';

.disabled {
  background: var(--color-disabled);
}
</style>
