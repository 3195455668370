<script lang="ts" setup>
import { computed, shallowRef, useAttrs } from 'vue';
import LitLink, { type Props as LinkProps } from './LitLink.vue';

type Props = {
  label?: string | null
  loading?: boolean
  size?: 'md' | 'sm'
  color?: 'primary' | 'secondary'
  variant?: 'solid' | 'outline' | 'link'
  block?: boolean
} & Partial<LinkProps>;

const props = withDefaults(defineProps<Props>(), {
  label: null,
  loading: false,
  size: 'md',
  color: 'primary',
  variant: 'solid',
  as: 'button',
  type: 'button',
  disabled: false,
  to: '',
  block: false,
});

const attrs = useAttrs();

const variants = shallowRef({
  solid: 'lit-btn-solid',
  outline: 'lit-btn-outline',
  link: 'lit-btn-link',
});

const sizes = shallowRef({
  md: '',
  sm: 'lit-btn-sm',
});

const colors = shallowRef({
  primary: 'lit-btn-primary',
  secondary: 'lit-btn-secondary',
});

const variant = computed(() => {
  return variants.value[props.variant];
});

const size = computed(() => {
  return sizes.value[props.size];
});

const color = computed(() => {
  return colors.value[props.color];
});
</script>

<template>
  <LitLink
    v-bind="{ ...attrs, ...props }"
    class="lit-btn"
    :class="[
      {
        'disabled': (props.disabled || props.loading),
        'pl-4': $slots.after,
        'lit-btn--block': props.block
      },
      color,
      variant,
      size
    ]"
    :type
    :disabled="props.disabled || props.loading"
  >
    <slot name="before" />

    <slot>
      <span v-if="props.label">
        {{ props.label }}
      </span>
    </slot>

    <slot name="after" />
  </LitLink>
</template>

<style lang="scss" scoped>
@import '@sass/tools/mixins.scss';
@import '@sass/tools/variables.scss';

.lit-btn {
  font-family: $fontSecondary;
  font-weight: $bold;
  border: 1px solid transparent;
  text-decoration: none;
  transition: all .5s ease-in-out;
  padding: 1rem 1.5rem;
  border-radius: 100px;
  font-size: $root;
  display: inline-flex;
  align-items: center;
  column-gap: 1rem;
  line-height: 1.25;
  min-height: 54px;

  &-primary {
    $color: getColor(white);
    $bg: getColor(green);

    &:hover {
      background: getColor(dark-green);
    }

    &.lit-btn-solid {
      background: getColor(green);
      color: getColor(white);

      a,
      span,
      i {
        color: getColor(white);
      }

      &:hover {
        background: getColor(dark-green);
        border-color: getColor(dark-green);

        a,
        span,
        i {
          color: getColor(white);
        }

        svg, svg path {
          fill: getColor(white);
        }
      }
    }

    &.lit-btn-outline {
      background: getColor(white);
      color: getColor(green);
      border-color: getColor(green);

      & > * {
        color: getColor(green);
      }

      &:hover {
        color: getColor(white);
        background: getColor(green);

        & > * {
          color: getColor(white);
        }

        svg, svg path {
          fill: getColor(white);
        }
      }
    }

    &.lit-btn-link {
      display: inline-flex;
      background: transparent;
      color: getColor(green);
      border: none;
      font-weight: normal;
      padding: 0 !important;
      text-decoration: underline;
      min-height: auto;

      &:hover {
        text-decoration: none;
        background: transparent;
        color: getColor(dark-green);
      }
    }
  }

  @at-root &#{&}-sm {
    font-size: $root-small;
    padding: .71rem 1rem;
    min-height: 42px;
  }

  &.disabled {
    background: #8397b3;
    border-color: #8397b3;
    pointer-events: none;
    color: getColor(white);

    &.lit-btn-link {
      background: transparent;
      color: #8397b3;
    }
  }

  &--block {
    width: 100%;
    justify-content: center;
  }
}
</style>
