<script setup lang="ts">
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

interface Props {
  type?: 'success' | 'warning' | 'danger' | 'info'
  showIcon?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: 'success',
  showIcon: true,
});
</script>

<template>
  <div class="text-center">
    <slot
      v-if="showIcon"
      name="icon"
    >
      <LitIconSvg icon-name="icon_success" />
    </slot>

    <h2 :class="[`text-${props.type}`]">
      <slot />
    </h2>
  </div>
</template>
