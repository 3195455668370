<script setup lang="ts" generic="T extends LegalRequirementsReturn">
import type { LegalRequirementsReturn } from '@/js/stores/createStoreFields';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';
import { useIncomeTranslation } from '@/js/composables/useIncomeTranslation.ts';

interface Props {
  storePath: T
  namespace: string
  isParticipant: boolean
  isSignerPoliticallyExposed: boolean
}

const props = defineProps<Props>();

function income (value: string) {
  return useIncomeTranslation(value);
}
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Zákonné požadavky</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${namespace}.legalRequirements` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <RecapitulationTableRow :path="props.storePath.paysTaxesInCzechia" table-key="Odvádí klient daně v České republice?">
        <template #tableValue>
          {{ props.storePath.paysTaxesInCzechia.value === true ? 'Ano' : 'Ne' }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow :path="props.storePath.isPoliticallyExposed" table-key="Je klient politicky exponovanou osobou?">
        <template #tableValue>
          {{ props.storePath.isPoliticallyExposed.value === true ? 'Ano' : 'Ne' }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow :path="props.storePath.sourceOfIncome" table-key="Z čeho pocházejí přijmy klienta?">
        <template #tableValue>
          {{ income(props.storePath.sourceOfIncome.value) }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow :path="props.storePath.occupation" table-key="Povolání klienta" />

      <tr v-show="!props.isParticipant">
        <td>Je podepisující politicky exponovanou osobou?</td>
        <td
          colspan="2"
        >
          {{ isSignerPoliticallyExposed === true ? 'Ano' : 'Ne' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
