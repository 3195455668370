import axios from 'axios';
import env from '../env';
import type { PreviewType } from '@/js/types/contract.ts';
import { EPreviewType } from '@/js/types/contract.ts';

export async function create (
  contractData: unknown,
  contractType = 'online',
  previewType: PreviewType = EPreviewType.NewContract,
  render = true,
  signMethod: string,
  verifiedBank: string | null = null,
) {
  return await axios.post(`${env.REST_API_URI}/v1/contracts/previews`, {
    contract: contractData,
    type: contractType,
    previewType,
    render,
    signMethod,
    verifiedBank,
  }, {
    responseType: render ? 'blob' : 'json',
  });
}

export default {
  create,
};
