<template>
  <div class="container--xs">
    <h2>Pokračujte kontrolou vašich údajů na počítači</h2>

    <div class="text-center my-20">
      <i>
        <img
          :src="getIconUrl('icon-mobl2PC')"
          alt="Ikonka přechod zpět na počítač"
        >
      </i>
    </div>

    <LitAlert class="mb-20">
      Nyní můžete pokračovat v zakládání penzijního spoření na počítači,
      kde jste před focením dokladů začali.<br>
      V prohlížeči na počítači byste nyní měli vidět údaje z vyfocených dokladů.
    </LitAlert>

    <p>
      Pokud vaše údaje na počítači nevidíte,
      <button
        type="button"
        class="btn-simple"
        @click="resendData"
      >
        odešlete je znovu
      </button>.
    </p>
  </div>
</template>

<script lang="ts">
import { update } from '@/js/api/contracts';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import { getIconUrl } from '@/js/utils';

export default {
  components: {
    LitAlert,
  },

  setup () {
    return {
      getIconUrl,
    };
  },

  mounted () {
    this.broadcastScanFinish();
  },

  methods: {
    resendData () {
      this.$router.push({ name: 'onlineAgreement.personalDataDocumentsScan' });
    },

    async broadcastScanFinish () {
      const { contractUuid } = this.$route.params;

      await update(contractUuid as string, {
        documentsScanFinished: 'pc',
        validateOnly: [],
      });
    },
  },
};
</script>
