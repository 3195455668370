<template>
  <div class="slider">
    <div class="slider__wrapper">
      <div class="slider__track--bg" />

      <div
        class="slider__track--fill"
        :style="{ width: `${syncedValue}%` }"
      />

      <input
        ref="slider"
        v-model="syncedValue"
        class="slider__input"
        type="range"
        :max="max"
        :min="min"
        :step="step"
        @click="onClick"
      >

      <div
        class="slider__thumb"
        :style="{ left: `${sliderThumbPosition}px` }"
      />
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  props: {
    modelValue: {
      type: Number,
      default: null,
    },

    min: {
      type: String,
      default: '0',
    },

    max: {
      type: String,
      default: '100',
    },

    step: {
      type: String,
      default: '1',
    },
  },

  emits: [
    'update:modelValue',
  ],

  data: () => ({
    sliderWidth: 0,
    sliderValue: null,
  }),

  computed: {
    syncedValue: {
      get () {
        return this.modelValue === null
          ? this.sliderValue
          : this.modelValue;
      },

      set (val) {
        if (this.modelValue === null) {
          this.sliderValue = val;
        } else {
          this.$emit('update:modelValue', val);
        }
      },
    },

    sliderThumbPosition () {
      const val = this.syncedValue;
      const width = this.sliderWidth - 28; // slider with - thumb width
      const percent = (val - this.min) / (this.max - this.min);

      return width * percent;
    },
  },

  mounted () {
    nextTick(() => {
      this.onResize();
    });
  },

  created () {
    window.addEventListener('resize', this.onResize);
  },

  unmounted () {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize () {
      this.sliderWidth = this.$refs.slider.clientWidth;
    },

    onClick (e) {
      this.syncedValue = e.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

$thumb-size: 28px;
$thumb-background: getColor(green);

.slider {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__track {
    &--bg,
    &--fill {
      border-radius: 2px;
      height: 4px;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
    }

    &--bg {
      background: rgba(#773385, .5);
      width: 100%;
      z-index: 0;
    }

    &--fill {
      background: rgba(#09c, .5);
      left: 0;
      z-index: 1;
    }
  }

  &__input {
    -webkit-appearance: none; /* stylelint-disable-line */
    margin: 0;
    height: 4px;
    width: 100%;
    background: none;
    padding: 0;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:focus {
      outline: none;
    }
    /* stylelint-disable */
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 10px;
      cursor: pointer;
      background: transparent;
      border-radius: 0;
      border: none;
    }

    &::-webkit-slider-thumb {
      height: $thumb-size;
      width: $thumb-size;
      border-radius: 50%;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -9px;
      background: $thumb-background;
    }

    &::-moz-range-thumb {
      height: $thumb-size;
      width: $thumb-size;
      border-radius: 50%;
      background: $thumb-background;
      cursor: pointer;
      border: none;
    }

    &::-ms-thumb {
      height: $thumb-size;
      width: $thumb-size;
      border-radius: 50%;
      background: $thumb-background;
      cursor: pointer;
    }

    &:focus::-webkit-slider-runnable-track {
      background: transparent;
    }

    &::-moz-range-track {
      width: 100%;
      height: 10px;
      cursor: pointer;
      background: transparent;
      border-radius: 0;
    }

    &::-ms-track {
      width: 100%;
      height: 10px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 10px 0;
      border-radius: 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: transparent;
      border: none;
      border-radius: 0;
    }

    &::-ms-fill-upper {
      background: transparent;
      border: none;
      border-radius: 0;
    }
    /* stylelint-enable */
  }

  &__thumb {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border-radius: 50%;
    background: getColor(green);
    pointer-events: none;
    z-index: 2;

    &::after {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: getColor(dark-green);
    }
  }
}
</style>
