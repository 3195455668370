export const legalRepresentativeFields = [
  'legalRepresentative.firstName',
  'legalRepresentative.lastName',
  'legalRepresentative.personalIdNumber',
  'legalRepresentative.citizenship',
  'legalRepresentative.countryOfBirth',
  'legalRepresentative.birthplace',

  'legalRepresentative.permanentAddress.street',
  'legalRepresentative.permanentAddress.streetNumber',
  'legalRepresentative.permanentAddress.city',
  'legalRepresentative.permanentAddress.zip',
  'legalRepresentative.permanentAddress.country',
];

export const documentInfoFields = [
  'personalData.idCard.number',
  'personalData.idCard.validUntil',
  'personalData.idCard.issuedBy',

  'personalData.secondDocument.number',
  'personalData.secondDocument.validUntil',
  'personalData.secondDocument.issuedBy',
];
