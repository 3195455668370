<template>
  <div dusk="additional-data">
    <TransferStepper current-step="additionalData" />

    <div class="container--sm my-20">
      <h2>Doplňující údaje</h2>

      <form
        @submit.prevent="submitStep"
      >
        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="transfer"
          field-path="currentContractInformation.contractNumber"
          @validate="validateField"
        >
          <LitInput
            class="mb-30"
            label="Číslo smlouvy, která má být ukončena"
            name="current-contract-number"
            dusk="current-contract-number"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, errors, input, validate }"
          namespace="transfer"
          field-path="currentContractInformation.contractValidityFrom"
          :debounce="0"
          @validate="validateField"
        >
          <DatePicker
            class="mb-20"
            name="current-contract-validity-from"
            label="Platnost smlouvy od"
            dusk="current-contract-validity-from"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @change="validate"
          />
        </ServerValidatedFormField>

        <div v-if="displayEndContractDateAsInput">
          <ServerValidatedFormField
            v-slot="{ value, input, errors, validate }"
            namespace="transfer"
            field-path="currentContractInformation.terminationDay"
            @validate="validateField"
          >
            <LitInput
              class="mb-30"
              label="Orientační datum ukončení smlouvy"
              name="current-contract-termination"
              dusk="current-contract-termination"
              disabled
              tooltip="Datum ukončení se odvíjí od data doručení do PS a na výpovědní lhůtě."
              :model-value="formatDate(value)"
              :errors="errors"
              @update:model-value="input"
              @blur="validate($event.target.value)"
            />
          </ServerValidatedFormField>
        </div>

        <div v-else>
          <ServerValidatedFormField
            v-slot="{ value, input, errors }"
            namespace="transfer"
            field-path="currentContractInformation.terminationDay"
            :validate-on-input="true"
            @validate="validateField"
          >
            <LitSelect
              class="mb-30"
              label="Datum ukončení smlouvy"
              dusk="current-contract-termination-select"
              :model-value="value"
              :options="endDateCurrentPensionSavingsOptions"
              :error="errors.length > 0 ? errors[0] : ''"
              @update:model-value="input"
            />
          </ServerValidatedFormField>
        </div>

        <ServerValidatedFormField
          v-slot="{ value, input }"
          namespace="transfer"
          field-path="currentContractInformation.terminationByAgreement"
          @validate="validateField"
        >
          <LitCheckbox
            class="mb-20"
            dusk="termination-by-agreement"
            :model-value="value"
            @update:model-value="input"
          >
            Žádám o výpověď smlouvy dohodou
          </LitCheckbox>
        </ServerValidatedFormField>
      </form>
    </div>

    <div class="container my-30">
      <hr>
    </div>

    <StepButtons
      namespace="transfer"
      previous-step="personalData"
      :fields="fields"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import {
  add,
  eachMonthOfInterval,
  format,
  lastDayOfMonth,
} from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import StepButtons from '../../common/StepButtons.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';

import DatePicker from '@/js/components/DatePicker.vue';

import TransferStepper from '@/js/components/Steppers/TransferStepper.vue';
import gtm from '@/js/services/gtm';
import { useTransferStore } from '@/js/stores';
import { scrollToError } from '@/js/utils';

const route = useRoute();
const router = useRouter();

const store = useTransferStore();
const { validateField, validateFields } = store;
const { currentContractInformation } = storeToRefs(store);

const validating = ref(false);
const dateFormat = ref('dd. MM. yyyy');
const today = ref(new Date());
const fields = shallowRef([
  'currentContractInformation.contractNumber',
  'currentContractInformation.contractValidityFrom',
  'currentContractInformation.terminationDay',
  'currentContractInformation.terminationByAgreement',
]);

const isTerminationByAgreement = computed(() => {
  return currentContractInformation.value.terminationByAgreement.value;
});

const isTerminationByAgreementInEndOfMonth = computed(() => {
  // pokud klient přijde 1.-20. v měsíci, tak se nabídne nejbližší datum,
  // pokud přijde 21.-31. - nabídne se mu až následující měsíc
  return isTerminationByAgreement.value && today.value.getDate() >= 21;
});

const displayEndContractDateAsInput = computed(() => {
  return !isTerminationByAgreement.value || isTerminationByAgreementInEndOfMonth.value;
});

const endDateCurrentContract = computed(() => {
  if (displayEndContractDateAsInput.value) {
    let noticePeriod = 0;

    if (isTerminationByAgreementInEndOfMonth.value) {
      noticePeriod = 1; // month
    } else if (!isTerminationByAgreement.value) {
      noticePeriod = 2; // months
    }

    const contractEndMonth = today.value.getMonth() + noticePeriod;
    const contractEndDate = new Date(today.value.getFullYear(), contractEndMonth);

    return format(lastDayOfMonth(contractEndDate), 'yyyy-MM-dd');
  }

  return null;
});

const endDateCurrentPensionSavingsOptions = computed(() => {
  const rangeOfMonths = eachMonthOfInterval({
    start: today.value,
    end: add(today.value, {
      months: 1,
    }),
  });

  return rangeOfMonths.map((date) => ({
    value: format(lastDayOfMonth(date), 'yyyy-MM-dd'),
    label: format(lastDayOfMonth(date), dateFormat.value),
  }));
});

function formatDate (date: string) {
  const dateToFormat = new Date(date);
  return format(dateToFormat, dateFormat.value);
}

async function setContractTerminationDay (date: string | null) {
  const { contractUuid } = route.params;

  currentContractInformation.value.terminationDay.value = date;

  await validateFields({
    contractUuid,
    throwOnErrors: true,
    fieldPaths: [
      'currentContractInformation.terminationDay',
    ],
  });
}

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    await router.push({
      name: 'transfer.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('doplnujici-udaje');
  } catch (e) {
    validating.value = false;

    scrollToError();

    console.warn(`There was a validation recapitulationError: ${e}`);
  }
}

watch(endDateCurrentContract, (newValue) => {
  setContractTerminationDay(newValue);
});

onMounted(() => {
  setContractTerminationDay(endDateCurrentContract.value);
});
</script>
