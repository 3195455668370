<template>
  <div class="toggle-switch">
    <label class="switch">
      <input
        type="checkbox"
        class="switch__input"
        :checked="modelValue"
        :name="name"
        @change="$emit('update:modelValue', ($event.target as HTMLInputElement).checked)"
      >
      <span
        class="slider round"
        :dusk="dusk"
      />
    </label>
    <span class="switch__label">
      {{ label }}
    </span>
    <slot name="after">
      <LitTooltip
        v-if="tooltip && showTooltip"
        :position="tooltipPosition"
        :icon-path="iconTooltip"
      >
        <span
          class="link"
          v-html="tooltip"
        />
      </LitTooltip>
    </slot>
    <small
      v-if="errors.length > 0"
      class="error"
    >
      {{ errors[0] }}
    </small>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import LitTooltip from './LitTooltip.vue';

const props = defineProps({
  name: {
    type: String,
    default: '',
  },

  modelValue: {
    type: Boolean,
    default: false,
  },

  dusk: {
    type: String,
    default: '',
  },

  errors: {
    type: Array,
    required: false,
    default: () => ([]),
  },

  iconTooltip: {
    type: String,
    default: '',
  },

  tooltip: {
    type: String,
    default: '',
  },

  tooltipPosition: {
    type: String,
    default: 'top',
  },

  showTooltip: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    default: '',
  },

  labelMinWidth: {
    type: Number,
    default: 110,
  },
});

defineEmits([
  'update:modelValue',
]);

const labelWidth = computed(() => {
  return `${props.labelMinWidth ?? 110}px`;
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';

$control-bg: #d8e8c5;

.toggle-switch {
  display: flex;
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 2.8rem;
  height: 1.491rem;

  &--disabled {
    opacity: .5;
  }

  &__label {
    align-self: center;
    margin-left: 20px;
    min-width: v-bind(labelWidth);
  }
}

.switch__control {
  width: rem(24);
  height: rem(24);
  border-radius: rem(2);
  border: 1px solid transparent;
  background: $control-bg;
  margin-right: 10px;

  &--error {
    background: getColor(danger, .1);
    border-color: getColor(danger, 1);
    color: getColor(danger, 1);
  }
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: revert;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d8e8c5;
  transition: .4s;
}

.slider::before {
  position: absolute;
  content: '';
  height: 1.2rem;
  width: 1.2rem;
  left: 1px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: getColor(green);
}

input:focus + .slider {
  box-shadow: 0 0 1px getColor(green);
}

input:checked + .slider::before {
  transform: translateX(1.4rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}

.error {
  display: block;
  padding: .125em 0;
  margin-top: .25em;
  color: getColor(danger, 1);
  margin-left: rem(34);
}
</style>
