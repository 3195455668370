import axios from 'axios';

export async function login (email: string, password: string): Promise<any> {
  const { data } = await axios.post('/login', {
    email,
    password,
  }, {
    withCredentials: false,
  });
  return data;
}

export default {
  login,
};
