<script setup lang="ts">
import { GuidePost } from '@csob-penze/csob-penze-design';
import { ref } from 'vue';
import { getHomepageUrl } from '@/js/utils.ts';

const loading = ref(false);

function getRedirectUrl (page: string) {
  return `${getHomepageUrl()}${page}`;
}
</script>

<template>
  <div class="container--sm mt-20">
    <div class="mb-20">
      <h2>Prodejní rozhovor</h2>
    </div>

    <div class="mb-20">
      <GuidePost
        :column-count="3"
        :tiles="[
          {
            mainTitle: 'Představení penzijního spoření - výhody',
            redirectingText: 'Dochází k přesměrování',
            color: '#092f68',
            iconName: 'savings-outline-dark-blue',
            href: getRedirectUrl('pro-stavajici-klienty/penzijni-sporeni/'),
            target: '_blank',
            loading
          },
          {
            mainTitle: 'Výhody a celkové shrnutí',
            redirectingText: 'Dochází k přesměrování',
            color: '#092f68',
            iconName: 'checklist',
            href: getRedirectUrl('proc-sporit/'),
            target: '_blank',
            loading
          },
          {
            mainTitle: 'Kalkulačky, kolik si naspořím',
            redirectingText: 'Dochází k přesměrování',
            color: '#092f68',
            iconName: 'calculate',
            href: getRedirectUrl('kalkulacky/'),
            target: '_blank',
            loading
          },
          {
            mainTitle: 'Zhodnocení: jednotlivé účastnické fondy',
            redirectingText: 'Dochází k přesměrování',
            color: '#092f68',
            iconName: 'eco',
            href: getRedirectUrl('nase-penzijni-sporeni/'),
            target: '_blank',
            loading
          },
          {
            mainTitle: 'Strategie životního cyklu',
            redirectingText: 'Dochází k přesměrování',
            color: '#092f68',
            iconName: 'replay',
            href: getRedirectUrl('strategie-zivotniho-cyklu/'),
            target: '_blank',
            loading
          },
          {
            mainTitle: 'Jednotlivé typy dávek',
            redirectingText: 'Dochází k přesměrování',
            color: '#092f68',
            iconName: 'money',
            href: getRedirectUrl('distribuce/jak-vybrat-prostredky-z-dps-a-pp/'),
            target: '_blank',
            loading
          }
        ]"
      />
    </div>
  </div>
</template>
