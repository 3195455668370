<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';

import LitInput from '@/js/components/Base/LitInput.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import ChangeRequestFormsOfferModal from '@/js/components/Modals/ChangeRequestFormsOfferModal.vue';
import { useDateRange } from '@/js/composables/useDateRange.ts';
import incomeFromOptions from '@/js/data/incomeFromOptions.ts';

import gtm from '@/js/services/gtm';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';
import { scrollToError } from '@/js/utils.ts';
import StepButtons from '@/js/views/common/StepButtons.vue';

const route = useRoute();
const router = useRouter();

const store = useChangeRequestStore();
const { validateField, validateFields } = store;
const { legalRequirements } = storeToRefs(store);

const validating = ref(false);
const showFormsOfferModal = ref(false);

const payTaxesInCZOptions = shallowRef([
  { value: true, label: 'Ano' },
  { value: false, label: 'Ne' },
]);

const politicallyExposedPersonOptions = shallowRef([
  { value: false, label: 'Ne, není' },
  { value: true, label: 'Ano, je' },
]);

const taxPaymentChange = computed(() => {
  return legalRequirements.value.paysTaxesInCzechia.value;
});

const politicalChange = computed(() => {
  return legalRequirements.value.isPoliticallyExposed.value;
});

const sourceOfIncomeChange = computed(() => {
  return legalRequirements.value.sourceOfIncome.value;
});

const occupationChange = computed(() => {
  return legalRequirements.value.occupation.value;
});

watch(politicalChange, (newVal) => {
  if (newVal !== null || newVal !== '') {
    legalRequirements.value.changeRequired.value = true;
  }
});

watch(taxPaymentChange, (newVal) => {
  if (newVal !== null || newVal !== '') {
    legalRequirements.value.changeRequired.value = true;
  }
});

watch(sourceOfIncomeChange, (newVal) => {
  if (newVal !== null || newVal !== '') {
    legalRequirements.value.changeRequired.value = true;
  }
});

watch(occupationChange, (newVal) => {
  if (newVal !== null || newVal !== '') {
    legalRequirements.value.changeRequired.value = true;
  }
});

function offerForms () {
  showFormsOfferModal.value = true;
}

async function handleSubmit () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        'legalRequirements.paysTaxesInCzechia',
        'legalRequirements.isPoliticallyExposed',
        'legalRequirements.sourceOfIncome',
        'legalRequirements.occupation',
        'legalRequirements.effectiveDate',
        'changeSettings.involvedForms',
      ],
    });

    await router.push({
      name: 'changeRequest.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zakonne-pozadavky');
  } catch (e) {
    scrollToError();

    console.warn(`There was a validation clientsStatementError: ${e}`);
  } finally {
    validating.value = false;
  }
}

const tooltips = shallowRef({
  paysTaxesInCzechia: `Možnost Ne označte pouze v případě, že alespoň jeden ze svých příjmů daníte v zahraničí.
   V případě, že žádný zdanitelný příjem nemáte, protože jste např. student nebo jste v důchodu,
   jste stále daňovým rezidentem ČR. Zvolte tedy možnost Ano.`,
  isPoliticallyExposed: 'Politicky exponovaná osoba je osoba ve významné veřejné funkci nebo fyzická osoba s takovou osobou příbuzná či v partnerském vztahu. ',
  sourceOfIncome: 'Vyberte zdroj příjmu, ze kterého si budete přispívat na své penzijní spoření.',
  occupation: 'Uveďte prosím co nejpřesněji svou profesi (např. lékař, podnikající v oblasti zemědělství, jednatel ve společnosti aj.).',
});

const labels = shallowRef({
  paysTaxesInCzechia: 'Odvádíte daně v České republice?',
  isPoliticallyExposed: 'Jste politicky exponovanou osobou?',
  sourceOfIncome: 'Z čeho pocházejí vaše příjmy?',
  occupation: 'Jaké je vaše povolání?',
});

function resetFilled () {
  legalRequirements.value.changeRequired.value = false;
  legalRequirements.value.occupation.value = '';
  legalRequirements.value.sourceOfIncome.value = '';
  legalRequirements.value.isPoliticallyExposed.value = null;
  legalRequirements.value.paysTaxesInCzechia.value = null;
}
</script>

<template>
  <div class="container--sm">
    <div class="flex flex-between">
      <h3 dusk="contribution-heading" class="mt-10">
        Zákonné požadavky
      </h3>
      <LitIconSvg
        icon-name="choice-reset"
        class="pointer"
        @click="resetFilled"
      />
    </div>
    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      namespace="changeRequest"
      field-path="legalRequirements.paysTaxesInCzechia"
      :validate-on-input="true"
      @validate="validateField"
    >
      <LitRadio
        class="mb-20"
        name="payTaxesInCZ"
        dusk="tax-payment"
        :tooltip="tooltips.paysTaxesInCzechia"
        :label="labels.paysTaxesInCzechia"
        :model-value="value"
        :error="errors.length > 0 ? errors[0] : ''"
        :show-inline="true"
        :options="payTaxesInCZOptions"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate, readOnly }"
      namespace="changeRequest"
      field-path="legalRequirements.isPoliticallyExposed"
      :validate-on-input="true"
      @validate="validateField"
    >
      <LitRadio
        v-if="!readOnly"
        class="mb-20"
        dusk="politically-exposed"
        name="politicallyExposedPerson"
        :tooltip="tooltips.isPoliticallyExposed"
        :label="labels.isPoliticallyExposed"
        :model-value="value"
        :error="errors.length > 0 ? errors[0] : ''"
        :show-inline="true"
        :options="politicallyExposedPersonOptions"
        @update:model-value="input"
      />

      <LitInput
        v-else
        name="politicallyExposedPerson"
        class="mb-30"
        dusk="politically-exposed"
        :label="labels.isPoliticallyExposed"
        :model-value="value === false ? 'Ne' : 'Ano'"
        :errors="errors"
        :disabled="readOnly"
        :description="readOnly ? 'Předvyplněno z Bank iD, tento údaj nelze změnit.' : ''"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      namespace="changeRequest"
      field-path="legalRequirements.sourceOfIncome"
      :validate-on-input="true"
      @validate="validateField"
    >
      <LitSelect
        class="mb-20"
        dusk="income-from"
        :tooltip="tooltips.sourceOfIncome"
        :label="labels.sourceOfIncome"
        :model-value="value"
        :error="errors.length > 0 ? errors[0] : ''"
        :options="incomeFromOptions"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors, validate }"
      namespace="changeRequest"
      field-path="legalRequirements.occupation"
      :debounce="0"
      @validate="validateField"
    >
      <LitInput
        name="job"
        class="mb-30"
        dusk="employment"
        placeholder="Např. Vedoucí provozu"
        :tooltip="tooltips.occupation"
        :label="labels.occupation"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
        @blur="validate($event.target.value)"
      />
    </ServerValidatedFormField>

    <ServerValidatedFormField
      v-slot="{ value, input, errors }"
      namespace="changeRequest"
      field-path="legalRequirements.effectiveDate"
      :validate-on-input="true"
      :debounce="0"
      @validate="validateField"
    >
      <LitSelect
        class="mb-20 mt-30"
        dusk="optimalization-start-date"
        label="Datum účinnosti úpravy zákonných požadavků"
        :options="useDateRange(3)"
        :model-value="value"
        :errors="errors"
        @update:model-value="input"
      />
    </ServerValidatedFormField>

    <hr class="separator my-30">

    <div class="flex flex-between">
      <StepButtons
        namespace="changeRequest"
        previous-step="clientData"
        @submit="offerForms"
      />
    </div>

    <ChangeRequestFormsOfferModal
      v-model="showFormsOfferModal"
      :forms="store.changeSettings.involvedForms.value"
      @close="showFormsOfferModal = false"
      @finish="handleSubmit"
    />
  </div>
</template>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>
