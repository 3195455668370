import axios from './axios';

interface Signature {
  signMethod: string
  client: string
}

export function create (contractUuid: string | string[], signatures: string | Signature[]) {
  return axios.post(`/api/v1/contracts/${contractUuid.toString()}/signatures`, {
    signatures,
  });
}

export function show (contractUuid: string | string[]): Promise<{ data: Array<Signature> }> {
  return axios.get(`/api/v1/contracts/${contractUuid.toString()}/signatures`);
}

export function update (contractUuid: string | string[], signatures: string | Signature[], isSubmitting = false, allowToChangeSignMethod = true) {
  return axios.patch(`/api/v1/contracts/${contractUuid.toString()}/signatures`, {
    signatures,
    isSubmitting,
    allowToChangeSignMethod,
  });
}

export default {
  create,
  update,
  show,
};
