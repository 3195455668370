<script setup lang="ts" generic="T extends ContractSettings">
import type { ContractSettings } from '@/js/stores/types';

import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';
import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat.ts';

interface Props {
  storePath: T
  namespace: string
}

const props = defineProps<Props>();

function formatDate (date: string | null) {
  if (date === null) {
    return '';
  }

  return useDateTimeFormat(new Date(date), 'dd. MM. yyyy');
}
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Nastavení smlouvy</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.contractSettings` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <RecapitulationTableRow :path="props.storePath.contractSignaturePlace" table-key="Místo podepsání smlouvy" />
      <RecapitulationTableRow :path="props.storePath.contractSignatureDate" table-key="Datum podepsání smlouvy">
        <template #tableValue>
          {{ formatDate(props.storePath.contractSignatureDate.value) }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow :path="props.storePath.taxOptimization" table-key="Daňová optimalizace">
        <template #tableValue>
          {{ props.storePath.taxOptimization.value === true ? 'Ano' : 'Ne' }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow :path="props.storePath.contributionFromEmployer" table-key="Příspěvek zaměstnavatele">
        <template #tableValue>
          {{ props.storePath.contributionFromEmployer.value === true ? 'Ano' : 'Ne' }}
        </template>
      </RecapitulationTableRow>

      <template v-if="props.storePath.contributionFromEmployer.value">
        <RecapitulationTableRow :path="props.storePath.employerInfo.name" table-key="Název zaměstnavatele" />
        <RecapitulationTableRow :path="props.storePath.employerInfo.vat" table-key="IČ zaměstnavatele" />
      </template>

      <RecapitulationTableRow :path="props.storePath.specifyNominees" table-key="Určené osoby">
        <template #tableValue>
          {{ props.storePath.specifyNominees.value === true ? 'Ano' : 'Ne' }}
        </template>
      </RecapitulationTableRow>

      <template v-if="props.storePath.specifyNominees.value">
        <RecapitulationTableRow
          v-for="(nominee, index) in props.storePath.nominees"
          :key="index"
          :path="nominee.firstName"
          :table-key="`Určená osoba č. ${index + 1}`"
          :additional-validation-paths="[
            nominee.firstName,
            nominee.lastName,
            nominee.personalId,
            nominee.birthdate,
            nominee.countryOfBirth,
            nominee.permanentAddress.query,
            nominee.share
          ]"
        >
          <template #tableValue>
            {{ nominee.firstName.value }} {{ nominee.lastName.value }}<br>
            <span v-if="nominee.usePersonalId.value !== false">
              {{ nominee.personalId.value }}
            </span>
            <span v-else>
              {{ nominee.birthdate.value }}<br>
              {{ nominee.countryOfBirth.value }}
            </span>
            <br>
            {{ nominee.permanentAddress.query.value }} <br>
            Podíl: {{ nominee.share.value }}%
          </template>
        </RecapitulationTableRow>
      </template>
    </tbody>
  </table>
</template>
