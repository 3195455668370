import * as Termination from '../../views/distribution/termination';

const termination = {
  path: '/vypoved/:contractUuid',
  component: Termination.Layout,
  children: [
    {
      path: 'nez-zacneme',
      name: 'termination.beforeStart',
      component: Termination.BeforeStart,
    },

    {
      path: 'prodejni-rozhovor',
      name: 'termination.salesInterview',
      component: Termination.SalesInterview,
    },

    {
      path: 'kontaktni-udaje',
      name: 'termination.contactInformation',
      component: Termination.ContactInformation,
    },

    {
      path: 'zadani-osobnich-udaju',
      name: 'termination.personalData',
      component: Termination.PersonalData,
    },

    {
      path: 'zpusob-vyplaty',
      name: 'termination.paymentOption',
      component: Termination.PaymentOption,
    },

    {
      path: 'doplnujici-udaje-vyplaty',
      name: 'termination.additionInformation',
      component: Termination.AdditionalInformation,
    },

    {
      path: 'rekapitulace',
      name: 'termination.recapitulation',
      component: Termination.Recapitulation,
    },

    {
      path: 'mezidekovaci-stranka',
      name: 'termination.intermediateStep',
      component: Termination.TerminationTransferConfirmation,
    },

    {
      path: 'podpis-bank-id',
      name: 'termination.bankIdSignaturePage',
      component: Termination.BankList,
    },

    {
      path: 'dekujeme-odeslani-odkazu',
      name: 'termination.thankYouSignaturePage',
      component: Termination.ThankYouSignaturePage,
    },

    {
      path: 'dekovaci-stranka',
      name: 'termination.thankYouPage',
      component: Termination.ThankYouPage,
    },

    {
      path: 'dekujeme',
      name: 'termination.bankIdThankYouPage',
      component: Termination.BankIdThankYouPage,
    },
  ],
};

export default termination;
