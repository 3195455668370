<template>
  <Stepper
    :steps
    :current-step
  />
</template>

<script setup lang="ts">
import { shallowRef } from 'vue';
import Stepper from './StepperDistribution.vue';

defineProps<Props>();

type Step = typeof steps.value;

interface Props {
  currentStep: keyof Step
}

const steps = shallowRef({
  salesInterview: {
    title: 'Prodejní rozhovor',
    uri: { name: 'jps.salesInterview' },
  },
  contactInformation: {
    title: 'Kontaktní údaje podepisujícího',
    uri: { name: 'jps.contactInformation' },
  },
  contractDocumentsAndConsents: {
    title: 'Předsmluvní dokumentace',
    uri: { name: 'jps.contractDocumentsAndConsents' },
  },
  personalData: {
    title: 'Osobní údaje klienta',
    uri: { name: 'jps.personalData' },
  },
  legalRequirements: {
    title: 'Zákonné požadavky',
    uri: { name: 'jps.legalRequirements' },
  },
  contributionAndStrategy: {
    title: 'Příspěvek a strategie',
    uri: { name: 'jps.contributionAndStrategy' },
  },
  contractSettings: {
    title: 'Nastavení smlouvy',
    uri: { name: 'jps.contractSettings' },
  },
  clientsStatement: {
    title: 'Prohlášení klienta',
    uri: { name: 'jps.clientsStatement' },
  },
  recapitulation: {
    title: 'Rekapitulace',
    uri: { name: 'jps.recapitulation' },
  },
});
</script>
