<template>
  <div dusk="transfer-confirmation">
    <div class="benefits bg-blue pt-30 pb-10">
      <div class="container--sm" />
    </div>

    <div class="container--sm my-30">
      <div class="text-center">
        <TheHeading
          v-if="isNewContractFinished && !isProcessed"
          type="info"
          :show-icon="false"
        >
          A už jen podpis
        </TheHeading>

        <TheHeading v-else-if="isProcessed">
          A máte hotovo!
        </TheHeading>

        <TheHeading
          v-else
          type="info"
          :show-icon="false"
        >
          Výborně, to těžší máte za sebou!
        </TheHeading>

        <TwoFormsStepper
          v-if="!isProcessed"
          class="my-30"
          :steps="steps"
        />
      </div>

      <div v-if="!isNewContractFinished && !isProcessed">
        <StepButtons
          dusk="go-to-new-contract"
          namespace="termination"
          button-text="POKRAČOVAT NA NOVOU SMLOUVU"
          button-position="flex-center"
          :show-submit-after-icon="false"
          :display-previous-step-button="false"
          @submit="createNewContract"
        />
      </div>

      <div v-if="isLitSignEnabled">
        <div
          v-if="isNewContractFinished && !isProcessed"
        >
          <div v-if="isRedirected">
            <button
              type="button"
              class="btn btn-green btn-big"
              dusk="digital-submit-step-redirection"
              @click="showDigitalSignModal = true"
            >
              <span>
                PODEPSAT
              </span>
            </button>
          </div>

          <div v-if="isParticipant === false">
            <StepButtons
              namespace="transfer"
              button-text="TISK DOKUMENTŮ"
              button-position="flex-end"
              container-size="container"
              :display-previous-step-button="false"
              :show-submit-after-icon="false"
              dusk="print-submit-step"
              @submit="submitStep('offline')"
            />
          </div>

          <LitButton
            v-if="isParticipant && !isRedirected && !isProcessed"
            class="text-uppercase"
            @click="showDigitalSignModal = true"
          >
            Podepsat elektronicky
          </LitButton>
          <LitButton
            v-else
            class="text-uppercase"
            @click="submitStep('digital')"
          >
            Podepsat elektronicky s&nbsp;klientem
          </LitButton>
        </div>

        <div
          v-show="isParticipant && isNewContractFinished && !isProcessed"
          dusk="print-info"
          class="print-info"
        >
          <div class="container mt-30">
            <hr>
          </div>

          <PrintOffer
            v-if="isNewContractFinished"
            form-type="request"
            @show-modal="openModal('offline')"
          />
        </div>

        <ContractSubmissionModal
          type="termination"
          :model-value="redirecting"
          :is-digital="true"
        >
          <template #action>
            <p
              v-if="signingLink !== null"
              class="text-loading text-center"
            >
              Pokud nedojde k přesměrování, klikněte prosím na
              <a
                :href="signingLink"
              >
                tento odkaz
              </a>.
            </p>
          </template>
        </ContractSubmissionModal>

        <FinishWithPaperPrintModal
          form-type="request"
          :model-value="showPrintConfirmationModal"
          @close="showPrintConfirmationModal = false"
          @click="submitStep('offline')"
        />

        <ContractSigningModal
          v-model="showDigitalSignModal"
          @sign-with-phone="sendDraftToken('digital')"
          @sign-later="sendDraftToken('digital')"
          @sign-with-computer="submitStep('digital')"
        />
      </div>

      <div v-if="isNewContractFinished && !isProcessed">
        <StepButtons
          namespace="transfer"
          button-text="VYTISKNOUT DOKUMENTY"
          button-position="flex-end"
          container-size="container"
          :display-previous-step-button="false"
          dusk="print-submit-step"
          @submit="submitStep('offline')"
        />
      </div>

      <div v-if="isProcessed">
        <div v-if="isCsobDistribution">
          <p class="mb-20">
            <strong>Žádost o výplatu dávky</strong> 2x vytiskněte
            a v závislosti na výši naspořené částky
            na smlouvě klienta postupujte dle následujících pravidel.
          </p>

          <p>
            Naspořená částka je:
          </p>

          <ul class="text-left">
            <li>
              <strong>menší nebo rovna 150 000 Kč</strong> – podepíše klient a jeden obchodní
              zástupce s patřičným oprávněním
            </li>
            <li>
              <strong>
                větší než 150 000 Kč a zároveň menší nebo rovna 300 000 Kč</strong> – podepíše
              klient, jeden obchodní zástupce s patřičným oprávněním a druhý obchodní zástupce
            </li>
            <li>
              <strong>větší než 300 000 Kč</strong> – podepíše klient a jeden obchodní
              zástupce s patřičným oprávněním,
              zároveň je potřeba jedno vyhotovení nechat úředně ověřit.
              Podpis můžete nechat ověřit bezplatně
              na pobočce ČSOB, nebo s poplatkem na pobočkách České pošty
              (Czech point), na obecním úřadě či u&nbsp;notáře.
            </li>
          </ul>

          <p>
            Je-li součástí i <strong>nová smlouva o doplňkovém penzijním spoření</strong>,
            nezapomeňte ji také 2x vytisknout a&nbsp;s&nbsp;klientem řádně podepsat.
          </p>
          <p>
            Jedno podepsané vyhotovení/Úředně ověřené vyhotovení
            (včetně nové smlouvy, je-li součástí)
            nebo další doplňující dokumenty získané od klienta
            (rozsudek soudu, rodný list,…)
            bez odkladu odešlete do sídla Penzijní společnosti,
            druhé podepsané vyhotovení předejte klientovi.
          </p>
        </div>

        <div v-else>
          <p>
            <strong>Žádost o výplatu dávky</strong> 2x vytiskněte,
            podepište Vy i klient a jedno vyhotovení nechte úředně ověřit.
            Podpis můžete nechat ověřit bezplatně na pobočce ČSOB,
            nebo s poplatkem na pobočkách České pošty (Czech point), na obecním úřadě či u notáře.
          </p>
          <p>
            Je-li součástí i <strong>nová smlouva o doplňkovém penzijním spoření</strong>,
            nezapomeňte ji také 2x vytisknout a&nbsp;s&nbsp;klientem řádně podepsat.
          </p>
          <p>
            Úředně ověřené vyhotovení (včetně nové smlouvy)
            nebo další doplňující dokumenty získané od klienta
            (rozsudek soudu, rodný list,…) bez odkladu odešlete
            do sídla Penzijní společnosti, druhé vyhotovení předejte klientovi.
          </p>
        </div>
      </div>

      <div v-if="isProcessed">
        <div v-if="loading">
          <div class="flex flex-center mb-20 flip">
            <img
              :src="getIconUrl('icon-loader_blue')"
              alt="Načítání..."
              class="spin"
            >
          </div>
          <div class="text-loading text-center text-bold">
            Nahráváme ke stažení uzavřenou žádost o výpověď
            <span v-if="!terminationWithoutNewContract">
              a novou smlouvu
            </span>
            v PDF, chvíli strpení
          </div>
        </div>

        <LitAlert
          v-if="beforeStart.isTiedAgent.value && !showTerminationHiddenAlert"
          alert-type="warning"
          class="my-20"
        >
          <strong>Dokumentace již není odesílána na e-mail obchodníka.</strong> V případě ukončení procesu/zavření
          tohoto okna,
          už nebude možné dokumentaci znovu vytisknout.
        </LitAlert>

        <LitAlert
          v-if="showTerminationHiddenAlert"
          class="mb-20"
          alert-type="info"
          dusk="contract-sending-failure"
        >
          <div>
            Odkaz ke stažení žádosti o výpověď smlouvy již není platný nebo jste
            jej stáhli již {{ terminationMaxDownloadCount }}x. Žádost však
            <span v-if="!store.beforeStart.isTiedAgent.value">máte Vy i klient</span>
            <span v-else>má klient</span> v e-mailu.
          </div>
        </LitAlert>

        <hr
          v-if="termination.pdf && terminationRemainingAttempts > 0"
          class="py=20"
        >

        <div
          v-if="termination.pdf && terminationRemainingAttempts > 0"
          class="my-20"
        >
          <div class="mt-10">
            Odkaz ke stažení žádosti o převod <span v-if="newContract.pdf && newContractRemainingAttempts > 0"> a&nbsp;nové
              smlouvy </span>ve formátu PDF je <strong>platný
              do {{ terminationAccessTokenValidity && useDateTimeFormat(terminationAccessTokenValidity) }}</strong>.
            Žádost o převod můžete stáhnout {{ terminationRemainingAttempts }}x<span
              v-if="newContract.pdf && newContractMaxDownloadPdf > 0"
            >, novou smlouvu {{ newContractMaxDownloadPdf }}x</span>.
          </div>
        </div>

        <div class="buttons flex flex-center gap-1 mt-20">
          <div v-if="termination.pdf && terminationRemainingAttempts > 0">
            <LitButton
              download
              target="_blank"
              class="download-button"
              :href="termination.pdf.link"
              as="a"
              @click="termination.downloaded++"
            >
              <template #before>
                <Download :size="20" />
              </template>

              <template #default>
                <div class="flex flex-dir-column">
                  <span class="text-uppercase">Žádost o výpověď smlouvy</span>
                  <span class="text-sm font-weight-light">PDF: {{ termination.pdf.size }} kB</span>
                </div>
              </template>
            </LitButton>
          </div>

          <div v-if="newContract.pdf && newContractMaxDownloadPdf > 0">
            <LitButton
              download
              target="_blank"
              class="download-button"
              :href="newContract.pdf.link"
              as="a"
              @click="newContract.downloaded++"
            >
              <template #before>
                <Download :size="20" />
              </template>

              <template #default>
                <div class="flex flex-dir-column">
                  <span class="text-uppercase">Nová smlouva</span>
                  <span class="text-sm font-weight-light"><span v-if="store.beforeStart.isTiedAgent.value">vč. záznamu z jednání, </span>PDF: {{
                    newContract.pdf?.size
                  }} kB </span>
                </div>
              </template>
            </LitButton>
          </div>
        </div>

        <hr class="py-20 mt-20">

        <div
          v-if="isProcessed"
          class="text-center"
        >
          <LitButton
            variant="outline"
            :to="{ name: 'distribution.home' }"
          >
            <span>
              Zpět na hlavní rozcestník
            </span>
          </LitButton>
        </div>
      </div>

      <ContractSubmissionModal
        type="termination"
        :model-value="submitting"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Download } from 'lucide-vue-next';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import StepButtons from '../../common/StepButtons.vue';
import type { File } from '@/js/stores/types';
import { contracts, draftTokens, signatures } from '@/js/api';
import contractFiles from '@/js/api/contractFiles';

import { fields } from '@/js/commonStepFieldPaths';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import ContractSigningModal from '@/js/components/Modals/ContractSigningModal.vue';
import ContractSubmissionModal from '@/js/components/Modals/ContractSubmissionModal.vue';
import FinishWithPaperPrintModal from '@/js/components/Modals/FinishWithPaperPrintModal.vue';

import PrintOffer from '@/js/components/PrintOffer.vue';
import TwoFormsStepper from '@/js/components/Steppers/TwoFormsStepper.vue';
import TheHeading from '@/js/components/TheHeading.vue';
import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat';
import env from '@/js/env';

import { useDistributionStore, useTerminationStore } from '@/js/stores';
import { copyRootFields, resetState, submitContract } from '@/js/stores/utils';
import {
  getIconUrl,
  removeContractsMetadata,
  scrollToError,
} from '@/js/utils';

const route = useRoute();
const router = useRouter();

const store = useTerminationStore();
const {
  beforeStart,
  terminationSettings,
  isParticipant,
  isCsobDistribution,
  submitResponse,
  newContract: newContractUuid,
  draftToken,
} = storeToRefs(store);
const { validateFields } = store;

const distributionStore = useDistributionStore();

const termination = ref<{ pdf: null | { size: number, link: string }, downloaded: number }>({
  pdf: null,
  downloaded: 0,
});
const newContract = ref<{ pdf: null | { size: number, link: string }, downloaded: number }>({
  pdf: null,
  downloaded: 0,
});

const loading = ref(false);
const now = ref(new Date());
const showPrintConfirmationModal = ref(false);
const showDigitalSignModal = ref(false);
const isRedirected = ref(false);
const redirecting = ref(false);
const signingLink = ref(null);
const isProcessed = ref(false);
const submitting = ref(false);
const error = ref(false);
const validating = ref(false);
const currentTimeInterval = ref<number | null>(null);

const isNewContractFinished = computed(() => {
  if (route.query.contract) {
    return route.query.contract;
  }

  return false;
});

const steps = computed(() => {
  return [
    {
      stepNumber: 1,
      title: 'Žádost o výplatu dávky',
      isActive: true,
    },
    {
      stepNumber: 2,
      title: 'Založení nové smlouvy',
      isActive: isNewContractFinished.value,
    },
    {
      stepNumber: 3,
      title: 'Podpis obou dokumentů',
      isActive: isProcessed.value,
    },
  ];
});

const newContractAccessTokenValidity = computed(() => {
  const validToken = distributionStore.submitResponse.value?.metadata.accessTokenValidity;
  return validToken ? new Date(validToken) : null;
});

const terminationAccessTokenValidity = computed(() => {
  const validToken = submitResponse.value.value?.metadata.accessTokenValidity;
  return validToken ? new Date(validToken) : null;
});

const terminationMaxDownloadCount = computed(() => {
  return submitResponse.value.value?.metadata.maxDownloadCount;
});

const isTerminationTokenValid = computed(() => {
  if (terminationAccessTokenValidity.value) {
    return terminationAccessTokenValidity.value > now.value;
  }

  return false;
});

const terminationPdf = computed(() => {
  return termination.value.pdf;
});

const isNewContractTokenValid = computed(() => {
  if (newContractAccessTokenValidity.value) {
    return newContractAccessTokenValidity.value > now.value;
  }

  return false;
});

const newContractRemainingAttempts = ref(0);

const newContractMaxDownloadPdf = computed(() => {
  return newContractRemainingAttempts.value - newContract.value.downloaded;
});

const newContractMaxDownloadCount = ref(0);

watch(newContractMaxDownloadCount, (newValue) => {
  newContractRemainingAttempts.value = newValue - newContract.value.downloaded;
});

const newContractPdf = computed(() => {
  return newContract.value.pdf;
});

const terminationRemainingAttempts = computed(() => {
  return (terminationMaxDownloadCount.value || 0) - termination.value.downloaded;
});

const terminationId = computed(() => {
  return submitResponse.value.value?.metadata.contractId;
});

const showTerminationHiddenAlert = computed(() => {
  return !isTerminationTokenValid.value || terminationRemainingAttempts.value <= 0;
});

const terminationWithoutNewContract = computed(() => {
  return !terminationSettings.value.withTransfer.value;
});

const isLitSignEnabled = computed(() => {
  return env.ENABLE_LITSIGN;
});

async function openModal (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    if (signMethod === 'digital') {
      showDigitalSignModal.value = true;
    }

    if (signMethod === 'offline') {
      showPrintConfirmationModal.value = true;
    }
  } catch (e: any) {
    throw new Error(e);
  }
}

function clearLocalTerminationContractData () {
  resetState(store.$state, false);
}

function clearLocalDistributionContractData () {
  resetState(distributionStore.$state, false);
}

function copyCommonFields () {
  copyRootFields(store.$state, distributionStore.$state, fields());
}

function copyContractNumber () {
  copyRootFields(store.$state, distributionStore.$state, [
    {
      pathFrom: 'currentContractInformation.contractNumber.value',
      pathTo: 'contractSettings.currentContract.contractNumber.value',
    },
  ]);
}

function waitForTerminationPdf () {
  let attempts = 0;

  const interval = setInterval(async () => {
    const files: File[] = await contractFiles.index(terminationId.value);

    attempts += 1;
    const file = files.find((file) => file.file_type === 'contract');

    if (files.length > 0 && file) {
      termination.value.pdf = {
        link: `${env.REST_API_URI}/v1/files/${file.id}?token=${submitResponse.value.value?.metadata.accessToken}&contractId=${terminationId.value}`,
        size: Math.round(file.file_size / 1024),
      };

      clearInterval(interval);
    } else if (attempts > 10) {
      clearInterval(interval);
    }
  }, 3 * 1000);
}

async function waitForNewContractPdf () {
  let attempts = 0;

  if (newContractUuid.value.uuid.value === null) {
    throw new Error('Invalid Contract UUID: cannot be null');
  }

  const { data } = await contracts.show(newContractUuid.value.uuid.value, 'distribution');
  const metadata = data.metadata;

  newContractMaxDownloadCount.value = metadata.maxDownloadCount;
  const submitResponse = distributionStore?.$state.submitResponse.value;

  if (submitResponse === null) {
    throw new Error('Submit has failed: Response is null');
  }

  submitResponse.metadata.accessTokenValidity = metadata.accessTokenValidity;

  const interval = setInterval(async () => {
    const files: File[] = await contractFiles.index(data.rest_api_contract_id);

    attempts += 1;

    const fileType = store.beforeStart.isTiedAgent.value ? 'joined-pdf' : 'contract';
    const file = files.find((file) => file.file_type === fileType);

    if (files.length > 0 && file) {
      newContract.value.pdf = {
        link: `${env.REST_API_URI}/v1/files/${file.id}?token=${data.metadata.accessToken}&contractId=${data.rest_api_contract_id}`,
        size: Math.round(file.file_size / 1024),
      };

      clearInterval(interval);
    } else if (attempts > 10) {
      clearInterval(interval);
    }
  }, 3 * 1000);
}

function startTimeMeasuring () {
  currentTimeInterval.value = setInterval(() => {
    now.value = new Date();
  }, 1000);
}

async function createNewContract () {
  try {
    const { contractUuid } = route.params;

    const response = await contracts.create('distribution');

    const signature = [
      {
        signer: 'distributor',
        signMethod: 'offline',
      },
      {
        signer: 'client',
        signMethod: 'offline',
      },
    ];

    await signatures.create(response.contract.uuid, JSON.stringify(signature));

    copyCommonFields();
    copyContractNumber();

    copyRootFields(store.$state, distributionStore.$state, [
      {
        pathFrom: 'personalData.isPoliticallyExposed.value',
        pathTo: 'legalRequirements.isPoliticallyExposed.value',
      },
      {
        pathFrom: 'terminationSettings.currentContractEndDate.value',
        pathTo: 'contractSettings.currentContract.contractEndsAt.value',
      },
    ]);

    await distributionStore.validateFields({
      contractUuid: response.contract.uuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    await router.push({
      name: 'distribution.contractDocumentsAndConsents',
      params: { contractUuid: response.contract.uuid },
      query: { terminationUuid: contractUuid },
    });
  } catch (e: any) {
    const status = e?.response?.status;
    const conflictingUuid = e?.response?.headers['x-conflicts-with'];

    if (status === 409 && conflictingUuid) {
      console.warn('Redirecting to ongoing contract', conflictingUuid);

      await router.push({
        name: 'distribution.contractDocumentsAndConsents',
        params: { contractUuid: conflictingUuid },
        query: route.query,
      });
    } else {
      await router.push({
        name: 'error',
      });
    }
  }
}

async function sendDraftToken (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [],
    });

    validating.value = false;

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), false, true);

    const { data } = await draftTokens.create(contractUuid as string, signMethod);

    draftToken.value.value.value = data.draftToken;
    draftToken.value.value.expiresAt = data.validTo;

    // TODO where to redirect, if draftToken is sent
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}

async function submitStep (signMethod: string) {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;
    showDigitalSignModal.value = false;
    submitting.value = true;

    const isDigital = signMethod === 'digital';

    if (isDigital) {
      redirecting.value = true;
    }

    const signature = [
      {
        signer: 'distributor',
        signMethod,
      },
      {
        signer: 'client',
        signMethod,
      },
    ];

    await signatures.update(contractUuid, JSON.stringify(signature), true);

    if (!terminationWithoutNewContract.value) {
      await signatures.update(newContractUuid.value.uuid.value!, JSON.stringify(signature), true);
      await sendNewContract(signMethod);
    }

    await submitContract(store.$state, {
      contractUuid: contractUuid as string,
      throwOnErrors: true,
      signature,
      removeContract: false,
    });

    if (isDigital) {
      // TODO get signingLinks and redirect
    } else {
      showPrintConfirmationModal.value = false;
      loading.value = true;
      isProcessed.value = true;
      submitting.value = false;

      if (!terminationWithoutNewContract.value) {
        await waitForNewContractPdf();
      }

      waitForTerminationPdf();

      startTimeMeasuring();
    }
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;

    if (newContractUuid.value.uuid.value) {
      removeContractsMetadata([contractUuid as string, newContractUuid.value.uuid.value]);
    }
  }
}

async function sendNewContract (signMethod: string) {
  const { newContractUuid } = route.query;

  try {
    await submitContract(distributionStore.$state, {
      contractUuid: newContractUuid as string,
      throwOnErrors: true,
      signature: [{
        signer: 'distributor',
        signMethod,
      }, {
        signer: 'client',
        signMethod,
      }],
      removeContract: false,
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  }
}

watch(terminationPdf, (newValue) => {
  if (newValue !== null) {
    loading.value = false;
  }
});

watch(newContractPdf, (newValue) => {
  if (newValue !== null && terminationPdf.value !== null) {
    loading.value = false;
  }
});

watch(isTerminationTokenValid, (newValue) => {
  if (!newValue) {
    currentTimeInterval.value && clearInterval(currentTimeInterval.value);
    termination.value.pdf = null;
  }
});

watch(isNewContractTokenValid, (newValue) => {
  if (!newValue) {
    currentTimeInterval.value && clearInterval(currentTimeInterval.value);
    newContract.value.pdf = null;
  }
});

onBeforeRouteLeave((to, _from, next) => {
  if (isProcessed.value) {
    clearLocalTerminationContractData();

    if (isNewContractFinished.value) {
      clearLocalDistributionContractData();
    }

    const disallowedPaths = [
      'termination.recapitulation',
      'termination.transferConfirmation',
      'notFound',
    ];

    if (to.name && disallowedPaths.includes(to.name.toString())) {
      return next({ name: 'distribution.home' });
    }
  }

  return next();
});

onMounted(() => {
  if (route.query.newContractUuid) {
    newContractUuid.value.uuid.value = route.query.newContractUuid as string;
  }

  if (terminationWithoutNewContract.value) {
    isProcessed.value = true;
    waitForTerminationPdf();
  }
});
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
/* stylelint-disable-line no-extra-semicolons */
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.modal__body {
  .btn-custom {
    padding-left: 0;
    padding-right: 0;
  }

  .btn-big {
    padding: 20px 30px 15px 25px;
  }
;

  .btns-small-font {
    .btn {
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .icons {
    flex: 0 0 70px;
    position: relative;

    &__warning {
      position: absolute;
      top: 0;
      right: 20px;
      width: 18px;
    }

    &__default {
      width: 35px;
      height: 35px;
    }
  }

  .info-text {
    color: rgba(9, 47, 104, .647);
  }
}

.buttons {
  @include media(max, $md) {
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
  }
}

.download-button {
  @include media(max, $md) {
    width: 100%;
  }
}
</style>
