<template>
  <div>
    <Stepper
      :steps
      :current-step
    />
  </div>
</template>

<script lang="ts" setup>
import { shallowRef } from 'vue';
import Stepper from './StepperDistribution.vue';

type Step = typeof steps.value;

interface Props {
  currentStep: keyof Step
}

defineProps<Props>();

const steps = shallowRef({
  salesInterview: {
    title: 'Prodejní rozhovor',
    uri: { name: 'transfer.salesInterview' },
  },
  contactInformation: {
    title: 'Kontaktní údaje podepisujícího',
    uri: { name: 'transfer.contactInformation' },
  },
  personalData: {
    title: 'Osobní údaje klienta',
    uri: { name: 'transfer.personalData' },
  },
  additionalData: {
    title: 'Doplňující údaje',
    uri: { name: 'transfer.additionalData' },
  },
  recapitulation: {
    title: 'Rekapitulace',
    uri: { name: 'transfer.recapitulation' },
  },
});
</script>
