type GrammaticalCase = 'nominative' | 'accusative' | 'locative';

interface Signers {
  participant: Record<GrammaticalCase, string>
  legalRepresentative: Record<GrammaticalCase, string>
  committee: Record<GrammaticalCase, string>
}

export type SignerKeys = keyof Signers;
export type SignerTranslation = keyof Signers['participant'] | keyof Signers['legalRepresentative'] | keyof Signers['committee'];

export const signers: Signers = {
  participant: {
    nominative: 'účastník',
    accusative: 'účastníka',
    locative: 'účastníkovi',
  },
  legalRepresentative: {
    nominative: 'zákonný zástupce',
    accusative: 'zákonného zástupce',
    locative: 'zákonném zástupci',
  },
  committee: {
    nominative: 'opatrovník',
    accusative: 'opatrovníka',
    locative: 'opatrovníkovi',
  },
};
