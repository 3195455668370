<template>
  <div dusk="personal-data">
    <DistributionStepper current-step="personalData" />

    <div class="container--sm mt-20">
      <form
        method="post"
        @submit.prevent="submitStep"
      >
        <ClientsPersonalData
          namespace="distribution"
          store-path="personalData"
          :display-insurance-numbers="personalData.permanentAddressArea.value === 'eu'"
          :validate-fnc="validateField"
        />

        <SignerPersonalData
          store-path="signer"
          namespace="distribution"
          copy-address-source="personalData"
          copy-names-source="contactInformation"
          :validate-fnc="validateField"
        />

        <div class="container mt-30">
          <hr>
        </div>
      </form>
    </div>

    <StepButtons
      namespace="distribution"
      previous-step="contractDocumentsAndConsents"
      :fields="fields"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { parse as parseDate } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import DistributionStepper from '@/js/components/Steppers/DistributionStepper.vue';
import { clientsPersonalDataFields } from '@/js/data/validationFields/commonFields';
import { documentInfoFields, signerFields } from '@/js/data/validationFields/distribution';
import gtm from '@/js/services/gtm';

import { useDistributionStore } from '@/js/stores';
import { canEnterRent, scrollToError } from '@/js/utils';
import ClientsPersonalData from '@/js/views/common/ClientsPersonalData.vue';
import SignerPersonalData from '@/js/views/common/SignerPersonalData.vue';

import StepButtons from '@/js/views/common/StepButtons.vue';

const store = useDistributionStore();
const { personalData, participantsBirthDay } = storeToRefs(store);
const { validateFields, resetPensionQuestionnaire, validateField } = store;

const route = useRoute();
const router = useRouter();

const validating = ref(false);

const fields = shallowRef([
  'personalData.birthDate',
  'personalData.gender',
  'personalData.permanentAddressArea',
  ...clientsPersonalDataFields,
  ...documentInfoFields,
  ...signerFields,
]);

const birthDay = computed(() => {
  if (personalData.value.permanentAddressArea.value !== 'cz') {
    return parseDate(personalData.value.birthDate.value, 'dd. MM. yyyy', new Date());
  }

  return participantsBirthDay.value;
});

const preferConservativeFund = computed(() => {
  return canEnterRent(birthDay.value);
});

watch(preferConservativeFund, () => {
  resetPensionQuestionnaire();
});

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: fields.value,
    });

    validating.value = false;

    await router.push({
      name: 'distribution.legalRequirements',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });

    gtm.onStepSubmit('zadani-osobnich-udaju');
  } catch (e) {
    validating.value = false;

    personalData.value.permanentAddress.setManually.value = true;

    scrollToError();

    console.warn(`There was a validation legalRequirementsError: ${e}`);
  }
}
</script>
