<template>
  <div
    class="row"
    dusk="contribution-selection"
  >
    <div class="col-sm-4 mb-sm-20 mb-15">
      <div
        dusk="contribution-500"
        :class="{
          'contribution': true,
          'selected': syncContributionGroup === 'option-500',
          'contribution--error': error
        }"
      >
        <label for="contribution1">
          <div class="contribution__value">
            <div class="contribution__input">
              <input
                id="contribution1"
                v-model="syncContributionGroup"
                type="radio"
                name="contributionGroup"
                value="option-500"
              >

              <span class="contribution__control" />
            </div>

            <div class="contribution__label">
              500 Kč
            </div>
          </div>

          <div class="contribution__result">
            <span>
              {{ labels.stateContribution }}
            </span>

            <span :class="isGrantedPension ? 'text-danger' : 'text-warning'">
              <span class="text-bold">{{ isGrantedPension ? 0 : (isForNovel ? '100 Kč' : '130 Kč') }}</span> / měsíc
            </span>
          </div>

          <div class="contribution__result">
            <span>
              {{ labels.taxSaving }}
            </span>

            <span :class="isGrantedPension ? 'text-warning' : 'text-danger'">
              <span class="text-bold">{{ isGrantedPension ? 900 : 0 }} Kč</span> / rok
            </span>
          </div>
        </label>
      </div>
    </div>

    <div class="col-sm-4 mb-sm-20 mb-15">
      <div
        dusk="contribution-1000"
        :class="{
          'contribution': true,
          'selected': syncContributionGroup === 'option-1000',
          'contribution--error': error
        }"
      >
        <label for="contribution2">
          <div class="contribution__value">
            <div class="contribution__input">
              <input
                id="contribution2"
                v-model="syncContributionGroup"
                type="radio"
                name="contributionGroup"
                value="option-1000"
              >

              <span class="contribution__control" />
            </div>

            <div class="contribution__label">
              {{ isForNovel ? '1 700 Kč' : '1 000 Kč' }}
            </div>
          </div>

          <div class="contribution__result">
            <span>{{ labels.stateContribution }}</span>

            <span :class="isGrantedPension ? 'text-danger' : 'text-success'">
              <span class="text-bold">{{ isGrantedPension ? 0 : (isForNovel ? '340 Kč' : '230 Kč') }}</span> / měsíc
            </span>
          </div>

          <div class="contribution__result">
            <span>
              {{ labels.taxSaving }}
            </span>

            <span :class="isGrantedPension ? 'text-warning' : 'text-danger'">
              <span class="text-bold">{{ isGrantedPension ? '3 060' : 0 }} Kč</span> / rok
            </span>
          </div>
        </label>

        <div
          v-if="!isGrantedPension"
          class="contribution__batch"
        >
          Nejvyšší státní příspěvek
        </div>
      </div>
    </div>

    <div class="col-sm-4 mb-sm-20 mb-15">
      <div
        dusk="contribution-3000"
        :class="{
          'contribution': true,
          'selected': syncContributionGroup === 'option-5000',
          'contribution--error': error
        }"
      >
        <label for="contribution3">
          <div class="contribution__value">
            <div class="contribution__input">
              <input
                id="contribution3"
                v-model="syncContributionGroup"
                type="radio"
                name="contributionGroup"
                value="option-5000"
              >

              <span class="contribution__control" />
            </div>

            <div class="contribution__label">
              {{ isForNovel ? '5 700 Kč' : '5 000 Kč' }}
            </div>
          </div>

          <div class="contribution__result">
            <span>{{ labels.stateContribution }}</span>

            <span :class="isGrantedPension ? 'text-danger' : 'text-success'">
              <span class="text-bold">{{ isGrantedPension ? 0 : (isForNovel ? '340 Kč' : '230 Kč') }}</span> / měsíc
            </span>
          </div>

          <div class="contribution__result">
            <span>
              {{ labels.taxSaving }}
            </span>

            <span class="text-success">
              <span class="text-bold">7 200 Kč</span> / rok
            </span>
          </div>
        </label>

        <div
          v-if="!isGrantedPension"
          class="contribution__batch bach--light"
        >
          Nejvyšší vrácení daní
        </div>
      </div>
    </div>

    <div class="col-12 mb-5">
      <small
        v-if="error"
        class="error"
      >
        {{ error }}
      </small>
    </div>

    <div class="col-12">
      <div
        dusk="custom-contribution"
        class="contribution contribution-custom"
        :class="{
          'selected': syncContributionGroup === 'option-custom',
          'contribution--error': error
        }"
      >
        <label
          for="contribution4"
        >
          <div class="contribution__value">
            <div class="contribution__input">
              <input
                id="contribution4"
                v-model="syncContributionGroup"
                type="radio"
                name="contributionGroup"
                value="option-custom"
              >
              <span class="contribution__control" />
            </div>

            <div class="contribution__label">
              {{ labels.anotherContributionValue }}
            </div>
          </div>

          <div
            v-show="syncContributionGroup === 'option-custom'"
            class="contribution__input"
          >
            <LitInput
              v-model.number="contributionAmount"
              name="customContributionAmount"
              input-type="number"
              :tooltip="customTooltipText"
              :show-append="true"
            >
              <template #append>
                <span class="unit">
                  Kč
                </span>
              </template>
            </LitInput>
          </div>

          <div
            v-show="syncContributionGroup === 'option-custom'"
            class="contribution__summary"
          >
            <div class="contribution__result">
              <span>{{ labels.stateContribution }}</span>

              <span
                dusk="state-contribution"
                :class="stateContribution.class"
              >
                <span class="text-bold">{{ stateContribution.value }} Kč</span> / měsíc
              </span>
            </div>

            <div class="contribution__result">
              <span>
                {{ labels.taxSaving }}
              </span>

              <span
                dusk="annual-tax-saving"
                :class="annualTaxSaving.class"
              >
                <span class="text-bold">{{ formatThousands(annualTaxSaving.value) }} Kč</span> / rok
              </span>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { annualTaxSaving, formatThousands, stateContribution } from '../utils';
import LitInput from './Base/LitInput.vue';

export default {
  components: {
    LitInput,
  },

  props: {
    modelValue: {
      type: Number,
      default: 500,
    },

    contributionGroup: {
      type: String,
      required: true,
    },

    error: {
      type: String,
      default: '',
    },

    isForDistributor: {
      type: Boolean,
      default: false,
    },

    isForNovel: {
      type: Boolean,
      default: true,
    },

    isGrantedPension: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'update:contributionGroup',
  ],

  computed: {
    labels () {
      if (this.isForDistributor) {
        return {
          stateContribution: 'Státní příspěvek',
          taxSaving: 'Daňová úspora',
          anotherContributionValue: 'Jiná výše příspěvku',
        };
      }

      return {
        stateContribution: 'Stát mi přispěje',
        taxSaving: 'Na daních ušetřím',
        anotherContributionValue: 'Chci zadat jiný příspěvek',
      };
    },

    customTooltipText () {
      if (this.isForDistributor) {
        return 'Minimální výše příspěvku účastníka je 100 Kč, maximální výše 99 999 Kč';
      }

      return 'Minimální výše příspěvku je 100 Kč, maximální výše 99 999 Kč';
    },

    syncContributionGroup: {
      get () {
        return this.contributionGroup;
      },
      set (val: string) {
        this.$emit('update:contributionGroup', val);
      },
    },

    contributionAmount: {
      get () {
        return this.modelValue;
      },
      set (val: number) {
        this.$emit('update:modelValue', val);
      },
    },

    stateContribution () {
      return stateContribution(this.contributionAmount, this.isForNovel, this.isGrantedPension);
    },

    annualTaxSaving () {
      return annualTaxSaving(this.contributionAmount, this.isForNovel, this.isGrantedPension);
    },
  },

  watch: {
    contributionGroup (val) {
      let amount = 0;

      if (val === 'option-1') {
        return;
      }

      switch (val) {
        case 'option-500':
          amount = 500;
          break;

        case 'option-1000':
          amount = this.isForNovel ? 1700 : 1000;
          break;

        case 'option-5000':
          amount = this.isForNovel ? 5700 : 5000;
          break;

        case 'option-custom':
          amount = 500;
          break;

        default:
          amount = 0;
      }

      this.contributionAmount = amount;
    },
  },
  methods: { formatThousands },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.contribution {
  position: relative;

  label {
    display: block;
    line-height: 1.45;
    padding: 20px;
    border: 1px solid getColor(lighter-grey);
    border-radius: 2px;

    &:focus-within {
      box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em getColor(green);
    }
  }

  &__value {
    font-weight: $bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  &__input {
    display: flex;
    align-items: center;
    margin-right: 10px;
    flex-shrink: 0;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }
  }

  &__control {
    $control-bg: #d8e8c5;

    width: rem(23);
    height: rem(23);
    border-radius: 50%;
    border: 1px solid $control-bg;
    background: $control-bg;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      width: rem(9);
      height: rem(9);
      border-radius: 50%;
      transition: 180ms transform ease-in-out;
      transform: scale(0);
      background: getColor(green);
    }
  }

  input:checked + &__control {
    background: getColor(white);
    border-color: getColor(green);

    &::before {
      transform: scale(1);
    }
  }

  &__result {
    font-size: $root-mini;
    display: flex;
    justify-content: space-between;

    & > span:first-child {
      margin-right: 20px;
    }
  }

  &__batch {
    position: absolute;
    top: -10px;
    right: 20px;
    min-height: 20px;
    font-size: rem(10);
    padding: 4px 5px;
    z-index: 2;
    pointer-events: none;
    color: getColor(white);
    font-weight: $bold;
    background: getColor(default);

    &.bach--light {
      background: getColor(blue);
    }
  }

  &.selected label {
    border-color: getColor(green);
    box-shadow: inset 0 4px 0 getColor(green);
  }

  &--error label {
    border-color: getColor(danger, 1);
  }

  &--error.selected label {
    border-color: getColor(danger, 1);
    box-shadow: inset 0 4px 0 getColor(danger, 1);
  }

  &:not(.selected) label:hover {
    cursor: pointer;
  }
}

.contribution-custom {
  display: flex;

  & label {
    width: 100%;

    @include media(min, $sm) {
      min-height: 94px;
      display: flex;
      align-items: center;
    }
  }

  & .contribution__input {
    .field {
      display: flex;

      /* stylelint-disable */
      :deep(input) {
        font-weight: $bold;
      }
      /* stylelint-enable */

      @include media(min, $sm) {
        max-width: 300px;
      }
    }

    @include media(min, $sm) {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  & .contribution__value {
    margin-bottom: 0;

    @include media(min, $sm) {
      margin-right: 10px;
    }
  }

  &.selected {
    & .contribution__value {
      margin-bottom: 10px;

      @include media(min, $sm) {
        margin-bottom: 0;
        flex-basis: 157px;
      }
    }
  }

  & .contribution__summary {
    margin-top: 10px;

    @include media(min, $sm) {
      margin-top: 0;
      margin-left: auto;
    }
  }
}

.error {
  display: block;
  padding: .125em 0;
  margin-top: .25em;
  color: getColor(danger, 1);
}
</style>
