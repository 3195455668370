<template>
  <div>
    <JpsStepper current-step="contractDocumentsAndConsents" />

    <div class="container--sm mt-20">
      <DocumentsAndConsents
        namespace="jps"
        :validate-fnc="validateField"
        :display-tied-agent-info="store.beforeStart.isTiedAgent.value"
      />
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="jps"
      previous-step="contactInformation"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import JpsStepper from '@/js/components/Steppers/JpsStepper.vue';
import { consentsFields } from '@/js/data/validationFields/commonFields';

import { useJpsStore } from '@/js/stores';
import { scrollToError } from '@/js/utils';
import DocumentsAndConsents from '@/js/views/common/DocumentsAndConsents.vue';
import StepButtons from '@/js/views/common/StepButtons.vue';

const store = useJpsStore();

const { validateFields, validateField } = store;

const route = useRoute();
const router = useRouter();

const validating = ref(false);
const error = ref(false);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: consentsFields,
    });

    await router.push({
      name: 'jps.personalData',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}
</script>
