export const fields = () => ([

  // BEFORE START
  {
    pathFrom: `beforeStart.s1.value`,
    pathTo: `beforeStart.s1.value`,
  },

  {
    pathFrom: `beforeStart.s2.value`,
    pathTo: `beforeStart.s2.value`,
  },

  {
    pathFrom: `beforeStart.s3.value`,
    pathTo: `beforeStart.s3.value`,
  },

  {
    pathFrom: `beforeStart.promoCode.value`,
    pathTo: `beforeStart.promoCode.value`,
  },

  {
    pathFrom: `beforeStart.isTiedAgent.value`,
    pathTo: `beforeStart.isTiedAgent.value`,
  },

  {
    pathFrom: `beforeStart.distributionName.value`,
    pathTo: `beforeStart.distributionName.value`,
  },

  {
    pathFrom: `beforeStart.meetingAddress.city.value`,
    pathTo: `beforeStart.meetingAddress.city.value`,
  },

  {
    pathFrom: `beforeStart.meetingAddress.country.value`,
    pathTo: `beforeStart.meetingAddress.country.value`,
  },

  {
    pathFrom: `beforeStart.meetingAddress.editable.value`,
    pathTo: `beforeStart.meetingAddress.editable.value`,
  },

  {
    pathFrom: `beforeStart.meetingAddress.query.value`,
    pathTo: `beforeStart.meetingAddress.query.value`,
  },

  {
    pathFrom: `beforeStart.meetingAddress.setManually.value`,
    pathTo: `beforeStart.meetingAddress.setManually.value`,
  },

  {
    pathFrom: `beforeStart.meetingAddress.street.value`,
    pathTo: `beforeStart.meetingAddress.street.value`,
  },

  {
    pathFrom: `beforeStart.meetingAddress.streetNumber.value`,
    pathTo: `beforeStart.meetingAddress.streetNumber.value`,
  },

  {
    pathFrom: `beforeStart.meetingAddress.zip.value`,
    pathTo: `beforeStart.meetingAddress.zip.value`,
  },

  {
    pathFrom: `beforeStart.distributor.firstName.value`,
    pathTo: `beforeStart.distributor.firstName.value`,
  },

  {
    pathFrom: `beforeStart.distributor.lastName.value`,
    pathTo: `beforeStart.distributor.lastName.value`,
  },

  {
    pathFrom: `beforeStart.distributor.email.value`,
    pathTo: `beforeStart.distributor.email.value`,
  },

  // CONTACT INFORMATION
  {
    pathFrom: `contactInformation.firstName.value`,
    pathTo: `contactInformation.firstName.value`,
  },

  {
    pathFrom: `contactInformation.lastName.value`,
    pathTo: `contactInformation.lastName.value`,
  },

  {
    pathFrom: `contactInformation.email.value`,
    pathTo: `contactInformation.email.value`,
  },

  {
    pathFrom: `contactInformation.phoneNumber.value`,
    pathTo: `contactInformation.phoneNumber.value`,
  },

  {
    pathFrom: `contactInformation.phonePrefix.value`,
    pathTo: `contactInformation.phonePrefix.value`,
    defaultValue: '420',
  },

  // PERSONAL DATA
  {
    pathFrom: `personalData.firstName.value`,
    pathTo: `personalData.firstName.value`,
  },

  {
    pathFrom: `personalData.lastName.value`,
    pathTo: `personalData.lastName.value`,
  },

  {
    pathFrom: `personalData.personalIdNumber.value`,
    pathTo: `personalData.personalIdNumber.value`,
  },

  {
    pathFrom: `personalData.personalIdNumber.value`,
    pathTo: `personalData.personalIdNumber.value`,
  },

  {
    pathFrom: `personalData.citizenship.value`,
    pathTo: `personalData.citizenship.value`,
    defaultValue: 'cz',
  },

  {
    pathFrom: `personalData.countryOfBirth.value`,
    pathTo: `personalData.countryOfBirth.value`,
    defaultValue: 'cz',
  },

  {
    pathFrom: `personalData.birthplace.value`,
    pathTo: `personalData.birthplace.value`,
  },

  {
    pathFrom: `personalData.permanentAddress.city.value`,
    pathTo: `personalData.permanentAddress.city.value`,
  },

  {
    pathFrom: `personalData.permanentAddress.country.value`,
    pathTo: `personalData.permanentAddress.country.value`,
  },

  {
    pathFrom: `personalData.permanentAddress.editable.value`,
    pathTo: `personalData.permanentAddress.editable.value`,
  },

  {
    pathFrom: `personalData.permanentAddress.query.value`,
    pathTo: `personalData.permanentAddress.query.value`,
  },

  {
    pathFrom: `personalData.permanentAddress.setManually.value`,
    pathTo: `personalData.permanentAddress.setManually.value`,
  },

  {
    pathFrom: `personalData.permanentAddress.street.value`,
    pathTo: `personalData.permanentAddress.street.value`,
  },

  {
    pathFrom: `personalData.permanentAddress.streetNumber.value`,
    pathTo: `personalData.permanentAddress.streetNumber.value`,
  },

  {
    pathFrom: `personalData.permanentAddress.zip.value`,
    pathTo: `personalData.permanentAddress.zip.value`,
  },

  {
    pathFrom: `personalData.permanentAddressArea.value`,
    pathTo: `personalData.permanentAddressArea.value`,
  },

  {
    pathFrom: `personalData.mailingAddress.city.value`,
    pathTo: `personalData.mailingAddress.city.value`,
  },

  {
    pathFrom: `personalData.mailingAddress.country.value`,
    pathTo: `personalData.mailingAddress.country.value`,
  },

  {
    pathFrom: `personalData.mailingAddress.editable.value`,
    pathTo: `personalData.mailingAddress.editable.value`,
  },

  {
    pathFrom: `personalData.mailingAddress.query.value`,
    pathTo: `personalData.mailingAddress.query.value`,
  },

  {
    pathFrom: `personalData.mailingAddress.setManually.value`,
    pathTo: `personalData.mailingAddress.setManually.value`,
  },

  {
    pathFrom: `personalData.mailingAddress.street.value`,
    pathTo: `personalData.mailingAddress.street.value`,
  },

  {
    pathFrom: `personalData.mailingAddress.streetNumber.value`,
    pathTo: `personalData.mailingAddress.streetNumber.value`,
  },

  {
    pathFrom: `personalData.mailingAddress.zip.value`,
    pathTo: `personalData.mailingAddress.zip.value`,
  },

  {
    pathFrom: `personalData.permanentAddressArea.value`,
    pathTo: `personalData.permanentAddressArea.value`,
  },
  {
    pathFrom: `personalData.specifyMailingAddress.value`,
    pathTo: `personalData.specifyMailingAddress.value`,
    defaultValue: false,
  },

  {
    pathFrom: `personalData.birthDate.value`,
    pathTo: `personalData.birthDate.value`,
  },

  {
    pathFrom: `personalData.gender.value`,
    pathTo: `personalData.gender.value`,
  },

  {
    pathFrom: `personalData.permanentAddressArea.value`,
    pathTo: `personalData.permanentAddressArea.value`,
    defaultValue: 'cz',
  },

  {
    pathFrom: `personalData.citizenshipInEu.value`,
    pathTo: `personalData.citizenshipInEu.value`,
    defaultValue: true,
  },

  {
    pathFrom: `personalData.permanentAddressInEu.value`,
    pathTo: `personalData.permanentAddressInEu.value`,
    defaultValue: true,
  },

  {
    pathFrom: `personalData.insuranceNumbers.medicalInsurance.value`,
    pathTo: `personalData.insuranceNumbers.medicalInsurance.value`,
  },

  {
    pathFrom: `personalData.insuranceNumbers.pensionInsurance.value`,
    pathTo: `personalData.insuranceNumbers.pensionInsurance.value`,
  },

  {
    pathFrom: `personalData.insuranceNumbers.retirementPension.value`,
    pathTo: `personalData.insuranceNumbers.retirementPension.value`,
  },

  {
    pathFrom: `personalData.document.type.value`,
    pathTo: `personalData.document.type.value`,
    defaultValue: 'idCard',
  },

  {
    pathFrom: `personalData.document.number.value`,
    pathTo: `personalData.document.number.value`,
  },

  {
    pathFrom: `personalData.document.validUntil.value`,
    pathTo: `personalData.document.validUntil.value`,
  },

  {
    pathFrom: `personalData.document.issuedBy.value`,
    pathTo: `personalData.document.issuedBy.value`,
  },

  {
    pathFrom: `personalData.document.name.value`,
    pathTo: `personalData.document.name.value`,
  },

  // SIGNER
  {
    pathFrom: `signer.type.value`,
    pathTo: `signer.type.value`,
    defaultValue: 'participant',
  },

  {
    pathFrom: `signer.firstName.value`,
    pathTo: `signer.firstName.value`,
  },

  {
    pathFrom: `signer.lastName.value`,
    pathTo: `signer.lastName.value`,
  },

  {
    pathFrom: `signer.citizenship.value`,
    pathTo: `signer.citizenship.value`,
    defaultValue: 'cz',
  },

  {
    pathFrom: `signer.countryOfBirth.value`,
    pathTo: `signer.countryOfBirth.value`,
    defaultValue: 'cz',
  },

  {
    pathFrom: `signer.personalIdNumber.value`,
    pathTo: `signer.personalIdNumber.value`,
  },

  {
    pathFrom: `signer.birthDate.value`,
    pathTo: `signer.birthDate.value`,
  },

  {
    pathFrom: `signer.birthplace.value`,
    pathTo: `signer.birthplace.value`,
  },

  {
    pathFrom: `signer.permanentAddressArea.value`,
    pathTo: `signer.permanentAddressArea.value`,
    defaultValue: 'cz',
  },
  {
    pathFrom: `signer.permanentAddress.city.value`,
    pathTo: `signer.permanentAddress.city.value`,
  },

  {
    pathFrom: `signer.permanentAddress.country.value`,
    pathTo: `signer.permanentAddress.country.value`,
  },

  {
    pathFrom: `signer.permanentAddress.editable.value`,
    pathTo: `signer.permanentAddress.editable.value`,
  },

  {
    pathFrom: `signer.permanentAddress.query.value`,
    pathTo: `signer.permanentAddress.query.value`,
  },

  {
    pathFrom: `signer.permanentAddress.setManually.value`,
    pathTo: `signer.permanentAddress.setManually.value`,
  },

  {
    pathFrom: `signer.permanentAddress.street.value`,
    pathTo: `signer.permanentAddress.street.value`,
  },

  {
    pathFrom: `signer.permanentAddress.streetNumber.value`,
    pathTo: `signer.permanentAddress.streetNumber.value`,
  },

  {
    pathFrom: `signer.permanentAddress.zip.value`,
    pathTo: `signer.permanentAddress.zip.value`,
  },

  {
    pathFrom: `signer.isPoliticallyExposed.value`,
    pathTo: `signer.isPoliticallyExposed.value`,
  },

  {
    pathFrom: `signer.document.type.value`,
    pathTo: `signer.document.type.value`,
  },

  {
    pathFrom: `signer.document.type.value`,
    pathTo: `signer.document.type.value`,
  },

  {
    pathFrom: `signer.document.number.value`,
    pathTo: `signer.document.number.value`,
  },

  {
    pathFrom: `signer.document.validUntil.value`,
    pathTo: `signer.document.validUntil.value`,
  },

  {
    pathFrom: `signer.document.issuedBy.value`,
    pathTo: `signer.document.issuedBy.value`,
  },

  {
    pathFrom: `signer.document.name.value`,
    pathTo: `signer.document.name.value`,
  },

  // CURRENT CONTRACT INFORMATION
  {
    pathFrom: `currentContractInformation.contractNumber.value`,
    pathTo: `contractSettings.currentContract.contractNumber.value`,
  },
]);
