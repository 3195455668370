export const formData = {
  beforeStart: {
    s1: { errors: [], value: '222' },
    s2: { errors: [], value: '22' },
    s3: { errors: [], value: '22' },
    promoCode: { errors: [], value: null },
    distributor: {
      firstName: { errors: [], value: 'asd' },
      lastName: { errors: [], value: 'dsf' },
      email: { errors: [], value: 'asdsa@sdas.cz' },
    },
  },
  contactInformation: {
    firstName: { errors: [], value: 'Kyra' },
    lastName: { errors: [], value: 'Mays' },
    email: { errors: [], value: 'kiwi@mailinator.com' },
    phonePrefix: { errors: [], value: '420' },
    phoneNumber: { errors: [], value: '776841947' },
  },
  personalData: {
    firstName: { errors: [], value: 'Kyra' },
    lastName: { errors: [], value: 'Mays' },
    personalIdNumber: { errors: [], value: '9408180177' },
    birthDate: { errors: [], value: null },
    gender: { errors: [], value: null },
    citizenship: { errors: [], value: 'cz' },
    countryOfBirth: { errors: [], value: 'cz' },
    birthplace: { errors: [], value: 'Praha' },
    permanentAddressArea: { errors: [], value: 'cz' },
    permanentAddress: {
      street: { errors: [], value: 'n\u00E1m. Arno\u0161ta z Pardubic' },
      streetNumber: { errors: [], value: '42' },
      city: { errors: [], value: '\u010Cesk\u00FD Brod' },
      zip: { errors: [], value: '28201' },
      country: { errors: [], value: 'cz' },
      query: { errors: [], value: 'n\u00E1m. Arno\u0161ta z Pardubic 42, 28201 \u010Cesk\u00FD Brod' },
      editable: { errors: [], value: false },
      setManually: { errors: [], value: true },
    },
    specifyMailingAddress: { errors: [], value: false },
    mailingAddress: {
      street: { errors: [], value: null },
      streetNumber: { errors: [], value: null },
      city: { errors: [], value: null },
      zip: { errors: [], value: null },
      country: { errors: [], value: 'cz' },
      query: { errors: [], value: null },
      editable: { errors: [], value: true },
      setManually: { errors: [], value: false },
    },
    citizenshipInEu: { errors: [], value: true },
    permanentAddressInEu: { errors: [], value: true },
    insuranceNumbers:
      {
        medicalInsurance: { errors: [], value: null },
        pensionInsurance: { errors: [], value: null },
        retirementPension: { errors: [], value: null },
      },
    document: {
      type: { errors: [], value: 'idCard' },
      number: { errors: [], value: '55' },
      validUntil: { errors: [], value: '03. 09. 2024' },
      issuedBy: { errors: [], value: 'asdas' },
      name: { errors: [], value: null },
    },
  },
  signer: {
    type: { errors: [], value: 'participant' },
    firstName: { errors: [], value: null },
    lastName: { errors: [], value: null },
    citizenship: { errors: [], value: 'cz' },
    countryOfBirth: { errors: [], value: 'cz' },
    personalIdNumber: { errors: [], value: null },
    birthDate: { errors: [], value: null },
    birthplace: { errors: [], value: null },
    permanentAddressArea: { errors: [], value: 'cz' },
    permanentAddress: {
      street: { errors: [], value: null },
      streetNumber: { errors: [], value: null },
      city: { errors: [], value: null },
      zip: { errors: [], value: null },
      country: { errors: [], value: 'cz' },
      query: { errors: [], value: null },
      editable: { errors: [], value: true },
      setManually: { errors: [], value: false },
    },
    isPoliticallyExposed: { errors: [], value: null },
    document: {
      type: { errors: [], value: 'idCard' },
      number: { errors: [], value: null },
      validUntil: { errors: [], value: null },
      issuedBy: { errors: [], value: null },
      name: { errors: [], value: null },
    },
  },
  legalRequirements: {
    paysTaxesInCzechia: { errors: [], value: true },
    isPoliticallyExposed: { errors: [], value: false },
    sourceOfIncome: { errors: [], value: 'ZAM' },
    occupation: { errors: [], value: 'asdas' },
  },
  contributionAndStrategy: {
    contributionAmount: { errors: [], value: 500 },
    selectCustomStrategy: { errors: [], value: false },
    questionnaire: {
      question1: { errors: [], value: 'a' },
      question2: { errors: [], value: null },
      question3: { errors: [], value: null },
      question4: { errors: [], value: null },
      question5: { errors: [], value: null },
      question6: { errors: [], value: null },
    },
    selectedStrategy: { errors: [], value: 'conservative' },
    fundCombination: {
      errors: [],
      value: {
        dynamic: 0,
        balanced: 0,
        pension: 0,
        conservative: 0,
      },
    },
  },
  contractSettings: {
    contractCreationDate: { errors: [], value: '2022-12-01' },
    contractSignatureDate: { errors: [], value: '30. 09. 2023' },
    contractSignaturePlace: { errors: [], value: 'asd' },
    taxOptimization: { errors: [], value: false },
    contributionFromEmployer: { errors: [], value: true },
    employerInfo: { name: { errors: [], value: 'sadas' }, vat: { errors: [], value: null } },
    transferExistingRetirementSavings: { errors: [], value: false },
    currentContract: {
      type:
        { errors: [], value: null },
      companyName: { errors: [], value: null },
      contractNumber: { errors: [], value: null },
      contractEndsAt: { errors: [], value: null },
    },
    specifyNominees: { errors: [], value: false },
    nominees: [],
    nomineesShare: { errors: [], value: 50 },
  },
  consents: {
    marketingPurposes: { errors: [], value: false },
    sharingInformation: { errors: [], value: true },
    usingEmailAddress: { errors: [], value: true },
  },
};
export const secondFormData = {
  beforeStart: {
    s1: { errors: [], value: '222' },
    s2: { errors: [], value: '22' },
    s3: { errors: [], value: '22' },
    promoCode: { errors: [], value: null },
    distributor: {
      firstName: { errors: [], value: 'JOsef' },
      lastName: { errors: [], value: 'Josef' },
      email: { errors: [], value: 'josef@sdas.cz' },
    },
  },
  contactInformation: {
    firstName: { errors: [], value: 'Kyra' },
    lastName: { errors: [], value: 'Mays' },
    email: { errors: [], value: 'kiwi@mailinator.com' },
    phonePrefix: { errors: [], value: '420' },
    phoneNumber: { errors: [], value: '776841947' },
  },
  personalData: {
    firstName: { errors: [], value: 'Kyra' },
    lastName: { errors: [], value: 'Mays' },
    personalIdNumber: { errors: [], value: '9408180177' },
    birthDate: { errors: [], value: null },
    gender: { errors: [], value: null },
    citizenship: { errors: [], value: 'cz' },
    countryOfBirth: { errors: [], value: 'cz' },
    birthplace: { errors: [], value: 'Praha' },
    permanentAddressArea: { errors: [], value: 'cz' },
    permanentAddress: {
      street: { errors: [], value: 'n\u00E1m. Arno\u0161ta z Pardubic' },
      streetNumber: { errors: [], value: '42' },
      city: { errors: [], value: '\u010Cesk\u00FD Brod' },
      zip: { errors: [], value: '28201' },
      country: { errors: [], value: 'cz' },
      query: { errors: [], value: 'n\u00E1m. Arno\u0161ta z Pardubic 42, 28201 \u010Cesk\u00FD Brod' },
      editable: { errors: [], value: false },
      setManually: { errors: [], value: true },
    },
    specifyMailingAddress: { errors: [], value: false },
    mailingAddress: {
      street: { errors: [], value: null },
      streetNumber: { errors: [], value: null },
      city: { errors: [], value: null },
      zip: { errors: [], value: null },
      country: { errors: [], value: 'cz' },
      query: { errors: [], value: null },
      editable: { errors: [], value: true },
      setManually: { errors: [], value: false },
    },
    citizenshipInEu: { errors: [], value: true },
    permanentAddressInEu: { errors: [], value: true },
    insuranceNumbers:
      {
        medicalInsurance: { errors: [], value: null },
        pensionInsurance: { errors: [], value: null },
        retirementPension: { errors: [], value: null },
      },
    document: {
      type: { errors: [], value: 'idCard' },
      number: { errors: [], value: '55' },
      validUntil: { errors: [], value: '03. 09. 2024' },
      issuedBy: { errors: [], value: 'asdas' },
      name: { errors: [], value: null },
    },
  },
  signer: {
    type: { errors: [], value: 'participant' },
    firstName: { errors: [], value: null },
    lastName: { errors: [], value: null },
    citizenship: { errors: [], value: 'cz' },
    countryOfBirth: { errors: [], value: 'cz' },
    personalIdNumber: { errors: [], value: null },
    birthDate: { errors: [], value: null },
    birthplace: { errors: [], value: null },
    permanentAddressArea: { errors: [], value: 'cz' },
    permanentAddress: {
      street: { errors: [], value: null },
      streetNumber: { errors: [], value: null },
      city: { errors: [], value: null },
      zip: { errors: [], value: null },
      country: { errors: [], value: 'cz' },
      query: { errors: [], value: null },
      editable: { errors: [], value: true },
      setManually: { errors: [], value: false },
    },
    isPoliticallyExposed: { errors: [], value: null },
    document: {
      type: { errors: [], value: 'idCard' },
      number: { errors: [], value: null },
      validUntil: { errors: [], value: null },
      issuedBy: { errors: [], value: null },
      name: { errors: [], value: null },
    },
  },
  legalRequirements: {
    paysTaxesInCzechia: { errors: [], value: true },
    isPoliticallyExposed: { errors: [], value: false },
    sourceOfIncome: { errors: [], value: 'ZAM' },
    occupation: { errors: [], value: 'asdas' },
  },
  contributionAndStrategy: {
    contributionAmount: { errors: [], value: 500 },
    selectCustomStrategy: { errors: [], value: false },
    questionnaire: {
      question1: { errors: [], value: 'a' },
      question2: { errors: [], value: null },
      question3: { errors: [], value: null },
      question4: { errors: [], value: null },
      question5: { errors: [], value: null },
      question6: { errors: [], value: null },
    },
    selectedStrategy: { errors: [], value: 'conservative' },
    fundCombination: {
      errors: [],
      value: {
        dynamic: 0,
        balanced: 0,
        pension: 0,
        conservative: 0,
      },
    },
  },
  contractSettings: {
    contractCreationDate: { errors: [], value: '2022-12-01' },
    contractSignatureDate: { errors: [], value: '30. 09. 2023' },
    contractSignaturePlace: { errors: [], value: 'asd' },
    taxOptimization: { errors: [], value: false },
    contributionFromEmployer: { errors: [], value: true },
    employerInfo: { name: { errors: [], value: 'sadas' }, vat: { errors: [], value: null } },
    transferExistingRetirementSavings: { errors: [], value: false },
    currentContract: {
      type: { errors: [], value: null },
      companyName:
        { errors: [], value: null },
      contractNumber: { errors: [], value: null },
      contractEndsAt: { errors: [], value: null },
    },
    specifyNominees: { errors: [], value: false },
    nominees: [],
    nomineesShare: { errors: [], value: 50 },
  },
  consents: {
    marketingPurposes: { errors: [], value: false },
    sharingInformation: { errors: [], value: true },
    usingEmailAddress: { errors: [], value: true },
  },
};
