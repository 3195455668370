import * as Distribution from '../../views/distribution/newContract';

const distribution = {
  path: '/distribuce/:contractUuid',
  component: Distribution.Layout,
  children: [
    {
      path: 'nez-zacneme',
      name: 'distribution.beforeStart',
      component: Distribution.BeforeStart,
    },
    {
      path: 'kontaktni-udaje',
      name: 'distribution.contactInformation',
      component: Distribution.ContactInformation,
    },
    {
      path: 'zadani-osobnich-udaju',
      name: 'distribution.personalData',
      component: Distribution.PersonalData,
    },
    {
      path: 'zakonne-pozadavky',
      name: 'distribution.legalRequirements',
      component: Distribution.LegalRequirements,
    },
    {
      path: 'vyse-prispevku-a-strategie',
      name: 'distribution.contributionAndStrategy',
      component: Distribution.ContributionAndStrategy,
    },
    {
      path: 'smlouva-nastaveni-parametru',
      name: 'distribution.contractSettings',
      component: Distribution.ContractSettings,
    },
    {
      path: 'prodejni-rozhovor',
      name: 'distribution.salesInterview',
      component: Distribution.SalesInterview,
    },
    {
      path: 'smlouva-dokumenty-a-souhlasy',
      name: 'distribution.contractDocumentsAndConsents',
      component: Distribution.ContractDocumentsAndConsents,
    },
    {
      path: 'prohlaseni-klienta',
      name: 'distribution.clientsStatement',
      component: Distribution.ClientsStatement,
    },
    {
      path: 'rekapitulace',
      name: 'distribution.recapitulation',
      component: Distribution.Recapitulation,
    },
    {
      path: 'dekujeme',
      name: 'distribution.thankYouPage',
      component: Distribution.ThankYouPage,
    },
    {
      path: 'dekujeme-za-sjednani',
      name: 'distribution.thankRedirectPage',
      component: Distribution.LitSignThankYouRedirectPage,
    },
    {
      path: 'dekujeme-odeslani-odkazu',
      name: 'distribution.thankYouSignaturePage',
      component: Distribution.ThankYouSignaturePage,
    },
  ],
};

export default distribution;
