<script setup lang="ts">
import { computed } from 'vue';
import type { StoreField } from '@/js/composables/useStoreField.ts';

interface Props {
  path: StoreField<number | string | null | boolean>
  tableKey: string
  additionalValidationPaths?: Array<StoreField<number | string | null | boolean>>
}

const props = defineProps<Props>();

const error = computed(() => {
  if (!props.path && typeof props.path !== 'object') {
    return false;
  }

  if (props.path.errors.length > 0) {
    return true;
  }

  if (Array.isArray(props.additionalValidationPaths)) {
    return props.additionalValidationPaths.some((path) => path.errors.length > 0);
  }

  return false;
});
</script>

<template>
  <tr
    class="table_td"
  >
    <td :class="error !== false ? 'error__first' : ''">
      {{ props.tableKey }}
    </td>
    <td
      :class="error !== false ? 'error__second' : ''"
      colspan="2"
    >
      <slot name="tableValue">
        <span>{{ props.path.value }}</span>
      </slot>

      <small v-if="error !== false">
        {{ path.errors[0] }}
      </small>
    </td>
  </tr>
</template>

<style scoped lang="scss">
.error {
  &__first {
    color: #c9141c;
    background: #c9141c1a;
    position: relative;
  }

  &__first::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 100%;
    background: #c9141c1a;
    left: -8px;
    top: 0;
    border-radius: 4px 0 0 4px;
  }

  &__second {
    color: #c9141c;
    background: #c9141c1a;
    border-radius: 0 4px 4px 0;
    padding-right: 8px;
  }
}

small {
  font-weight: 400;
  line-height: 1.3;
  display: block;
  margin-top: 4px;
}

p {
  margin: 0;
}
</style>
