<template>
  <div>
    <JpsStepper current-step="clientsStatement" />

    <div class="container--sm  mt-20">
      <div class="mb-20">
        <h2>Prohlášení klienta</h2>

        <p>
          Před podpisem smlouvy jsem byl/a seznámen/a s následujícími dokumenty,
          které mi byly předány:
          Záznam z jednání, Klíčové informace, Obchodní podmínky ČSOB PS,
          Zpracování osobních údajů a další informace připojené ke smlouvě.
          Byl/a jsem seznámen/a se službou Penzijní portál, která umožňuje on-line
          přístup ke smlouvě. Registrace na
          <a
            href="https://penzijniportal.cz"
            target="_blank"
          > www.penzijniportal.cz</a>.
        </p>

        <p>
          Poskytnutím svojí e-mailové adresy ve smlouvě souhlasím s elektronickou komunikací
          s&nbsp;ČSOB&nbsp;PS.Zároveň beru na vědomí, že Penzijní společnost mi bude zasílat
          veškerou korespondenci na tuto doručovací e-mailovou adresu a že mohu využívat
          službu Penzijní portál v rozsahu uvedeném v Obchodních  podmínkách pro doplňkové
          penzijní spoření, pokud ve své smlouvě uvedu české číslo svého mobilního telefonu.
        </p>

        <p>
          Podpisem smlouvy dále souhlasím s poskytováním informací způsobem umožňující
          dálkový přístup, včetně sdělení klíčových informací a statutů fondů.
        </p>

        <p>
          Potvrzuji, že údaje uvedené ve smlouvě jsou pravdivé a úplné a jejich případnou
          změnu se zavazuji ČSOB PS bez zbytečného odkladu sdělit.
        </p>

        <p>
          Jsem si vědom/a, že v případě zvolení vlastní strategie spoření se
          vystavuji zvýšenému riziku, že vybraná strategie nebude odpovídat mým
          cílům spoření, vztahu k riziku, znalostem a zkušenostem s investičními
          nástroji a nástroji finančního trhu nebo zákonem
          doporučené investice do povinného konzervativního fondu.
        </p>
      </div>

      <div
        v-if="store.beforeStart.isTiedAgent.value"
        class="mb-20"
      >
        <h2>Vyjádření klienta</h2>

        <ServerValidatedFormField
          v-slot="{ value, input, errors, validate }"
          namespace="jps"
          field-path="consents.clientStatement"
          :debounce="0"
          @validate="validateField"
        >
          <LitTextarea
            class="mb-20"
            name="clientStatement"
            dusk="client-statement"
            label="Poznámka klienta do záznamu z jednání"
            placeholder="Např. Zaujalo mne ...."
            :model-value="value"
            :errors="errors"
            :resize="true"
            @update:model-value="input"
            @blur="validate($event.target.value)"
          >
            <template #label>
              Poznámka klienta do záznamu z jednání
            </template>
          </LitTextarea>
        </ServerValidatedFormField>
      </div>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <StepButtons
      namespace="jps"
      previous-step="contractSettings"
      @submit="submitStep"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LitTextarea from '@/js/components/Base/LitTextarea.vue';
import JpsStepper from '@/js/components/Steppers/JpsStepper.vue';

import { consentsFields } from '@/js/data/validationFields/commonFields';

import { useJpsStore } from '@/js/stores';

import { scrollToError } from '@/js/utils';
import StepButtons from '@/js/views/common/StepButtons.vue';

const route = useRoute();
const router = useRouter();

const store = useJpsStore();
const { validateFields, validateField } = store;

const validating = ref(false);
const error = ref(false);

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    error.value = false;
    validating.value = true;

    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: consentsFields,
    });

    await router.push({
      name: 'jps.recapitulation',
      params: {
        contractUuid: route.params.contractUuid,
      },
    });
  } catch (e: any) {
    error.value = true;

    scrollToError();

    console.error('Něco se nepovedlo', {
      response: e?.response,
    });
  } finally {
    validating.value = false;
  }
}
</script>
