<template>
  <div class="body">
    <TheHeader />

    <main>
      <div class="benefits bg-blue pt-30 pb-10">
        <div class="container--sm">
          <h2 class="dash text-bold">
            Smlouva expirovala
          </h2>
        </div>
      </div>

      <div class="center-page error-page mt-20">
        <div class="container--sm">
          <div class="error-icon" />
          <h2 class="text-center">
            <span class="text-bold">Smlouva expirovala</span>
          </h2>

          <p>
            <strong>
              Smlouvu již není možné dokončit, protože nebyla
              na stránce detekována aktivita po dobu delší než 30 minut.
            </strong>
          </p>

          <LitButton
            v-if="isDistribution || isOnlineAgreement"
            variant="outline"
            :href="getFormHomeUrl()"
            class="mt-30"
            as="a"
          >
            <span v-if="isOnlineAgreement">Vyplnit novou smlouvu</span>
            <span v-else>Zpět na hlavní rozcestník</span>
          </LitButton>
        </div>
      </div>
    </main>

    <TheFooter />
  </div>
</template>

<script>
import TheFooter from './TheFooter.vue';
import TheHeader from './TheHeader.vue';
import LitButton from '@/js/components/Base/LitButton.vue';

export default {
  components: {
    TheHeader,
    TheFooter,
    LitButton,
  },

  computed: {
    isDistribution () {
      const distributionFormTypes = [
        'distribution',
        'transfer',
        'termination',
        'jps',
        'changeRequest',
      ];

      return distributionFormTypes.includes(this.$route.query?.type);
    },

    isOnlineAgreement () {
      return this.$route.query?.type === 'onlineAgreement';
    },
  },

  methods: {
    back () {
      this.$router.back();
    },

    getFormHomeUrl () {
      return `${window.location.origin}/${this.isDistribution ? 'distribuce' : 'online'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/helpers';

h2 {
  margin-top: 0;
}

.wrap {
  flex-grow: 1;
}

.info-text {
  flex: 2;
}

.chart-img {
  flex-grow: 1;
  text-align: center;
  display: none;

  @include media(min, $md) {
    display: initial;
  }

  &__mobile {
    display: flex;
    justify-content: center;

    @include media(min, $md) {
      display: none;
    }
  }
}

.content__wrapper {
  width: 100%;

  @include media(min, $md) {
    width: 50%;
  }

  li {
    margin: 5px;
  }
}
</style>
