<script setup lang="ts">
import type { ValidateStoreParamsFnc } from '@/js/stores/utils';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import { getIconUrl } from '@/js/utils.ts';

interface Props {
  namespace: string
  displayTiedAgentInfo: boolean
  validateFnc: ValidateStoreParamsFnc
}

const props = withDefaults(defineProps<Props>(), {
  displayTiedAgentInfo: false,
});
</script>

<template>
  <div>
    <div class="mb-20">
      <h2>Souhlasy klienta</h2>

      <div dusk="consents">
        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          :namespace="namespace"
          field-path="consents.marketingPurposes"
          :default-value="false"
          :debounce="0"
          :validate-on-input="true"
          @validate="validateFnc"
        >
          <LitCheckbox
            class="mb-20"
            dusk="marketing-purposes"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            @update:model-value="input"
          >
            Souhlasím se

            <a
              href="https://www.csob.cz/portal/csob/ochrana-osobnich-udaju"
              target="_blank"
            >
              zpracováním a sdílením svých údajů ve skupině ČSOB pro marketingové účely</a>.
            Souhlas je účinný okamžikem podpisu smlouvy.
          </LitCheckbox>
        </ServerValidatedFormField>
      </div>
    </div>

    <div class="mb-20">
      <h2>Dokumenty ke smlouvě</h2>
      <LitAlert class="mb-20">
        Seznamte klienta s dokumenty ke smlouvě a potvrďte nám, zda s nimi klient souhlasí.
      </LitAlert>

      <div>
        <ul class="list-unstyled">
          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/informace_o_pobidkach.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Informace o pobídkách.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/klicove_informace_ucastnickych_fondu.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Klíčové informace účastnických fondů.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/podminky_pro_doplnkove_penzijni_sporeni.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Podmínky pro doplňkové penzijní spoření.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              :href="props.displayTiedAgentInfo ? '/storage/documents/predsmluvni_informace_cp.pdf' : '/storage/documents/predsmluvni_informace.pdf'"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Předsmluvní informace.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/sazebnik.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Sazebník.pdf
              </span>
            </a>
          </li>

          <li class="mb-20">
            <a
              class="link-icon"
              href="/storage/documents/statuty_ucastnickych_fondu.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Statuty účastnických fondů.pdf
              </span>
            </a>
          </li>

          <li
            v-if="props.displayTiedAgentInfo"
            class="mb-20"
          >
            <a
              class="link-icon"
              href="/storage/documents/informace_o_vazanem_zastupci_cp.pdf"
              target="_blank"
            >
              <i>
                <img
                  :src="getIconUrl('icon-filetype_PDF')"
                  alt="Ikona pdf"
                >
              </i>

              <span>
                Informace o vázaném zástupci.pdf
              </span>
            </a>
          </li>
        </ul>
      </div>

      <p class="mb-20">
        Stáhnout všechny dokumenty najednou ve formátu ZIP

        &nbsp;<a
          class="link-icon"
          :href="props.displayTiedAgentInfo
            ? '/storage/documents/DPS_smluvni_dokumentace_cp.zip'
            : '/storage/documents/DPS_smluvni_dokumentace.zip'"
          download
        >
          <i>
            <img
              :src="getIconUrl('icon-filetype_ZIP')"
              alt="Ikona pdf"
            >
          </i>

          <span>.ZIP</span>
        </a>

        &nbsp;nebo&nbsp;<a
          class="link-icon"
          :href="props.displayTiedAgentInfo
            ? '/storage/documents/DPS_smluvni_dokumentace_cp.pdf'
            : '/storage/documents/DPS_smluvni_dokumentace.pdf'"
          download
        >
          <i>
            <img
              :src="getIconUrl('icon-filetype_PDF')"
              alt="Ikona pdf"
            >
          </i>

          <span>.PDF</span>
        </a>
      </p>
    </div>
  </div>
</template>
