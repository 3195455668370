<template>
  <div
    dusk="meeting-record-preview"
    class="meeting-record-preview"
  >
    <LitButton
      class="mb-20"
      variant="outline"
      dusk="showMeetingRecordPdf"
      @click="loadMeetingPreview"
    >
      Náhled záznamu z jednání
    </LitButton>

    <LitModal
      v-model="modalIsVisible"
      :max-width="838"
      @close="modalIsVisible = false"
    >
      <template #header>
        <h3 class="text-center">
          Záznam z jednání
        </h3>
      </template>

      <template #body>
        <div class="preview-wrapper">
          <div
            v-if="loadingPreview"
            class="ratio"
          >
            <div class="loading flex flex-center">
              <div class="flex flex-center mb-20 flip">
                <img
                  :src="getIconUrl('icon-loader_blue')"
                  alt="Načítání..."
                  class="spin"
                >
              </div>
              <div class="text-loading text-center text-bold">
                Nahráváme náhled záznamu z jednání
              </div>
            </div>
          </div>

          <iframe
            v-if="!loadingPreview && previewUrl"
            :src="pdfViewerLink"
          >
            <p>
            Váš prohlížeč nepodporuje nahlížení PDF.
            Zobrazte si jej <a :href="previewUrl">zde</a>.
            </p>
          </iframe>
        </div>
      </template>
    </LitModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { meetingRecords } from '@/js/api';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitModal from '@/js/components/Base/LitModal.vue';

import env from '@/js/env';
import { getIconUrl } from '@/js/utils.ts';

const props = defineProps({
  meetingState: {
    type: String,
    default: 'completedPreview',
  },
});

const route = useRoute();
const previewUrl = ref<null | string>(null);
const loadingPreview = ref(false);
const modalIsVisible = ref(false);

const pdfViewerLink = computed(() => {
  return `${env.PDF_VIEWER_URL}?file=${encodeURIComponent(previewUrl.value || '')}`;
});

async function loadMeetingPreview () {
  modalIsVisible.value = !modalIsVisible.value;

  const { contractUuid } = route.params;
  loadingPreview.value = true;

  try {
    const postResponse = await meetingRecords.create(contractUuid, props.meetingState);
    previewUrl.value = `${env.APP_URL}/api/v1/contracts/${Number.parseInt(postResponse.data.id)}/meeting-records`;
  } catch (e) {
    console.error(e);
  } finally {
    loadingPreview.value = false;
  }
}
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

.ratio {
  position: relative;
  width: 100%;
  padding-bottom: 65%;
  min-height: 300px;
}

.buttons {
  align-items: center;

  span {
    display: block;
  }

  @include media(max, $xs) {
    margin-top: 20px;
    flex-direction: column-reverse;
  }
}

.loading {
  background-color: lightgray;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.preview-wrapper {
  width: 100%;
  aspect-ratio: 1 / 1.414;

  object, iframe {
    width: 100%;
    height: 100%;
  }
}

.btn-big {
  margin: 0;
}

.meeting-record-preview {
  @include media(max, $xs) {
    margin: auto;
    text-align: center;
  }

  @include media(max, $xl) {
    margin-bottom: 20px;
  }
}
</style>
