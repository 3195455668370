<script setup lang="ts">
import { ChevronRight } from 'lucide-vue-next';
import { computed, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LitButton from '@/js/components/Base/LitButton.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitModal from '@/js/components/Base/LitModal.vue';
import IconWrapper from '@/js/components/IconWrapper.vue';
import { useChangeRequestStore } from '@/js/stores/distibution/changeRequest';

defineProps<Props>();

const emit = defineEmits([
  'close',
  'finish',
]);

const store = useChangeRequestStore();
const { validateFields } = store;

const route = useRoute();
const router = useRouter();

interface Props {
  forms: Array<string>
}

const changeRequestForms = shallowRef([
  { value: 'contractSettings', label: 'Nastavení smlouvy' },
  { value: 'emailAndConsents', label: 'E-mail a souhlasy' },
  { value: 'identityDocument', label: 'Doklady totožnosti' },
  { value: 'legalRequirements', label: 'Zákonné požadavky' },
  { value: 'nominees', label: 'Určené osoby' },
  { value: 'personalData', label: 'Osobní a kontaktní údaje' },
  { value: 'strategy', label: 'Strategie spoření' },
]);

const formOffer = computed(() => {
  return changeRequestForms.value.filter((form) => !store.changeSettings.involvedForms.value.includes(form.value));
});

async function redirectToForm (form: string) {
  const { contractUuid } = route.params;

  store.changeSettings.involvedForms.value.unshift(form);

  await validateFields({
    contractUuid,
    throwOnErrors: true,
    fieldPaths: [
      'changeSettings.involvedForms',
    ],
  });

  await router.push({
    name: `changeRequest.${form}`,
    params: {
      contractUuid: route.params.contractUuid,
    },
  });
}

const model = defineModel({ type: Boolean, default: false });
</script>

<template>
  <LitModal
    v-model="model"
    :max-width="480"
    @click="emit('close')"
  >
    <template #icon>
      <IconWrapper type="info">
        <LitIconSvg icon-name="icon_question" />
      </IconWrapper>
    </template>

    <template #header>
      <h3>
        Potřebujete změnit ještě další údaje?
      </h3>
    </template>

    <template #body>
      <div class="forms">
        <div
          v-for="(form, index) in formOffer"
          :key="index"
          class="form"
        >
          <LitButton
            variant="link"
            class="link"
            @click="redirectToForm(form.value)"
          >
            {{ form.label }}
          </LitButton>
          <ChevronRight :size="14" />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-center content-center flex-dir-column">
        <LitButton
          size="sm"
          class="mb-24 text-uppercase"
          @click="emit('finish')"
        >
          Vše je změněno
        </LitButton>
      </div>
    </template>
  </LitModal>
</template>

<style scoped lang="scss">
.forms {
  padding: 20px 12px;
  gap: 16px;
  border-radius: 4px 0 0;
  border: 1px solid #e2e6ef;
}

.form {
  display: flex;
  justify-content: space-between;
  color: #092f68;
  padding: 8px;
  font-size: 14px;

  &:hover {
    background-color: #f8fafb;
  }

  .link {
    color: #092f68;
    text-decoration: none;

    &:hover {
      color: #092f68;
    }
  }
}
</style>
