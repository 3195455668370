<script setup lang="ts"  generic="T extends CurrentContractReturn">
import type { CurrentContractReturn } from '@/js/stores/types.ts';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';
import { useDateTimeFormat } from '@/js/composables/useDateTimeFormat.ts';
import pensionCompanies from '@/js/data/pensionCompanies';

interface Props {
  storePath: T
  namespace: string
  isFromTransfer: boolean
}

const props = defineProps<Props>();

function pensionCompany (name: string | null) {
  const pensionCompany = pensionCompanies.find((company) => company.value === name);

  return pensionCompany?.label;
}

function formatDate (date: string | null) {
  if (date === null) {
    return '';
  }

  return useDateTimeFormat(new Date(date), 'dd. MM. yyyy');
}
</script>

<template>
  <template v-if="props.storePath.type.value === 'pensionCompany'">
    <RecapitulationTableRow
      :path="props.storePath.companyName"
      table-key="Název penzijní společnosti"
    >
      <template #tableValue>
        {{ pensionCompany(props.storePath.companyName.value) }}
      </template>
    </RecapitulationTableRow>

    <RecapitulationTableRow :path="props.storePath.contractNumber" table-key="Číslo stávající smlouvy" />

    <RecapitulationTableRow
      v-if="!props.isFromTransfer"
      :path="props.storePath.contractEndsAt"
      table-key="Datum ukončení stávajícího penzijního spoření"
    >
      <template #tableValue>
        {{ formatDate(props.storePath.contractEndsAt.value) }}
      </template>
    </RecapitulationTableRow>
  </template>
</template>
