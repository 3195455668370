export interface UseStoreFieldReturn<T> {
  value: T
  errors: string[]
  defaultValue: T
  includeInRequest?: boolean
  transformation?: CallableFunction
  bankIdReceivable?: boolean
  __storeField: boolean
}

export function useStoreField<T> (
  defaultValue: T,
  includeInRequest = true,
  transformation: CallableFunction | undefined = undefined,
  bankIdReceivable = false,
): UseStoreFieldReturn<T> {
  return {
    value: defaultValue,
    errors: [],
    defaultValue,
    includeInRequest,
    transformation,
    bankIdReceivable,
    __storeField: true,
  };
}

export type StoreField<T> = UseStoreFieldReturn<T>;
