<script lang="ts" setup>
import LitButton from '@/js/components/Base/LitButton.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitModal from '@/js/components/Base/LitModal.vue';
import TheBox from '@/js/components/TheBox.vue';

const emit = defineEmits([
  'signWithPhone',
  'signWithComputer',
  'signLater',
]);

const model = defineModel({ type: Boolean, default: false });
</script>

<template>
  <LitModal v-model="model" :max-width="1024">
    <template #header>
      <slot name="header">
        <h2>Jak chcete smlouvu podepsat?</h2>
      </slot>
    </template>
    <template #body>
      <div v-if="$slots.default" class="text-center">
        <slot />
      </div>

      <div class="box-wrapper mt-30">
        <TheBox>
          <template #icon>
            <LitIconSvg icon-name="tablet" />
          </template>
          <template #default>
            Podepíšete dotykově na svém <strong>chytrém telefonu</strong> nebo <strong>tabletu</strong>.
          </template>
          <template #button>
            <LitButton
              class="text-uppercase"
              block
              @click="emit('signWithPhone')"
            >
              podepsat na mobilu
            </LitButton>
          </template>
        </TheBox>

        <TheBox>
          <template #icon>
            <LitIconSvg icon-name="computer" />
          </template>
          <template #default>
            Podepíšete na svém <strong>počítači</strong> a použijete <strong>myš</strong>.
          </template>
          <template #button>
            <LitButton
              class="text-uppercase"
              variant="outline"
              block
              @click="emit('signWithComputer')"
            >
              podepsat na počítači
            </LitButton>
          </template>
        </TheBox>

        <TheBox>
          <template #icon>
            <LitIconSvg icon-name="calendar-later" />
          </template>
          <template #default>
            Podepíšete <strong>později přes odkaz z e-mailu</strong>. Můžete použít počítač, mobil nebo tablet.
          </template>
          <template #button>
            <LitButton
              class="text-uppercase"
              variant="outline"
              block
              @click="emit('signLater')"
            >
              podepsat později
            </LitButton>
          </template>
        </TheBox>
      </div>
    </template>
  </LitModal>
</template>

<style lang="scss" scoped>
.box-wrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  > * {
    flex: 1 1 235px;
  }
}
</style>
