<template>
  <div>
    <p class="mb-15">{{ lifeCycleDescription }}</p>
    <div class="table-container mb-30">
      <table>
        <thead>
          <tr>
            <td>Věk</td>
            <td>0 - 42</td>
            <td>43</td>
            <td>44</td>
            <td>45</td>
            <td>46</td>
            <td>47</td>
            <td>48</td>
            <td>49</td>
            <td>50</td>
            <td>51</td>
            <td>52</td>
            <td>53</td>
            <td>54</td>
            <td>55</td>
            <td>56</td>
            <td>57</td>
            <td>58</td>
            <td>59</td>
            <td>60</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Dynamický fond</td>
            <td>100 %</td>
            <td>90 %</td>
            <td>80 %</td>
            <td>70 %</td>
            <td>60 %</td>
            <td>50 %</td>
            <td>40 %</td>
            <td>30 %</td>
            <td>20 %</td>
            <td>12 %</td>
            <td>8 %</td>
            <td>4 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
          </tr>
          <tr>
            <td>Vyvážený fond</td>
            <td>0 %</td>
            <td>10 %</td>
            <td>20 %</td>
            <td>30 %</td>
            <td>40 %</td>
            <td>50 %</td>
            <td>60 %</td>
            <td>70 %</td>
            <td>80 %</td>
            <td>88 %</td>
            <td>84 %</td>
            <td>84 %</td>
            <td>84 %</td>
            <td>70 %</td>
            <td>56 %</td>
            <td>42 %</td>
            <td>28 %</td>
            <td>14 %</td>
            <td>0 %</td>
          </tr>
          <tr>
            <td>Fond pro penzi</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>0 %</td>
            <td>8 %</td>
            <td>12 %</td>
            <td>16 %</td>
            <td>30 %</td>
            <td>44 %</td>
            <td>58 %</td>
            <td>72 %</td>
            <td>86 %</td>
            <td>100 %</td>
          </tr>
        </tbody>
      </table>
    </div>

    <img
      :src="getImageUrl('rozlozeni_strategie_zivotnihoy_cyklu_graf', 'png')"
      alt="Rozložení fondů ve Strategii životního cyklu"
      class="mb-10"
    >
  </div>
</template>

<script lang="ts" setup>
import { shallowRef } from 'vue';

import FUNDS from '../data/fundsData';
import { getImageUrl } from '../utils';

const lifeCycleDescription = shallowRef(FUNDS.lifeCycle.content.description);
</script>

<style lang="scss" scoped>
.table-container {
  overflow-x: auto;
}

table {
  td {
    padding: 3px;
    white-space: nowrap;
    text-align: center;
    min-width: 43px;

    &:first-child {
      text-align: right;
    }
  }

  tbody {
    tr:nth-child(even) {
      td {
        background-color: #deeef3;
      }
    }

    td {
      color: #09c;
      font-size: 13px;
    }
  }
}
</style>
