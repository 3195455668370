<template>
  <div
    v-if="previews !== null"
    class="flex flex-between"
  >
    <div
      v-if="previews?.length > 1"
      class="previews-bar"
    >
      <div
        v-for="(preview, index) in previews"
        :key="index"
        :class="['previews-bar__file', activePreview === preview.previewType ? 'active' : '']"
        @click="updatePreview(preview)"
      >
        <p>
          {{ preview.previewName }}
        </p>
      </div>
    </div>

    <div :class="['preview', previews?.length > 1 ? 'w-80' : '']">
      <div
        v-if="loadingContractPreview"
        class="ratio"
      >
        <div class="loading flex flex-center">
          <div class="my-20 flip">
            <img
              :src="getIconUrl('icon-loader_blue')"
              alt="Načítání..."
              class="spin"
            >
          </div>
          <div class="text-loading text-center text-bold">
            Nahráváme náhled smlouvy, chvíli strpení
          </div>
        </div>
      </div>

      <div :class="['preview-wrapper', previews?.length > 1 ? 'ration-1' : '']">
        <iframe
          v-if="!loadingContractPreview && previewUrl"
          :src="pdfViewerLink"
        >
          <p>
          Váš prohlížeč nepodporuje nahlížení PDF.
          Zobrazte si jej <a :href="previewUrl">zde</a>.
          </p>
        </iframe>
      </div>

      <div
        v-if="!loadingContractPreview && previewUrl"
        class="fallback-link"
      >
        Pokud se vám náhled smlouvy nezobrazuje správně,
        <a
          target="_blank"
          :href="previewUrl"
        >
          klikněte zde
        </a>.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import type { Preview, Previews } from '@/js/stores/types.ts';
import type { PreviewType } from '@/js/types/contract.ts';
import { contractPreviews } from '@/js/api';
import env from '@/js/env.ts';
import { prepareState } from '@/js/stores/utils.ts';
import { getIconUrl } from '@/js/utils.ts';

const props = withDefaults(defineProps<Previews>(), {
  previews: null,
});

const loadingContractPreview = ref(false);
const activePreview: Ref<PreviewType | null> = ref(null);

watch(() => props.previews, async (val) => {
  const prev = props.previews;
  if (val !== null && prev !== null) {
    activePreview.value = prev[0].previewType;

    await loadContractPreview(val[0]);
  } else {
    loadingContractPreview.value = true;
  }
}, { immediate: true });

const previewUrl = ref<null | string>(null);

const pdfViewerLink = computed(() => {
  return `${env.PDF_VIEWER_URL}?file=${encodeURIComponent(previewUrl.value || '')}`;
});

const updatePreview = async (preview: Preview) => {
  activePreview.value = preview.previewType;

  await loadContractPreview(
    preview,
    false,
  );
};

async function loadContractPreview (
  preview: Preview,
  inlineBlob = false,
) {
  if (preview.signMethod === null) {
    throw new Error('signMethod can not be null');
  }

  try {
    loadingContractPreview.value = true;
    const contractData = JSON.stringify(prepareState(preview.store.$state));

    const response = await contractPreviews.create(
      contractData,
      preview.contractType,
      preview.previewType,
      inlineBlob,
      preview.signMethod,
      preview.bankData,
    );

    if (inlineBlob) {
      previewUrl.value = URL.createObjectURL(response.data);
    } else {
      const { fileId, accessToken } = response.data;
      previewUrl.value = `${env.REST_API_URI}/v1/files/${fileId}?token=${accessToken}`;
    }
  } catch (e) {
    console.error(e);
  } finally {
    loadingContractPreview.value = false;
  }
}
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';
@import '@sass/tools/mixins';

$breakpoint-mobile-small: 600px;

.ratio {
  position: relative;
  width: 100%;
  padding-bottom: 65%;
  min-height: 300px;
}

.loading {
  background-color: lightgray;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.preview-wrapper {
  aspect-ratio: 1 / 1.414;

  object, iframe {
    width: 100%;
    height: 100%;
  }
}

.previews-bar {
  width: 17%;
  min-height: 100%;
  background: rgba(9, 47, 104, .05);
  padding: 2px;
  border-radius: 8px;

  @media screen and (max-width: $breakpoint-mobile-small) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__file {
    width: 80%;
    height: 120px;
    background: white;
    margin: 20px auto;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .5);

    @media screen and (max-width: $breakpoint-mobile-small) {
      width: 50%;
      height: 60px;
      margin: auto;
    }

    p {
      font-size: .8rem;
      padding: 5px;
      height: 100%;

      @media screen and (max-width: $breakpoint-mobile-small) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
      }
    }
  }
}

.preview {
  width: 100%;

  @media screen and (max-width: $breakpoint-mobile-small) {
    width: 100%;
  }
}

.flex {
  @media screen and (max-width: $breakpoint-mobile-small) {
    flex-direction: column;
    justify-content: space-between !important;
  }
}

.active {
  border: 2px solid rgba(0, 153, 204, 1);
  box-shadow: 2px 2px 1px rgb(0, 153, 204);
}

.fallback-link {
  text-align: left;
}

.w-80 {
  width: 80%;
}

.ration-1 {
  aspect-ratio: 1 / 1.3 !important;
  height: 100%;
}
</style>
