<script setup lang="ts" generic="T extends DistributionPersonalData">
import type { DistributionPersonalData } from '@/js/stores/types';

import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import RecapitulationTableRow from '@/js/components/RecapitulationTableRow.vue';
import { useCitizenShipTranslation } from '@/js/composables/useCitizenShipTranslation';
import { useCountryTranslation } from '@/js/composables/useCountryTranslation';
import { usePermanentAddressAreaTranslation } from '@/js/composables/usePermanentAddressAreaTranslation';
import Document from '@/js/views/common/recapitulation/Document.vue';

interface Props {
  storePath: T
  namespace: string
  isParticipant: boolean
}

const props = defineProps<Props>();
</script>

<template>
  <table class="recapitulation-table mb-16">
    <thead>
      <tr>
        <th
          colspan="2"
        >
          <h3>Osobní údaje klienta</h3>
        </th>
        <th>
          <router-link
            :to="{ name: `${props.namespace}.personalData` }"
            class="edit-section"
          >
            <LitIconSvg icon-name="edit_green" />

            Upravit
          </router-link>
        </th>
      </tr>
    </thead>

    <tbody>
      <template v-if="!isParticipant">
        <RecapitulationTableRow
          :path="props.storePath.firstName"
          table-key="Jméno"
        />

        <RecapitulationTableRow
          :path="props.storePath.lastName"
          table-key="Příjmení"
        />
      </template>

      <RecapitulationTableRow
        :path="props.storePath.citizenship"
        table-key="Státní občanství"
      >
        <template #tableValue>
          {{ useCitizenShipTranslation(props.storePath.citizenship.value) }}
        </template>
      </RecapitulationTableRow>

      <template v-if="props.storePath.permanentAddressArea.value === 'cz'">
        <RecapitulationTableRow
          :path="props.storePath.personalIdNumber"
          table-key="Rodné číslo"
        />
      </template>
      <template v-else>
        <RecapitulationTableRow
          :path="props.storePath.birthDate"
          table-key="Datum narození"
        />

        <RecapitulationTableRow
          :path="props.storePath.gender"
          table-key="Pohlaví"
        >
          <template #tableValue>
            {{ props.storePath.gender.value === 'f' ? 'žena' : 'muž' }}
          </template>
        </RecapitulationTableRow>
      </template>

      <RecapitulationTableRow
        v-if="props.storePath.permanentAddressArea.value === 'eu'
          && props.storePath.personalIdNumber.value"
        :path="props.storePath.personalIdNumber"
        table-key="Číslo pojištěnce zdravotní pojištovny"
      />

      <RecapitulationTableRow :path="props.storePath.birthplace" table-key="Místo narození" />

      <RecapitulationTableRow :path="props.storePath.permanentAddressArea" table-key="Trvalé bydliště">
        <template #tableValue>
          {{ usePermanentAddressAreaTranslation(props.storePath.permanentAddressArea.value) }}
        </template>
      </RecapitulationTableRow>

      <RecapitulationTableRow
        :path="props.storePath.permanentAddress.street"
        table-key="Trvalá adresa"
        :additional-validation-paths="[
          props.storePath.permanentAddress.city,
          props.storePath.permanentAddress.zip,
          props.storePath.permanentAddress.country]"
      >
        <template #tableValue>
          <ul class="list-unstyled">
            <li>
              {{
                props.storePath.permanentAddress.street.value
                  ? props.storePath.permanentAddress.street.value
                  : props.storePath.permanentAddress.city.value
              }} {{ props.storePath.permanentAddress.streetNumber.value }}
            </li>
            <li>{{ props.storePath.permanentAddress.city.value }}</li>
            <li>{{ props.storePath.permanentAddress.zip.value }}</li>
            <li>{{ useCountryTranslation(props.storePath.permanentAddress.country.value) }}</li>
          </ul>
        </template>
      </RecapitulationTableRow>

      <template v-if="props.storePath.specifyMailingAddress.value">
        <RecapitulationTableRow
          v-if="props.storePath.mailingAddress !== null"
          :path="props.storePath.mailingAddress.street"
          table-key="Korespondenční adresa"
          :additional-validation-paths="[
            props.storePath.mailingAddress.city,
            props.storePath.mailingAddress.zip,
            props.storePath.mailingAddress.country]"
        >
          <template #tableValue>
            <ul
              class="list-unstyled"
            >
              <li>
                {{
                  props.storePath.mailingAddress.street.value
                    ? props.storePath.mailingAddress.street.value
                    : props.storePath.mailingAddress.city.value
                }} {{ props.storePath.mailingAddress.streetNumber.value }}
              </li>
              <li>{{ props.storePath.mailingAddress.city.value }}</li>
              <li>{{ props.storePath.mailingAddress.zip.value }}</li>
              <li>{{ useCountryTranslation(props.storePath.mailingAddress.country.value) }}</li>
            </ul>
          </template>
        </RecapitulationTableRow>
      </template>

      <template v-if="isParticipant">
        <Document
          :store-path="props.storePath.document"
          :namespace="namespace"
        />
      </template>

      <template v-if="props.storePath.permanentAddressArea.value === 'eu'">
        <RecapitulationTableRow
          v-if="props.storePath.insuranceNumbers.medicalInsurance.value"
          :path="props.storePath.insuranceNumbers.medicalInsurance"
          table-key="Zdravotní pojištění v ČR od data"
        />

        <RecapitulationTableRow
          v-if="props.storePath.insuranceNumbers.pensionInsurance.value"
          :path="props.storePath.insuranceNumbers.pensionInsurance"
          table-key="Důchodové pojištění v ČR od data"
        />

        <RecapitulationTableRow
          v-if="props.storePath.insuranceNumbers.retirementPension.value"
          :path="props.storePath.insuranceNumbers.retirementPension"
          table-key="Starobní důchod byl přiznán od data"
        />
      </template>
    </tbody>
  </table>
</template>
