<template>
  <div dusk="thank-you">
    <div class="benefits bg-blue pt-30 pb-10" />
    <div class="container--sm my-30">
      <TheHeading>Hotovo, vše máte v pořádku podepsané!</TheHeading>
    </div>
  </div>

  <div class="container--sm my-30">
    <h2 class="thanksAlert">
      Teď se koukněte do e-mailu
    </h2>

    <h4>
      Poslali jsme vám
    </h4>

    <ul>
      <li class="mb-10">smlouvu o penzijním spoření <span v-if="store.beforeStart.isTiedAgent.value">vč. záznamu z jednání</span></li>
      <li class="mb-10">další dokumenty k vašemu novému penzijnímu spoření</li>
    </ul>
  </div>

  <div class="container--sm">
    <LitAlert alert-type="info">
      <p class="thanksAlert">
        Až bude smlouva účinná, zašleme vám do e-mailu pokyny, jak si penzijko platit.
      </p>
    </LitAlert>
  </div>

  <div class="container--sm my-20">
    <hr class="py-20">

    <div class="flex flex-center">
      <LitButton
        variant="outline"
        :href="getHomepageUrl()"
        as="a"
      >
        <span>
          Zpět na domovskou stránku
        </span>
      </LitButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { destroy } from '@/js/api/contracts';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitButton from '@/js/components/Base/LitButton.vue';
import TheHeading from '@/js/components/TheHeading.vue';

import { useJpsStore } from '@/js/stores';
import { getHomepageUrl } from '@/js/utils';

const store = useJpsStore();
const route = useRoute();

onMounted (async () => {
  const { contractUuid } = route.params;
  await destroy(contractUuid as string);
});
</script>

<style lang="scss" scoped>
.thanksAlert {
  margin: auto;
}

.thank-you-header {
  background: #f1f9e8;
}

h4 {
  font-size: .95rem;
}
</style>
