export const beforeStartFields = [
  'beforeStart.s1',
  'beforeStart.s2',
  'beforeStart.s3',
  'beforeStart.promoCode',

  'beforeStart.isTiedAgent',
  'beforeStart.distributionName',

  'beforeStart.meetingAddress.street',
  'beforeStart.meetingAddress.streetNumber',
  'beforeStart.meetingAddress.city',
  'beforeStart.meetingAddress.zip',
  'beforeStart.meetingAddress.country',

  'beforeStart.distributor.firstName',
  'beforeStart.distributor.lastName',
  'beforeStart.distributor.email',
];

export const documentInfoFields = [
  'personalData.document.type',
  'personalData.document.number',
  'personalData.document.validUntil',
  'personalData.document.issuedBy',
  'personalData.document.name',
];

export const insuranceNumbersFields = [
  'personalData.insuranceNumbers.medicalInsurance',
  'personalData.insuranceNumbers.pensionInsurance',
];
export const signerFields = [
  'signer.firstName',
  'signer.lastName',
  'signer.citizenship',
  'signer.countryOfBirth',
  'signer.birthplace',
  'signer.personalIdNumber',
  'signer.birthDate',

  'signer.permanentAddressArea',

  'signer.permanentAddress.street',
  'signer.permanentAddress.streetNumber',
  'signer.permanentAddress.city',
  'signer.permanentAddress.zip',
  'signer.permanentAddress.country',

  'signer.document.number',
  'signer.document.validUntil',
  'signer.document.issuedBy',
  'signer.document.name',

  'signer.isPoliticallyExposed',
];
