<template>
  <div>
    <Stepper
      :current-step
      :steps="steps"
    />
  </div>
</template>

<script lang="ts" setup>
import { shallowRef } from 'vue';

import Stepper from './StepperDistribution.vue';

type Step = typeof steps.value;

defineProps<{
  currentStep: keyof Step
}>();

const steps = shallowRef({
  salesInterview: {
    title: 'Prodejní rozhovor',
    uri: { name: 'distribution.salesInterview' },
  },
  contactInformation: {
    title: 'Kontaktní údaje podepisujícího',
    uri: { name: 'distribution.contactInformation' },
  },
  contractDocumentsAndConsents: {
    title: 'Předsmluvní dokumentace',
    uri: { name: 'distribution.contractDocumentsAndConsents' },
  },
  personalData: {
    title: 'Osobní údaje klienta',
    uri: { name: 'distribution.personalData' },
  },
  legalRequirements: {
    title: 'Zákonné požadavky',
    uri: { name: 'distribution.legalRequirements' },
  },
  contributionAndStrategy: {
    title: 'Příspěvek a strategie',
    uri: { name: 'distribution.contributionAndStrategy' },
  },
  contractSettings: {
    title: 'Nastavení smlouvy',
    uri: { name: 'distribution.contractSettings' },
  },
  clientsStatement: {
    title: 'Prohlášení klienta',
    uri: { name: 'distribution.clientsStatement' },
  },
  recapitulation: {
    title: 'Rekapitulace',
    uri: { name: 'distribution.recapitulation' },
  },
});
</script>
