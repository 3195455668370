import { ref, watch } from 'vue';

export function useTruncate (text: string, maxLength: number) {
  if (maxLength < 1) {
    throw new Error('The maximum length must be greater than 0.');
  }

  const truncatedText = ref('');

  watch(() => text, (newText) => {
    if (newText.length > maxLength) {
      let truncated = newText.slice(0, maxLength);
      truncated += '...';
      truncatedText.value = truncated;
    } else {
      truncatedText.value = newText;
    }
  }, { immediate: true });

  return truncatedText.value;
}
