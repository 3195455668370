<template>
  <div
    class="row steps"
    dusk="two-form-stepper"
  >
    <div
      v-for="step in steps"
      :key="step.stepNumber"
      :dusk="`step-${step.stepNumber}`"
      class="col-sm-4 step"
    >
      <div :class="['icon', step.isActive ? 'icon--active' : 'icon--inactive']">
        <LitIconSvg
          icon-name="check-white"
        />
      </div>

      <h3>{{ step.title }}</h3>
      <p>{{ step.description }}</p>
    </div>
  </div>
</template>

<script>
import LitIconSvg from '../Base/LitIconSvg.vue';

export default {
  components: {
    LitIconSvg,
  },

  props: {
    currentStep: {
      type: Number,
      default: 1,
    },

    steps: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.steps {
  margin-top: 40px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    width: 66.667%;
    height: 1px;
    background: #d6d6d6;
    margin: auto;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.icon {
  width: 41px;
  height: 41px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--active {
    background-color: #78c31c;
  }

  &--inactive {
    background-color: #d6d6d6;
  }
}
</style>
