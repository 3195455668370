export const useDocumentTypeTranslation = (value: string) => {
  switch (true) {
    case value === 'idCard':
      return 'Občanský průkaz';
    case value === 'passport':
      return 'Cestovní pas';
    case value === 'anotherDoc':
      return 'Jiný doklad';
    default:
      return value;
  }
};
