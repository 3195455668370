<template>
  <div>
    <div dusk="personal-information">
      <h3>
        <LitIconSvg
          v-if="!isParticipant"
          class="mr-5"
          icon-name="ico_client"
        />
        {{ title }}
      </h3>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.firstName`"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          name="given-name"
          dusk="first-name"
          placeholder="Např. Karel"
          :label="labels.firstName"
          :model-value="value"
          :errors="errors"
          :warning="firstNameWarning"
          :disabled="isParticipant"
          @update:model-value="input"
          @blur="validate($event.target.value);"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.lastName`"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          name="family-name"
          dusk="last-name"
          placeholder="Např. Novák"
          :label="labels.lastName"
          :warning="lastNameWarning"
          :model-value="value"
          :errors="errors"
          :disabled="isParticipant"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.citizenship`"
        :debounce="0"
        :validate-on-input="true"
        @validate="validateFnc"
      >
        <LitSelect
          class="mb-20"
          dusk="citizenship"
          :debounce="0"
          :searchable="true"
          :validate-on-input="true"
          :label="labels.citizenship"
          :options="citizenshipOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.countryOfBirth`"
        :debounce="0"
        :validate-on-input="true"
        @validate="validateFnc"
      >
        <LitSelect
          class="mb-20"
          dusk="country-of-birth"
          :searchable="true"
          :label="labels.countryOfBirth"
          :options="countryOfBirthOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.birthplace`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          name="birthplace"
          dusk="birth-place"
          :label="labels.birthplace"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.permanentAddressArea`"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitRadio
          class="mb-20"
          label="Trvalé bydliště"
          dusk="permanent-address-area"
          name="permanent-address-area"
          :options="permanentAddressAreaOptions"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <AddressEntry
        label="Trvalá adresa"
        name="permanent-address"
        dusk="permanent-address"
        :namespace="namespace"
        :store-path="`${storePath}.permanentAddress`"
        :show-alert="isParticipant"
        :tooltip="!isParticipant ? 'Postupným psaním názvu ulice, čísla popisného '
          + 'a PSČ si můžete ze seznamu vybrat potřebnou adresu.' : ''"
        :allow-autocomplete="!permanentStayOutsideCzechia"
        :searchable-country="permanentStayOutsideCzechia"
        :country-options="clientCountryOptions"
        :validate-fnc="validateFnc"
        @place-detail-loaded="updatePermanentAddress"
      />

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.personalIdNumber`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          v-if="showPersonalIdInput"
          class="mb-20"
          name="personal-id"
          dusk="personal-id"
          :label="labels.personalId"
          :model-value="value"
          :errors="errors"
          date-pick
          @update:model-value="input"
          @blur="validate($event.target.value)"
          @keydown.space.prevent
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, errors, input, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.birthDate`"
        :debounce="0"
        @validate="validateFnc"
      >
        <DatePicker
          v-if="permanentStayOutsideCzechia"
          class="mb-20"
          name="birth-day"
          dusk="birth-date"
          :label="labels.birthDate"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @change="validate"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, errors, input }"
        :namespace="namespace"
        :field-path="`${storePath}.gender`"
        :validate-on-input="true"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitRadio
          v-if="permanentStayOutsideCzechia"
          class="mb-20"
          dusk="gender"
          name="gender"
          :label="labels.gender"
          :options="genderTypes"
          :show-inline="true"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <div
        v-show="displayInsuranceNumbers"
        dusk="insurance-numbers"
      >
        <LitAlert
          class="mb-20"
        >
          Nárok na státní příspěvek má účastník, který má bydliště na území členského státu
          a je účasten důchodového nebo veřejného zdravotního pojištění v ČR.
          K prokázání musí být uvedeno číslo pojištěnce a datum sjednání.
        </LitAlert>

        <ServerValidatedFormField
          v-slot="{ value, errors, input, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.insuranceNumbers.medicalInsurance`"
          :debounce="0"
          @validate="validateFnc"
        >
          <DatePicker
            class="mb-20"
            label="Zdravotní pojištění v ČR od data"
            name="medical-insurance"
            dusk="medical-insurance"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @change="validate"
          />
        </ServerValidatedFormField>

        <ServerValidatedFormField
          v-slot="{ value, errors, input, validate }"
          :namespace="namespace"
          :field-path="`${storePath}.insuranceNumbers.pensionInsurance`"
          :debounce="0"
          @validate="validateFnc"
        >
          <DatePicker
            class="mb-20"
            label="Důchodové pojištění v ČR od data"
            name="pension-insurance"
            dusk="pension-insurance"
            :model-value="value"
            :errors="errors"
            @update:model-value="input"
            @change="validate"
          />
        </ServerValidatedFormField>
      </div>

      <AddressEntry
        v-show="specifyMailingAddress"
        label="Korespondenční adresa"
        name="mailing-address"
        dusk="mailing-address"
        :namespace="namespace"
        :store-path="`${storePath}.mailingAddress`"
        tooltip="Postupným psaním názvu ulice, čísla popisného
        a PSČ si můžete ze seznamu vybrat potřebnou adresu."
        :validate-fnc="validateFnc"
      >
        <template #autocomplete-actions>
          <button
            type="button"
            class="btn btn-simple btn-simple--red"
            @click="specifyMailingAddress = false"
          >
            <i>
              <img
                :src="getIconUrl('icon-delete')"
                alt="Ikona smazat"
              >
            </i>
            Odstranit adresu
          </button>
        </template>
      </AddressEntry>

      <LitCheckbox
        class="mb-20"
        dusk="add-mailing-address"
        :model-value="!specifyMailingAddress"
        @update:model-value="specifyMailingAddress = !$event"
      >
        Korespondeční adresa stejná jako trvalá
      </LitCheckbox>
    </div>

    <div
      v-show="isParticipant"
      dusk="document-data"
    >
      <h3>Doklad totožnosti</h3>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        :namespace="namespace"
        :field-path="`${storePath}.document.type`"
        :debounce="0"
        :validate-on-input="true"
        @validate="validateFnc"
      >
        <LitSelect
          class="mb-20"
          label="Typ dokladu"
          name="document-type"
          dusk="document-type"
          :options="documentTypes"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.document.name`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          v-show="documentType === 'anotherDoc'"
          class="mb-20"
          label="Druh průkazu totožnosti"
          name="document-name"
          dusk="document-name"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.document.number`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-20"
          label="Číslo dokladu"
          name="document-number"
          dusk="document-number"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.document.validUntil`"
        :debounce="0"
        @validate="validateFnc"
      >
        <DatePicker
          class="mb-20"
          label="Platný do"
          name="document-valid"
          dusk="document-valid"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @change="validate"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate }"
        :namespace="namespace"
        :field-path="`${storePath}.document.issuedBy`"
        :debounce="0"
        @validate="validateFnc"
      >
        <LitInput
          class="mb-30"
          label="Vydal"
          name="document-issued"
          dusk="document-issued"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>
    </div>
  </div>
</template>

<script setup lang="ts">
import { get } from 'lodash';
import { getActivePinia } from 'pinia';
import { computed, onMounted, shallowRef, watch } from 'vue';
import type { Address } from '@/js/stores/types';
import type { ValidateStoreParamsFnc } from '@/js/stores/utils';
import AddressEntry from '@/js/components/AddressEntry.vue';

import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitCheckbox from '@/js/components/Base/LitCheckbox.vue';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';
import DatePicker from '@/js/components/DatePicker.vue';

import { useCheckTitleInName } from '@/js/composables/useTitleInName.ts';

import { citizenshipCountries } from '@/js/data/citizenshipOptions';

import countriesOfEU from '@/js/data/countriesOfEU';
import { countriesOfBirth } from '@/js/data/countryOfBirthOptions';
import { copyFields, setStoreFields, syncedStoreField } from '@/js/stores/utils';
import { getIconUrl } from '@/js/utils';

interface Props {
  namespace: 'distribution' | 'jps' | 'termination' | 'transfer' | 'changeRequest'
  storePath: string
  displayInsuranceNumbers?: boolean
  title?: string
  validateFnc: ValidateStoreParamsFnc
}

const props = withDefaults(defineProps<Props>(), {
  displayInsuranceNumbers: false,
  title: 'Osobní údaje osoby, pro kterou je smlouva uzavírána',
});

const countryOfBirthOptions = shallowRef(countriesOfBirth);
const citizenshipOptions = shallowRef(citizenshipCountries);

const activeStore = computed(() => {
  const stores = getActivePinia();

  if (!stores) {
    throw new Error('No active pinia stores found');
  }

  const store = get(stores.state.value, props.namespace);

  if (!store) {
    throw new Error(`No store found for namespace ${props.namespace}`);
  }

  return store;
});

const firstNameWarning = useCheckTitleInName(computed<string>(() => activeStore.value[props.storePath].firstName.value));
const lastNameWarning = useCheckTitleInName(
  computed<string>(() => activeStore.value[props.storePath].lastName.value),
  'příjmení nesmí obsahovat titul',
);

const documentTypes = shallowRef([
  { value: 'idCard', label: 'Občanský průkaz' },
  { value: 'passport', label: 'Cestovní pas' },
  { value: 'anotherDoc', label: 'Jiný doklad' },
]);

const genderTypes = shallowRef([
  { value: 'm', label: 'Muž' },
  { value: 'f', label: 'Žena' },
]);

const permanentAddressAreaOptions = shallowRef([
  { value: 'cz', label: 'v ČR' },
  { value: 'eu', label: 'v EU' },
  { value: 'other', label: 'mimo EU' },
]);

const countriesOfEUWithoutCzechia = computed(() => {
  return countriesOfEU.filter((country) => country !== 'cz');
});

const isClientFromCzechia = computed(() => {
  return activeStore.value.personalData.permanentAddressArea.value === 'cz';
});

const permanentAddressArea = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.permanentAddressArea.value`,
  }),
});

const specifyMailingAddress = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.specifyMailingAddress.value`,
  }),
});

const personalIdNumber = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.personalIdNumber.value`,
  }),
});

const personalIdNumberErrors = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.personalIdNumber.errors`,
  }),
});

const stateOfPermanentAddress = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.permanentAddress.country.value`,
  }),
});

const documentType = computed({
  ...syncedStoreField(activeStore.value, {
    path: `${props.storePath}.document.type.value`,
  }),
});

const permanentStayOutsideCzechia = computed(() => {
  return permanentAddressArea.value !== '' && !isClientFromCzechia.value;
});

const labels = computed(() => {
  return {
    firstName: 'Jméno',
    lastName: 'Příjmení',
    personalId: permanentStayOutsideCzechia.value ? 'Číslo pojištěnce zdravotní pojištovny' : 'Rodné číslo',
    citizenship: 'Státní občanství',
    countryOfBirth: 'Stát narození',
    birthplace: 'Místo narození',
    permanentAddress: 'Trvalá adresa',
    birthDate: 'Datum narození',
    gender: 'Pohlaví',
  };
});

const isParticipant = computed(() => {
  return activeStore.value.signer.type.value === 'participant';
});

const showPersonalIdInput = computed(() => {
  return permanentAddressArea.value !== 'other';
});

const clientCountryOptions = computed(() => {
  if (permanentAddressArea.value === 'other') {
    return countryOfBirthOptions.value.filter(
      (country) => !(countriesOfEU.includes(country.value) || country.value === ''),
    );
  }

  if (permanentAddressArea.value === 'eu') {
    return countryOfBirthOptions.value.filter(
      (country) => countriesOfEUWithoutCzechia.value.includes(country.value),
    );
  }

  if (permanentAddressArea.value === 'cz') {
    return countryOfBirthOptions.value.filter(
      (country) => country.value === 'cz',
    );
  }

  return countryOfBirthOptions.value;
});

function copyPersonalNamesFromContactInformation () {
  copyFields(activeStore.value, [
    {
      pathFrom: 'contactInformation.firstName.value',
      pathTo: `${props.storePath}.firstName.value`,
    },
    {
      pathFrom: 'contactInformation.lastName.value',
      pathTo: `${props.storePath}.lastName.value`,
    },
  ]);
}

function updateAddress (prefix: string, name: string, params: Address) {
  setStoreFields(activeStore.value, [
    {
      path: `${prefix}.${name}.city.value`,
      value: params.city,
    },
    {
      path: `${prefix}.${name}.street.value`,
      value: params.street,
    },
    {
      path: `${prefix}.${name}.streetNumber.value`,
      value: params.streetNumber,
    },
    {
      path: `${prefix}.${name}.zip.value`,
      value: params.zip,
    },
    {
      path: `${prefix}.${name}.country.value`,
      value: params.countryCode?.toLowerCase(),
    },
  ]);
}

function updatePermanentAddress (addressDetail: Address) {
  updateAddress('personalData', 'permanentAddress', addressDetail);
}

watch(permanentAddressArea, (newVal) => {
  switch (newVal) {
    case 'cz':
      stateOfPermanentAddress.value = 'cz';
      personalIdNumber.value = '';
      break;

    case 'eu':
      stateOfPermanentAddress.value = '';
      personalIdNumberErrors.value = [];
      break;

    case 'other':
      stateOfPermanentAddress.value = '';
      personalIdNumber.value = '';
      personalIdNumberErrors.value = [];
      break;

    default:
      stateOfPermanentAddress.value = 'cz';
      personalIdNumber.value = '';
  }
});

onMounted(() => {
  if (isParticipant.value) {
    copyPersonalNamesFromContactInformation();
  }
});
</script>
