<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li
        v-for="tab in items"
        :key="tab.title + tab.hashId"
        :dusk="tab.dusk"
        :class="{
          tabs__selected: tab.hashId === selectedId
        }"
        tabindex="0"
        @click="selectedId = tab.hashId"
        @keyup.enter="selectedId = tab.hashId"
      >
        <span
          v-if="tab.label !== null"
          class="tabs__batch"
          :class="`bg-${tab.labelType}`"
          dusk="recommendation"
        >
          <i
            :class="tab.label === '' ? '' : 'icon'"
          >
            <img
              v-if="tab.labelIcon"
              :src="getIconUrl(tab.labelIcon)"
              :class="tab.label === '' ? 'icon__noText' : ''"
              alt="Ikona"
            >
          </i>
          {{ tab.label }}
        </span>

        <span
          class="tabs__title"
          :class="{ 'mb-md-15': tab.titleInfo }"
        >
          <span v-if="tab.title" class="tabs__title__wrap">
            <span class="tabs__control" />
            {{ tab.title }}
          </span>

          <span
            v-if="tab.timeIndicator"
            class="tabs__indicator"
            :class="`text-${tab.indicatorType}`"
          >
            <i>
              <img
                :src="getIconUrl(`icon-hours-${tab.indicatorType}`)"
                alt="Ikona hodin"
              >
            </i>
            {{ tab.timeIndicator }}
          </span>
        </span>

        <ul
          v-if="tab.titleInfo"
          class="list"
        >
          <li
            v-for="(item, tabIndex) in tab.titleInfo"
            :key="item + tabIndex"
            v-html="item"
          />
        </ul>
      </li>
    </ul>
    <slot class="tabs__content" />
  </div>
</template>

<script lang="ts" setup>
import { computed, provide, ref } from 'vue';
import { getIconUrl } from '@/js/utils';

interface Props {
  returnAttribute?: string
  modelValue?: string | number | null
}

interface Tab {
  title: string
  label: string | null
  labelIcon: string
  labelType: string
  titleInfo: string[]
  timeIndicator: string
  indicatorType: string
  dusk: string
  hashId: string
  attrs: Record<string, string>
}

const props = defineProps<Props>();

const emit = defineEmits([
  'update:modelValue',
]);

const items = ref<Tab[]>([]);

const selectedId = computed({
  get () {
    if (!props.returnAttribute) {
      return props.modelValue;
    }

    return items.value?.find((tab) => tab.attrs[props.returnAttribute!] === props.modelValue)?.hashId ?? null;
  },

  set (val: string) {
    if (!props.returnAttribute) {
      emit('update:modelValue', val);
    } else {
      const tab = items.value.find((tab) => tab.hashId === val);
      emit('update:modelValue', tab?.attrs[props.returnAttribute]);
    }
  },
});

provide('createTab', (tab: Tab) => {
  const count = items.value.push(tab);

  if (props.modelValue === null && count === 1) {
    selectedId.value = tab.hashId;
  }
});

provide('removeTab', (tab: Tab) => {
  const index = items.value.findIndex((item) => item.attrs[props.returnAttribute!] === tab.attrs[props.returnAttribute!]);

  if (index === -1) {
    return;
  }

  items.value.splice(index, 1);
});

provide('activeTabHashId', selectedId);
</script>

<style lang="scss" scoped>
  @import '@sass/tools/variables';
  @import '@sass/tools/mixins';
  @import '@sass/tools/functions';

  .tabs {
    &__header {
      padding-left: 0;
      margin: 0;
      list-style: none;
      display: flex;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid getColor(green);
        z-index: 1;
      }

      @include media(max, $md) {
        overflow-y: hidden;
        overflow-x: auto;
        padding-top: 10px;
      }

      & > li {
        border: 1px solid getColor(lighter-grey);
        border-bottom-color: transparent;
        border-radius: 2px 2px 0 0;
        min-height: 60px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        flex-grow: 1;
        min-width: 185px;

        @include media(min, $md) {
          padding: 20px;
        }

        &:not(.tabs__selected) {
          cursor: pointer;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }

        &.tabs__selected {
          border-color: getColor(green);
          box-shadow: inset 0 4px 0 getColor(green);
          border-bottom-color: getColor(white);
          z-index: 2;

          &:focus {
            border-bottom-color: getColor(white);
          }
        }

        &:focus {
          border-color: getColor(green);
          outline: none;
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1.45;
      font-weight: $bold;

      &__wrap {
        display: flex;
        align-items: center;
      }
    }

    &__batch {
      position: absolute;
      top: -10px;
      right: 20px;
      min-height: 20px;
      font-size: rem(10);
      padding: 4px 5px;
      z-index: 2;
      pointer-events: none;
      color: getColor(white);
      font-weight: $normal;
      border-radius: 2px;
      display: flex;

      .icon {
        vertical-align: text-top;
        margin-right: 3px;

        img {
          max-height: 15px;
        }
      }
    }

    &__control {
      $control-bg: #d8e8c5;

      width: rem(23);
      height: rem(23);
      border-radius: 50%;
      border: 1px solid $control-bg;
      transform: translateY(-.05em);
      background: $control-bg;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      flex-shrink: 0;

      &::before {
        content: '';
        width: rem(9);
        height: rem(9);
        border-radius: 50%;
        transition: 180ms transform ease-in-out;
        transform: scale(0);
        background: getColor(green);
      }
    }

    &__selected {
      .tabs__control {
        background: getColor(white);
        border-color: getColor(green);

        &::before {
          transform: scale(1);
        }
      }
    }

    &__indicator {
      font-weight: $normal;
      margin-left: 10px;
      white-space: nowrap;

      i {
        margin-right: 5px;
        vertical-align: text-top;

        img {
          height: 16px;
        }
      }
    }

    :deep(.tabs__content) {
      background: getColor(white);
      padding: 20px 15px;
      border: 1px solid getColor(green);
      border-top: none;

      @include media(min, $md) {
        padding: 20px;
      }

      /* stylelint-disable */
      .control__number:not(.field-modal) :deep(.field label) {
        @include media(min, $sm) {
          flex-basis: 160px;
        }
      }
      /* stylelint-enable */
    }

    @include media(max, $md) {
      .list {
        display: none;
      }
    }
  }

  .icon__noText {
    width: 15px;
    height: auto;
    margin-bottom: -2px;
  }

  .bg-warning {
    padding: 6px 5px;
  }

  .bg-success {
    background-color: getColor(green);
  }
</style>
