<template>
  <div>
    <OnlineAgreementStepper :current-step="1" />

    <div class="container--sm">
      <h2>Vyberte způsob vyplnění vašich osobních údajů</h2>

      <LitAlert
        v-if="!isBankIdOn"
        alert-type="warning"
        class="my-20"
      >
        {{ bankIdDeactivateText }}
      </LitAlert>

      <button
        v-if="!isProduction"
      >
        <p
          v-if="isBankIdEnabled"
          @click="isBankIdEnabled = false"
        >
          [ DEBUG ] Zakázat Bank iD
        </p>
        <p
          v-else
          @click="isBankIdEnabled = true"
        >
          [ DEBUG ] Povolit Bank iD
        </p>
      </button>

      <div
        v-if="selectedEntryMethodError !== null"
        class="error mb-5"
      >
        {{ selectedEntryMethodError }}
      </div>

      <div class="row">
        <div
          class="col-md-6 mb-20"
          dusk="bank-id"
        >
          <div
            :class="[bankIdTileVisible ? '' : 'disabled opacity-50', 'signpost']"
          >
            <div class="signpost__header">
              <img
                :src="getIconUrl('logo-bankId-black')"
                alt="bankID logo"
              >

              <span>
                Přihlašte se přes <strong>Bank iD</strong>
              </span>
            </div>

            <div class="signpost__body">
              <ul class="list">
                <li>
                  Nemusíte vyplňovat své osobní údaje. <strong>Bank iD to udělá za vás.</strong>
                  Také nám nebudete muset posílat žádné osobní doklady
                </li>
                <li>
                  Použití Bank iD je stejně jednoduché jako přihlášení do internetového bankovnictví
                </li>
              </ul>

              <div class="signpost__info">
                <span>
                  <img
                    :src="getIconUrl('icon-hours_green')"
                    alt="hodiny"
                  >

                  5 min
                </span>

                <span>
                  <img
                    :src="getIconUrl('icon-email_online_green')"
                    alt="email"
                  >

                  Online
                </span>
              </div>

              <BankIdButton
                class="mt-20"
                label="Přihlásit se"
                sub-label="přes Bank iD"
                @click="redirectToBankList('bankId')"
              />
            </div>
          </div>
        </div>

        <div
          class="col-md-6 mb-20"
          dusk="documents-photo"
        >
          <div :class="[mobilePhotoTileIsVisible ? '' : 'disabled opacity-50', 'signpost']">
            <div class="signpost__header">
              <img
                :src="getIconUrl('icon-vyfotte_mobilem')"
                alt="mobilní telefon"
              >

              <span>
                <strong>Vyfoťte své doklady</strong>
                mobilním telefonem
              </span>
            </div>

            <div class="signpost__body">
              <ul class="list">
                <li>
                  Vyfotíte svůj <strong>občanský průkaz</strong> a <strong>řidičský průkaz</strong>
                  nebo <strong>cestovní pas</strong> mobilním telefonem
                </li>

                <li>
                  <strong>Doplníte</strong> potřebné osobní údaje
                  a <strong>sjednáte si spoření online</strong>
                </li>
              </ul>

              <div class="signpost__info">
                <span>
                  <img
                    :src="getIconUrl('icon-hours_green')"
                    alt="hodiny"
                  >

                  10 min
                </span>

                <span>
                  <img
                    :src="getIconUrl('icon-email_online_green')"
                    alt="email"
                  >

                  Online
                </span>
              </div>

              <div class="signpost__action mt-20">
                <button
                  type="button"
                  class="btn-primary btn"
                  dusk="documents-photo-button"
                  @click="scanDocuments('sms')"
                >
                  Vyfotit doklady
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 mb-20"
          dusk="documents-copy"
        >
          <div :class="[mobilePhotoTileIsVisible ? '' : 'disabled opacity-50', 'signpost']">
            <div class="signpost__header">
              <img
                :src="getIconUrl('icon-nahrejte_do_pc')"
                alt="počítač"
              >

              <span>
                <strong>Nahrajte kopie</strong>
                svých dokladů z PC
              </span>
            </div>

            <div class="signpost__body">
              <ul class="list">
                <li>
                  Nahrajete kopie svého <strong>občanského průkazu</strong> a
                  <strong>řidičského průkazu</strong> nebo <strong>cestovního pasu</strong>
                </li>

                <li>
                  <strong>Doplníte</strong> potřebné osobní údaje
                  a <strong>sjednáte si spoření online</strong>
                </li>
              </ul>

              <div class="signpost__info">
                <span>
                  <img
                    :src="getIconUrl('icon-hours_green')"
                    alt="hodiny"
                  >

                  10 min
                </span>

                <span>
                  <img
                    :src="getIconUrl('icon-email_online_green')"
                    alt="email"
                  >
                  Online
                </span>
              </div>

              <div class="signpost__action mt-20">
                <button
                  type="button"
                  class="btn-primary btn"
                  dusk="documents-copy-button"
                  @click="uploadDocuments('sms')"
                >
                  Nahrát kopie dokladů
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 mb-20"
          dusk="manually-filled"
        >
          <div :class="[courierTileIsVisible ? '' : 'disabled opacity-50', 'signpost']">
            <div class="signpost__header">
              <img
                :src="getIconUrl('icon-rucne_vyplnte')"
                alt="Vyplnit ručně"
              >

              <span>
                <strong>Vyplňte</strong>
                své osobní údaje
              </span>
            </div>

            <div class="signpost__body">
              <ul class="list">
                <li>
                  Přejdete na další krok, <strong>vyplníte své osobní
                    a kontaktní údaje</strong>
                </li>

                <li>
                  Odešlete žádost a <strong>vyčkáte na kurýra</strong>,
                  u kterého smlouvu podepíšete
                </li>
              </ul>

              <div class="signpost__info danger">
                <span>
                  <img
                    :src="getIconUrl('icon-hours_red')"
                    alt="hodiny"
                  >

                  3 prac. dny
                </span>

                <span>
                  <img
                    :src="getIconUrl('icon-email_online_red')"
                    alt="email"
                  >
                  Kurýr
                </span>
              </div>

              <div class="signpost__action mt-20">
                <button
                  type="button"
                  class="btn-primary btn"
                  dusk="fill-data-manually-button"
                  @click="fillDataManually('offline')"
                >
                  Vyplnit údaje ručně
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LitModal
      v-model="showDistributorModal"
    >
      <template #header>
        <h3>
          Něco se pokazilo
        </h3>
      </template>

      <template #body>
        <h3>
          Nemůžeme vaši smlouvu dokončit pomocí BankID.
        </h3>
        <p>
          Vyberte si prosím
          <a
            target="_blank"
            href="https://www.csob-penze.cz/pobocky/"
          >
            <strong>některou z našich poboček</strong></a>
          nebo nás kontaktujte na telefonu
          <a href="tel:+420495800600">
            495&nbsp;800&nbsp;600
          </a>.
          Rádi vám se založením penzijního spoření pomůžeme.
        </p>
      </template>
      <template #footer>
        <div class="flex flex-center">
          <button
            dusk="close-modal"
            class="btn btn-big btn-green"
            @click="closeDistributorRecommendedModal"
          >
            ROZUMÍM
          </button>
        </div>
      </template>
    </LitModal>

    <LitModal
      v-model="showBankIdRejectionModal"
    >
      <template #header>
        <h3>
          Něco se pokazilo
        </h3>
      </template>

      <template #body>
        <div class="center">
          <h3>Nemůžeme vaši smlouvu dokončit pomocí BankID.</h3>
          <p>
            Vyberte prosím <strong>jiný způsob zadání osobních údajů</strong> pro dokončení
            vaší smlouvy.
          </p>
        </div>
      </template>

      <template #footer>
        <div class="flex flex-center">
          <button
            dusk="close-modal"
            class="btn btn-big btn-green"
            @click="closeBankIdRejectModal"
          >
            ROZUMÍM
          </button>
        </div>
      </template>
    </LitModal>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { bankMetadata, signatures } from '@/js/api';
import BankIdButton from '@/js/components/BankIdButton.vue';
import LitAlert from '@/js/components/Base/LitAlert.vue';
import LitModal from '@/js/components/Base/LitModal.vue';
import OnlineAgreementStepper from '@/js/components/Steppers/OnlineAgreementStepper.vue';

import env from '@/js/env';
import gtm from '@/js/services/gtm';

import { useOnlineAgreementStore } from '@/js/stores';
import { personalData } from '@/js/stores/createStoreFields.ts';
import { measureAdobeAnalytics } from '@/js/stores/utils';

import { EContractType } from '@/js/types/contract.ts';
import { clearBankIdData, getIconUrl, isBankIdDataFilled } from '@/js/utils';

export default {
  components: {
    LitAlert,
    BankIdButton,
    LitModal,
    OnlineAgreementStepper,
  },

  setup () {
    const store = useOnlineAgreementStore();
    const { validateFields } = store;
    const { isChild, personalData, contractSettings, contractSelection } = storeToRefs(store);

    const route = useRoute();
    const router = useRouter();

    const showBankIdRejectionModal = ref(false);
    const showDistributorModal = ref(false);
    const isBankIdEnabled = ref(true);

    const isProduction = computed(() => {
      return env.APP_ENV === 'production';
    });

    const isBankIdOn = computed(() => {
      if (isProduction.value) {
        return env.BANKID_ENABLED === true;
      }

      return isBankIdEnabled.value;
    });

    const bankIdDeactivateText = computed(() => {
      return `Momentálně evidujeme výpadek na straně poskytovatele služby Bank iD. Omlouváme se za případné komplikace. ${env.BANKID_DISABLED_END_DATE_INFO_TEXT}`;
    });

    const isTransfer = computed(() => {
      return contractSelection.value.type.value !== EContractType.Online;
    });

    const bankIdTileVisible = computed(() => {
      return !isChild.value && isBankIdOn.value;
    });

    const mobilePhotoTileIsVisible = computed(() => {
      return !isChild.value && !isTransfer.value;
    });

    const courierTileIsVisible = computed(() => {
      return !isTransfer.value;
    });

    const selectedEntryMethodError = computed(() => {
      const errors = personalData.value.selectedEntryMethod.errors;

      if (errors.length > 0) {
        return errors[0];
      }

      return null;
    });

    const isBankIdRejected = computed(() => {
      return route.query?.bankId === 'rejected' && route.query?.online === 'true';
    });

    const isDistributorRecommended = computed (() => {
      return route.query?.bankId === 'rejected' && route.query?.online === 'false';
    });

    const wasBankIdEntryCanceled = computed (() => {
      return route.query?.bankId === 'navrat' || personalData.value.verifiedBank.value !== null;
    });

    async function redirectToBankList (signMethod: string) {
      if (!isBankIdOn.value) {
        return;
      }

      personalData.value.selectedEntryMethod.value = 'bankId';

      if (isBankIdDataFilled(store.$state)) {
        clearBankIdData(store.$state);
      }

      const { contractUuid } = route.params;

      const signature = [
        {
          signer: 'client',
          signMethod,
        },
      ];

      await signatures.update(contractUuid, JSON.stringify(signature));

      await router.push({
        name: 'onlineAgreement.bankList',
        params: {
          contractUuid: route.params.contractUuid,
        },
        query: { bankId: wasBankIdEntryCanceled.value ? 'canceled' : null },
      });
    }

    function closeBankIdRejectModal () {
      router.replace({ query: undefined });
      showBankIdRejectionModal.value = false;
    }

    function closeDistributorRecommendedModal () {
      router.replace({ query: undefined });
      showDistributorModal.value = false;
    }

    async function validateSelectedEntryMethod () {
      const { contractUuid } = route.params;

      await validateFields({
        contractUuid: contractUuid as string,
        throwOnErrors: true,
        fieldPaths: ['personalData.selectedEntryMethod'],
      });
    }

    async function scanDocuments (signMethod: string) {
      personalData.value.selectedEntryMethod.value = 'scan';

      const isDesktop = window.env.AGENT_DESKTOP;

      await validateSelectedEntryMethod();

      // Skip sending SMS when user is already on mobile
      // device.
      if (isDesktop) {
        router.push({ name: 'onlineAgreement.personalDataPhoneRedirect' });
      } else {
        router.push({
          name: 'onlineAgreement.personalDataDocumentsScan',
          query: {
            redirected: '0',
          },
        });
      }

      gtm.onStepSubmit('volba-zadani-osobnich-udaju');

      const { contractUuid } = route.params;

      const signature = [
        {
          signer: 'client',
          signMethod,
        },
      ];

      await signatures.update(contractUuid, JSON.stringify(signature));

      measureAdobeAnalytics({
        state: store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });
    }

    async function uploadDocuments (signMethod: string) {
      personalData.value.selectedEntryMethod.value = 'upload';

      await validateSelectedEntryMethod();

      router.push({
        name: 'onlineAgreement.documentsUpload',
      });

      gtm.onStepSubmit('volba-zadani-osobnich-udaju');

      const { contractUuid } = route.params;

      const signature = [
        {
          signer: 'client',
          signMethod,
        },
      ];

      await signatures.update(contractUuid, JSON.stringify(signature));

      measureAdobeAnalytics({
        state: store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });
    }

    async function fillDataManually (signMethod: string) {
      personalData.value.selectedEntryMethod.value = 'manually';
      contractSettings.value.finishMethod.value = 'courier';
      await validateSelectedEntryMethod();

      gtm.onStepSubmit('volba-zadani-osobnich-udaju');

      const { contractUuid } = route.params;

      const signature = [
        {
          signer: 'client',
          signMethod,
        },
      ];

      await signatures.update(contractUuid, JSON.stringify(signature));

      measureAdobeAnalytics({
        state: store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });

      router.push({
        name: 'onlineAgreement.personalData',
      });
    }

    watch(() => store.personalData.selectedEntryMethod.value, async (newVal, oldVal) => {
      if (newVal !== null && oldVal === 'bankId' && isBankIdDataFilled(store.$state)) {
        const { contractUuid } = route.params;
        clearBankIdData(store.$state);
        await bankMetadata.update(contractUuid, 'canceled', 'verification');

        personalData.value.specifyMailingAddress.value = false;
        personalData.value.permanentAddress.editable.value = true;
        personalData.value.mailingAddress.editable.value = true;
      }
    });

    onMounted(async () => {
      personalData.value.selectedEntryMethod.errors = [];

      if (isBankIdRejected.value) {
        showBankIdRejectionModal.value = true;
        personalData.value.selectedEntryMethod.value = null;
      }

      if (isDistributorRecommended.value) {
        showDistributorModal.value = true;
        personalData.value.selectedEntryMethod.value = null;
      }

      if (store.personalData.selectedEntryMethod.value === 'bankId' || wasBankIdEntryCanceled.value) {
        const { contractUuid } = route.params;
        clearBankIdData(store.$state);
        await bankMetadata.update(contractUuid, 'canceled', 'verification');
        personalData.value.specifyMailingAddress.value = false;
        personalData.value.permanentAddress.editable.value = true;
        personalData.value.mailingAddress.editable.value = true;
      }
    });

    return {
      getIconUrl,
      isChild,
      isBankIdOn,
      isBankIdEnabled,
      showBankIdRejectionModal,
      showDistributorModal,
      isBankIdRejected,
      isDistributorRecommended,
      isProduction,
      isTransfer,
      courierTileIsVisible,
      mobilePhotoTileIsVisible,
      selectedEntryMethodError,
      bankIdTileVisible,
      bankIdDeactivateText,
      redirectToBankList,
      closeBankIdRejectModal,
      closeDistributorRecommendedModal,
      scanDocuments,
      uploadDocuments,
      fillDataManually,
    };
  },
  methods: { personalData },
};
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/functions';

.signpost {
  line-height: 1.45;
  display: flex;
  flex-flow: column;
  height: 100%;

  &__header {
    background: getColor(lighter-blue);
    padding: 20px;
    display: flex;
    align-items: center;
    font-size: $root;

    img {
      margin-right: 15px;
    }
  }

  &__body {
    padding: 20px;
    background: getColor(white);
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    border: 1px solid #e2e6ef;
    border-top: none;

    ul {
      flex-grow: 1;
    }
  }

  &__info {
    margin-top: 7px;
    padding: 12px 0;
    display: flex;
    align-items: center;
    border: 1px solid getColor(light-grey);
    border-left: 0;
    border-right: 0;

    &.danger {
      color: getColor(danger);
    }

    span {
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:first-child {
        margin-right: auto;
      }

      &:not(:first-child) {
        margin-left: 15px;
      }

      &:not(:last-child) {
        img {
          margin-right: 5px;
        }
      }
    }
  }

  .btn {
    width: 100%;
  }
}

.disabled {
  background: #f5f5f5;
  pointer-events: none;
}

.opacity-50 {
  opacity: 50%;
}

.error {
  color: rgba(var(--color-danger), 1);
}
</style>
