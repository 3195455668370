<template>
  <div class="body">
    <TheHeader>
      <template #heading>
        <h1><strong>Ukončení smlouvy</strong></h1>
      </template>

      <template #header_contact_phone>
        <a
          :href="getHeaderPhoneNumber.href"
        >
          <img
            :src="getIconUrl('phone')"
            alt="Icon tel."
          ><span>{{ getHeaderPhoneNumber.value }}</span>
        </a>
      </template>
    </TheHeader>

    <InactivityModal
      contract-type="termination"
      :contract-uuid="$route.params.contractUuid as string"
      :forbidden-route-names="modalForbiddenRoutes"
    />

    <main v-if="rehydrated.value">
      <router-view />
    </main>

    <TheFooter
      :display-link="showFooterLink"
    />
  </div>
</template>

<script lang="ts">
import type { StateTree } from 'pinia';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { storeToRefs } from 'pinia';
import TheFooter from '../../TheFooter.vue';
import TheHeader from '../../TheHeader.vue';
import { accessTokens, contracts } from '@/js/api';

import InactivityModal from '@/js/components/Modals/InactivityModal.vue';
import { useDistributionStore, useGeneralStore, useTerminationStore } from '@/js/stores';
import { restoreState } from '@/js/stores/utils';
import { getIconUrl, getPersistedData, persistContractMetadata } from '@/js/utils';

type StatusResponse = 404 | 500;

export default {
  components: {
    TheHeader,
    TheFooter,
    InactivityModal,
  },

  async beforeRouteUpdate (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { contractUuid } = to.params;

    // This method on every update checks whether the
    // contract exists/is available.

    // We do not want to do that after the last step
    // when the contract is submitted/signed and becomes unavailable.
    // Therefor we skip the validation for the thankYou page.
    if (to.name === 'termination.thankYouPage') {
      next();
      return;
    }

    try {
      await contracts.show(contractUuid as string, 'termination');
      next();
    } catch (e: any) {
      const status = e?.response?.status as StatusResponse;

      const pages = {
        404: 'notFound',
        500: 'internalError',
      };

      const name = pages[status] || 'error';
      const type = (to.name as string).split('.')[0];

      next({
        name,
        query: {
          type,
        },
      });
    }
  },

  async beforeRouteEnter (to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { contractUuid } = to.params;
    const { draftToken, redirectToken, newContractUuid } = to.query;

    try {
      if (typeof redirectToken !== 'undefined' || typeof draftToken !== 'undefined') {
        const tokenName = typeof redirectToken !== 'undefined' ? 'redirectToken' : 'draftToken';
        const tokenValue = typeof redirectToken !== 'undefined' ? redirectToken : draftToken;

        const { data } = await accessTokens.create(
          contractUuid,
          {
            name: tokenName,
            value: tokenValue,
          },
        );

        persistContractMetadata({
          contractUuid,
          accessTokenValue: data.token,
          contractType: 'termination',
          accessTokenValidTo: data.valid_to,
        });
      }

      const { data } = await contracts.show(contractUuid as string, 'termination');
      const newContractFormData = newContractUuid !== undefined
        ? JSON.parse((await contracts.show(newContractUuid as string, 'distribution')).data.form_data)
        : null;

      if (data.form_data) {
        const formData = JSON.parse(data.form_data);

        next((vm: any) => {
          vm.restoreStateData(formData);

          if (newContractUuid !== undefined) {
            const distributionStore = useDistributionStore();
            restoreState(distributionStore.$state, newContractFormData);
          }
        });
      } else {
        next((vm: any) => {
          vm.rehydrated.value = true;
        });
      }
    } catch (e: any) {
      const status = e?.response?.status as StatusResponse;

      const pages = {
        404: 'notFound',
        500: 'internalError',
      };

      const name = pages[status] || 'error';
      const type = (to.name as string).split('.')[0];

      next({
        name,
        query: {
          type,
        },
      });
    }
  },

  setup () {
    const store = useTerminationStore();
    const generalStore = useGeneralStore();

    const { rehydrated } = storeToRefs(store);

    function restoreStateData (data: StateTree) {
      restoreState(store.$state, data);
    }

    function setDataFromLocalStorage () {
      const generalStoreData = getPersistedData('distribution');

      if (generalStoreData !== null) {
        const data = JSON.parse(generalStoreData);

        generalStore.isTiedAgent.value = data.isTiedAgent;
        generalStore.distribution.value = data.distribution;
      }
    }

    setDataFromLocalStorage();

    return {
      getIconUrl,
      rehydrated,
      restoreStateData,
    };
  },

  data: () => ({
    modalForbiddenRoutes: [
      'termination.thankYouPage',
      'termination.bankIdThankYouPage',
      'termination.thankYouSignaturePage',
      'termination.bankIdSignaturePage',
    ],

    customerPages: [
      'termination.bankIdSignaturePage',
      'termination.bankIdThankYouPage',
    ],
  }),

  computed: {
    getHeaderPhoneNumber () {
      if (this.$route.name && this.customerPages.includes(this.$route.name as string)) {
        return {
          href: 'tel:+420495800600',
          value: '495 800 600',
        };
      }

      return {
        href: 'tel:+420224116655',
        value: '224 116 655',
      };
    },

    showFooterLink () {
      return !this.customerPages.includes(this.$route.name as string);
    },
  },

};
</script>
