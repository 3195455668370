export const EPreviewType = {
  NewContract: 'newContract',
  Termination: 'termination',
} as const;

export const EContractType = {
  Online: 'online',
  JpsOnline: 'jpsOnline',
  Distribution: 'distribution',
  Transfer: 'transfer',
  ChangeRequest: 'changeRequest',
  Termination: 'termination',
  Jps: 'jps',
} as const;

export type PreviewType = typeof EPreviewType[keyof typeof EPreviewType];
export type ContractType = typeof EContractType[keyof typeof EContractType];
