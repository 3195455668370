import type { SignerKeys, SignerTranslation } from '@/js/data/signers.ts';
import { signers } from '@/js/data/signers.ts';

export const useSignerTranslation = (signerType: SignerKeys, grammaticalCase: SignerTranslation = 'nominative') => {
  if (typeof signerType === 'undefined' || signerType === null) {
    return;
  }

  return signers[signerType][grammaticalCase];
};
