import type { NullableString, SubmitResponse } from '@/js/stores/types';

import { useStoreField } from '@/js/composables/useStoreField';
import {
  beforeStart,
  contactInformation,
  personalData,
  personalDocument,
  signer,
} from '@/js/stores/createStoreFields';

export const state = {
  rehydrated: useStoreField(false, false),

  beforeStart: beforeStart({}),

  contactInformation: {
    ...contactInformation(),
    phonePrefix: useStoreField('420'),
  },

  personalData: {
    ...personalData({}),

    birthDate: useStoreField(''),
    gender: useStoreField(''),
    citizenshipInEu: useStoreField(true),

    permanentAddressArea: useStoreField('cz'),
    permanentAddressInEu: useStoreField(true),

    insuranceNumbers: {
      medicalInsurance: useStoreField<NullableString>(''),
      pensionInsurance: useStoreField<NullableString>(''),
      retirementPension: useStoreField<NullableString>(''),
    },

    document: personalDocument({}),
  },

  signer: signer({}),

  currentContractInformation: {
    contractNumber: useStoreField<NullableString>(null),
    contractValidityFrom: useStoreField<NullableString>(null),
    terminationDay: useStoreField<NullableString>(null),
    terminationByAgreement: useStoreField(false),
  },

  contractSettings: {
    transferSignatureDate: useStoreField<NullableString>(null),
    transferSignaturePlace: useStoreField<NullableString>(null),
  },

  newContract: {
    hasAlreadyNewContract: useStoreField(false),
    contractNumber: useStoreField<NullableString>(null),
    uuid: useStoreField<NullableString>(null),
  },

  meetingRecordFilePath: useStoreField<NullableString>(null),

  submitResponse: useStoreField<SubmitResponse | null>(null, false),
};
