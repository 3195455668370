<template>
  <div>
    <Stepper
      :steps
      :current-step
    />
  </div>
</template>

<script lang="ts" setup>
import { shallowRef } from 'vue';
import Stepper from './StepperDistribution.vue';

interface Props {
  currentStep: keyof typeof steps.value
}

defineProps<Props>();

const steps = shallowRef({
  salesInterview: {
    title: 'Prodejní rozhovor',
    uri: { name: 'termination.salesInterview' },
  },
  contactInformation: {
    title: 'Kontaktní údaje podepisujícího',
    uri: { name: 'termination.contactInformation' },
  },
  personalData: {
    title: 'Osobní údaje klienta',
    uri: { name: 'termination.personalData' },
  },
  paymentOption: {
    title: 'Způsob výplaty',
    uri: { name: 'termination.paymentOption' },
  },
  additionInformation: {
    title: 'Doplňující udaje',
    uri: { name: 'termination.additionInformation' },
  },
  recapitulation: {
    title: 'Rekapitulace',
    uri: { name: 'termination.recapitulation' },
  },
});
</script>
