export const citizenshipCountries = [
  { label: 'Česká republika', value: 'cz' },
  { label: '----------', value: '' },
  { label: 'Afghánistán', value: 'af' },
  { label: 'Alandy', value: 'ax' },
  { label: 'Albánie', value: 'al' },
  { label: 'Alžírsko', value: 'dz' },
  { label: 'Americká Samoa', value: 'as' },
  { label: 'Americké Panenské ostrovy', value: 'vi' },
  { label: 'Andorra', value: 'ad' },
  { label: 'Angola', value: 'ao' },
  { label: 'Anguilla', value: 'ai' },
  { label: 'Antarktida', value: 'aq' },
  { label: 'Antigua a Barbuda', value: 'ag' },
  { label: 'Argentina', value: 'ar' },
  { label: 'Arménie', value: 'am' },
  { label: 'Aruba', value: 'aw' },
  { label: 'Austrálie', value: 'au' },
  { label: 'Ázerbájdžán', value: 'az' },
  { label: 'Bahamy', value: 'bs' },
  { label: 'Bahrajn', value: 'bh' },
  { label: 'Bangladéš', value: 'bd' },
  { label: 'Barbados', value: 'bb' },
  { label: 'Belgie', value: 'be' },
  { label: 'Belize', value: 'bz' },
  { label: 'Bělorusko', value: 'by' },
  { label: 'Benin', value: 'bj' },
  { label: 'Bermudy', value: 'bm' },
  { label: 'Bhútán', value: 'bt' },
  { label: 'Bolívie', value: 'bo' },
  { label: 'Bonaire, Svatý Eustach a Saba', value: 'bq' },
  { label: 'Bosna a Hercegovina', value: 'ba' },
  { label: 'Botswana', value: 'bw' },
  { label: 'Bouvetův ostrov', value: 'bv' },
  { label: 'Brazílie', value: 'br' },
  { label: 'Britské indickooceánské území', value: 'io' },
  { label: 'Britské Panenské ostrovy', value: 'vg' },
  { label: 'Brunej', value: 'bn' },
  { label: 'Bulharsko', value: 'bg' },
  { label: 'Burkina Faso', value: 'bf' },
  { label: 'Burundi', value: 'bi' },
  { label: 'Cookovy ostrovy', value: 'ck' },
  { label: 'Curaçao', value: 'cw' },
  { label: 'Čad', value: 'td' },
  { label: 'Černá Hora', value: 'me' },
  { label: 'Čína', value: 'cn' },
  { label: 'Dánsko', value: 'dk' },
  { label: 'Demokratická republika Kongo', value: 'cd' },
  { label: 'Dominika', value: 'dm' },
  { label: 'Dominikánská republika', value: 'do' },
  { label: 'Džibutsko', value: 'dj' },
  { label: 'Egypt', value: 'eg' },
  { label: 'Ekvádor', value: 'ec' },
  { label: 'Eritrea', value: 'er' },
  { label: 'Estonsko', value: 'ee' },
  { label: 'Etiopie', value: 'et' },
  { label: 'Faerské ostrovy', value: 'fo' },
  { label: 'Falklandy', value: 'fk' },
  { label: 'Fidži', value: 'fj' },
  { label: 'Filipíny', value: 'ph' },
  { label: 'Finsko', value: 'fi' },
  { label: 'Francie', value: 'fr' },
  { label: 'Francouzská Guyana', value: 'gf' },
  { label: 'Francouzská jižní a antarktická území', value: 'tf' },
  { label: 'Francouzská Polynésie', value: 'pf' },
  { label: 'Gabon', value: 'ga' },
  { label: 'Gambie', value: 'gm' },
  { label: 'Ghana', value: 'gh' },
  { label: 'Gibraltar', value: 'gi' },
  { label: 'Grenada', value: 'gd' },
  { label: 'Grónsko', value: 'gl' },
  { label: 'Gruzie', value: 'ge' },
  { label: 'Guadeloupe', value: 'gp' },
  { label: 'Guam', value: 'gu' },
  { label: 'Guatemala', value: 'gt' },
  { label: 'Guernsey', value: 'gg' },
  { label: 'Guinea', value: 'gn' },
  { label: 'Guinea-Bissau', value: 'gw' },
  { label: 'Guyana', value: 'gy' },
  { label: 'Haiti', value: 'ht' },
  { label: 'Heardův ostrov a MacDonaldovy ostrovy', value: 'hm' },
  { label: 'Honduras', value: 'hn' },
  { label: 'Hongkong', value: 'hk' },
  { label: 'Chile', value: 'cl' },
  { label: 'Chorvatsko', value: 'hr' },
  { label: 'Indie', value: 'in' },
  { label: 'Indonésie', value: 'id' },
  { label: 'Irák', value: 'iq' },
  { label: 'Írán', value: 'ir' },
  { label: 'Irsko', value: 'ie' },
  { label: 'Island', value: 'is' },
  { label: 'Itálie', value: 'it' },
  { label: 'Izrael', value: 'il' },
  { label: 'Jamajka', value: 'jm' },
  { label: 'Japonsko', value: 'jp' },
  { label: 'Jemen', value: 'ye' },
  { label: 'Jersey', value: 'je' },
  { label: 'Jižní Afrika', value: 'za' },
  { label: 'Jižní Georgie a Jižní Sandwichovy ostrovy', value: 'gs' },
  { label: 'Jižní Súdán', value: 'ss' },
  { label: 'Jordánsko', value: 'jo' },
  { label: 'Kajmanské ostrovy', value: 'ky' },
  { label: 'Kambodža', value: 'kh' },
  { label: 'Kamerun', value: 'cm' },
  { label: 'Kanada', value: 'ca' },
  { label: 'Kapverdy', value: 'cv' },
  { label: 'Katar', value: 'qa' },
  { label: 'Kazachstán', value: 'kz' },
  { label: 'Keňa', value: 'ke' },
  { label: 'Kiribati', value: 'ki' },
  { label: 'Kokosové (Keelingovy) ostrovy', value: 'cc' },
  { label: 'Kolumbie', value: 'co' },
  { label: 'Komory', value: 'km' },
  { label: 'Konžská republika', value: 'cg' },
  { label: 'Korejská lidově demokratická republika', value: 'kp' },
  { label: 'Korejská republika', value: 'kr' },
  { label: 'Kosovo', value: 'xk' },
  { label: 'Kostarika', value: 'cr' },
  { label: 'Kuba', value: 'cu' },
  { label: 'Kuvajt', value: 'kw' },
  { label: 'Kypr', value: 'cy' },
  { label: 'Kyrgyzstán', value: 'kg' },
  { label: 'Laos', value: 'la' },
  { label: 'Lesotho', value: 'ls' },
  { label: 'Libanon', value: 'lb' },
  { label: 'Libérie', value: 'lr' },
  { label: 'Libye', value: 'ly' },
  { label: 'Lichtenštejnsko', value: 'li' },
  { label: 'Litva', value: 'lt' },
  { label: 'Lotyšsko', value: 'lv' },
  { label: 'Lucembursko', value: 'lu' },
  { label: 'Macao', value: 'mo' },
  { label: 'Madagaskar', value: 'mg' },
  { label: 'Maďarsko', value: 'hu' },
  { label: 'Makedonie', value: 'mk' },
  { label: 'Malajsie', value: 'my' },
  { label: 'Malawi', value: 'mw' },
  { label: 'Maledivy', value: 'mv' },
  { label: 'Mali', value: 'ml' },
  { label: 'Malta', value: 'mt' },
  { label: 'Man', value: 'im' },
  { label: 'Maroko', value: 'ma' },
  { label: 'Marshallovy ostrovy', value: 'mh' },
  { label: 'Martinik', value: 'mq' },
  { label: 'Mauricius', value: 'mu' },
  { label: 'Mauritánie', value: 'mr' },
  { label: 'Mayotte', value: 'yt' },
  { label: 'Menší odlehlé ostrovy USA', value: 'um' },
  { label: 'Mexiko', value: 'mx' },
  { label: 'Mikronésie', value: 'fm' },
  { label: 'Moldavsko', value: 'md' },
  { label: 'Monako', value: 'mc' },
  { label: 'Mongolsko', value: 'mn' },
  { label: 'Montserrat', value: 'ms' },
  { label: 'Mosambik', value: 'mz' },
  { label: 'Myanmar', value: 'mm' },
  { label: 'Namibie', value: 'na' },
  { label: 'Nauru', value: 'nr' },
  { label: 'Německo', value: 'de' },
  { label: 'Nepál', value: 'np' },
  { label: 'Niger', value: 'ne' },
  { label: 'Nigérie', value: 'ng' },
  { label: 'Nikaragua', value: 'ni' },
  { label: 'Niue', value: 'nu' },
  { label: 'Nizozemsko', value: 'nl' },
  { label: 'Norfolk', value: 'nf' },
  { label: 'Norsko', value: 'no' },
  { label: 'Nová Kaledonie', value: 'nc' },
  { label: 'Nový Zéland', value: 'nz' },
  { label: 'Omán', value: 'om' },
  { label: 'Pákistán', value: 'pk' },
  { label: 'Palau', value: 'pw' },
  { label: 'Palestina', value: 'ps' },
  { label: 'Panama', value: 'pa' },
  { label: 'Papua Nová Guinea', value: 'pg' },
  { label: 'Paraguay', value: 'py' },
  { label: 'Peru', value: 'pe' },
  { label: 'Pitcairn', value: 'pn' },
  { label: 'Pobřeží slonoviny', value: 'ci' },
  { label: 'Polsko', value: 'pl' },
  { label: 'Portoriko', value: 'pr' },
  { label: 'Portugalsko', value: 'pt' },
  { label: 'Rakousko', value: 'at' },
  { label: 'Réunion', value: 're' },
  { label: 'Rovníková Guinea', value: 'gq' },
  { label: 'Rumunsko', value: 'ro' },
  { label: 'Rusko', value: 'ru' },
  { label: 'Rwanda', value: 'rw' },
  { label: 'Řecko', value: 'gr' },
  { label: 'Saint Pierre a Miquelon', value: 'pm' },
  { label: 'Salvador', value: 'sv' },
  { label: 'Samoa', value: 'ws' },
  { label: 'San Marino', value: 'sm' },
  { label: 'Saúdská Arábie', value: 'sa' },
  { label: 'Senegal', value: 'sn' },
  { label: 'Severní Mariany', value: 'mp' },
  { label: 'Seychely', value: 'sc' },
  { label: 'Sierra Leone', value: 'sl' },
  { label: 'Singapur', value: 'sg' },
  { label: 'Slovensko', value: 'sk' },
  { label: 'Slovinsko', value: 'si' },
  { label: 'Somálsko', value: 'so' },
  { label: 'Spojené arabské emiráty', value: 'ae' },
  { label: 'Spojené státy', value: 'us' },
  { label: 'Srbsko', value: 'rs' },
  { label: 'Středoafrická republika', value: 'cf' },
  { label: 'Súdán', value: 'sd' },
  { label: 'Surinam', value: 'sr' },
  { label: 'Svatá Helena', value: 'sh' },
  { label: 'Svatá Lucie', value: 'lc' },
  { label: 'Svatý Bartoloměj', value: 'bl' },
  { label: 'Svatý Kryštof a Nevis', value: 'kn' },
  { label: 'Svatý Martin (FR)', value: 'mf' },
  { label: 'Svatý Martin (NL)', value: 'sx' },
  { label: 'Svatý Tomáš a Princův ostrov', value: 'st' },
  { label: 'Svatý Vincenc a Grenadiny', value: 'vc' },
  { label: 'Svazijsko', value: 'sz' },
  { label: 'Sýrie', value: 'sy' },
  { label: 'Šalomounovy ostrovy', value: 'sb' },
  { label: 'Španělsko', value: 'es' },
  { label: 'Špicberky a Jan Mayen', value: 'sj' },
  { label: 'Šrí Lanka', value: 'lk' },
  { label: 'Švédsko', value: 'se' },
  { label: 'Švýcarsko', value: 'ch' },
  { label: 'Tádžikistán', value: 'tj' },
  { label: 'Tanzanie', value: 'tz' },
  { label: 'Thajsko', value: 'th' },
  { label: 'Tchaj-wan', value: 'tw' },
  { label: 'Togo', value: 'tg' },
  { label: 'Tokelau', value: 'tk' },
  { label: 'Tonga', value: 'to' },
  { label: 'Trinidad a Tobago', value: 'tt' },
  { label: 'Tunisko', value: 'tn' },
  { label: 'Turecko', value: 'tr' },
  { label: 'Turkmenistán', value: 'tm' },
  { label: 'Turks a Caicos', value: 'tc' },
  { label: 'Tuvalu', value: 'tv' },
  { label: 'Uganda', value: 'ug' },
  { label: 'Ukrajina', value: 'ua' },
  { label: 'Uruguay', value: 'uy' },
  { label: 'Uzbekistán', value: 'uz' },
  { label: 'Vánoční ostrov', value: 'cx' },
  { label: 'Vanuatu', value: 'vu' },
  { label: 'Vatikán', value: 'va' },
  { label: 'Velká Británie', value: 'gb' },
  { label: 'Venezuela', value: 've' },
  { label: 'Vietnam', value: 'vn' },
  { label: 'Východní Timor', value: 'tl' },
  { label: 'Wallis a Futuna', value: 'wf' },
  { label: 'Zambie', value: 'zm' },
  { label: 'Západní Sahara', value: 'eh' },
  { label: 'Zimbabwe', value: 'zw' },
];
