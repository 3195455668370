<template>
  <div dusk="legal-requirements">
    <OnlineAgreementStepper :current-step="2" />

    <div class="container--sm">
      <h2>Pár státem požadovaných údajů…</h2>

      <h3 v-show="isChild">
        Údaje dítěte, pro které je sjednáváno penzijní spoření
      </h3>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="onlineAgreement"
        field-path="legalRequirements.paysTaxesInCzechia"
        :validate-on-input="true"
        @validate="validateField"
      >
        <LitRadio
          class="mb-20"
          name="payTaxesInCZ"
          dusk="tax-payment"
          :tooltip="tooltips.paysTaxesInCzechia"
          :label="labels.paysTaxesInCzechia"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :show-inline="true"
          :options="payTaxesInCZOptions"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors, validate, readOnly }"
        namespace="onlineAgreement"
        field-path="legalRequirements.isPoliticallyExposed"
        :validate-on-input="true"
        @validate="validateField"
      >
        <LitRadio
          v-if="!readOnly"
          class="mb-20"
          dusk="politically-exposed"
          name="politicallyExposedPerson"
          :tooltip="tooltips.isPoliticallyExposed"
          :label="labels.isPoliticallyExposed"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :show-inline="true"
          :options="isChild ? childPoliticallyExposedOptions : politicallyExposedPersonOptions"
          @update:model-value="input"
        />

        <LitInput
          v-else
          name="politicallyExposedPerson"
          class="mb-30"
          dusk="politically-exposed"
          :label="labels.isPoliticallyExposed"
          :model-value="value === false ? 'Ne' : 'Ano'"
          :errors="errors"
          :disabled="readOnly"
          :description="readOnly ? 'Předvyplněno z BankID, tento údaj nelze změnit.' : ''"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-slot="{ value, input, errors }"
        namespace="onlineAgreement"
        field-path="legalRequirements.sourceOfIncome"
        :validate-on-input="true"
        @validate="validateField"
      >
        <LitSelect
          class="mb-20"
          dusk="income-from"
          :tooltip="tooltips.sourceOfIncome"
          :label="labels.sourceOfIncome"
          :model-value="value"
          :error="errors.length > 0 ? errors[0] : ''"
          :options="incomeFromOptions"
          @update:model-value="input"
        />
      </ServerValidatedFormField>

      <ServerValidatedFormField
        v-if="displayOccupationField"
        v-slot="{ value, input, errors, validate }"
        namespace="onlineAgreement"
        field-path="legalRequirements.occupation"
        :debounce="0"
        @validate="validateField"
      >
        <LitInput
          name="job"
          class="mb-30"
          dusk="employment"
          placeholder="Např. Vedoucí provozu"
          :tooltip="tooltips.occupation"
          :label="labels.occupation"
          :model-value="value"
          :errors="errors"
          @update:model-value="input"
          @blur="validate($event.target.value)"
        />
      </ServerValidatedFormField>

      <div v-show="isChild">
        <h3>Údaje zákonného zástupce (vaše údaje)</h3>

        <ServerValidatedFormField
          v-slot="{ value, input, errors }"
          namespace="onlineAgreement"
          field-path="legalRepresentative.isPoliticallyExposed"
          :validate-on-input="true"
          @validate="validateField"
        >
          <LitRadio
            tooltip="Politicky exponovaná osoba je osoba ve významné veřejné funkci
            nebo fyzická osoba s takovou osobou příbuzná či v partnerském vztahu."
            dusk="politically-exposed"
            name="legalRepresentativePoliticallyExposedPerson"
            label="Je zástupce politicky exponovanou osobou?"
            :model-value="value"
            :error="errors.length > 0 ? errors[0] : ''"
            :show-inline="true"
            :options="childPoliticallyExposedOptions"
            @update:model-value="input"
          />
        </ServerValidatedFormField>
      </div>
    </div>

    <div class="container mt-30">
      <hr>
    </div>

    <div class="container--sm flex flex-between">
      <router-link
        :to="{ name: 'onlineAgreement.personalData' }"
        class="btn btn-big btn-icon-back btn-outline"
      >
        <LitIconSvg
          class="btn__icon btn__icon--big"
          icon-name="arrow_green-left"
        />

        <span>
          Zpět
        </span>
      </router-link>

      <button
        class="btn btn-green btn-big btn-icon-right"
        dusk="submit-step"
        @click="submitStep"
      >
        <span>
          Pokračovat
        </span>

        <LitIconSvg
          class="btn__icon btn__icon--end btn__icon--end--big"
          icon-name="arrow_white-right"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { StateTree } from 'pinia';
import { get, set } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LitIconSvg from '@/js/components/Base/LitIconSvg.vue';
import LitInput from '@/js/components/Base/LitInput.vue';
import LitRadio from '@/js/components/Base/LitRadio.vue';
import LitSelect from '@/js/components/Base/LitSelect.vue';

import OnlineAgreementStepper from '@/js/components/Steppers/OnlineAgreementStepper.vue';
import incomeFromOptions from '@/js/data/incomeFromOptions';
import { legalRequirementsFields } from '@/js/data/validationFields/commonFields';
import gtm from '@/js/services/gtm';
import { useOnlineAgreementStore } from '@/js/stores';

import { measureAdobeAnalytics } from '@/js/stores/utils';

import { scrollToError, traverseObject } from '@/js/utils';

const store = useOnlineAgreementStore();
const { isChild, legalRequirements, isOnlineJps } = storeToRefs(store);
const { validateFields, validateField } = store;

const route = useRoute();
const router = useRouter();

const payTaxesInCZOptions = shallowRef([
  { value: true, label: 'Ano' },
  { value: false, label: 'Ne' },
]);

const politicallyExposedPersonOptions = shallowRef([
  { value: false, label: 'Ne, nejsem' },
  { value: true, label: 'Ano, jsem' },
]);

const childPoliticallyExposedOptions = shallowRef([
  { value: false, label: 'Ne, není' },
  { value: true, label: 'Ano, je' },
]);

const labels = computed(() => {
  if (isChild.value) {
    return {
      paysTaxesInCzechia: 'Odvádí daně v České republice?',
      isPoliticallyExposed: 'Je politicky exponovanou osobou?',
      sourceOfIncome: 'Z čeho pocházejí jeho/její příjmy?',
      occupation: 'Jaké je jeho/její povolání?',
    };
  }

  return {
    paysTaxesInCzechia: 'Odvádíte daně v České republice?',
    isPoliticallyExposed: 'Jste politicky exponovanou osobou?',
    sourceOfIncome: 'Z čeho pocházejí vaše příjmy?',
    occupation: 'Jaké je vaše povolání?',
  };
});

const tooltips = computed(() => {
  if (isChild.value) {
    return {
      paysTaxesInCzechia: 'Možnost Ne označte pouze v případě, že alespoň jeden ze svých příjmů daníte v zahraničí. '
        + 'V případě, že žádný zdanitelný příjem nemáte, protože jste např. student nebo jste v důchodu, '
        + 'jste stále daňovým rezidentem ČR. Zvolte tedy možnost Ano.',
      isPoliticallyExposed: 'Politicky exponovaná osoba je osoba ve významné veřejné funkci nebo fyzická osoba '
        + 's takovou osobou příbuzná či v partnerském vztahu.',
      sourceOfIncome: 'Vyberte zdroj příjmů, ze kterého budete dítěti přispívat na jeho penzijní spoření.',
      occupation: 'Uveďte povolání osoby, která bude dítěti přispívat na penzijní spoření.',
    };
  }

  return {
    paysTaxesInCzechia: 'Možnost Ne označte pouze v případě, že alespoň jeden ze svých příjmů daníte v zahraničí. '
      + 'V případě, že žádný zdanitelný příjem nemáte, protože jste např. student nebo jste v důchodu, '
      + 'jste stále daňovým rezidentem ČR. Zvolte tedy možnost Ano.',
    isPoliticallyExposed: 'Politicky exponovaná osoba je osoba ve významné veřejné funkci nebo fyzická osoba s takovou osobou příbuzná či v partnerském vztahu. ',
    sourceOfIncome: 'Vyberte zdroj příjmu, ze kterého si budete přispívat na své penzijní spoření.',
    occupation: 'Uveďte prosím co nejpřesněji svou profesi (např. lékař, podnikající v oblasti zemědělství, jednatel ve společnosti aj.).',
  };
});

const displayOccupationField = computed(() => {
  const sourceOfIncome = legalRequirements.value.sourceOfIncome.value;
  return sourceOfIncome === 'ZAM' || sourceOfIncome === 'POD';
});

function loadBankIdFields (state: StateTree) {
  traverseObject(state, ({ nodeKey, parentPath }) => {
    if (nodeKey === '__storeField') {
      const bankId = get(state, `${parentPath}.bankIdReceivable`);
      const val = get(state, `${parentPath}.value`);

      if (route.query.bankId === 'success' && bankId === true && val !== null) {
        set(state, `${parentPath}.readOnly`, true);
      }
    }
  });
}

async function submitStep () {
  const { contractUuid } = route.params;

  try {
    await validateFields({
      contractUuid,
      throwOnErrors: true,
      fieldPaths: [
        ...legalRequirementsFields,
        'legalRepresentative.isPoliticallyExposed',
        'contractSettings.transferExistingRetirementSavings',
      ],
    });

    gtm.onStepSubmit('zakonne-pozadavky');

    if (!isOnlineJps.value) {
      measureAdobeAnalytics({
        state: store.$state,
        action: 'ufSubmit',
        contractUuid,
        path: route.path,
        fields: [
          { storePath: 'contactInformation.firstName.value', fieldName: 'firstname' },
          { storePath: 'contactInformation.lastName.value', fieldName: 'lastname' },
          { storePath: 'contactInformation.phoneNumber.value', fieldName: 'phone' },
          { storePath: 'contactInformation.email.value', fieldName: 'email' },
          { storePath: 'personalData.personalIdNumber.value', fieldName: 'personalid1' },
          { fetcher: () => true, fieldName: 'marketingagreement' },
        ],
      });
    }

    await router.push({
      name: 'onlineAgreement.contributionAndStrategy',
      params: {
        contractUuid,
      },
    });
  } catch (e: any) {
    if (e?.response.status === 401 && e?.response && e?.response.data && e?.response.data.message && e?.response.data.message.includes('Bank iD validation Error')) {
      await router.push({
        name: 'error',
      });
    }

    scrollToError();

    console.warn(`There was a validation ContributionAndStrategyError: ${e}`);
  }
}

watch(displayOccupationField, (newVal) => {
  if (!newVal) {
    legalRequirements.value.occupation.value = '';
  }
});

onMounted(() => {
  const { contractUuid } = route.params;

  measureAdobeAnalytics({
    state: store.$state,
    action: 'formStepView',
    contractUuid,
    path: route.path,
  });

  loadBankIdFields(store.$state);
});
</script>
