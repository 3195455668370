<template>
  <canvas
    ref="lineChart"
    :width="width"
    :height="height"
  />
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'Chart',

  props: {
    width: {
      type: Number,
      default: 0,
      validator: (value) => value > 0,
    },

    height: {
      type: Number,
      default: 0,
      validator: (value) => value > 0,
    },

    labels: {
      type: Array,
      default: null,
    },

    datasets: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    chart: null,
  }),

  watch: {
    datasets (newVal) {
      this.chart.data.datasets = newVal;
      this.chart.update();
    },

    labels (newVal) {
      this.chart.data.labels = newVal;
      this.chart.update();
    },
  },

  mounted () {
    this.chart = new Chart(this.$refs.lineChart, {
      type: 'line',

      data: {
        labels: this.labels,
        datasets: this.datasets,
      },

      options: Object.assign(this.options, {
        tooltips: {
          enabled: false,
          mode: 'index',
          position: 'nearest',
          intersect: false,
          custom: this.customTooltips,
          callbacks: {
            title: (items, data) => data.datasets[items[0].datasetIndex]
              .data[items[0].index]
              .title,
          },
        },

        hover: {
          intersect: false,
          pointHoverBackgroundColor: 'red',
        },
      }),
    });
  },

  deactivated () {
    if (this.chart) {
      this.chart.destroy();
    }
  },

  methods: {
    customTooltips (tooltip) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        this.chart.canvas.parentNode.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');

      if (tooltip.yAlign) {
        tooltipEl.classList.add(tooltip.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      function getBody (bodyItem) {
        return bodyItem.lines;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(getBody);
        let innerHtml = '<thead>';

        // tooltip title
        innerHtml += `
            <tr><td colspan="2">
              ${titleLines.join(' - ')}
            </td></tr>`;

        innerHtml += '</thead><tbody>';
        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];
          let style = `background:${colors.borderColor}`;
          const bodyText = body[0].split(':');

          style += `; border-color:${colors.borderColor}`;
          style += '; border-width: 2px';
          const span = `<span class="chartjs-tooltip-key" style="${style}"></span>`;
          innerHtml += `
            <tr>
              <td>
                <span class="chartjs-tooltip-wrapper">
                  ${span + bodyText[0]}:
                </span>
              </td>
              <td>
                ${new Intl.NumberFormat(
                  'cs-CZ',
                  { style: 'currency', currency: 'CZK', maximumSignificantDigits: 10 },
                )
                  .format(bodyText[1])}
            </td>
            </tr>
          `;
        });
        innerHtml += '</tbody>';
        const tableRoot = tooltipEl.querySelector('table');

        tableRoot.innerHTML = innerHtml;
      }

      // Display, position, and set styles for font
      const { offsetLeft: positionX, offsetTop: positionY } = this.chart.canvas;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';

      if (window.innerWidth < tooltip.caretX + 260) {
        tooltipEl.style.right = `${15}px`;
        tooltipEl.style.left = 'auto';
      } else {
        tooltipEl.style.left = `${positionX + tooltip.caretX + 5}px`;
        tooltipEl.style.right = 'auto';
      }

      tooltipEl.style.top = `${positionY + tooltip.caretY + 5}px`;
      tooltipEl.style.pointerEvents = 'none';
    },
  },
};
</script>

<style lang="scss">
@import '@sass/tools/mixins';
@import '@sass/tools/variables';

#chartjs-tooltip {
  background: white;
  border: 1px solid getColor(blue);
  border-radius: 7px;
  min-width: 240px;
  overflow: hidden;
  z-index: 3;

  .chartjs-tooltip-key {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
  }

  .chartjs-tooltip-wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    tbody {
      display: block;

      &::before,
      &::after {
        content: '';
        display: block;
        height: 10px;
      }
    }

    td, th {
      padding: 5px 10px;
    }

    thead {
      background: getColor(light-blue);
      display: block;

      td {
        font-weight: $bold;
        text-align: left;
        padding: 10px;
      }
    }

    tbody td {
      &:last-child {
        font-weight: $bold;
      }
    }
  }
}
</style>
