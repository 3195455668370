<template>
  <div
    dusk="login-form"
    class="container--sm"
  >
    <h2>Přihlášení</h2>
    <LitInput
      v-model="email"
      class="mb-20"
      dusk="login-email"
      name="email"
      label="E-mail"
      placeholder="E-mail"
    />
    <LitInput
      v-model="password"
      class-name="mb-20"
      dusk="login-password"
      name="password"
      label="Heslo"
      type="password"
    />

    <LitButton @click="login">
      <span>Přihlásit</span>
    </LitButton>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { login as apiLogin } from '../api/login.ts';
import LitButton from '../components/Base/LitButton.vue';
import LitInput from '@/js/components/Base/LitInput.vue';

const router = useRouter();

const email = ref<string>('');
const password = ref<string>('');

function login () {
  apiLogin(email.value, password.value)
    .catch(() => {
      router.push({ path: '/chyba' });
    })
    .then((response) => {
      window.location.href = response.redirectTo;
    });
}
</script>

<style lang="scss">
@import '@sass/tools/mixins';
@import '@sass/tools/variables';
@import '@sass/tools/functions';
</style>
