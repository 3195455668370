<template>
  <div>
    <Stepper
      :steps
      :current-step="2"
    />
  </div>
</template>

<script setup lang="ts">
import { shallowRef } from 'vue';
import Stepper from './Stepper.vue';

interface Props {
  currentStep: keyof typeof steps.value
}

defineProps<Props>();

const steps = shallowRef([
  {
    stepNumber: 1,
    title: 'Podpis smlouvy k novému DPS',
    uri: { name: 'jps.litSignSignature' },
    disabled: true,
  },
  {
    stepNumber: 2,
    title: 'Podpis výpovědi s převodem k ČSOB PS',
    uri: { name: 'jps.bankIdSignature' },
    disabled: true,
  },
]);
</script>
