import axios from './axios';

export function getUri (contractUuid, authBank = null) {
  return axios.get(`/api/v1/redirect-contract/${contractUuid}`, {
    params: { authBank },
  });
}

export default {
  getUri,
};
