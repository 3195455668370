import * as Transfer from '../../views/distribution/transfer';

const transfer = {
  path: '/prevod-smlouvy/:contractUuid',
  component: Transfer.Layout,
  children: [
    {
      path: 'nez-zacneme',
      name: 'transfer.beforeStart',
      component: Transfer.BeforeStart,
    },
    {
      path: 'kontaktni-udaje',
      name: 'transfer.contactInformation',
      component: Transfer.ContactInformation,
    },
    {
      path: 'zadani-osobnich-udaju',
      name: 'transfer.personalData',
      component: Transfer.PersonalData,
    },
    {
      path: 'doplnujici-udaje',
      name: 'transfer.additionalData',
      component: Transfer.AdditionalData,
    },
    {
      path: 'prodejni-rozhovor',
      name: 'transfer.salesInterview',
      component: Transfer.SalesInterview,
    },
    {
      path: 'rekapitulace',
      name: 'transfer.recapitulation',
      component: Transfer.Recapitulation,
    },
    {
      path: 'zadost-o-prevod-dokoncena',
      name: 'transfer.transferConfirmation',
      component: Transfer.TransferConfirmationPage,
    },
    {
      path: 'udaje-o-nove-smlouve',
      name: 'transfer.newContract',
      component: Transfer.NewContract,
    },
  ],
};

export default transfer;
