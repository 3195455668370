import * as OnlineAgreement from '../../views/online/newContract';

const online = {
  path: '/online/:contractUuid',
  component: OnlineAgreement.Layout,
  children: [
    {
      path: 'kontaktni-udaje',
      name: 'onlineAgreement.contactInformation',
      component: OnlineAgreement.ContactInformation,
    },
    {
      path: 'volba-zadani-osobnich-udaju',
      name: 'onlineAgreement.personalDataEntryMethodSelection',
      component: OnlineAgreement.PersonalDataEntryMethodSelection,
    },
    {
      path: 'scan-dokladu-prechod-na-mobil',
      name: 'onlineAgreement.personalDataPhoneRedirect',
      component: OnlineAgreement.PersonalDataPhoneRedirect,
    },
    {
      path: 'scan-dokladu',
      name: 'onlineAgreement.personalDataDocumentsScan',
      component: OnlineAgreement.PersonalDataDocumentsScan,
    },
    {
      path: 'scan-dokladu-prechod-na-pc',
      name: 'onlineAgreement.personalDataComputerRedirect',
      component: OnlineAgreement.PersonalDataComputerRedirect,
    },
    {
      path: 'upload-dokladu',
      name: 'onlineAgreement.documentsUpload',
      component: OnlineAgreement.PersonalDataDocumentsUpload,
    },
    {
      path: 'overeni',
      name: 'onlineAgreement.bankList',
      component: OnlineAgreement.BankList,
    },
    {
      path: 'zadani-osobnich-udaju',
      name: 'onlineAgreement.personalData',
      component: OnlineAgreement.PersonalData,
    },
    {
      path: 'zakonne-pozadavky',
      name: 'onlineAgreement.legalRequirements',
      component: OnlineAgreement.LegalRequirements,
    },
    {
      path: 'vyse-prispevku-a-strategie',
      name: 'onlineAgreement.contributionAndStrategy',
      component: OnlineAgreement.ContributionAndStrategy,
    },
    {
      path: 'smlouva-nastaveni-parametru',
      name: 'onlineAgreement.contractSettings',
      component: OnlineAgreement.ContractSettings,
    },
    {
      path: 'smlouva-dokumenty-a-souhlasy',
      name: 'onlineAgreement.contractDocumentsAndConsents',
      component: OnlineAgreement.ContractDocumentsAndConsents,
    },
    {
      path: 'nahled-smlouvy',
      name: 'onlineAgreement.contractPreview',
      component: OnlineAgreement.ContractPreview,
    },
    {
      path: 'smlouva-sms-podpis',
      name: 'onlineAgreement.contractSmsSignature',
      component: OnlineAgreement.ContractSmsSignature,
    },
    {
      path: 'dekujeme',
      name: 'onlineAgreement.thankYouPage',
      component: OnlineAgreement.ThankYouPage,
    },
  ],
};

export default online;
