import axios from './axios';

export function show (contractUuid) {
  return axios.get(`/api/v1/contracts/${contractUuid}/bank-metadata`);
}

export function create (contractUuid, authBank, process) {
  return axios.post(`/api/v1/contracts/${contractUuid}/bank-metadata`, {
    authBank,
    process,
  });
}

export function update (contractUuid, status, action) {
  return axios.patch(`/api/v1/contracts/${contractUuid}/bank-metadata`, {
    status,
    action,
  });
}

export default {
  show,
  create,
  update,
};
