<template>
  <div>
    <div class="row funds">
      <div class="col-12 col-md-8">
        <LitSelect
          v-model="selectedPeriod"
          class="mb-20"
          :options="periodOptions"
          label="Za období:"
          return-object
        >
          <template #selected-option="option">
            {{ option.label }}
          </template>
          <template #select-option="option">
            <div class="bank-code__item">
              <span>{{ option.label }}</span>
            </div>
          </template>
        </LitSelect>

        <LineChart
          :width="470"
          :height="320"
          :datasets="displayedDatasets"
          :options="options"
        />
      </div>

      <div class="col-12 col-md-4">
        <div
          class="funds__sticker"
          :class="fundType"
        >
          <div>
            <div class="funds__sticker__title">
              {{ datasets[fundType]?.label }}
            </div>

            <div class="funds__sticker__description">
              zhodnocení
              <span v-if="selectedPeriod && selectedPeriod.value.year !== null">
                za rok&nbsp;{{ selectedPeriod.label }}
              </span>
              <span v-else>
                {{ selectedPeriod ? selectedPeriod.label : 'od vzniku fondu' }}
              </span>
            </div>
          </div>
          <div
            v-if="datasets[fundType]?.evaluation !== null && datasets[fundType]?.evaluation !== undefined"
            class="funds__sticker__percent"
          >
            {{ formatNumberToCs(datasets[fundType].evaluation) }}&nbsp;%
          </div>
        </div>

        <div class="funds__title">
          <img
            :src="getIconUrl('icon-graph-selector', 'png')"
            alt="Porovnat s jinými fondy"
          >
          Porovnat s jinými fondy
        </div>

        <label
          v-for="fund in availableFunds"
          :key="fund.type"
          :for="fund.type"
          class="funds__types"
          :class="fund.selected ? 'selected' : true"
        >
          <span class="funds__input">
            <input
              :id="fund.type"
              v-model="selectedFunds"
              type="checkbox"
              :value="fund.type"
              :disabled="fund.type === fundType"
            >

            <span class="funds__control">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                width="16"
                height="16"
              >
                <path
                  fill="#61A30F"
                  d="M4.5,0.5 L4.499,5.499 L14.5,5.5 L14.5,9.5 L4.499,
              9.499 L4.5,9.5 L0.5,9.5 L0.5,0.5 L4.5,0.5 Z"
                  transform="rotate(-45 15.51 2.2)"
                />
              </svg>
            </span>
          </span>

          <span class="mr-12">
            {{ fund.label }}
          </span>

          <span
            v-if="fund.evaluation !== null && fund.evaluation !== undefined"
            class="funds__types__sticker"
          >
            {{ formatNumberToCs(fund.evaluation) }}&nbsp;%
          </span>

          <span class="funds__types--glow" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { eachYearOfInterval, format, startOfYear, subYears } from 'date-fns';

import { getFundData } from '../api/funds';
import { getIconUrl } from '../utils';

import LitSelect from './Base/LitSelect.vue';
import LineChart from './LineChart.vue';

const DATASETS_OPTIONS = {
  backgroundColor: 'rgba(0,0,0,0)',
  borderWidth: 2,
  pointRadius: 0,
  lineTension: 0,
  evaluation: null,
  data: [],
};

const FUND_DATASETS = {
  balanced: {
    label: 'Vyvážený fond',
    borderColor: '#092f68',
    pointBackgroundColor: '#092f68',
    ...DATASETS_OPTIONS,
  },
  pension: {
    label: 'Fond pro penzi',
    borderColor: '#782f87',
    pointBackgroundColor: '#782f87',
    ...DATASETS_OPTIONS,
  },
  conservative: {
    label: 'Konzervativní fond',
    borderColor: '#C50101',
    pointBackgroundColor: '#C50101',
    ...DATASETS_OPTIONS,
  },
  dynamic: {
    label: 'Dynamický fond',
    borderColor: '#ee602f',
    pointBackgroundColor: '#ee602f',
    ...DATASETS_OPTIONS,
  },
  esg: {
    label: 'Zodpovědný dynamický fond',
    borderColor: '#78c31c',
    pointBackgroundColor: '#78c31c',
    ...DATASETS_OPTIONS,
  },
};

const GRAPH_OPTIONS = {
  scales: {
    xAxes: [{
      type: 'time',
      time: {
        displayFormats: {
          month: 'MM. YY',
        },
      },
      gridLines: {
        display: false,
      },
    }],

    yAxes: [{
      ticks: {
        callback: (label) => label.toLocaleString('cs-CZ'),
      },
    }],
  },

  legend: {
    display: false,
  },

  responsive: true,
};

export default {
  components: {
    LineChart,
    LitSelect,
  },

  props: {
    fundType: {
      type: String,
      default: 'balanced',
      validator (val) {
        return [
          'balanced',
          'pension',
          'conservative',
          'dynamic',
          'esg',
        ].includes(String(val));
      },
    },
  },

  setup () {
    return {
      getIconUrl,
    };
  },

  data: () => ({
    selectedFunds: [],
    displayedFunds: [],
    selectedPeriod: null,
    datasets: FUND_DATASETS,
    options: GRAPH_OPTIONS,
  }),

  computed: {
    availableFunds () {
      return [
        'balanced',
        'pension',
        'conservative',
        'dynamic',
        'esg',
      ]
        .filter((fund) => this.fundType !== fund)
        .map((fund) => ({
          type: fund,
          label: this.datasets[fund].label,
          evaluation: this.datasets[fund].evaluation,
          selected: this.selectedFunds.includes(fund),
        }));
    },

    displayedDatasets () {
      return this.displayedFunds.map((fund) => this.datasets[fund]);
    },

    fundsData () {
      const data = {};

      Object.entries(this.datasets).forEach(([fundName, value]) => {
        data[fundName] = {
          ...value,
          selected: this.selectedFunds.includes(fundName),
        };
      });

      return data;
    },

    periodOptions () {
      const today = new Date();
      const dateFormat = 'yyyy-MM-dd';

      const rangeOfYears = eachYearOfInterval({
        start: new Date(2013, 1, 1),
        end: subYears(today, 1),
      });

      const periodOfYears = rangeOfYears.map((year) => ({
        label: format(year, 'yyyy'),
        value: {
          year: format(year, 'yyyy'),
          from: null,
          to: null,
        },
      }));

      return [
        {
          label: 'Od vzniku fondu',
          value: {
            from: null,
            to: null,
            year: null,
          },
        },
        ...periodOfYears,
        {
          label: 'Od začátku roku',
          value: {
            from: format(startOfYear(today), dateFormat),
            to: format(today, dateFormat),
            year: null,
          },
        },
        {
          label: 'Za posledních 12 měsíců',
          value: {
            from: format(subYears(today, 1), dateFormat),
            to: format(today, dateFormat),
            year: null,
          },
        },
      ];
    },
  },

  watch: {
    selectedPeriod (newVal) {
      if (newVal) {
        this.loadFundsData(this.selectedFunds);
      }
    },

    async selectedFunds (newVal, oldVal) {
      if (newVal) {
        const addedFunds = newVal.filter((fund) => !oldVal.includes(fund));

        await this.loadFundsData(addedFunds);

        this.displayedFunds = newVal;
      }
    },
  },

  created () {
    this.selectedFunds = [this.$props.fundType];
  },

  methods: {
    async loadFundsData (funds) {
      if (!this.selectedPeriod) {
        console.warn('No period selected, skipping');
        return;
      }

      const promises = [];

      funds.forEach((fund) => {
        promises.push(getFundData(
          fund,
          this.selectedPeriod.value.from,
          this.selectedPeriod.value.to,
          this.selectedPeriod.value.year,
        ));
      });

      try {
        const responses = await Promise.all(promises);
        const newDataSets = {};

        funds.forEach((fund, index) => {
          const { _evaluation, _data, ...fundOld } = this.datasets[fund];

          newDataSets[fund] = {
            ...fundOld,
            evaluation: responses[index].evaluation,
            data: responses[index].data?.map((item) => ({
              x: item.date_to,
              y: item.price,
              title: [
                format(new Date(item.date_from), 'dd. MM. yyyy'),
                format(new Date(item.date_to), 'dd. MM. yyyy'),
              ],
            })),
          };
        });

        Object.entries(this.datasets).forEach(([fund, dataset]) => {
          if (!(fund in newDataSets)) {
            newDataSets[fund] = dataset;
          }
        });

        this.datasets = newDataSets;
      } catch (e) {
        console.error(e);
      }
    },

    loadFundData (fundType) {
      return this.loadFundsData([fundType]);
    },

    formatNumberToCs (value) {
      return value?.toLocaleString('cs-CZ');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@sass/tools/mixins';
@import '@sass/tools/variables';

$control-bg: #d8e8c5;

.funds {
  &__types {
    padding: 12px 14px 12px 18px;
    display: flex;
    align-items: center;
    background: getColor(white);
    margin-bottom: 10px;
    border: 1px solid getColor(lighter-blue);
    border-radius: 3px;
    position: relative;
    min-height: 52px;
    z-index: 1;
    cursor: pointer;

    &:hover,
    &.selected {
      .funds__types--glow {
        opacity: 1;
      }
    }

    &.selected {
      .funds__types__sticker {
        display: block;
      }
    }

    &--glow {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      box-shadow: 0 4px 9px rgb(190 204 208 / 75%);
      z-index: -1;
      opacity: 0;
      transition: opacity .2s ease-in-out;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      border-radius: 3px 0 0 3px;
    }

    &[for='balanced'] {
      &::before,
      .funds__types__sticker {
        background: getColor(fund-balanced, 1);
      }
    }

    &[for='pension'] {
      &::before,
      .funds__types__sticker {
        background: getColor(fund-pension, 1);
      }
    }

    &[for='conservative'] {
      &::before,
      .funds__types__sticker {
        background: getColor(fund-conservative, 1);
      }
    }

    &[for='dynamic'] {
      &::before,
      .funds__types__sticker {
        background: getColor(fund-dynamic, 1);
      }
    }

    &[for='guaranteed'] {
      &::before,
      .funds__types__sticker {
        background: getColor(fund-guaranteed, 1);
      }
    }

    &[for='esg'] {
      &::before,
      .funds__types__sticker {
        background: getColor(fund-esg, 1);
      }
    }

    &__sticker {
      margin-left: auto;
      font-size: 13px;
      padding: 3px 10px;
      color: getColor(white);
      font-weight: $bold;
      border-radius: 3px;
      display: none;
      white-space: nowrap;
    }
  }

  &__title {
    font-size: $root;
    font-weight: $bold;
    color: getColor(default);
    margin-bottom: 10px;

    img {
      margin-right: 5px;
    }
  }

  &__control {
    display: inline-grid;
    width: rem(16);
    height: rem(16);
    border-radius: rem(2);
    border: 1px solid transparent;
    background: $control-bg;
    margin-right: 10px;

    svg {
      transition: transform .1s ease-in 25ms;
      transform: scale(0);
    }
  }

  &__input {
    flex-shrink: 0;
    display: grid;
    grid-template-areas: 'checkbox';
    color: getColor(green);

    > * {
      grid-area: checkbox;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:focus + .funds__control {
        box-shadow: 0 0 0 .05em #fff, 0 0 .15em .1em currentColor;
      }

      &:checked + .funds__control {
        background: getColor(white);
        border-color: $control-bg;

        svg {
          transform: scale(1);
        }
      }

      &:disabled + .funds__control {
        color: getColor(disabled);
      }
    }
  }

  &__sticker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 3px;
    margin-bottom: 30px;
    margin-top: 30px;

    @include media(min, $md) {
      margin-top: 0;
    }

    &__title {
      font-size: $root;
      font-weight: $bold;
      margin-bottom: 5px;
    }

    &__description {
      color: getColor(black);
      text-transform: lowercase;
      font-size: 13px;
    }

    &__percent {
      font-size: 24px;
      font-weight: $bold;
      color: getColor(white);
      border-radius: 3px;
      margin-left: 10px;
      padding: 6px 10px;
      white-space: nowrap;
    }

    &.balanced {
      background: getColor(fund-balanced, .1);

      .funds__sticker__title {
        color: getColor(fund-balanced, 1);
      }

      .funds__sticker__percent {
        background: getColor(fund-balanced, 1);
      }
    }

    &.guaranteed {
      background: getColor(fund-guaranteed, .15);

      .funds__sticker__title {
        color: getColor(fund-guaranteed, 1);
      }

      .funds__sticker__percent {
        background: getColor(fund-guaranteed, 1);
      }
    }

    &.pension {
      background: getColor(fund-pension, .12);

      .funds__sticker__title {
        color: getColor(fund-pension, 1);
      }

      .funds__sticker__percent {
        background: getColor(fund-pension, 1);
      }
    }

    &.conservative {
      background: getColor(fund-conservative, .15);

      .funds__sticker__title {
        color: getColor(fund-conservative, 1);
      }

      .funds__sticker__percent {
        background: getColor(fund-conservative, 1);
      }
    }

    &.dynamic {
      background: getColor(fund-dynamic, .15);

      .funds__sticker__title {
        color: getColor(fund-dynamic, 1);
      }

      .funds__sticker__percent {
        background: getColor(fund-dynamic, 1);
      }
    }

    &.esg {
      background: getColor(fund-esg, .15);

      .funds__sticker__title {
        color: getColor(fund-esg, 1);
      }

      .funds__sticker__percent {
        background: getColor(fund-esg, 1);
      }
    }
  }

  canvas {
    max-width: 100%;
  }
}
</style>
