<template>
  <div>
    <LitSelect
      class="prefix"
      name="phonePrefix"
      :label="label"
      :model-value="modelValue"
      :error="error"
      :errors="errors"
      :options="phonePrefixes"
      :searchable="searchable"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { shallowRef } from 'vue';

import phonePrefixOptions from '../data/phonePrefixes';
import LitSelect from './Base/LitSelect.vue';

defineProps({
  label: {
    type: String,
    default: '',
  },

  error: {
    type: String,
    default: '',
  },

  errors: {
    type: Array,
    required: false,
    default: () => ([]),
  },

  modelValue: {
    type: [Object, String, Number],
    default: null,
  },

  searchable: {
    type: Boolean,
    default: false,
  },
});

defineEmits([
  'update:modelValue',
]);

const phonePrefixes = shallowRef(phonePrefixOptions);
</script>

<style lang="scss" scoped>
@import '@sass/tools/variables';
@import '@sass/tools/mixins';

.prefix {
  width: 303px;
  margin-right: 5px;

  @include media(max, $sm) {
    width: auto;
    margin-right: 0;
    margin-bottom: 5px;
  }
}
</style>
