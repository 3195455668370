import env from '../env';
import axios from './axios';

async function create (contractUuid, file, type = '') {
  const formData = new FormData();

  formData.append('document', file);
  formData.append('document_type', type);

  const { data } = await axios.post(`/api/v1/contracts/${contractUuid}/files`, formData);

  return data;
}

async function index (contractId, meetingRecordExpected = false) {
  const { data } = await axios.get(`${env.REST_API_URI}/v1/files`, {
    params: {
      contractId,
      meetingRecordRequired: meetingRecordExpected,
    },
    withCredentials: false,
  });

  return data;
}

async function get (uuid, id) {
  const { data } = await axios.get(`/api/v1/contracts/${uuid}/files/${id}?width=170&height=113&bestfit=1`);

  return data;
}

export default {
  create,
  index,
  get,
};
